
<template>
  <!--  日清周清：制版质量-->
  <div>
    <div class="section">
      <condition :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :conditionFrom="conditionFrom" @search="search"></condition>
    </div>

    <div v-if="showDisplay" style="padding: 0 0 0 20px">
      <div style="margin-bottom: 20px;font-size: 18px;font-weight: bold">{{ $t('trend_plate_quality') }}</div>
      <div class="table-box content" style="width: 100%;">
        <div v-for="(item, index) in echartList" :key="index">
          <div style="font-size: 16px;font-weight: bold">{{ item.name }}</div>
          <LineChart2
            v-if="item.list.source.length > 1"
            :id="`printChart${index}`"
            :data="item.list"
            :timeList="timeList"
          ></LineChart2>
          <div v-else style="padding: 20px 0;color: #999999">{{ $t('noData') }}</div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import LineChart2 from "@/components/ChartsTime/LineChart2";
import API from '@/api';
import condition from './condition'
export default {
  props: {
    enterpriseList: {
      type: Array,
      default: () => []
    },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  name: "plateMakingQuality.vue",
  components: { LineChart2, condition },
  data() {
    return {
      time: '',
      filter: {
        startTime: undefined,
        endTime: undefined,
        teamId: ''
      },
      teamId: '',
      options: {
        status: [],
      },
      timeList: [],
      showDisplay: false,
      pickerOptions: {
        disabledDate: time => {
          if (this.filter.endTime) {
            return (
              time.getTime() > new Date(this.filter.endTime).getTime() || time.getTime() < new Date(this.filter.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.filter.startTime) {
            return (
              time.getTime() < new Date(this.filter.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.filter.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      chartData: {
        // 数据集
        source: [
          ['product', this.$t('average_score')]
        ],
        // 系列
        series: [
          {
            name: "1",
            type: 'line',
            // data: [220, 182, 191, 234, 290, 330, 310]
          },
        ]
      },
      echartList: [],
      historyQuery:{}
    };
  },
  mounted() {

  },
  created() {
  },
  methods: {
    startTimeChange(val){
      if(this.filter.endTime && new Date(val).getTime() > new Date(this.filter.endTime).getTime()){
        this.filter.startTime = null
      }else{
        this.filter.startTime = val
      }
    },
    endTimeChange(val){
      if(this.filter.startTime && new Date(val).getTime() < new Date(this.filter.startTime).getTime()){
        this.filter.endTime = null
      }else{
        this.filter.endTime = val
      }
    },
    getChart() {
      API.getPlateQuality({...this.filter}).then(res => {
        res.message.data.forEach(item => {
          const tempItem = {
            name: item.name,
            list: {
              // 数据集
              source: [
                ['product', item.name,]
              ],
              // 系列
              series: [{
                type: 'line',
              }]
            }
          }
          item.list.forEach(line => {
            this.timeList.push({date:line.date,id:line.index})
            tempItem.list.source.push([
              // line.data,
              line.index,
              Number(line.score),
              line.date,
            ])
          })
          this.echartList.push(tempItem)
        })
      })
    },
    // 查询
    search(condition) {
      if (!this.$store.state.condition.enterprise) {
        console.log(this.$store.state.condition)
        this.$store.commit('setFormDate',condition)
      }
      this.echartList = []
      this.showDisplay = false
      this.filter = {
        ...condition.query,
        teamId:condition.enterprise,
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise
      }
      this.getChart()
      this.showDisplay = true
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--card > .el-tabs__header{
  border-bottom: 0px solid #e4e7ed;
}
.content{
  height: calc(100vh - 290px);
  overflow: auto;
  .chart{
    width: 94%;
    height: 400px;
    .title{
      display: flex;
      justify-content: space-between;
    }

    .echatsImg{
      width: 90%;
      height: 300px;
    }
  }
}
</style>
