<template>
  <!--  日清周清：维修-->
  <div>
    <div class="section">
      <condition :historyQuery = "historyQuery" :device="true" :enterpriseList="enterpriseList" :conditionFrom="conditionFrom" @search="search"></condition>
    </div>

    <div class="content" v-if="showDisplay" style="padding: 0 0 0 20px;">
      <div style="text-align: right;">
        <el-button
            style="margin-right: 20px;text-align: right"
            type="primary"
            @click="handleDetails()"
        >
          {{ $t('checkDetail') }}
        </el-button>
      </div>
      <div class="table-box">
        <div v-for="(item, index) in tableData" :key="index">
          <div style="margin-bottom: 20px;margin-top: 20px">{{ item.name }}</div>
          <el-table
            :data="item.detailList"
            border
            style="width: 100%;"
            height="300"
          >
            <el-table-column
              prop="name"
              :label="$t('maintenance_person')"
              align="left"
            ></el-table-column>
            <el-table-column
              prop="time"
              :label="$t('work_hours')"
              align="center"
            >
              <template slot-scope="{row}">
                <span>{{row.time ? row.time : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="price"
              :label="$t('maintenance_cost_yuan')"
              align="center"
            >
              <template slot-scope="{row}">
                <span>{{row.price ? row.price : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="info"
              :label="$t('maintenance_program')"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="date"
              :label="$t('finish_time')"
              align="center"
            ></el-table-column>
          </el-table>
        </div>

      </div>
    </div>

    <!--分页-->
<!--    <div class="pagination-box">-->
<!--      <el-pagination-->
<!--        v-show="filter.total>0"-->
<!--        :current-page="filter.page"-->
<!--        :page-sizes="[10, 20, 30, 40]"-->
<!--        :page-size="filter.count"-->
<!--        :total="filter.total"-->
<!--        layout="total, sizes, prev, pager, next, jumper"-->
<!--        @size-change="handleSizeChange"-->
<!--        @current-change="handleCurrentChange"-->
<!--      />-->
<!--    </div>-->
  </div>
</template>

<script>

import condition from './condition'
import API from '@/api';
export default {
  name: "maintenance.vue",
  components: { condition },
  props: {
    enterpriseList: {
      type: Array,
      default: () => []
    },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      filter: {
        startTime: undefined,
        endTime: undefined,
        teamId: ''
      },
      teamId: '',
      showDisplay: false,
      options: {
        status: [],
      },
      tableData: [],
      pickerOptions: {
        disabledDate: time => {
          if (this.filter.endTime) {
            return (
              time.getTime() > new Date(this.filter.endTime).getTime() || time.getTime() < new Date(this.filter.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.filter.startTime) {
            return (
              time.getTime() < new Date(this.filter.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.filter.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      historyQuery:{}
    };
  },
  mounted() {

  },
  created() {
    // this.getChart()
  },
  methods: {
    handleDetails(item) {
      this.$router.push({ path: 'maintenanceDetail', query: { ...this.filter, } });
    },
    startTimeChange(val){
      if(this.filter.endTime && new Date(val).getTime() > new Date(this.filter.endTime).getTime()){
        this.filter.startTime = null
      }else{
        this.filter.startTime = val
      }
    },
    endTimeChange(val){
      if(this.filter.startTime && new Date(val).getTime() < new Date(this.filter.startTime).getTime()){
        this.filter.endTime = null
      }else{
        this.filter.endTime = val
      }
    },
    getChart() {
      API.getRepair({...this.filter}).then(res => {
        this.tableData = res.message.data
      })
    },
    // 查询
    search(condition) {
      if (!this.$store.state.condition.enterprise) {
        console.log(this.$store.state.condition)
        this.$store.commit('setFormDate',condition)
      }
      this.showDisplay = false
      this.filter = {
        ...condition.query,
        teamId:condition.enterprise,
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise
      }
      this.getChart()
      this.showDisplay = true
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--card > .el-tabs__header {
  border-bottom: 0px solid #e4e7ed;
}
.content{
  height: calc(100vh - 220px);
  overflow: auto;
  .chart{
    width: 94%;
    height: 400px;
    .title{
      display: flex;
      justify-content: space-between;
    }

    .echatsImg{
      width: 100%;
      height: 300px;
    }
  }
}
.control-box {
  margin-bottom: 0;
}
::v-deep .table-box table thead th .cell{
  color: #606266;
}
</style>
