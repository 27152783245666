
<!--异常提报明细-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">{{ $t('exception_submission') }}</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
            ref="infoForm"
            :model="info"
            label-width="140px"
            label-position="left"
        >
          <el-form-item :label="$t('deviceName')" prop="name">
            <div>{{info.name}}</div>
          </el-form-item>
          <el-form-item :label="$t('edingTime')" prop="" style="max-width: 1000px">
            <div>{{info.ratedTime}}</div>
          </el-form-item>
          <el-form-item :label="$t('startTime')" prop="code">
            <div>{{info.startTime}}</div>
          </el-form-item>
          <el-form-item :label="$t('endTime')" prop="name">
            <div>{{info.endTime}}</div>
          </el-form-item>
          <el-form-item :label="$t('working_hours')" prop="name">
            <div>{{info.workTime}}</div>
          </el-form-item>
          <el-form-item :label="$t('yichangTime')" prop="" style="max-width: 1000px">
            <div>{{info.actualTime}}</div>
          </el-form-item>
          <el-form-item :label="$t('submission_reason')" prop="code">
            <el-select v-model="info.reason" multiple :disabled="info.reasonList ? true : false"  clearable :placeholder="$t('please_select')" @change="handleChange">
              <el-option
                  v-for="item in data"
                  :key="item"
                  :label="item"
                  :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <div v-if="info.reasonList">
            <div v-for="(item,index) in info.reasonList" :key="index">
              <el-form-item :label="$t(item.reason)" v-if="item.reason === '材料原因'" >
                <span>{{item.materialReason}}</span>
              </el-form-item>
              <el-form-item :label="$t(item.reason + item.orderDate)" prop="">
                <el-input-number
                    v-model="item.time"
                    show-word-limit
                    :max="1000000000000"
                    :min="0"
                    disabled
                    :precision="0"
                    :placeholder="$t('enter_working_hours')"
                />
                <span style="margin-left: 10px">{{ $t('min') }}</span>
              </el-form-item>
            </div>
          </div>
<div v-if="!info.reasonList && info.reason">
  <div v-for="(item,index) in ReasonReporting" :key="index">
    <el-form-item :label="$t(item.reason2)" v-if="item.reason2 === '材料原因'" >
      <el-select v-model="item.materialReason" clearable :disabled="info.materialReason2 ? true : false"  :placeholder="$t('please_select')">
        <el-option
            v-for="item in contentMaterialList"
            :key="item"
            :label="item"
            :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t(item.reason2)" prop="">
      <el-input-number
          v-model="item.time"
          show-word-limit
          :max="1000000000000"
          :min="0"
          :precision="0"
          :placeholder="$t('enter_working_hours')"
      />
      <span style="margin-left: 10px">{{ $t('min') }}</span>
    </el-form-item>
  </div>
</div>


          <el-form-item :label="$t('remark')" prop="name">
            <el-input
                :disabled="info.remark2 ? true : false"
                v-model.trim="info.remark"
                maxlength="32"
                clearable
                :placeholder="$t('Please_fill_remarks')"
                size="medium"
            />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submit" v-if="!hide && !info.reasonList" class="submit-btn">
              {{ $t('submit') }}
            </el-button>
            <el-button type="primary" @click="handleSwitch" v-if="info.reasonList && info.nextState === 1" class="submit-btn">
              {{ $t('switch') }}
            </el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api'

export default {
  name: "abnormalReport",
  data() {
    return {
      hide: false,
      isUpdate: true,
      data: [],
      contentMaterialList: [
        this.$t('Printing_Plates'),this.$t('Paper'), this.$t('Ink'), this.$t('Rubber_Rollers'), this.$t('Blanket'), this.$t('Fountain_Solution'), this.$t('Powder'), this.$t('Varnish')
      ],
      info: {
        form: '',
        reason2: '',
        materialReason2: '',
        remark2: '',
        workHours: [],
        reasonList: [],
        reason: []
      },
      form: {
        name: '',
        page: 1,
        count: 100000000,
        teamId: ''
      },
      ReasonReporting: []
    }
  },
  created() {
    this.updateDevice()
  },
  methods: {
    back() {
      this.$router.push({ path: 'PrintHome'})
    },
    updateDevice() {
      API.getErrorDetail(this.$route.query.id).then(res => {
        if (res.message.success) {
          this.info = res.message.data;
          this.info.reason2 = res.message.data.reason;
          this.info.materialReason2 = res.message.data.materialReason;
          this.info.remark2 = res.message.data.remark;
          this.info.workHours = res.message.data.workHours;
          this.info.reasonList = res.message.data.reasonList;
          console.log(this.info.reasonList)
          console.log(this.info.reason)
          this.info.reason = []
          this.info.reasonList.forEach(item => {
            console.log(item.reason)
            if (item.reason === '无单待机') {
              this.info.reason.push(item.reason + item.orderDate)
            } else {
              this.info.reason.push(item.reason)
            }
          })
          console.log(this.info.reason)
        } else {
          this.$message({type:'error', message:res.message.message})
        }
      })
      API.getReportReasonList().then(res => {
        this.data = res.message.data
      })
    },
    valueTime(value) {
      this.ReasonReporting = []
      if (value.includes("材料原因")) {
        // this.content.reason2 = '材料原因'
      }
      for (let i = 0; i< value.length; i++) {
        if (value[i] === '无单待机') {
          console.log(this.info.workHours)
          this.info.workHours.forEach(line => {
            this.ReasonReporting.push({
              reason: `无单待机`, // 动态生成日期
              reason2: `无单待机${line.date}`, // 动态生成日期
              materialReason: null,
              time: undefined,
              date: '', // 将日期从 list 中获取
              minute: line.time,
              orderDate: `${line.date}`
            });
          })
        } else {
          console.log(this.info.workHours.length)
          this.ReasonReporting.push({
            reason: value[i],
            reason2: value[i],
            materialReason: null,
            time:  undefined,
            date: '',
            orderDate: ''
          })
          // this.content.reason2 = null
        }
      }
    },
    handleChange(value) {
      this.ReasonReporting = []
      this.valueTime(value)
    },
    submit() {
      let number = 0
      let sumNumber = 0
      for (const item of this.ReasonReporting) {
        if (item.reason !== '无单待机') {
          if (!item.time || Number(item.time) === 0) {
            this.$message({type:'warning', message:this.$t('reason6')});
            return;
          }
        } else {
          sumNumber += Number(item.time)
          console.log(sumNumber)
        }
        if(item.reason === '材料原因' && !item.materialReason){
          this.$message({type:'warning', message:'请选择材料原因'});
          return;
        }
        if (item.minute) {
          if (Number(item.time) > item.minute) {
            this.$message({type:'warning', message:this.$t('reason4')});
            return;
          }
        }
        number += Number(item.time);
      }
      if (this.ReasonReporting.some(item => item.reason.includes('无单待机'))) {
        if (sumNumber === 0 || !sumNumber) {
          message('warning', '无单待机提报原因总时长不允许为空或等于0');
          return;
        }
      }
      if (number !== Number(this.info.actualTime.match(/(\d+)/)[0])) {
        this.$message({type: 'warning', message:this.$t('reason5')});
        return;
      }
      if (!this.info.reason) {
        this.$message({
          message: this.$t('please_select_submission_reason'),
          type: 'warning',
        })
        return
      }
      // for (let i = 0; i< this.info.reason.length; i++) {
      //   if (this.info.reason[i] === '材料原因' && !this.info.materialReason) {
      //     this.$message({
      //       message: '请选择材料原因',
      //       type: 'warning',
      //     })
      //     return;
      //   }
      // }
      if ((this.info.reason.includes('其他原因') && !this.info.remark) || (this.info.reason.includes('故障抢修') && !this.info.remark) || (this.info.reason.includes('系统待机时间不准') && !this.info.remark)) {
        this.$message({
          message: this.$t('Please_fill_remarks'),
          type: 'warning',
        })
        return
      }
      const obj = {
        id: this.$route.query.id,
        reasonList: this.ReasonReporting,
        remark: this.info.remark
      }
      API.submitErrorReport(obj).then(res => {
        if (res.message.success) {
          this.$message({
            message: this.$t('submit_success'),
            type: 'success',
          })
          this.updateDevice()
        } else {
          this.$message({
            message: response.message.message,
            type: response.message.success ? 'success' : 'error',
          })
        }
      })
    },
    handleSwitch() {
      API.getExcetionSwitch(this.$route.query.id).then(res => {
        if (res.message.success) {
          this.$message({
            message: this.$t('switching_success'),
            type: 'success',
          })
          this.info.remark = ''
          // this.data = {}
          this.$router.push({ path: 'abnormalReportDetail', query: { id: res.message.data.id } });
          API.getErrorDetail(res.message.data.id).then(res => {
            console.log(res)
            if (res.message.success) {
              this.info = res.message.data;
              this.info.reason2 = res.message.data.reason;
              this.info.materialReason2 = res.message.data.materialReason;
              this.info.remark2 = res.message.data.remark;
              this.info.workHours = res.message.data.workHours;
              this.info.reasonList = res.message.data.reasonList;
              this.info.reasonList.forEach(item => {
                console.log(item.reason)
                // this.info.reason.push(item.reason)
              })
              // if (this.info.reason) {
              //   this.info.reason = this.info.reason.split(',')
              // }
            } else {
              this.$message({
                message: res.message.message,
                type: 'error',
              })
            }
          })
        } else {
          this.$message({
            message: this.$t('noData'),
            type: 'error',
          })
          this.$router.go(-1);
        }
      })
    }
  }
}
</script>
<style>
.widthTable {
  width: 1550px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

}
.title div:last-child{
  font-size: 16px;
  font-weight: 400;
}
.el-table .hidden-row {
  display: none;
}
</style>
