<template>
  <div class="page-container">
    <div class="section">
      <!--搜索栏-->
      <div class="control-box">
        <div class="cb-left">
          <el-input
            v-model.trim="TableParams.name"
            placeholder="文档名称"
            style="width: 200px;margin-right:20px"
            clearable
            class="control-item"
            @keyup.enter.native="getTechnicalSpportList"
          />
          <el-button
            class="control-item"
            type="primary"
            icon="el-icon-search"
            @click="getTechnicalSpportList"
          >
            {{ $t('search') }}
          </el-button>
        </div>

<!--        <div class="cb-right">-->
<!--          <el-button-->
<!--            class="control-item"-->
<!--            style="margin-left: 10px;"-->
<!--            type="primary"-->
<!--            icon="el-icon-edit"-->
<!--            @click="ToCreate"-->
<!--          >-->
<!--            添加-->
<!--          </el-button>-->
<!--        </div>-->
      </div>
      <div>
        <div class="table-box">
          <el-table :data="tableData" border fit highlight-current-row style="width: 100%;">
            <el-table-column label="文档名称" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <span>{{ row.title }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('type')" align="center" width="120">
              <template slot-scope="{ row }">
                <span>{{ row.type === 1 ? '富文本' : row.type === 2 ? 'PDF' : '视频' }}</span>
              </template>
            </el-table-column>

            <el-table-column label="上架状态" align="center" width="120">
              <template slot-scope="{ row }">
                <span>{{ row.shelves === '1' ? '已上架' : '未上架' }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('creation_time')" align="center" width="120">
              <template slot-scope="{ row }">
                <span>{{ row.dCreateDate }}</span>
              </template>
            </el-table-column>

            <el-table-column
                :label="$t('caozuo')"
                align="left"
                width="120"
                class-name="small-padding fixed-width"
            >
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click.stop="toCheckInfo(row)">
                  {{ $t('device_view') }}
                </el-button>
                <!--              <el-button-->
                <!--                v-if="row.shelves === '2'"-->
                <!--                type="primary"-->
                <!--                size="mini"-->
                <!--                @click.stop="ToEdit(row)"-->
                <!--              >-->
                <!--                编辑-->
                <!--              </el-button>-->
                <!--              <el-button-->
                <!--                :type="row.shelves === '2' ? 'success' : 'danger'"-->
                <!--                size="mini"-->
                <!--                @click.stop="handleSwitch(row)"-->
                <!--              >-->
                <!--                {{ row.shelves === '2' ? '上架' : '下架' }}-->
                <!--              </el-button>-->
                <!--              <el-button-->
                <!--                v-if="row.shelves === '2'"-->
                <!--                type="danger"-->
                <!--                size="mini"-->
                <!--                @click.stop="handleDelete(row)"-->
                <!--              >-->
                <!--                删除-->
                <!--              </el-button>-->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="TableParams.count"
              :total="totalCount"
              :current-page="TableParams.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
          <!--        <Pagination-->
          <!--          v-show="totalCount > 0"-->
          <!--          :total="totalCount"-->
          <!--          :page.sync="TableParams.page"-->
          <!--          :limit.sync="TableParams.count"-->
          <!--          @pagination="getTechnicalSpportList"-->
          <!--        />-->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import API from '@/api'

export default {
  data() {
    return {
      TableParams: {
        name: '',
        type: '',
        state: '',
        page: 1,
        count: 10,
      },
      searchType: [
        {
          value: 1,
          label: '富文本',
        },
        {
          value: 2,
          label: 'PDF',
        },
        {
          value: 3,
          label: '视频',
        },
      ],
      searchState: [
        {
          value: 1,
          label: '已上架',
        },
        {
          value: 2,
          label: '未上架',
        },
      ],
      tableData: [],
      totalCount: 0,
    };
  },
  created() {
    this.getTechnicalSpportList();
  },
  methods: {
    getTechnicalSpportList() {
      API.technicalSpportList(this.TableParams).then((res) => {
        console.log(res)
        this.tableData = res.message.data.list;
        this.totalCount = res.message.data.totalCount;
      });
    },
    handleSwitch(row) {
      if (row.shelves === '2') {
        this.$confirm('确定要将当前技术支持文档上架吗？', this.$t('prompt'), {
          closeOnClickModal: false,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning',
        }).then((res) => {
          API.update({ state: 1 }, row.id).then((res) => {
            console.log(res);
            this.$message({
              message: '上架成功',
              type: 'success',
            });
            this.getTechnicalSpportList();
          });
        });
      } else if (row.shelves === '1') {
        this.$confirm('确定要将当前技术支持文档下架吗？', this.$t('prompt'), {
          closeOnClickModal: false,
          confirmButtonText: '确定',
          cancelButtonText: this.$t('cancel'),
          type: 'warning',
        }).then((res) => {
          API.update({ state: 2 }, row.id).then((r) => {
            this.$message({
              message: '下架成功',
              type: 'success',
            });
            this.getTechnicalSpportList();
          });
        });
      }
    },
    handleDelete(row) {
      this.$confirm('确定删除当前技术支持文档吗？', this.$t('prompt'), {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      }).then((res) => {
        API.update({ state: 3 }, row.id).then((r) => {
          this.$message({
            message: this.$t('Deleted_successfully'),
            type: 'success',
          });
          if (this.tableData.length === 1 && this.TableParams.page !== 1) {
            this.TableParams.page -= 1;
          }
          this.getTechnicalSpportList();
        });
      });
    },
    ToCreate() {
      const title = '添加文档';
      // titleName(title);
      this.$router.push('TechnicalSupportInfo');
    },
    ToEdit(row) {
      const title = '编辑文档';
      // titleName(title);
      this.$router.push({
        path: 'TechnicalSupportInfo',
        query: { id: row.id },
      });
    },
    toCheckInfo(row) {
      this.$router.push({
        path: 'CheckInfo',
        query: { id: row.id },
      });
    },
    handleSizeChange(val) {
      this.TableParams.count = val;
      this.TableParams.page = 1;
      this.getTechnicalSpportList();
    },
    handleCurrentChange(val) {
      this.TableParams.page = val;
      this.getTechnicalSpportList();
    },
  },
};
</script>

<style></style>
