
<!--维修报告-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">维修报告</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
            ref="infoForm"
            :model="info"
            label-width="120px"
            label-position="left"
            :rules="rules.inviteRules"
        >
          <div class="title">{{ $t('basic_information') }}</div>
          <el-form-item label="维修结果" prop="name">
            <el-select v-model="info.name"  clearable :placeholder="$t('please_select')"" >
              <el-option
                  v-for="(item,index) in optionDrop"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Acceptance_Person')" prop="personId" v-if="info.name === $t('Not_Repaired') || info.name === $t('Partially_Repaired') ">
            <el-select v-model="info.personId"  clearable :placeholder="$t('please_select')" >
              <el-option
                  v-for="(item,index) in personIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="维修用时" prop="" style="max-width: 1000px">
            <el-input
                v-model="info.code"
                :disabled="isUpdate"
                clearable
                placeholder=""
                maxlength="20"
            />
          </el-form-item>
          <el-form-item label="维修工时" prop="date">
            <el-input-number
                style="width:220px"
                v-model.trim="info.date"
                show-word-limit
                :max="1000000000000"
                :min="0"
                :step="0.1"
                :precision="1"
                placeholder="请输入维修工时"
            />  小时
          </el-form-item>
          <el-form-item :label="$t('Maintenance_Summary')" prop="remark" style="max-width: 1000px">
            <el-input
                v-model="info.remark"
                show-word-limit
                clearable
                type="textarea"
                :rows="4"
                placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="图片" prop="" style="max-width: 1000px">
           <div class="pic-and-video-wrapper">
             <div v-for="(img, index) in pics" :key="index" class="preview">
               <el-image
                   style="width: 100%"
                   :src="img"
                   :preview-src-list="pics">
               </el-image>
               <el-button class="van-btton" icon="el-icon-delete-solid" type="text" @click="removeImage(index)"/>
             </div>
             <div class="btn" @click="selectImage" v-show="pics.length < 3">
               <img src="@/assets/img/add-pic.png" alt="add pic"/>
               <span>{{ $t('add_img') }}</span>
               <input v-show="false" ref="imgInput" type="file" accept="image/*" multiple
                      onclick="event.cancelBubble = true" @input="pickImage"/>
             </div>
           </div>
          </el-form-item>
          <el-form-item label="视频" prop="" style="max-width: 1000px">
            <div class="pic-and-video-wrapper">
              <div class="btn" v-if="!!video">
                <span @click="playVideo">{{ $t('click_play') }}</span>
                <div class="closeVideo-btn" @click="removeVideo">
                  <img src="@/assets/img/img_2.png"/>
                </div>
              </div>
              <div class="btn" @click="selectVideo" v-show="!video">
                <img src="@/assets/img/add-video.png" alt="add video"/>
                <span>{{ $t('Add_Video') }}</span>
                <input v-show="false" ref="videoInput" type="file" accept="video/*" multiple
                       onclick="event.cancelBubble = true" @input="pickVideo"/>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">{{ $t('submit') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog :visible.sync="showVideo"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               @close="close2()">
      <video ref="videoShow" style="width:100%;height:100%" controls>
        <source :src="videoSrc" type="video/mp4">。
      </video>
    </el-dialog>
  </div>
</template>
<script>
import API from '@/api'
import {base64toFile} from '@/utils/file';
export default {
  name: "maintenanceReport",
  data() {
    return {
      isUpdate: true,
      info: {
        date: 0
      },
      personIdList:[],
      optionDrop: [
        {name: this.$t('Repaired')},
        {name: this.$t('Not_Repaired')},
        {name: this.$t('Partially_Repaired')},
      ],
      formList: {},
      showVideo: false,
      videoSrc: null,
      show: false,
      pics: [],
      video: null,
      voice: null,
      hide: false,
      previewImage: null,
      loading: false,
      repairId: "",//维修ID
      rules: {
        inviteRules: {
          name: [{required: true, message: '请选择维修结果', trigger: 'change'}],
          date: [{required: true, message: '请输入维修工时', trigger: 'change'}],
          remark: [{required: true, trigger: 'blur', message: '请填写维修小结'}],
          personId: [{required: true, message: '请选择验收人', trigger: 'change'}],
        },
      }
    }
  },
  created() {
    this.info.code = this.$route.query.time
    this.repairId = this.$route.query.repairId
    this.getList()
  },
  methods: {
    getList() {
      API.byRepairId(this.$route.query.repairId).then(res => {
        console.log(res)
        this.personIdList = res.message.data
      })
    },
    back() {
      this.$router.go(-1)
    },
    selectImage() {
      this.$refs.imgInput.click()
    },
    //移除图片
    removeImage(index) {
      console.log("index:" + index);
      this.pics.splice(index, 1);
    },
    close2() {
      this.showVideo = false;
    },
    //点击选择图片
    pickImage() {
      let input = this.$refs.imgInput
      if (input.files.length + this.pics.length > 3) {
        this.$message({
          message: this.$t('most_uploaded_img'),
          // showClose: true,
          type: 'warning',
        });
        return;
      }
      for (let i = 0; i < input.files.length; i++) {
        if(input.files[i]) {
          //在此限制图片的大小
          let imgSize = input.files[i].size;
          //35160  计算机存储数据最为常用的单位是字节(B)
          //在此处我们限制图片大小为2M
          if(imgSize>50*1024*1024){
            this.$message({
              message: this.$t('uploaded_image_large50M_please_reselect'),
              // showClose: true,
              type: 'warning',
            });
            return;
          }
          let reader = new FileReader
          reader.onload = e => {
            this.pics.push(e.target.result)
          }
          reader.readAsDataURL(input.files[i])
        }
      }
      this.$refs.imgInput.value = null;
    },
    //选择视频
    selectVideo() {
      this.$refs.videoInput.click()
    },
    //移除视频
    removeVideo() {
      console.log("移除视频")
      //this.$refs.videoShow.src = null; // empty source
      this.video = null;
      this.videoSrc = null;
    },
    //播放视频
    playVideo() {
      console.log("视频播放")
      this.showVideo = true;
    },
    //点击选择视频
    pickVideo() {
      let input = this.$refs.videoInput
      //console.log("input.files:" + JSON.stringify(input.files))
      //console.log("input.files.length:" + input.files.length)
      if (input.files.length > 0) {
        let videoSize = input.files[0].size;
        //35160  计算机存储数据最为常用的单位是字节(B)
        //在此处我们限制图片大小为2M
        if(videoSize>50*1024*1024){
          this.$message({
            message: this.$t('uploaded_video_large50M_please_reselect'),
            // showClose: true,
            type: 'warning',
          });
          return;
        }
        let reader = new FileReader
        reader.onload = e => {
          this.video = e.target.result;
          this.videoSrc = e.target.result;
          //this.videoSrc += '?t=' + (+new Date().getTime());
          //console.log("e.target.result" + e.target.result)
        }
        //this.videoSrc = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
      this.$refs.videoInput.value = null
    },
    submit() {
      this.$refs.infoForm.validate((v) => {
        if (v) {
          const formdata = new FormData();
          formdata.append('repairResult', this.info.name)
          formdata.append('costTime', this.info.code)
          if (this.info.personId) {
            formdata.append('personId', this.info.personId)
          } else {
            formdata.append('personId', '')
          }
          // if (this.$route.query.orderType === '2') {
          //   formdata.append('labourCost', this.amountSpent)
          // }
          if (this.$route.query.orderType === '1') {
            formdata.append('time', this.info.date)
          }
          formdata.append('summary', this.info.remark)
          formdata.append('repairId', this.repairId)
          this.pics.forEach((pic, index) => {
            formdata.append(`pic${index + 1}`, base64toFile(pic))
          });
          if (this.video) {
            formdata.append('vid', base64toFile(this.video));
          }
          API.submitReport(formdata)
              .then((response) => {
                if (response.message.success) {
                  this.$message({
                    message: this.$t('submit_success'),
                    // showClose: true,
                    type: 'success',
                  });
                  this.$router.go(-2)
                } else if (!response.message.success) {
                  this.addUserBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    // showClose: true,
                    type: 'error',
                  });
                }
              })
              .catch(() => {
                this.addUserBtnLoading = false;
              });
        }else {
          return false;
        }
        return v;
      })
    }
  }
}
</script>
<style scoped lang="scss">
.widthTable {
  width: 1550px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
}
.el-table .hidden-row {
  display: none;
}
.pic-and-video-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

.btn {
  width: 100px;
  height: 100px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 0;
  padding: 0;
img {
  width: 20px;
  height: 20px;
}

span {
  font-size: 13px;
  color: grey;
}
.closeVideo-btn {
  margin-top: 8px;
}
}
}

.preview {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  position: relative;

img {
  position: absolute;
  width: 100px;
  height: 100px;
}

.van-btton {
  position: absolute;
  right: 0;
  font-size: 20px;
  //top: -15px;
  background: #fff;
  padding: 0;
  color: #f56c6c;
}
}

.voice {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

img {
  width: 22px;
  height: 22px;
}
}

.video-label {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: lightgrey;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  font-size: 13px;
  color: grey;

.close-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

img {
  width: 20px;
  height: 20px;
}
}
}
</style>
