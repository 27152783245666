<template>
<div>
      <!--          v-if="line.deviceList.length > 0"-->
      <div v-if="!formListBig.title">
        <RingChart2
          v-if="formListBig.deviceList.length > 0"
          :id="formListBig.deviceId"
          :data="formListBig.deviceList"
          :legendRight="'auto'"
          :legendTop="'90%'"
          :height="'350px'"
          :total="formListBig.count"
          :totalTop="'52%'"
          :legendFontSize="15"
        ></RingChart2>
        <div v-else style="text-align: center; display: flex; margin-top: 150px; margin-bottom: 150px; justify-content: center; flex-direction: column; align-items: center;">
          {{ $t('noData') }}</div>
      </div>
      <div v-else>
        <RingChart
          v-if="formListBig.deviceList.length > 0"
          :id="formListBig.title"
          :data="formListBig.deviceList"
          :legendRight="'auto'"
          :legendTop="'90%'"
          :center="['50%', '50%']"
          :height="'330px'"
          :total="formListBig.count"
          :totalTop="'40%'"
          :legendFontSize="15"
        ></RingChart>
        <div v-else style="text-align: center; display: flex; position: absolute;margin-left: 220px;margin-top: 135px; justify-content: center; flex-direction: column; align-items: center;">
          {{ $t('noData') }}</div>
      </div>
</div>
</template>

<script>
import RingChart2 from "@/components/ChartsTime/RingChart2";
import RingChart from "@/components/ChartsTime/RingChart";
export default {
  name: "dialogDig.vue",
  components:{RingChart2,RingChart},
  props: {
    formListBig:{
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      // formListBig: {
      //   deviceList: []
      // },
    };
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
