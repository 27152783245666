<!--团队管理：人员管理-->
<template>
  <div class="">
    <div class="page-container">
      <div class="section">
        <!--        <h2 class="h2Style">-->
        <!--          人员管理-->
        <!--        </h2>-->
        <!--搜索栏-->
        <div class="control-box1 h2Style">
          <div class="cb-left">
            <el-input
                v-model.trim="form.name"
                clearable
                :placeholder="$t('plate_material_name')"
                style="width: 200px;"
                @clear="form.name = undefined"
                @keyup.enter.native="handleFilter"
            />
            <el-button
                class="control-item"
                icon="el-icon-search"
                type="primary"
                @click="handleFilter"
            >
              {{ $t('search') }}
            </el-button>
          </div>

          <div class="cb-right">
            <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addPanelShow()"
            >
              {{ $t('add') }}
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div class="table-box" style="width: 100%">
          <el-table
              v-loading="listLoading"
              :data="tableData"
              element-loading-text="Loading"
              border
              highlight-current-row
              style="width: 100%"
          >
            <!--昵称-->
            <el-table-column prop="name" :label="$t('plate_material_name')" align="left" />
            <el-table-column prop="cname" :label="$t('creator')" align="left" />
            <el-table-column prop="updateDate" :label="$t('update_time')" align="left" />
            <el-table-column :label="$t('caozuo')" align="left" width="180">
              <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="updatePanel(scope.row)"
                >
                  {{ $t('device_edit') }}
                </el-button>
                <el-button
                    size="mini"
                    icon="el-icon-delete"
                    type="primary"
                    plain
                    @click="deletePanel(scope.row)"
                >
                  {{ $t('delete') }}
                </el-button>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <!--分页-->
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.count"
              :total="totalCount"
              :current-page="form.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <!--弹出框：添加账号-->
      <el-dialog
          v-if="panelShow"
          :visible.sync="panelShow"
          :title="dateAccount"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="false"
          width="680px"
      >
        <el-form ref="inviteForm" label-width="100px" :model="panel" :rules="rules.inviteRules">
          <el-form-item :label="$t('plate_material_name') + '：'" prop="name">
            <el-input
                v-model="panel.name"
                show-word-limit
                maxlength="20"
                clearable
                :placeholder="$t('enter_plate_material_name')"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="cancel">{{ $t('cancel') }}</el-button>
          <el-button type="primary" :loading="addPanelBtnLoading" @click="addPanel()">
            {{ $t('confirm') }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import API from '@/api';
// import convert from "@/utils/convert";
export default {
  name: 'PersonManagement',
  data() {
    return {
      tableData: [],
      panelShow: false,
      addPanelBtnLoading: false,
      listLoading: false,
      // 弹窗标题
      dateAccount: this.$t('Add_Plate_Material'),
      // 添加账号
      panel: {
        name: undefined,
        id: undefined
      },
      leader: '',
      // 查询
      form: {
        name: undefined,
        page: 1,
        count: 10,
      },
      rules: {
        inviteRules: {
          name: [
            { required: true, message: this.$t('enter_plate_material_name'), trigger: 'change' },
            {
              min: 2, max: 20, message: this.$t('length_characters'), trigger: 'blur',
            },
          ],
        },
      },
      totalCount: 0,
      buttons: '',
    };
  },
  computed: {
  },
  created() {
  },
  mounted() {
    this.getPanelList();
  },
  methods: {
    updatePanel(row){
      this.dateAccount = this.$t('Modify_Plate_Material')
      this.panelShow = true
      this.panel.id = row.id
      this.panel.name = row.name
    },
    cancel(){
      this.panelShow = false
      this.emptyPanel()
    },
    emptyPanel(){
      this.panel = {
        id: undefined,
        name: undefined
      }
    },
    getPanelList() {
      // console.log(this.form)
      API.PanelList(this.form).then((response) => {
        this.tableData = response.message.data.list;
        this.totalCount = response.message.data.totalCount;
      });
    },
    handleFilter() {
      this.form.count = 10;
      this.form.page = 1;
      this.getPanelList();
    },
    addPanelShow() {
      this.dateAccount = this.$t('Add_Plate_Material')
      this.emptyPanel()
      this.panelShow = true;
    },
    // 添加弹窗确定
    addPanel() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          this.addPanelBtnLoading = true;
          if(this.panel.id) {
            API.PanelUpdate(this.panel, this.panel.id).then((res) => {
              if (res.message.success === true) {
                this.panelShow = false;
                this.addPanelBtnLoading = false;
                this.$message.success(this.$t('edit_successful'));
                this.getPanelList();
              } else {
                this.$message.error(res.message.message);
              }
            }).catch(() => {
              this.addPanelBtnLoading = false;
            }).finally(() => {
              this.addPanelBtnLoading = false;
            });
          } else {
            API.PanelAdd(this.panel).then((res) => {
              if (res.message.success === true) {
                this.panelShow = false;
                this.addPanelBtnLoading = false;
                this.$message.success(this.$t('add_successful'));
                this.getPanelList();
              } else {
                this.$message.error(res.message.message);
              }
            }).catch(() => {
              this.addPanelBtnLoading = false;
            }).finally(() => {
              this.addPanelBtnLoading = false;
            });
          }

        } else {
          return false;
        }
        return v;
      });
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.form.page = 1;
      this.getPanelList();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getPanelList();
    },
    deletePanel(row) {
      this.$confirm(this.$t('confirm_delete_current_plate_material'), this.$t('delete_plate_material'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      }).then(() => {
        API.PanelDelete(row.id).then((response) => {
          this.$message({
            message: response.message.message,
            showClose: true,
            type: 'success',
          });
          if (this.tableData.length === 1 && this.form.page !== 1) {
            this.form.page -= 1;
          }
          this.getPanelList();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//.page-container{
//  padding: 40px;
//}
//.section{
//  padding: 30px 40px;
//}
.h2Style {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdfe6;
  font-size: 20px;
}
.PersonManagement {
  width: 100%;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
}
.cb-left {
  display: -webkit-box;
}
.control-item {
  margin-left: 15px;
}
::v-deep .form-item{
  display: inline-block;
}
.input-width {
  width: 220px;
}
</style>
