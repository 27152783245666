import Vue from 'vue';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import * as echarts from 'echarts';
import 'echarts-liquidfill'
import App from './App.vue';

import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'

import { router } from '@/router';
import { store } from '@/store';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/style.scss'; // 自定义样式
import '@/components/sidebarStyle.scss';
import '@/permission';
import '@/icons';
import screenfull from "screenfull";
import "amfe-flexible"
import VueI18n from 'vue-i18n';
import zh from './i18n/config/zh';
import en from './i18n/config/en';

console.log( localStorage.getItem('locale'))
if (localStorage.getItem('locale') === 'en') {
  locale.use(enLocale)
} else {
  locale.use(zhLocale)
}

// Vue.use(Element)
// 设置英文


// Vue.use(Element)
// 图表
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh',
  messages: {
    zh,
    en,
  },
});
console.log( localStorage.getItem('locale'))
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
