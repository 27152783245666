<template>
<div class="page-container">
  <div class="section">
    <div class="control-box control-box-width">
      <div class="left" style="width: 80%;">
        <el-button class="control-item" icon="el-icon-back" @click="goBack">
          {{ $t('return') }}
        </el-button>
        <el-date-picker
            class="item-component"
            v-model="query.startTime"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('select_start_date')"
            :picker-options="pickerOptionsStart"
            @change="startTimeChange"
        >
        </el-date-picker>
        <el-date-picker
            class="item-component ml10"
            v-model="query.endTime"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('select_end_date')"
            :picker-options="pickerOptionsEnd"
            @change="endTimeChange"
        >
        </el-date-picker>
        <el-select
          style="width: 250px"
          class="item-component-select ml10"
          v-model="equipment"
          clearable
          :placeholder="$t('acquisition_device')"
          filterable
        >
          <el-option
            v-for="item in tableNumber"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-select
            style="width: 250px"
            class="item-component-select ml10"
            v-model="query.name"
            clearable
            :placeholder="$t('please_select_submission_reason')"
            filterable
        >
          <el-option
              v-for="item in reasonList"
              :key="item.id"
              :label="item.value"
              :value="item.value"
          />
        </el-select>
      </div>
      <div class="cb-right right-center">
        <el-button
            type="primary"
            @click="handleExport"
        >
          {{ $t('export') }}
        </el-button>
        <el-button
          type="primary"
          @click="handelQueryCheck"
        >
          {{ $t('query') }}
        </el-button>
      </div>
    </div>
    <div class="content" style="padding: 0 0 0 20px">
      <div class="table-box" >
        <el-table
          :data="maintainData"
          border
          style="width: 100%;"
        >
          <el-table-column
            prop="name"
            width="150"
            :label="$t('deviceName')"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="orderDate"
            :label="$t('date')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="actualTime"
            :label="$t('actual_changeover_hours')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="workTime"
            :label="$t('effective_work_duration')"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="startTime"
            :label="$t('startTime')"
            width="160"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="endTime"
            :label="$t('endTime')"
            align="center"
            width="160"
          >
          </el-table-column>
          <el-table-column
            prop="person"
            :label="$t('reporter')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="date"
            :label="$t('submit_time')"
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="reason"
            :label="$t('submission_reason')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remark"
            :label="$t('remark')"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="examineUser"
            :label="$t('approver')"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="examineDate"
            :label="$t('approval_time')"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="stateStr"
            :label="$t('device_state')"
            align="center"
          >
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            v-show="totalCount>0"
            :current-page="query.page"
            :page-sizes="[10, 20, 30, 40]"
            :total="totalCount"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import API from "@/api";
export default {
name: "abnormalDetails",
  data() {
    return {
      maintainData: [],
      tableNumber: [],
      filter: {
        startTime: undefined,
        endTime: undefined,
        teamId: '',
        count: 10,
        page: 1,
        name: '',
        totalCount: 0
      },
      query: {
        startTime: undefined,
        endTime: undefined,
        count: 10,
        page: 1,
        teamId: '',
        name: ''
        // sheetType: this.selectedSheetType,
      },
      reasonList: [],
      form: {
        page: 1,
        count: 1000000,
        name: '',
        type: 13,
        state: 1,
        teamState: -1
      },
      totalCount: 0,
      equipment: '',
      equipmentDown: '',
      startTime: undefined,
      endTime: undefined,
      pickerOptionsStart:{
        disabledDate: time => {
          if (this.query.endTime) {
            return (
                time.getTime() > new Date(this.query.endTime).getTime() || time.getTime() < new Date(this.query.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.query.startTime) {
            return (
                time.getTime() < new Date(this.query.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.query.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
    };
  },
  mounted() {

  },
  created() {
    this.query.startTime = this.$route.query.startTime
    this.query.endTime = this.$route.query.endTime
    this.query.teamId = this.$route.query.teamIds
    this.startTime = this.$route.query.startTime
    this.endTime = this.$route.query.endTime
    this.getList()
  },
  methods: {
  getList() {
    API.CompanyPreserveList({icollection: 1,type: -1,name: '', page: 1, count: 1000000000, teamId: this.$route.query.teamIds}).then((response) => {
      console.log(response)
      this.tableNumber = response.message.data.list;
      this.handelQuery()
      // this.totalCount = response.totalCount;
    });
    API.abnormalDataList(this.form).then(res => {
      console.log(res)
      this.reasonList = res.message.data.list
    })
  },
    handelQueryCheck() {
      if (!this.query.startTime || !this.query.endTime) {
        this.$message.warning(this.$t('please_select_start_end_time'))
        return
      }
      this.query.page = 1
      this.query.count = 10
      this.handelQuery()
    },
    handelQuery() {
      this.equipmentDown = this.equipment
      this.startTime = this.query.startTime
      this.endTime = this.query.endTime
      API.abnormalWebList({...this.query,deviceId: this.equipment, }).then(res => {
        console.log(res)
        this.maintainData = res.message.data.list
        this.totalCount = res.message.data.totalCount
      })
    },
    handleExport() {
      if (!this.query.startTime || !this.query.endTime) {
        this.$message.warning(this.$t('please_select_start_end_time'))
        return
      }
      require.ensure([], () => {
        const {export_json_to_excel} = require('@/views/ExcelLeadOut/Export2Excel'); // 注意这个Export2Excel路径
        const tHeader = [
          this.$t('device_add_name'),
          this.$t('date'),
          this.$t('actual_changeover_hours'),
          this.$t('effective_work_duration'),
          this.$t('startTime'),
          this.$t('endTime'),
          this.$t('reporter'),
          this.$t('submit_time'),
          this.$t('submission_reason'),
          this.$t('remark'),
          this.$t('approver'),
          this.$t('approval_time'),
          this.$t('device_state'),
        ]; // 表格标题
        const filterVal = [
          'name',
          'orderDate',
          'actualTime',
          'workTime',
          'startTime',
          'endTime',
          'person',
          'date',
          'reason',
          'remark',
          'examineUser',
          'examineDate',
          'stateStr'
        ]; // 标题属性key值
        API.abnormalWebList({...this.query,startTime: this.startTime,endTime:this.endTime,deviceId: this.equipmentDown,count:100000,page: 1}).then(res => {
          const list = res.message.data.list; // 把要导出的数据tableData存到list
          const data = this.formatJson(filterVal, list);
          export_json_to_excel(tHeader, data, this.$t('exception_standby_details')); // 最后一个是表名字
        })

      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    handleSizeChange(val) {
      this.query.count = val;
      this.query.page = 1;
      this.handelQuery();
    },
    // 当前页数改变时会触发
    handleCurrentChange(val) {
      this.query.page = val;
      this.handelQuery();
    },
    startTimeChange(val){
      if(this.query.endTime && new Date(val).getTime() > new Date(this.query.endTime).getTime()){
        this.query.startTime = null
      }else{
        this.query.startTime = val
      }
    },
    endTimeChange(val){
      // 最长时间跨度100天
      if(this.query.startTime && new Date(val).getTime() - new Date(this.query.startTime).getTime() > 100 * 24 * 60 * 60 *1000){
        this.query.endTime = null
      }else{
        this.query.endTime = val
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  }
}
</script>

<style scoped lang="scss">
.pagination-box {
  margin-top: 20px;
}
.control-item {
  margin-right: 15px;
}
.ml10{
  margin-left: 2%;
}
::v-deep .table-box table thead th .cell {
  white-space: normal;
}
</style>
