<template>
  <!--portal企业控制台首页-->
  <div class="page-container">
      <!--<HomeWelcome />-->
      <div class="page-inner home-box">
        <!--上-->
        <div class="flex-full">
          <div class="card-box3">
            <div class="card-top">
              <div class="card-item">
                <img src="@/assets/img/icon-count.png" alt="">
                <div class="card-text">
                  <div>{{ $t('total_employees') }}</div>
                  <div><span>{{ data.userCount }}</span>{{ $t('ming') }}</div>
                </div>
              </div>
              <div class="card-item">
                <img src="@/assets/img/icon-count3.png" alt="">
                <div class="card-text">
                  <div>{{ $t('total_number_data_acquisition_devices') }}</div>
                  <div><span>{{ data.allcount }}</span>{{ $t('tower') }}</div>
                </div>
              </div>
              <div class="card-item">
                <img src="@/assets/img/icon-count2.png" alt="">
                <div class="card-text">
                  <div>{{ $t('total_number_devices') }}</div>
                  <div><span>{{ data.deviceCount }}</span>{{ $t('tower') }}</div>
                </div>
              </div>
              <div class="card-item2">
                <div>
                  <div class="number">{{ data.yinQianCount }}</div>
                  <div>{{ $t('yinqian_device') }}</div>
                </div>
                <div>
                  <div class="number">{{ data.yinHouCount }}</div>
                  <div>{{ $t('yinhou_device') }}</div>
                </div>
                <div>
                  <div class="number">{{ data.yinShuaCount }}</div>
                  <div>{{ $t('yinshua_device') }}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="page-top">
          <div style="width: 1200px" class="flex-full">
            <el-row type="flex" :gutter="30" class="mb-20" style="margin-right: 0px;">
              <!--员工总数-->
              <el-col :span="12" style="padding-right:5px">
                <div class="card-box1">
                  <div class="card-head">
                    <div class="card-title">{{ $t('yesterday_quantity') }}</div>
                  </div>
                 <div>
                   <BarChart
                       v-if="chartData.source.length > 0"
                       :id="'chartData2'"
                       :subtext="$t('unitzhang')"
                       :data="chartData"
                       :height="250"
                       :barColor="barColor"
                       :legendShow="false"
                   />
                   <div v-else class="node">{{ $t('noData') }}</div>
                 </div>
                </div>
              </el-col>
              <!--设备总数-->
              <el-col :span="12" style="padding-right:5px">
                <div class="card-box1 is-bg">
                  <div class="card-head">
                    <div class="card-title">{{ $t('yesterday_time_utilization_Rate') }}</div>
                  </div>
                  <div>
                    <BarChart
                        v-if="chartData2.source.length > 0"
                        :id="'chartData3'"
                        :subtext="$t('unitmin')"
                        :data="chartData2"
                        :height="250"
                        :barColor="barColor"
                        :legendShow="false"
                    />
                    <div v-else class="node">{{ $t('noData') }}</div>
                  </div>
                </div>
              </el-col>
              <!--设备总数-->
              <el-col :span="12" style="padding-right:0px">
                <div class="card-box1 is-bg">
                  <div class="card-head">
                    <div class="card-title">{{ $t('yesterday_performance_utilization_rate') }}</div>
                  </div>
                  <div>
                    <BarChart
                        v-if="chartData3.source.length > 0"
                        :id="'chartData4'"
                        :subtext="$t('unitTime')"
                        :data="chartData3"
                        :height="250"
                        :barColor="barColor"
                        :legendShow="false"
                    />
                    <div v-else class="node">{{ $t('noData') }}</div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <!--数采设备总数-->

          </div>
          <!--右：员工活跃度-->

        </div>
        <div style="display: flex">
          <div class="card-box1 mr-20" style="width: 50%">
            <div class="card-head" style="margin-bottom: 0px">
              <div class="card-title">{{ $t('yichangTime') }}</div>
            </div>
            <div>
              <BarChart
                  v-if="chartData4.source.length > 0"
                  :id="'chartData4'"
                  :data="chartData4"
                  :subtext="$t('Unit_Minutes')"
                  :height="220"
                  :barColor="barColor2"
                  :legendShow="false"
              />
              <div v-else class="node">{{ $t('noData') }}</div>
            </div>
          </div>
          <div class="card-box1 " style="width: 50%">
            <div class="card-head" style="margin-bottom: 0px">
              <div class="card-title">{{ $t('shiyinzhanbi') }}</div>
            </div>
            <div>
              <BarChart
                  v-if="chartDataTotal.source.length > 0"
                  :id="'chartData5'"
                  :data="chartDataTotal"
                  :height="220"
                  :barColor="barColor2"
                  :legendShow="false"
              />
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import API from '@/api';
import { manageOauth } from '@/utils/validate';
import BarChart from '@/components/Charts/BarChart';
import {store} from "@/store";
import cookies from "@/utils/cookies"; // 图表组件
// import HomeWelcome from '@/components/HomeWelcome' // 首页欢迎
export default {
  // name: 'firstHome',
  // components: { HomeWelcome },
  components: { BarChart },
  data() {
    return {
      VUE_APP_HOME: process.env.VUE_APP_LOGIN,
      iconImg: false,
      teamOperate: false, // 操作人员权限
      deviceOperate: false, // 操作设备
      activeName: 'first',
      inspectionList: [],
      replacementList: [],
      abnormalList: [],
      abnormalList2: [],
      faultList: [],
      repairList: [],
      maintainanceList: [],
      inspectionCount: 0,
      replacementcount: 0,
      abnormalCount: 0,
      repairCount: 0,
      maintainanceCount: 0,
      // 页面数据
      data: {},
      formDate: {
        type: -1,
        page: 1,
        count: 10
      },
      chartDataTotal: {
        // 数据集
        source: [],
        // 系列
        series: [
          {
            type: "line",
          },
        ],
      },
      // 图表
      chartData: {
        // 数据集
        source: [],
        // y轴
        // yAxis: {
        //   type: 'value',
        //   // 坐标轴线
        //   axisLine: {
        //     show: true,
        //     lineStyle: {
        //       color: '#CACACA',
        //       width: 2
        //     }
        //   }
        // },
        // 系列
        series: [
          {
            type: 'bar',
            barMaxWidth: 25,
          },
        ],
      },
      chartData2: {
        // 数据集
        source: [],
        // 系列
        series: [
          {
            type: 'bar',
            barMaxWidth: 25,
          },
        ],
      },
      chartData3: {
        // 数据集
        source: [],
        // 系列
        series: [
          {
            type: 'bar',
            barMaxWidth: 25,
          },
        ],
      },
      chartData4: {
        // 数据集
        source: [],
        // 系列
        series: [
          {
            type: 'line',
          },
        ],
      },
      // 柱形颜色
      barColor: [
        // eslint-disable-next-line new-cap,no-new-require
        new require('echarts').graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#FA460D' },
          { offset: 1, color: '#F7B515' },
        ]),
      ],
      barColor2: [
        // eslint-disable-next-line new-cap,no-new-require
        new require('echarts').graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#FB7F19'},
          { offset: 1, color: '#FB7F19' },
        ]),
      ],
      userInfo: {
        userName: '',
        url: '',
        phone: '',
        email: '',
        teamName: '',
      },
      defaultUrl: '',
    };
  },
  created() {
    this.$i18n.locale = localStorage.getItem('locale')
    console.log(this.$i18n.locale)
    this.getList()
    this.getData();
    this.getUserInfo();
  },
  watch: {
    // 监听VUEX中buttons
    buttons: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log(2, val)
        if (val && val.length > 0) {
          // console.log(3, val)
          this.managePermission();
        }
      },
    },
  },
  computed: {
    // 获取VUEX中buttons
    buttons() {
      return this.$store.getters.userInfo.buttons;
    },Url() {
      return `${process.env.VUE_APP_API}/psl/user/url/modify`;
    },

  },
  methods: {
    // 获取数据
    getData() {
      API.printHome().then((res) => {
        // console.log(res.message.data)
        this.data = res.message.data;
        // // 处理图表数据
        // const { list } = res.message.data;
        // const source = [];
        // if (list.length > 0) {
        //   for (const item of list) {
        //     source.push([item.time, item.count]);
        //   }
        //   this.chartData.source = [
        //     ['product', '日活跃度'],
        //     ...source,
        //   ];
        // } else {
        //   this.chartData.source = [];
        // }
      });
      API.TimeDailyInfo({ day: 7, deviceId: '' }).then(res => {
        if (res.message.data.length > 0) {
          const list2 = res.message.data.map((item) => {
            if (item.time !== 'NaN') {
              return [item.date, Number(item.time)];
            }
          });
          if (list2[0]) {
            this.chartDataTotal.source = [["product", ""], ...list2]
          } else {
            this.chartDataTotal.source = [];
          }
        } else {
          this.chartDataTotal.source = [];
        }
      })
      API.deviceInfo().then(res => {
        if (res.message.data.length > 0) {
          const list = res.message.data.map((item) => {
            if (item.name !== 'NaN') {
              return [item.name, Number(item.value1)];
            }
          });
          if (list[0]) {
            this.chartData.source = [["product", ""], ...list]
          } else {
            this.chartData.source = [];
          }
          const list2 = res.message.data.map((item) => {
            if (item.name !== 'NaN') {
              return [item.name, Number(item.value2)];
            }
          });
          if (list2[0]) {
            this.chartData2.source = [["product", ""], ...list2]
          } else {
            this.chartData2.source = [];
          }
          const list3 = res.message.data.map((item) => {
            if (item.name !== 'NaN') {
              return [item.name, Number(item.value3)];
            }
          });
          if (list3[0]) {
            this.chartData3.source = [["product", ""], ...list3]
          } else {
            this.chartData3.source = [];
          }
          const list4 = res.message.data.map((item) => {
            if (item.name !== 'NaN') {
              return [item.name, Number(item.value4)];
            }
          });
          if (list4[0]) {
            this.chartData4.source = [["product", ""], ...list4]
          } else {
            this.chartData4.source = [];
          }
        } else {
          this.chartData.source = [];
          this.chartData2.source = [];
          this.chartData3.source = [];
          this.chartData4.source = [];
        }
      })
    },
    getList () {
      API.getTaskList({...this.formDate,type:2}).then(res => {
        this.inspectionList = res.message.data.list
        this.inspectionCount = res.message.data.totalCount
      })
      API.getTaskList({...this.formDate,type:4}).then(res => {
        this.replacementList = res.message.data.list
        this.replacementcount = res.message.data.totalCount
      })
      API.getTaskList({...this.formDate,type:6}).then(res => {
        this.abnormalList = res.message.data.list
        this.abnormalCount = res.message.data.totalCount
      })
      API.getTaskList({...this.formDate,type:7}).then(res => {
        this.abnormalList2 = res.message.data.list
        this.abnormalCount = res.message.data.totalCount
      })
      API.getTaskList({...this.formDate,type:3}).then(res => {
        this.repairList = res.message.data.list
        this.repairCount = res.message.data.totalCount
      })
      API.getTaskList({...this.formDate,type:1}).then(res => {
        this.maintainanceList = res.message.data.list
        this.maintainanceCount = res.message.data.totalCount
      })
    },
    load() {
      // 模拟异步加载数据
      if (this.formDate.count < this.maintainanceCount ) {
        this.formDate.count += 10
        API.getTaskList({...this.formDate,type:1}).then(res => {
          this.maintainanceList = res.message.data.list
          this.maintainanceCount = res.message.data.totalCount
        })
      }
      // setTimeout(() => {

      // }, 500);
    },
    getDeviceListById(tab) {
      console.log(tab)
      this.formDate.count = 10
      this.getList()
    },
    handleInspection(id) {
      this.$router.push({path: 'inspection', query: {id: id, type: 2}})
    },
    handleMaintenance(id) {
      this.$router.push({ path: 'maintenanceApproval', query: { id: id } })
    },
    checkAbnormal(id) {
      this.$router.push({ path: 'Approval', query: { id: id } })
    },
    checkFault(id) {
      this.$router.push({ path: 'faultDetail', query: { deviceRepairId: id } })
    },
    handleApproval(id) {
      this.$router.push({ path: 'abnormalDetail', query: { id: id } })
    },
    handleMaintain(id) {
      this.$router.push({ path: 'maintenDetail', query: { id: id } })
    },
    handlePeport(id) {
      this.$router.push({ path: 'abnormalReportDetail', query: { id: id } })
    },
    handleConfirm() {

    },
    // 是否有操作人员、设备权限
    managePermission() {
      // 人员权限
      if (manageOauth('team_operate', this.buttons)) {
        this.teamOperate = true;
      }
      // 设备权限
      if (manageOauth('device_operate', this.buttons)) {
        this.deviceOperate = true;
      }
    },
    // 跳转页面
    toPage(path, roleId, ref) {
      this.$router.push({
        path,
        query: {
          roleId, // 角色id
          ref, // 自动弹出下拉框的ref
        },
      });
    },
    handleIcon() {
      if (this.iconImg === true) {
        this.iconImg = false
      } else {
        this.iconImg = true
      }
    },
    handleIcon1() {
      if (this.iconImg === true) {
        this.iconImg = false
      }
    },
    // 返回portal首页
    ToHomepage() {
      // window.open(`${this.VUE_APP_HOME}`);
    },
    // 退出登录
    exit() {
      this.$confirm(this.$t('log_out_user'), this.$t('prompt'), {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            store.dispatch('logout').then(() => {
              cookies.remove('token');
              window.open(`${this.VUE_APP_HOME}login/:SUP/`, '_self');
            });
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消',
            // });
          });
    },
    // 获取个人信息
    getUserInfo() {
      store.dispatch('getInfo').then((res) => {
        console.log(res)
        if (res.message.success) {
          this.userInfo = res.message.data;
          // console.log(this.userInfo)
          this.defaultUrl = res.message.data.url;
          if (this.userInfo.userlanguage === 'en_US') {
            this.$i18n.locale = 'en'
            localStorage.setItem('locale', 'en')
          } else {
            this.$i18n.locale = 'zh'
            localStorage.setItem('locale', 'zh')
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {

  .page-inner {
    /*height: 100%;
    overflow: auto;
    margin-right: -40px;*/

    .page-top,
    .page-bottom {
      /*min-width: 1620px;
      padding-right: 40px;*/
      //margin-bottom: 20px;
    }

    .page-top {
      display: flex;
    }
  }
}
.section-title {
  padding-left: 0;
  border-left: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}
/*卡片*/
.card-box1 {
  display: flex;
  flex-direction: column;
  /*overflow: hidden;*/
  box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);
padding: 25px;
  background-color: #fff;
  border-radius: 5px;
  .card-head {
    //height: 38px;

    .card-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
    }
  }
  .card-body {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 50px 40px;
    background-color: #fff;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    transition: background 0.3s;
    border-radius: 2px;
    height: 150px;
    box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);
    /*左*/
    .left {
      width: 40%;
    }
    /*右*/
    .right {
      width: 60%;
      display: flex;
      /*margin-left: auto;*/
      .number {
        font-size: 26px;
        font-family: 'DIN-Medium';
        line-height: 1;
      }
      /*右上*/
      .right-top {
        display: flex;
        align-items: center;
        margin-right: 40px;
      }
      /*右下*/
      .right-bottom {
        display: flex;
        align-items: center;
      }
    }
    .number {
      font-size: 50px;
      font-family: 'DIN-Bold';
      line-height: 1;
    }
  }
}
.card-box2 {
  background: #fff;
  border-radius: 2px;
  padding: 30px 40px 40px;
  height: 100%;
  box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);

  .card-head {
    display: flex;
    justify-content: space-between;
    height: 31px;

    .card-title {
      font-size: 16px;
      color: #000;
      line-height: 1;
    }
  }
  .card-body {
    display: flex;
    justify-content: center;
  }
}
/*标签*/
.tag {
  font-size: 14px;
  line-height: 1;
  border-radius: 2px;
  padding: 4px 10px;
  background: #858585;
  color: #fff;
  white-space: nowrap;

  &.tag-warning {
    background: #f7633c;
  }
}
/*图片组*/
.img-box {
  text-align: center;
  cursor: pointer;
  // 禁用
  &.disable {
    cursor: initial;
  }

  img {
    max-width: 100%;
    /*分隔*/
    &.separated {
      padding: 40px 0;
    }
  }
  .text {
    font-size: 14px;
  }
}
.header-box {
  display: none;
}
@media only screen and (max-width: 1366px) {
  .card-box1 {
    .card-body {
      .number {
        font-size: 40px;
      }
      .right {
        .right-top {
          margin-right: 10px;
        }
      }
    }
  }
}
@media screen and (max-width:800px){
  .header-box {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    background: #3e2b25;
    box-shadow: 0px 2px 6px 3px #bcbcbc;
    padding: 0 50px;
    position: fixed;
    width: 100%;
    z-index: 99999;
    .menu-app {
      display: none;
      position: absolute;
      bottom: -750px;
      left: 0;
      background-color: #fff;
      z-index: 9999;
      width: 100%;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      box-shadow: 0rem 0px 18px 0px #aaa6a6;
      ul{
        list-style: none;
        padding: 0 100px;
        li {
          display: block;
          border-bottom: 1px solid #E1E1E1;
          font-size: 78px;
          color: #000;
          padding: 70px 0;
          text-align: center;
        }
        img {
          width: 70px;
          margin-right: 20px;
        }
        a{
          text-decoration: none;
          color: #000;
          display: inline-block;
          width: 600px;
          text-align: left;
        }
      }
    }
    .activeImg {
      display: block;
    }
    .right-box {
      float: right;
      //margin-right: 40px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    #logo {
      //height: 62px;
      //width: 202px;
      text-align: center;
      .img {
        margin-top: 30px;
        //height: 41px;
        width: 300px;
      }
    }
    .head li {
      font-size: 88px;
      color: #fff !important;
      //width: 80px;
      //height: 62px;
      //line-height: 62px;
      position: relative;
      align-items: center;
      float: left;
      //font-weight: bold;
      // margin-right: 80px;
      cursor: pointer;
      display: flex;
      .exit {
        width: 120px;
        height: 57px;
        line-height: 57px;
      }
    }
    .icon-order {
      width: 100px;
      height: 70px;
      margin-left: 30px;
    }
    .exit:hover {
      color: #f3a000;
    }
    .head > li a:hover {
      color: #f3a000;
    }
    .head {
      .active {
        color: #fff;
        text-decoration: none;
        transition: all ease 0.3s;
      }
    }
    .UserName {
      width: 200px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
      position: relative;
    }
    .UserName:hover .menu {
      display: block;
      transition: all ease 0.3s;
    }
    .information {
      width: 120px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
    }
    .menu {
      display: none;
      position: absolute;
      top: 60px;
      right: 0px;
      border: 1px solid #9999;
      background: #ffffff;
      z-index: 9;
      .pullDown {
        width: 62px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        display: block;
      }
      li {
        width: 62px;
        height: 60px;
        color: #231815;
      }
    }
    .pullDown:hover {
      color: #f3a000;
      transition: all ease 0.3s;
    }
    .menu > li:hover a {
      color: #f3a000;
      transition: all ease 0.3s;
    }
  }
  .activeImg {
    display: block;
  }
  .mr-30 {
    width: 100% !important;
  }
  .page-container {
    padding: 0!important;
    .home-box {
      padding: 40px;
    }
    .page-inner {
      /*height: 100%;
      overflow: auto;
      margin-right: -40px;*/

      .page-top,
      .page-bottom {
        /*min-width: 1620px;
        padding-right: 40px;*/
        margin-bottom: 30px;
        margin-top: 250px;
      }

      .page-top {
        display: block;
      }
    }
  }
  .section-title {
    padding-left: 0;
    border-left: none;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  }
  /*卡片*/
  .card-box1 {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    /*overflow: hidden;*/

    .card-head {
      height: 150px;

      .card-title {
        font-size: 78px;
        font-weight: bold;
        line-height: 1;
        margin-top: 20px;
      }
    }
    .card-body {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      padding: 300px 180px;
      background-color: #fff;
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      transition: background 0.3s;
      border-radius: 2px;
      height: 150px;
      box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);
      /*左*/
      .left {
        width: 40%;
      }
      /*右*/
      .right {
        width: 60%;
        display: flex;
        /*margin-left: auto;*/
        .number {
          font-size: 120px;
          font-family: 'DIN-Medium';
          line-height: 1;
        }
        /*右上*/
        .right-top {
          display: flex;
          align-items: center;
          margin-right: 100px;
        }
        /*右下*/
        .right-bottom {
          display: flex;
          align-items: center;
        }
      }
      .number {
        font-size: 180px;
        font-family: 'DIN-Bold';
        line-height: 1;
      }
    }
    .card-body2 {
      padding: 150px 100px !important;
    }

  }
  .card-box2 {
    background: #fff;
    border-radius: 2px;
    padding: 30px 40px 40px;
    height: 100%;
    box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);

    .card-head {
      display: flex;
      justify-content: space-between;
      height: 31px;

      .card-title {
        font-size: 16px;
        color: #000;
        line-height: 1;
      }
    }
    .card-body {
      display: flex;
      justify-content: center;
    }
    .card-body2 {
      padding: 0 !important;
    }
  }
  /*标签*/
  .tag {
    font-size: 68px;
    line-height: 1;
    border-radius: 15px;
    padding: 30px 20px;
    background: #858585;
    color: #fff;
    white-space: nowrap;

    &.tag-warning {
      background: #f7633c;
    }

  }
  /*图片组*/
  .img-box {
    text-align: center;
    cursor: pointer;
    // 禁用
    &.disable {
      cursor: initial;
    }

    img {
      max-width: 100%;
      /*分隔*/
      &.separated {
        padding: 40px 0;
      }
    }
    .text {
      font-size: 14px;
    }
  }
  .flex-full {
    width: 100% !important;
  }
  .mb-40 {
    margin: 0!important;
  }
  .el-col-12 {
    //width: 48% !important;
    padding: 15px !important;
  }
  .mr-10 {
    margin-right: 30px!important;
  }
}
.card-box3 {
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  box-shadow: 0 0 0.041667rem 0.020833rem rgba(215, 215, 215, 0.3);
  padding: 58px 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
}
.card-top {
  display: flex;
  font-size: 16px;
  color: #1D2129;
  align-items: center;
  img{
    width: 63px;
    margin-right: 14px;
  }
  .card-item {
    display: flex;
  }
  .card-item2 {
    color: #1D2129;
    display: flex;
    text-align: center;
    justify-content: space-between;
    width: 32%;
    .number {
      font-size: 28px;
      margin-bottom: 6px;
      font-weight: bold;
    }
  }
  .card-item {
    padding-right: 25px;
    margin-right: 50px;
    border-right: 1px solid #EBEBEB;
    width: 25%;
  }
  .card-text {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    div:first-child {
      margin-bottom: 10px;
    }
    div:last-child {
      color: #4E5969;
    }
    span{
      font-size: 26px;
      color: #1D2129;
      display: inline-block;
      margin-right: 10px;
    }
  }
}
.card-box3 .card-top:first-child {
  //margin-bottom: 75px;
}
.card-box {
  margin-bottom: 20px;
}
.todo {
  margin-top: 20px;
}
::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #F3A100;
}
::v-deep .el-tabs__item {
  font-size: 14px;
}
.node {
  height: 230px;
  text-align: center;
  font-size: 16px;
  line-height: 14;
}
</style>

