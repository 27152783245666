<template>
  <div class="page-container">
    <div class="section">
      <el-button icon="el-icon-back" @click="handleCancle">{{ $t('return') }}</el-button>
      <el-form ref="formSupport" :model="formSupport" label-position="left" label-width="100px">
        <el-form-item label="文档标题：">
          {{ formSupport.title }}
        </el-form-item>
        <el-form-item label="文档类型：">
          {{ formSupport.type === 2 ? 'PDF' : '富文本' }}
        </el-form-item>
        <el-form-item v-show="formSupport.type === 2" label="上传PDF：">
          {{ formSupport.urlName }}
          <a :href="formSupport.url" target="_blank">
            <i class="el-icon-download" style="color:#409EFF;margin-left:30px;"></i>
          </a>
        </el-form-item>
        <el-form-item v-show="formSupport.type === 1" label="内容说明：">
          <template>
            <div class="contentInfo" v-html="formSupport.info"></div>
          </template>
        </el-form-item>
      </el-form>
      <!-- <div class="btn-box">
        <el-button @click="handleCancle">返回</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import Editor from 'wangeditor';
import API from '@/api'

export default {
  data() {
    return {
      formSupport: {
        title: '',
        info: '',
        type: '',
        url: '',
        urlName: '',
      },
      editor: '',
      SupportId: '',
    };
  },
  created() {
    this.SupportId = this.$route.query.id;
    this.getSupportInfo();
  },
  mounted() {
    // this.createContent()
  },
  methods: {
    getSupportInfo() {
      API.SupportInfo({ id: this.SupportId }).then((res) => {
        if (res.message.data.type === 2) {
          this.formSupport = {
            title: res.message.data.title,
            type: res.message.data.type,
            url: res.message.data.content,
            urlName: res.message.data.urlName,
          };
        } else {
          this.formSupport = {
            title: res.message.data.title,
            info: res.message.data.info,
            type: res.message.data.type,
          };
        }
      });
    },
    // 内容
    createContent() {
      this.editor = new Editor('#content');
      this.editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'justify', // 对齐方式
        'quote', // 引用
        'image', // 插入图片
        'undo', // 撤销
        'redo', // 重复
      ];
      this.editor.config.zIndex = 10;
      this.editor.create();
      this.editor.disable();
    },
    handleCancle() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.contentInfo {
  p {
    margin: 0px 0px;
  }
}
</style>
