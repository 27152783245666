<template>
  <!--portal企业控制台首页-->
  <div class="page-container">
      <div class="header-box">
        <ul class="head">
          <li id="logo">
            <img class="img" src="~@/assets/img/Slice 19.png" @click="ToHomepage" alt="" />
          </li>
        </ul>
        <ul class="head" style="margin-right: 15px">
          <li v-if="userInfo.teamId" class="nav"  @click="handleIcon">
            <div class="FristPage">
              印刷管家
            </div>
            <img class="icon-order" src="~@/assets/img/icon-order.png" alt="">
          </li>
        </ul>

        <div @click="handleIcon1" class="right-box">
          <el-dropdown>
            <div class="block">
              <el-avatar
                  :size="30"
                  :src="defaultUrl ? defaultUrl : require(`@/assets/img/headerImg.png`)"
              ></el-avatar>
              <!--              <el-avatar :size="54" :src="require(`@/assets/img/headerImg.png`)"></el-avatar>-->
            </div>
            <el-dropdown-menu slot="dropdown" style="width: 150px;padding: 10px 0px 0px;font-size: 12px">
              <ol>
                <li class="li-text li1" @click="exit">
                  <div style="display: flex;padding: 10px 24px; margin-bottom: 10px">
                    <div style="padding-right: 11px;">
                      <img src="@/assets/img/Slice 37.png" alt="img" />
                    </div>
                    退出登录
                  </div>
                </li>
              </ol>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="menu-app" :class="iconImg === true ? 'activeImg' : ''">
          <ul class="app-ul">
            <li>
              <router-link :to="`/PrintSteward/PrintHome`">
                <img src="~@/assets/img/icon-home1.png" alt="">
                主页
              </router-link>
            </li>
            <li >
              <router-link :to="`/PrintSteward/boss`">
                <img src="~@/assets/img/icon-home2.png" alt="">
                大屏展示老板
              </router-link>
            </li>
            <li>
              <router-link :to="`/PrintSteward/workshop`">
                <img src="~@/assets/img/icon-home2.png" alt="">
                大屏展示车间
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!--<HomeWelcome />-->
      <div class="page-inner home-box">
        <!--上-->
        <div class="page-top">
          <!--左：员工总数、设备总数-->
          <div style="width: 1200px" class="flex-full mr-20">
            <el-row type="flex" :gutter="30" class="mb-20" style="margin-right: 0px;">
              <!--员工总数-->
              <el-col :span="12" style="padding-right:5px">
                <div class="card-box1">
                  <div class="card-head">
                    <div class="card-title">{{ $t('yesterday_quantity') }}</div>
                  </div>
                 <div>
                   <BarChart
                       v-if="chartData.source.length > 0"
                       :id="'chartData2'"
                       :subtext="$t('unitzhang')"
                       :data="chartData"
                       :height="250"
                       :barColor="barColor"
                       :legendShow="false"
                   />
                   <div v-else class="node">{{ $t('noData') }}</div>
                 </div>
                </div>
              </el-col>
              <!--设备总数-->
              <el-col :span="12" style="padding-right:5px">
                <div class="card-box1 is-bg">
                  <div class="card-head">
                    <div class="card-title">{{ $t('yesterday_time_utilization_Rate') }}</div>
                  </div>
                  <div>
                    <BarChart
                        v-if="chartData2.source.length > 0"
                        :id="'chartData3'"
                        :subtext="$t('unitmin')"
                        :data="chartData2"
                        :height="250"
                        :barColor="barColor"
                        :legendShow="false"
                    />
                    <div v-else class="node">{{ $t('noData') }}</div>
                  </div>
                </div>
              </el-col>
              <!--设备总数-->
              <el-col :span="12" style="padding-right:0px">
                <div class="card-box1 is-bg">
                  <div class="card-head">
                    <div class="card-title">{{ $t('yesterday_performance_utilization_rate') }}</div>
                  </div>
                  <div>
                    <BarChart
                        v-if="chartData3.source.length > 0"
                        :id="'chartData4'"
                        :subtext="$t('unitTime')"
                        :data="chartData3"
                        :height="250"
                        :barColor="barColor"
                        :legendShow="false"
                    />
                    <div v-else class="node">{{ $t('noData') }}</div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <!--数采设备总数-->
            <div class="card-box1 is-bg">
              <div class="card-head">
                <div class="card-title">待办</div>
              </div>
              <div class="todo">
                <div>
                  <el-tabs v-model="activeName" @tab-click="getDeviceListById">
                    <el-tab-pane label="点检" v-if="userInfo.roleType === 2 || userInfo.roleType === 4 || userInfo.roleType === 3" name="first">
                      <div class="table-box">
                        <el-table
                            :data="inspectionList"
                            border
                            height="315"
                            highlight-current-row
                            style="width: 100%"
                            v-infinite-scroll="load1"
                        >
                          <el-table-column :label="$t('deviceName')" align="center">
                            <template slot-scope="scope">
                              {{ scope.row.deviceName }}
                            </template>
                          </el-table-column>
                          <el-table-column :label="$t('maintenance_type')" align="center">
                            <template slot-scope="scope">
                              {{ scope.row.typeName}}
                            </template>
                          </el-table-column>
                          <el-table-column label="计划日期" align="center">
                              <template slot-scope="scope">
                                {{scope.row.startTime}}
                              </template>
                          </el-table-column>
                          <el-table-column label="实际完成日期" align="center">
                            <template slot-scope="scope">
                              {{scope.row.baoYangTime}}
                            </template>
                          </el-table-column>
                          <!--操作-->
                          <el-table-column :label="$t('caozuo')" header-align="center" align="center" width="240">
                            <template slot-scope="scope">
                              <el-button type="primary" v-if="scope.row.typeName !== $t('cancel_maintenance')" size="mini" @click="handleInspection(scope.row.id)">
                                去点检
                              </el-button>
                              <el-button type="primary" v-if="scope.row.typeName === $t('cancel_maintenance')" size="mini" @click="handleMaintenance(scope.row.id)">
                                去审批
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane v-if="userInfo.roleType === 2 " :label="$t('replacement')" name="second">
                      <div class="table-box">
                        <el-table
                            :data="replacementList"
                            ref="myTable"
                            border
                            height="315"
                            highlight-current-row
                            style="width: 100%"
                            v-infinite-scroll="load2"
                        >
                          <el-table-column :label="$t('deviceName')" align="center" >
                            <template slot-scope="scope">
                              {{ scope.row.deviceName }}
                            </template>
                          </el-table-column>
                          <!--性别-->
                          <el-table-column :label="$t('replacement_item')" align="center" >
                            <template slot-scope="scope">
                              {{ scope.row.project }}
                            </template>
                          </el-table-column>
                          <!--手机号-->
                          <el-table-column :label="$t('type')" align="center" width="150">
                            <template slot-scope="scope">
                              <span>{{ scope.row.typeName }}</span>
                            </template>
                          </el-table-column>
                          <!--操作-->
                          <el-table-column :label="$t('caozuo')" align="center" width="170">
                            <template slot-scope="scope">
                              <el-button
                                  type="primary"
                                  size="mini"
                                  @click="checkAbnormal(scope.row.id)"
                              >
                                去审批
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane v-if="userInfo.roleType === 5 || userInfo.roleType === 11" label="保养" :name="userInfo.roleType === 5 ? 'first' : userInfo.roleType === 11 ? 'first' : 'three'">
                      <!--表格-->
                      <div class="table-box">
                        <el-table
                            :data="maintainanceList"
                            element-loading-text="Loading"
                            border
                            height="315"
                            highlight-current-row
                            style="width: 100%"
                            v-infinite-scroll="load"
                        >
                          <!--昵称-->
                          <el-table-column :label="$t('deviceName')" align="center" >
                            <template slot-scope="scope">
                              {{ scope.row.deviceName }}
                            </template>
                          </el-table-column>
                          <el-table-column :label="$t('maintenance_type')" align="center" width="150">
                            <template slot-scope="scope">
                              <span>{{ scope.row.typeName }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column :label="$t('maintenance_personnel')" align="center" width="150">
                            <template slot-scope="scope">
                              <span>{{ scope.row.baoYangPreson }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column label="保养日期" align="center" width="150">
                            <template slot-scope="scope">
                              <span>{{ scope.row.baoYangDate }}</span>
                            </template>
                          </el-table-column>
                          <!--操作-->
                          <el-table-column :label="$t('caozuo')" align="center" width="170">
                            <template slot-scope="scope">
                              <el-button
                                  type="primary"
                                  size="mini"
                                  @click="handleMaintain(scope.row.id)"
                              >
                                去保养
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <!--分页-->
                    </el-tab-pane>
                    <el-tab-pane v-if="userInfo.roleType === 3" :label="$t('exception_submission')" name="four">
                      <!--表格-->
                      <div class="table-box">
                        <el-table
                            :data="abnormalList"
                            element-loading-text="Loading"
                            border
                            height="315"
                            highlight-current-row
                            style="width: 100%"
                            v-infinite-scroll="load3"
                        >
                          <!--昵称-->
                          <el-table-column :label="$t('deviceName')" align="center" >
                            <template slot-scope="scope">
                              {{ scope.row.deviceName }}
                            </template>
                          </el-table-column>
                          <el-table-column :label="$t('yichangReason')" align="center" width="150">
                            <template slot-scope="scope">
                              <span>{{ scope.row.reason }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column :label="$t('yichangTime')" align="center" width="150">
                            <template slot-scope="scope">
                              <span>{{ scope.row.invalidTime }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column label="提报人员" align="center" width="150">
                            <template slot-scope="scope">
                              <span>{{ scope.row.submitter }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column prop="created_at" :label="$t('submit_time')" align="center" width="170">
                            <template slot-scope="scope">
                              <span>{{ scope.row.baoYangTime }}</span>
                            </template>
                          </el-table-column>
                          <!--                          <el-table-column prop="created_at" label="状态" align="center" width="170">-->
                          <!--                            <template slot-scope="scope">-->
                          <!--                              <span>{{ scope.row.state }}</span>-->
                          <!--                            </template>-->
                          <!--                          </el-table-column>-->
                          <!--操作-->
                          <el-table-column :label="$t('caozuo')" align="center" width="170">
                            <template slot-scope="scope">
                              <el-button
                                  type="primary"
                                  size="mini"
                                  @click="handleApproval(scope.row.id)"
                              >
                                去审批
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <!--分页-->
                    </el-tab-pane>
                    <el-tab-pane v-if="userInfo.roleType === 5 || userInfo.roleType === 11" :label="$t('exception_submission')" name="four">
                      <!--表格-->
                      <div class="table-box">
                        <el-table
                            :data="abnormalList2"
                            element-loading-text="Loading"
                            border
                            height="315"
                            highlight-current-row
                            style="width: 100%"
                            v-infinite-scroll="load4"
                        >
                          <!--昵称-->
                          <el-table-column :label="$t('deviceName')" align="center" >
                            <template slot-scope="scope">
                              {{ scope.row.deviceName }}
                            </template>
                          </el-table-column>
<!--                          <el-table-column label="异常待机原因" align="center" width="150">-->
<!--                            <template slot-scope="scope">-->
<!--                              <span>{{ scope.row.reason }}</span>-->
<!--                            </template>-->
<!--                          </el-table-column>-->
                          <el-table-column :label="$t('yichangTime')" align="center" >
                            <template slot-scope="scope">
                              <span>{{ scope.row.invalidTime }}</span>
                            </template>
                          </el-table-column>
<!--                          <el-table-column label="提报人员" align="center" width="150">-->
<!--                            <template slot-scope="scope">-->
<!--                              <span>{{ scope.row.submitter }}</span>-->
<!--                            </template>-->
<!--                          </el-table-column>-->
<!--                          <el-table-column prop="created_at" label="提报时间" align="center" width="170">-->
<!--                            <template slot-scope="scope">-->
<!--                              <span>{{ scope.row.baoYangDate }}</span>-->
<!--                            </template>-->
<!--                          </el-table-column>-->
                          <!--                          <el-table-column prop="created_at" label="状态" align="center" width="170">-->
                          <!--                            <template slot-scope="scope">-->
                          <!--                              <span>{{ scope.row.state }}</span>-->
                          <!--                            </template>-->
                          <!--                          </el-table-column>-->
                          <!--操作-->
                          <el-table-column :label="$t('caozuo')" align="center" width="170">
                            <template slot-scope="scope">
                              <el-button
                                  type="primary"
                                  size="mini"
                                  @click="handlePeport(scope.row.id)"
                              >
                                去提报
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <!--分页-->
                    </el-tab-pane>
                    <el-tab-pane v-if="userInfo.roleType === 11 || userInfo.roleType === 2 || userInfo.roleType === 6 || userInfo.roleType === 5"  :label="$t('weixiu')" :name="userInfo.roleType === 6 ? 'first' : 'five'">
                      <!--表格-->
                      <div class="table-box">
                        <el-table
                            :data="repairList"
                            element-loading-text="Loading"
                            border
                            height="315"
                            highlight-current-row
                            style="width: 100%"
                            v-infinite-scroll="load5"
                        >
                          <!--昵称-->
                          <el-table-column :label="$t('deviceName')" align="center" >
                            <template slot-scope="scope">
                              {{ scope.row.deviceName }}
                            </template>
                          </el-table-column>
                          <el-table-column :label="$t('fault_issue')" align="center" width="150">
                            <template slot-scope="scope">
                              <span>{{ scope.row.problem }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column :label="$t('repair_request_personnel')" align="center" width="150">
                            <template slot-scope="scope">
                              <span>{{ scope.row.submitter }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column prop="created_at" :label="$t('submit_time')" align="center" width="170">
                            <template slot-scope="scope">
                              <span>{{ scope.row.baoYangDate }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column prop="created_at" :label="$t('device_state')" align="center" width="170">
                            <template slot-scope="scope">
                              <span>{{ scope.row.state }}</span>
                            </template>
                          </el-table-column>
                          <!--操作-->
                          <el-table-column :label="$t('caozuo')" align="center" width="170">
                            <template slot-scope="scope">
                              <el-button
                                  type="primary"
                                  size="mini"
                                  @click="checkFault(scope.row.id,1)"
                                  v-if="scope.row.state === '待指派'"
                              >
                                去指派
                              </el-button>
                              <el-button
                                  type="primary"
                                  size="mini"
                                  @click="checkFault(scope.row.id)"
                                  v-if="scope.row.state === $t('pending_start') "
                              >
                                去维修
                              </el-button>
                              <el-button
                                  type="primary"
                                  size="mini"
                                  @click="checkFault(scope.row.id)"
                                  v-if="scope.row.state === $t('in_progress')"
                              >
                                报告填写
                              </el-button>
                              <el-button
                                  type="primary"
                                  size="mini"
                                  @click="checkFault(scope.row.id)"
                                  v-if="scope.row.state === $t('pending_confirmation') || scope.row.state === '待验收'"
                              >
                                去确认
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <!--分页-->
                    </el-tab-pane>

                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
          <!--右：员工活跃度-->
          <div style="width: 460px" class="flex-full">
            <div class="card-box3">
              <div class="card-top">
                <div class="card-item">
                  <img src="@/assets/img/icon-count.png" alt="">
                  <div class="card-text" style="width: 70px">
                    <div>{{ $t('total_employees')  }}</div>
                    <div><span>{{ data.userCount }}</span>{{ $t('ming') }}</div>
                  </div>
                </div>
                <div class="card-item">
                  <img src="@/assets/img/icon-count3.png" alt="">
                  <div class="card-text">
                    <div>{{ $t('total_number_data_acquisition_devices') }}</div>
                    <div><span>{{ data.allcount }}</span>{{ $t('tower') }}</div>
                  </div>
                </div>
              </div>
              <div class="card-top">
                <div class="card-item">
                  <img src="@/assets/img/icon-count2.png" alt="">
                  <div class="card-text" style="width: 70px">
                    <div>{{ $t('total_number_devices') }}</div>
                    <div><span>{{ data.deviceCount }}</span>{{ $t('tower') }}</div>
                  </div>
                </div>
                <div class="card-item2">
                  <div>
                    <div class="number">{{ data.yinQianCount }}</div>
                    <div>{{ $t('yinqian_device') }}</div>
                  </div>
                  <div>
                    <div class="number">{{ data.yinHouCount }}</div>
                    <div>{{ $t('yinhou_device') }}</div>
                  </div>
                  <div>
                    <div class="number">{{ data.yinShuaCount }}</div>
                    <div>{{ $t('yinshua_device') }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-box1 card-box">
              <div class="card-head" style="margin-bottom: 0px">
                <div class="card-title">{{ $t('yichangTime') }}</div>
              </div>
              <div>
                <BarChart
                    v-if="chartData4.source.length > 0"
                    :id="'chartData4'"
                    :data="chartData4"
                    :subtext="$t('Unit_Minutes')"
                    :height="160"
                    :barColor="barColor2"
                    :legendShow="false"
                />
                <div v-else class="node2">{{ $t('noData') }}</div>
              </div>
            </div>
            <div class="card-box1 ">
              <div class="card-head" style="margin-bottom: 0px">
                <div class="card-title">{{ $t('shiyinzhanbi') }}</div>
              </div>
              <div>
                <BarChart
                    v-if="chartDataTotal.source.length > 0"
                    :id="'chartData5'"
                    :data="chartDataTotal"
                    :height="160"
                    :barColor="barColor2"
                    :legendShow="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import API from '@/api';
import { manageOauth } from '@/utils/validate';
import BarChart from '@/components/Charts/BarChart';
import {store} from "@/store";
import cookies from "@/utils/cookies"; // 图表组件
// import HomeWelcome from '@/components/HomeWelcome' // 首页欢迎
export default {
  // name: 'PrintSteward',
  // components: { HomeWelcome },
  components: { BarChart },
  data() {
    return {
      VUE_APP_HOME: process.env.VUE_APP_LOGIN,
      iconImg: false,
      teamOperate: false, // 操作人员权限
      deviceOperate: false, // 操作设备
      activeName: 'first',
      inspectionList: [],
      replacementList: [],
      abnormalList: [],
      abnormalList2: [],
      faultList: [],
      repairList: [],
      maintainanceList: [],
      inspectionCount: 0,
      replacementcount: 0,
      abnormalCount: 0,
      abnormalCount2: 0,
      repairCount: 0,
      maintainanceCount: 0,
      // 页面数据
      data: {},
      formDate: {
        type: -1,
        page: 1,
        count: 10
      },
      // 图表
      chartData: {
        // 数据集
        source: [],
        // 系列
        series: [
          {
            type: 'bar',
            barMaxWidth: 25,
          },
        ],
      },
      chartData2: {
        // 数据集
        source: [],
        // 系列
        series: [
          {
            type: 'bar',
            barMaxWidth: 25,
          },
        ],
      },
      chartData3: {
        // 数据集
        source: [],
        // 系列
        series: [
          {
            type: 'bar',
            barMaxWidth: 25,
          },
        ],
      },
      chartData4: {
        // 数据集
        source: [],
        // 系列
        series: [
          {
            type: 'line',
          },
        ],
      },
      // 柱形颜色
      barColor: [
        // eslint-disable-next-line new-cap,no-new-require
        new require('echarts').graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#FA460D' },
          { offset: 1, color: '#F7B515' },
        ]),
      ],
      barColor2: [
        // eslint-disable-next-line new-cap,no-new-require
        new require('echarts').graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#FB7F19'},
          { offset: 1, color: '#FB7F19' },
        ]),
      ],
      userInfo: {
        userName: '',
        url: '',
        phone: '',
        email: '',
        teamName: '',
      },
      chartDataTotal: {
        // 数据集
        source: [],
        // 系列
        series: [
          {
            type: "line",
          },
        ],
      },
      defaultUrl: '',
    };
  },
  created() {
    this.getList()
    this.getData();
    this.getUserInfo();
    this.activeName = this.$store.state.homeForm.activeName
  },
  watch: {
    // 监听VUEX中buttons
    buttons: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log(2, val)
        if (val && val.length > 0) {
          // console.log(3, val)
          this.managePermission();
        }
      },
    },
  },
  computed: {
    // 获取VUEX中buttons
    buttons() {
      return this.$store.getters.userInfo.buttons;
    },Url() {
      return `${process.env.VUE_APP_API}/psl/user/url/modify`;
    },

  },
  methods: {
    processData(data, chartData, valueKey) {
      const list = data.map((item) => {
        if (item.name !== 'NaN') {
          return [item.name, Number(item[valueKey])];
        }
      });
      if (list[0]) {
        chartData.source = [["product", ""], ...list];
      } else {
        chartData.source = [];
      }
    },
    // 获取数据
    getData() {
      API.printHome().then((res) => {
        // console.log(res.message.data)
        this.data = res.message.data;
        // 处理图表数据
        // const { list } = res.message.data;
        // const source = [];
        // if (list.length > 0) {
        //   for (const item of list) {
        //     source.push([item.time, item.count]);
        //   }
        //   this.chartData.source = [
        //     ['product', '日活跃度'],
        //     ...source,
        //   ];
        // } else {
        //   this.chartData.source = [];
        // }
      });
      API.TimeDailyInfo({ day: 7, deviceId: '' }).then(res => {
        if (res.message.data.length > 0) {
          const list2 = res.message.data.map((item) => {
            if (item.time !== 'NaN') {
              return [item.date, Number(item.time)];
            }
          });
          if (list2[0]) {
            this.chartDataTotal.source = [["product", ""], ...list2]
          } else {
            this.chartDataTotal.source = [];
          }
        } else {
          this.chartDataTotal.source = [];
        }
      })
      API.deviceInfo().then(res => {
        console.log(res)
        if (res.message.data.length > 0) {
          const data = res.message.data;
          this.processData(data, this.chartData, 'value1');
          this.processData(data, this.chartData2, 'value2');
          this.processData(data, this.chartData3, 'value3');
          this.processData(data, this.chartData4, 'value4');
        } else {
          this.chartData.source = [];
          this.chartData2.source = [];
          this.chartData3.source = [];
          this.chartData4.source = [];
        }
      })
    },
    getList () {
      API.getTaskList({...this.formDate,type:2}).then(res => {
        this.inspectionList = res.message.data.list
        this.inspectionCount = res.message.data.totalCount
      })
      API.getTaskList({...this.formDate,type:4}).then(res => {
        this.replacementList = res.message.data.list
        this.replacementcount = res.message.data.totalCount
      })
      API.getTaskList({...this.formDate,type:6}).then(res => {
        this.abnormalList = res.message.data.list
        this.abnormalCount = res.message.data.totalCount
      })
      API.getTaskList({...this.formDate,type:7}).then(res => {
        this.abnormalList2 = res.message.data.list
        this.abnormalCount2 = res.message.data.totalCount
      })
      API.getTaskList({...this.formDate,type:3}).then(res => {
        this.repairList = res.message.data.list
        this.repairCount = res.message.data.totalCount
      })
      API.getTaskList({...this.formDate,type:1}).then(res => {
        this.maintainanceList = res.message.data.list
        this.maintainanceCount = res.message.data.totalCount
      })
    },
    load() {
      console.log(window.scrollY)
      // 模拟异步加载数据
      if (this.formDate.count < this.maintainanceCount ) {
        this.formDate.count += 10
        API.getTaskList({...this.formDate,type:1}).then(res => {
          this.maintainanceList = res.message.data.list
          this.maintainanceCount = res.message.data.totalCount
        })
      }
    },
    load1() {
      // console.log(window.scrollY)
      // console.log(this.$refs.multipleTable.bodyWrapper.scrollTop)
      // 模拟异步加载数据
      if (this.formDate.count < this.inspectionCount ) {
        this.formDate.count += 10
        API.getTaskList({...this.formDate,type:2}).then(res => {
          this.inspectionList = res.message.data.list
          this.inspectionCount = res.message.data.totalCount
        })
      }
    },
    load2() {
      // const table = this.$refs.myTable.$el.querySelector('.el-table__body-wrapper');

// 获取 el-table 内容区域的 scrollHeight
//       const scrollHeight = table.scrollHeight;
//       console.log('Scroll Height:', scrollHeight);
      // 模拟异步加载数据
      if (this.formDate.count < this.replacementcount ) {
        this.formDate.count += 10
        API.getTaskList({...this.formDate,type:4}).then(res => {
          this.replacementList = res.message.data.list
          this.replacementcount = res.message.data.totalCount
        })
      }
    },
    load3() {
      // 模拟异步加载数据
      if (this.formDate.count < this.abnormalCount ) {
        this.formDate.count += 10
        API.getTaskList({...this.formDate,type:6}).then(res => {
          this.abnormalList = res.message.data.list
          this.abnormalCount = res.message.data.totalCount
        })
      }
    },
    load4() {
      // 模拟异步加载数据
      if (this.formDate.count < this.abnormalCount2 ) {
        this.formDate.count += 10
        API.getTaskList({...this.formDate,type:7}).then(res => {
          this.abnormalList2 = res.message.data.list
          this.abnormalCount2 = res.message.data.totalCount
        })
      }
    },
    load5() {
      // 模拟异步加载数据
      if (this.formDate.count < this.repairCount ) {
        this.formDate.count += 10
        API.getTaskList({...this.formDate,type:3}).then(res => {
          this.repairList = res.message.data.list
          this.repairCount = res.message.data.totalCount
        })
      }
    },
    getDeviceListById(tab) {
      console.log(tab)
      console.log(this.activeName)
      this.formDate.count = 10
      this.getList()
    },
    handleInspection(id) {
      this.$store.commit('homeFormSet', {page: 1,count: this.formDate.count, activeName: this.activeName});
      this.$router.push({path: 'inspection', query: {id: id, type: 2}})
    },
    handleMaintenance(id) {
      this.$store.commit('homeFormSet', {page: 1,count: this.formDate.count, activeName: this.activeName});
      this.$router.push({ path: 'maintenanceApproval', query: { id: id } })
    },
    checkAbnormal(id) {
      this.$store.commit('homeFormSet', {page: 1,count: this.formDate.count, activeName: this.activeName});
      this.$router.push({ path: 'Approval', query: { id: id } })
    },
    checkFault(id,number) {
      this.$store.commit('homeFormSet', {page: 1,count: this.formDate.count, activeName: this.activeName});
      this.$router.push({ path: 'faultDetail', query: { deviceRepairId: id, number: number} })
    },
    handleApproval(id) {
      this.$store.commit('homeFormSet', {page: 1,count: this.formDate.count, activeName: this.activeName});
      this.$router.push({ path: 'abnormalDetail', query: { id: id } })
    },
    handleMaintain(id) {
      this.$store.commit('homeFormSet', {page: 1,count: this.formDate.count, activeName: this.activeName});
      this.$router.push({ path: 'maintenDetail', query: { id: id } })
    },
    handlePeport(id) {
      this.$store.commit('homeFormSet', {page: 1,count: this.formDate.count, activeName: this.activeName});
      this.$router.push({ path: 'abnormalReportDetail', query: { id: id } })
    },
    handleConfirm() {

    },
    // 是否有操作人员、设备权限
    managePermission() {
      // 人员权限
      if (manageOauth('team_operate', this.buttons)) {
        this.teamOperate = true;
      }
      // 设备权限
      if (manageOauth('device_operate', this.buttons)) {
        this.deviceOperate = true;
      }
    },
    // 跳转页面
    toPage(path, roleId, ref) {
      this.$router.push({
        path,
        query: {
          roleId, // 角色id
          ref, // 自动弹出下拉框的ref
        },
      });
    },
    handleIcon() {
      if (this.iconImg === true) {
        this.iconImg = false
      } else {
        this.iconImg = true
      }
    },
    handleIcon1() {
      if (this.iconImg === true) {
        this.iconImg = false
      }
    },
    // 返回portal首页
    ToHomepage() {
      // window.open(`${this.VUE_APP_HOME}`);
    },
    // 退出登录
    exit() {
      this.$confirm('确定要退出此用户?', this.$t('prompt'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
          .then(() => {
            store.dispatch('logout').then(() => {
              cookies.remove('token');
              window.open(`${this.VUE_APP_HOME}login/:SUP/`, '_self');
            });
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消',
            // });
          });
    },
    // 获取个人信息
    getUserInfo() {
      store.dispatch('getInfo').then((res) => {
        if (res.message.success) {
          this.userInfo = res.message.data;
          // console.log(this.userInfo)
          this.defaultUrl = res.message.data.url;
          if (this.userInfo.userlanguage === 'en_US') {
            this.$i18n.locale = 'en'
            localStorage.setItem('locale', 'en')
          } else {
            this.$i18n.locale = 'zh'
            localStorage.setItem('locale', 'zh')
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {

  .page-inner {
    /*height: 100%;
    overflow: auto;
    margin-right: -40px;*/

    .page-top,
    .page-bottom {
      /*min-width: 1620px;
      padding-right: 40px;*/
      margin-bottom: 30px;
    }

    .page-top {
      display: flex;
    }
  }
}
.section-title {
  padding-left: 0;
  border-left: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}
/*卡片*/
.card-box1 {
  display: flex;
  flex-direction: column;
  /*overflow: hidden;*/
  box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);
padding: 25px;
  background-color: #fff;
  border-radius: 5px;
  .card-head {
    //height: 38px;

    .card-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
    }
  }
  .card-body {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 50px 40px;
    background-color: #fff;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    transition: background 0.3s;
    border-radius: 2px;
    height: 150px;
    box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);
    /*左*/
    .left {
      width: 40%;
    }
    /*右*/
    .right {
      width: 60%;
      display: flex;
      /*margin-left: auto;*/
      .number {
        font-size: 26px;
        font-family: 'DIN-Medium';
        line-height: 1;
      }
      /*右上*/
      .right-top {
        display: flex;
        align-items: center;
        margin-right: 40px;
      }
      /*右下*/
      .right-bottom {
        display: flex;
        align-items: center;
      }
    }
    .number {
      font-size: 50px;
      font-family: 'DIN-Bold';
      line-height: 1;
    }
  }
}
.card-box2 {
  background: #fff;
  border-radius: 2px;
  padding: 30px 40px 40px;
  height: 100%;
  box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);

  .card-head {
    display: flex;
    justify-content: space-between;
    height: 31px;

    .card-title {
      font-size: 16px;
      color: #000;
      line-height: 1;
    }
  }
  .card-body {
    display: flex;
    justify-content: center;
  }
}
/*标签*/
.tag {
  font-size: 14px;
  line-height: 1;
  border-radius: 2px;
  padding: 4px 10px;
  background: #858585;
  color: #fff;
  white-space: nowrap;

  &.tag-warning {
    background: #f7633c;
  }
}
/*图片组*/
.img-box {
  text-align: center;
  cursor: pointer;
  // 禁用
  &.disable {
    cursor: initial;
  }

  img {
    max-width: 100%;
    /*分隔*/
    &.separated {
      padding: 40px 0;
    }
  }
  .text {
    font-size: 14px;
  }
}
.header-box {
  display: none;
}
@media only screen and (max-width: 1366px) {
  .card-box1 {
    .card-body {
      .number {
        font-size: 40px;
      }
      .right {
        .right-top {
          margin-right: 10px;
        }
      }
    }
  }
}
@media screen and (max-width:800px){
  .header-box {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    background: #3e2b25;
    box-shadow: 0px 2px 6px 3px #bcbcbc;
    padding: 0 50px;
    position: fixed;
    width: 100%;
    z-index: 99999;
    .menu-app {
      display: none;
      position: absolute;
      bottom: -750px;
      left: 0;
      background-color: #fff;
      z-index: 9999;
      width: 100%;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      box-shadow: 0rem 0px 18px 0px #aaa6a6;
      ul{
        list-style: none;
        padding: 0 100px;
        li {
          display: block;
          border-bottom: 1px solid #E1E1E1;
          font-size: 78px;
          color: #000;
          padding: 70px 0;
          text-align: center;
        }
        img {
          width: 70px;
          margin-right: 20px;
        }
        a{
          text-decoration: none;
          color: #000;
          display: inline-block;
          width: 600px;
          text-align: left;
        }
      }
    }
    .activeImg {
      display: block;
    }
    .right-box {
      float: right;
      //margin-right: 40px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    #logo {
      //height: 62px;
      //width: 202px;
      text-align: center;
      .img {
        margin-top: 30px;
        //height: 41px;
        width: 300px;
      }
    }
    .head li {
      font-size: 88px;
      color: #fff !important;
      //width: 80px;
      //height: 62px;
      //line-height: 62px;
      position: relative;
      align-items: center;
      float: left;
      //font-weight: bold;
      // margin-right: 80px;
      cursor: pointer;
      display: flex;
      .exit {
        width: 120px;
        height: 57px;
        line-height: 57px;
      }
    }
    .icon-order {
      width: 100px;
      height: 70px;
      margin-left: 30px;
    }
    .exit:hover {
      color: #f3a000;
    }
    .head > li a:hover {
      color: #f3a000;
    }
    .head {
      .active {
        color: #fff;
        text-decoration: none;
        transition: all ease 0.3s;
      }
    }
    .UserName {
      width: 200px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
      position: relative;
    }
    .UserName:hover .menu {
      display: block;
      transition: all ease 0.3s;
    }
    .information {
      width: 120px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
    }
    .menu {
      display: none;
      position: absolute;
      top: 60px;
      right: 0px;
      border: 1px solid #9999;
      background: #ffffff;
      z-index: 9;
      .pullDown {
        width: 62px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        display: block;
      }
      li {
        width: 62px;
        height: 60px;
        color: #231815;
      }
    }
    .pullDown:hover {
      color: #f3a000;
      transition: all ease 0.3s;
    }
    .menu > li:hover a {
      color: #f3a000;
      transition: all ease 0.3s;
    }
  }
  .activeImg {
    display: block;
  }
  .mr-30 {
    width: 100% !important;
  }
  .page-container {
    padding: 0!important;
    .home-box {
      padding: 40px;
    }
    .page-inner {
      /*height: 100%;
      overflow: auto;
      margin-right: -40px;*/

      .page-top,
      .page-bottom {
        /*min-width: 1620px;
        padding-right: 40px;*/
        margin-bottom: 30px;
        margin-top: 250px;
      }

      .page-top {
        display: block;
      }
    }
  }
  .section-title {
    padding-left: 0;
    border-left: none;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  }
  /*卡片*/
  .card-box1 {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    /*overflow: hidden;*/

    .card-head {
      height: 150px;

      .card-title {
        font-size: 78px;
        font-weight: bold;
        line-height: 1;
        margin-top: 20px;
      }
    }
    .card-body {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      padding: 300px 180px;
      background-color: #fff;
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      transition: background 0.3s;
      border-radius: 2px;
      height: 150px;
      box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);
      /*左*/
      .left {
        width: 40%;
      }
      /*右*/
      .right {
        width: 60%;
        display: flex;
        /*margin-left: auto;*/
        .number {
          font-size: 120px;
          font-family: 'DIN-Medium';
          line-height: 1;
        }
        /*右上*/
        .right-top {
          display: flex;
          align-items: center;
          margin-right: 100px;
        }
        /*右下*/
        .right-bottom {
          display: flex;
          align-items: center;
        }
      }
      .number {
        font-size: 180px;
        font-family: 'DIN-Bold';
        line-height: 1;
      }
    }
    .card-body2 {
      padding: 150px 100px !important;
    }

  }
  .card-box2 {
    background: #fff;
    border-radius: 2px;
    padding: 30px 40px 40px;
    height: 100%;
    box-shadow: 0 0 8px 4px rgba(215, 215, 215, 0.3);

    .card-head {
      display: flex;
      justify-content: space-between;
      height: 31px;

      .card-title {
        font-size: 16px;
        color: #000;
        line-height: 1;
      }
    }
    .card-body {
      display: flex;
      justify-content: center;
    }
    .card-body2 {
      padding: 0 !important;
    }
  }
  /*标签*/
  .tag {
    font-size: 68px;
    line-height: 1;
    border-radius: 15px;
    padding: 30px 20px;
    background: #858585;
    color: #fff;
    white-space: nowrap;

    &.tag-warning {
      background: #f7633c;
    }

  }
  /*图片组*/
  .img-box {
    text-align: center;
    cursor: pointer;
    // 禁用
    &.disable {
      cursor: initial;
    }

    img {
      max-width: 100%;
      /*分隔*/
      &.separated {
        padding: 40px 0;
      }
    }
    .text {
      font-size: 14px;
    }
  }
  .flex-full {
    width: 100% !important;
  }
  .mb-40 {
    margin: 0!important;
  }
  .el-col-12 {
    //width: 48% !important;
    padding: 15px !important;
  }
  .mr-10 {
    margin-right: 30px!important;
  }
}
.card-box3 {
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  box-shadow: 0 0 0.041667rem 0.020833rem rgba(215, 215, 215, 0.3);
  padding: 58px 10px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
}
.card-top {
  display: flex;
  font-size: 16px;
  color: #1D2129;
  img{
    width: 63px;
    margin-right: 14px;
  }
  .card-item {
    display: flex;
    align-items: center;
  }
  .card-item2 {
    color: #1D2129;
    display: flex;
    text-align: center;
    justify-content: space-between;
    width: 55%;
    font-size: 13px;
    .number {
      font-size: 28px;
      margin-bottom: 6px;
      font-weight: bold;
    }
  }
  .card-item:first-child {
    padding-right: 25px;
    margin-right: 25px;
    border-right: 1px solid #EBEBEB;
  }
  .card-text {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    div:first-child {
      margin-bottom: 10px;
    }
    div:last-child {
      color: #4E5969;
    }
    span{
      font-size: 26px;
      color: #1D2129;
      display: inline-block;
      margin-right: 10px;
    }
  }
}
.card-box3 .card-top:first-child {
  margin-bottom: 75px;
}
.card-box {
  margin-bottom: 20px;
}
.todo {
  margin-top: 20px;
}
::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #F3A100;
}
::v-deep .el-tabs__item {
  font-size: 14px;
}
.node {
  height: 250px;
  text-align: center;
  font-size: 16px;
  line-height: 14;
}
.node2 {
  height: 160px;
  text-align: center;
  font-size: 16px;
  line-height: 10;
}
</style>

