
<!--设备运维：异常提报-->
<template>
  <div class="">
    <div class="page-container">
      <div class="section">
        <h2 class="h2Style">
          {{ $t('exception_submission') }}
        </h2>
        <!--搜索栏-->
        <div class="control-box1">
          <div class="cb-left">
          </div>

          <div class="cb-right">
            <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addTeamUserShow()"
            >
              {{ $t('add') }}
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div class="table-box" style="width: 100%">
          <el-table
              v-loading="listLoading"
              :data="teamUser"
              element-loading-text="Loading"
              border
              highlight-current-row
              style="width: 100%"
          >
            <!--昵称-->
            <el-table-column :label="$t('deviceName')" align="center" width="300">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <!--性别-->
            <el-table-column :label="$t('yichangTime')" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.actualTime }}
              </template>
            </el-table-column>
            <!--角色-->
            <el-table-column :label="$t('submission_reason')" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.reason }}</span>
              </template>
            </el-table-column>
            <!--手机号-->
            <el-table-column :label="$t('reporter')" align="center" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.person }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" :label="$t('submit_time')" align="center" width="170">
              <template slot-scope="scope">
                <span>{{ scope.row.date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" :label="$t('device_state')" align="center" width="150">
              <template slot-scope="scope">
                <span :style="{color:scope.row.state === 0 ? '#EA000E' : scope.row.state === 1 ? '#F7B515' : scope.row.state === 2 ? '#37C239' :'#777474' }">{{scope.row.state === 0 ? '未提报' : scope.row.state === 1 ? $t('unreviewed') : scope.row.state === 2 ? $t('reviewed') :$t('overtime_cancellation')}}</span>
              </template>
            </el-table-column>
            <!--操作-->
            <el-table-column :label="$t('caozuo')" align="center" width="170">
              <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="updateTeamUser(scope.row)"
                >
                  {{ $t('device_view') }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--分页-->
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.count"
              :total="totalCount"
              :current-page="form.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <!--弹出框：添加异常提报-->
      <el-dialog
          :visible.sync="TeamUserShow"
          title="添加异常提报"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="false"
          width="30%"
          @close="close1()"
      >
        <el-form label-position="left" ref="inviteForm" label-width="110px" :model="invite" :rules="rules.inviteRules">
          <el-form-item :label="$t('deviceName')" prop="deviceId">
            <el-select
                v-model="invite.deviceId"
                :placeholder="$t('xuanzeDevice')"
                style="width: 100%"
                @change="handleDevice"
            >
              <el-option
                  v-for="item in deviceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('edingTime')" prop="phone">
            <span>{{ratedTime}}</span>
          </el-form-item>
          <el-form-item :label="$t('startTime')" prop="startTime">
            <el-date-picker
                v-model="invite.startTime"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('endTime')" prop="endTime">
            <el-date-picker
                v-model="invite.endTime"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('working_hours')" prop="workTime">
            <el-input-number
                v-model="invite.workTime"
                show-word-limit
                :max="1000000000000"
                :min="0"
                :precision="0"
                :placeholder="$t('enter_working_hours')"
            />
            <span style="margin-left: 10px">{{ $t('min') }}</span>
          </el-form-item>
          <el-form-item :label="$t('yichangTime')" prop="actualTime">
            <el-input-number
                v-model="invite.actualTime"
                show-word-limit
                :max="1000000000000"
                :min="0"
                :precision="0"
                :placeholder="$t('enter_abnormal_standby_time')"
            />
            <span style="margin-left: 10px">{{ $t('min') }}</span>
          </el-form-item>
          <el-form-item :label="$t('submission_reason')" prop="reason">
            <el-select
                v-model="invite.reason"
                multiple
                :placeholder="$t('please_select_submission_reason')"
                style="width: 100%"
            >
              <el-option
                  v-for="item in reasonList"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('remark')" prop="">
            <el-input
                v-model="invite.remark"
                show-word-limit
                clearable
                :placeholder="$t('Please_fill_remarks')"
                maxlength="100"
                @input="changeValue"
            />
            <!--     size="medium"       -->
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="TeamUserShow = false">{{ $t('cancel') }}</el-button>
          <el-button type="primary" :loading="addUserBtnLoading" @click="addTeamUser()">
            {{ $t('submit') }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import API from '@/api';
import valid from '@/utils/validator';
import { manageOauth } from '@/utils/validate';
import moment from "moment";
export default {
  name: 'abnormalPeport',
  data() {
    return {
      teamUser: [],
      TeamUserShow: false,
      addUserBtnLoading: false,
      invite: {
        groupId:'',
        info: '',
        workTime: 0,
        actualTime: 0,
        deviceId: '',
        ratedTime: '',
        startTime: '',
        endTime: '',
        remark: '',
        reason: ''
      },
      ratedTime: '',
      pickerOptions: {
        selectableRange: '00:00:00-23:59:59' // 限制可选择的时间范围
      },
      deviceList:[],
      reasonList: [],
      listLoading: false,
      // 查询
      form: {
        page: 1,
        count: 10,
        type: 2
      },
      rules: {
        inviteRules: {
          deviceId: [{ required: true, message: this.$t('xuanzeDevice'), trigger: 'change' }],
          startTime: [{ required: true, message: this.$t('select_start_time'), trigger: 'change' }],
          endTime: [{ required: true, message: this.$t('select_end_time'), trigger: 'change' }],
          workTime: [
            { required: true, trigger: 'blur', message: this.$t('enter_working_hours') },
            {
              pattern: /^\d+$/,
              message: '请输入正确的工作时长',
              trigger: 'blur'
            }
          ],
          reason: [{ required: true, message: this.$t('please_select_submission_reason'), trigger: 'change' }],
          actualTime: [
            { required: true, trigger: 'blur', message: this.$t('enter_working_hours') },
            {
              pattern: /^\d+$/,
              message: '请输入正确的工作时长',
              trigger: 'blur'
            }
          ],

          remark: [{ required: true, trigger: 'blur', message: this.$t('Please_fill_remarks') }],
          // phone: [
          //   {
          //     required: true,
          //     trigger: 'blur',
          //     validator: (rule, value, callback) => {
          //       valid.checkNull(rule, value, callback, '请输入手机号', false);
          //       valid.checkByRegex(rule, value, callback, valid.REG_PHONE, '手机号不合法');
          //     },
          //   },
          // ],
          teamGroupId: [{ required: true, message: this.$t('please_select_team'), trigger: 'change' }],
          teamValues: [{ required: true, message: this.$t('please_select_team'), trigger: 'change' }],
        },
      },
      totalCount: 0,
    };
  },
  created() {
    this.getTeamUser();
    this.getList()
  },
  mounted() {

  },
  methods: {
    changeValue(value) {
      this.invite.phone = value.replace(/[^\d]/g, '');
    },
    getTeamUser() {
      API.getRecordList(this.form).then((response) => {
        this.teamUser = response.message.data.list;
        this.totalCount = response.message.data.totalCount;
      });
    },
    getList() {
      API.abnormaDeviceList().then(res => {
        this.deviceList.push(...res.message.data)
        console.log(this.deviceList)
      })
      API.reasonList({type:13}).then((res) => {
        console.log(res)
        this.reasonList.push(...res.message.data)
      })
    },
    handleDevice(e) {
      console.log(e)
      this.deviceList.forEach(item => {
        if (item.id === e) {
          this.ratedTime = item.time + 'min'
          this.invite.ratedTime = item.time
        }
      })
    },
    dateFmt(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm')
    },
    // 查看
    updateTeamUser() {

    },
    addTeamUserShow() {
      this.invite = {
        groupId:'',
        info: '',
        workTime: 0,
        actualTime: 0,
        deviceId: '',
        ratedTime: '',
        startTime: '',
        endTime: '',
        remark: '',
        reason: ''
      }
      this.TeamUserShow = true;
      this.$nextTick(() => {
        // 清除验证
        this.$refs.inviteForm.clearValidate();
      });
    },
    close1() {
      this.TeamUserShow = false;
    },
    addTeamUser() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          console.log(this.dateFmt(this.invite.startTime))
          this.invite.startTime = this.dateFmt(this.invite.startTime)
          this.invite.endTime = this.dateFmt(this.invite.endTime)
          this.invite.actualTime = String(this.invite.actualTime)
          this.invite.reason = this.invite.reason.join(';')
          this.addUserBtnLoading = true;
          API.SubmissionCreate(this.invite)
              .then((response) => {
                // console.log(response.message.success)
                if (response.message.success) {
                  this.addUserBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    // showClose: true,
                    type: 'success',
                  });
                  this.TeamUserShow = false;
                  this.getTeamUser();
                } else if (!response.message.success) {
                  this.addUserBtnLoading = false;
                  // this.$message({
                  //   message: response.message.message,
                  //   // showClose: true,
                  //   type: 'error',
                  // });
                }
              })
              .catch(() => {
                this.addUserBtnLoading = false;
              });
        } else {
          return false;
        }
        return v;
      });
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.form.page = 1;
      this.getTeamUser();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getTeamUser();
    },
  },
};
</script>

<style lang="scss" scoped>
//.page-container{
//  padding: 40px;
//}
//.section{
//  padding: 30px 40px;
//}
.h2Style {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdfe6;
  font-size: 20px;
}
.PersonManagement {
  width: 100%;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.cb-left {
  display: -webkit-box;
}
.control-item {
  margin-left: 15px;
}
::v-deep .form-item{
  display: inline-block;
}
</style>
