
<!--点检单-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">{{ $t('inspection_sheet') }}</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
            ref="formList"
            :model="formList"
            label-width="120px"
            :rules="rules"
            label-position="left"
        >
          <el-form-item :label="$t('deviceName')" prop="name">
           <div>{{ formList.deviceName }}</div>
          </el-form-item>
          <el-form-item :label="$t('sheet_number')" prop="" style="max-width: 1000px">
            <div>{{ formList.code }}</div>
          </el-form-item>
          <el-form-item :label="$t('maintenance_type')" prop="code">
           <div>{{ formList.maintainType === '1' ? $t('day_maintainance') : formList.maintainType === '2' ? $t('weeks_maintainance') : formList.maintainType === '3' ? $t('months_maintainance') : formList.maintainType === '30' ? $t('half_months_maintainance') : formList.maintainType === '4' ? $t('quarter_maintainance') : formList.maintainType === '5' ? $t('half_year_maintainance') : $t('year_maintainance') }}</div>
          </el-form-item>
          <el-form-item :label="$t('maintenance_time')" prop="name">
           <div>{{ formList.maintainDate }}</div>
          </el-form-item>
          <el-form-item :label="$t('baoyangren')" prop="name">
            <div>{{ formList.maintainDate }}</div>
          </el-form-item>
          <el-form-item :label="$t('maintenance_items')" prop="name">
            <div class="item-list" v-for="item in list" :key="item.id">
              {{ item }}
            </div>
          </el-form-item>
          <el-form-item label="保养备注" prop="name">
            <div>{{ remark }}</div>
          </el-form-item>
          <el-form-item label="保养图片" prop="name">
            <div class="group">
              <el-row :gutter="20">
                <el-col :span="4" v-for="(item,index) in data.imageUrls" :key="index">

                  <div class="item_img" v-if="item">
                    <el-image
                        style="width: 100%"
                        :src="item"
                        :preview-src-list="data.imageUrls">
                    </el-image>
                  </div>
                  <div v-else>{{ $t('no_image') }}</div>
                </el-col>

              </el-row>
            </div>

          </el-form-item>
          <el-form-item :label="$t('lubrication')" prop="lubricationScore" v-if="(formList.islubricationScore !== 0 && formList.iscleanScore === 0 && formList.isadjustScore === 0) || ((formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 0) || formList.islubricationScore !== 0 && formList.iscleanScore === 0 && formList.isadjustScore === 1) || (formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1)">
            <el-button  type="primary" @click="handlePoint" class="point">{{ $t('site_details') }}</el-button>
            <el-input :placeholder="formList.islubricationScore !== 0 && formList.iscleanScore === 0 && formList.isadjustScore === 0 ? '≤100' : (formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 0) || formList.islubricationScore !== 0 && formList.iscleanScore === 0 && formList.isadjustScore === 1 ? '≤70' : formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? '≤60' : '0'" type="number" v-model="formList.lubricationScore"
                      @input="lubricationScoreLimit($event, formList.islubricationScore !== 0 && formList.iscleanScore === 0 && formList.isadjustScore === 0 ? 100 : (formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 0) || formList.islubricationScore !== 0 && formList.iscleanScore === 0 && formList.isadjustScore === 1 ? 70 : formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? 60 : 0, 0)"></el-input>
          </el-form-item>
          <el-form-item :label="$t('inspection_cleaning')" prop="cleanScore" v-if="formList.islubricationScore === 0 && formList.isadjustScore === 0 ? true :formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 0 ? true : formList.islubricationScore === 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? true : formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? true : false">
            <el-input :placeholder="formList.islubricationScore === 0 && formList.isadjustScore === 0 ? '≤100':formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 0 ? '≤30' : formList.islubricationScore === 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? '≤50' : formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? '≤20' : ''" type="number" v-model="formList.cleanScore"
                      @input="cleanScoreLimit($event, formList.islubricationScore === 0 && formList.isadjustScore === 0 ? 100 :formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 0 ? 30 : formList.islubricationScore === 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? 50 : formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? 20 : 0, 0)"></el-input>
          </el-form-item>
          <el-form-item :label="$t('adjustment')" prop="adjustScore" v-if="formList.islubricationScore === 0 && formList.iscleanScore === 0 ? true : formList.islubricationScore !== 0 && formList.iscleanScore === 0 && formList.isadjustScore === 1 ? true : formList.islubricationScore === 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? true: formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? true:false">
            <el-input :placeholder="formList.islubricationScore === 0 && formList.iscleanScore === 0 ? '≤100' : formList.islubricationScore !== 0 && formList.iscleanScore === 0 && formList.isadjustScore === 1 ? '≤30' : formList.islubricationScore === 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? '≤50': formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? '≤20':''" type="number" v-model="formList.adjustScore"
                      @input="adjustScoreLimit($event, formList.islubricationScore === 0 && formList.iscleanScore === 0 ? 100 : formList.islubricationScore !== 0 && formList.iscleanScore === 0 && formList.isadjustScore === 1 ? 30 : formList.islubricationScore === 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? 50 : formList.islubricationScore !== 0 && formList.iscleanScore === 1 && formList.isadjustScore === 1 ? 20 : 0, 0)">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button v-if="!hide && !formList.cleanScore2"  type="primary" class="submit-btn" @click="handleSubmit">
              {{ $t('submit') }}</el-button>
            <el-button  @click="handleSwitch" class="submit-btn" type="primary" v-if="formList.cleanScore2 && formList.nextState === 1">
              {{ $t('switch') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog :title="$t('site_details')" :visible.sync="dialogTableVisiblePoint" :close-on-click-modal="false"
               width="20%" top="30vh">
      <el-table class="table" :data="tableData" size="mini" border>
        <el-table-column type="index" :label="$t('no')" align="center">
        </el-table-column>
        <el-table-column prop="key" :label="$t('number')" align="center">
        </el-table-column>
        <el-table-column prop="state" :label="$t('completion_status')" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.state === 1"><img src="@/assets/img/right.png" alt=""/></span>
            <span v-if="row.state === 0"><img src="@/assets/img/error.png" alt=""/></span>
          </template>
        </el-table-column>
      </el-table>
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--        <el-button type="primary" @click="dialogTableVisiblePoint = false">确 定</el-button>-->
      <!--      </span>-->
    </el-dialog>
  </div>
</template>
<script>
import API from '@/api'
export default {
  name: "inspection",
  data() {
    return {
      flag: null,
      hide: false,
      minute: 0,
      second: 0,
      tableData: [],
      dialogTableVisiblePoint: false,
      formList: {},
      infoList: [],
      list: [],
      data: {
        imageUrls: []
      },
      remark: "",
      rules: {
        lubricationScore: [
          { required: true, message: "请输入润滑得分", trigger: "blur" },
          {
            pattern: /[0-9]\d*/,
            max: 60,
            message: this.$t('positive_integer'),
            trigger: "change",
          },
        ],
        cleanScore: [
          { required: true, message: "请输入检查、清洁得分", trigger: "blur" },
          {
            pattern: /[1-9]\d*/,
            max: 20,
            message: this.$t('positive_integer'),
            trigger: "change",
          },
        ],
        adjustScore: [
          { required: true, message: "请输入调节得分", trigger: "blur" },
          {
            pattern: /[1-9]\d*/,
            max: 20,
            message: this.$t('positive_integer'),
            trigger: "change",
          },
        ],
      },
    }
  },
  created() {
    this.updateDevice()
  },
  mounted () {
  },
  methods: {
    back() {
      this.$router.push(`PrintHome`)
    },
    updateDevice() {
      API.InspectionList(this.$route.query.id,{
        type: 2,
        page: 1,
        count: 100,
      }).then(res => {
        console.log(res)
        this.formList = res.message.data
        if (this.formList.cleanScore) {
          this.formList.cleanScore2 = this.formList.cleanScore
        }
        if (this.formList.cleanScore === 0) {
          this.formList.cleanScore = ""
        }
        if (this.formList.adjustScore === 0) {
          this.formList.adjustScore = ""
        }
        API.maintenanceDetails(this.formList.id).then(res => {
          // if (res) {
          //   this.loading = false
          // }
          this.list = res.message.data.nameList
          this.remark = res.message.data.remark
          this.data.imageUrls = res.message.data.url.split(',')
          console.log(this.data.imageUrls)
        })
      })
      this.$nextTick(() => {
        // 清除验证
        this.$refs["formList"].clearValidate()
      })
      // API.detailList({ deviceRepairId: this.$route.query.deviceRepairId }).then(res => {
      //   this.infoList = res.message.data
      // })
    },
    lubricationScoreLimit (e, max, min) {
      this.formList.lubricationScore = this.limitInputNumber(e, max, min)
      this.$forceUpdate()
    },
    adjustScoreLimit (e, max, min) {
      this.formList.adjustScore = this.limitInputNumber(e, max, min)
      this.$forceUpdate()
    },
    cleanScoreLimit (e, max, min) {
      this.formList.cleanScore = this.limitInputNumber(e, max, min)
      this.$forceUpdate()
    },
    // 点检详情
    handlePoint() {
      this.dialogTableVisiblePoint = true
      API.pointList({id: this.$route.query.id}).then((res) => {
        this.tableData = res.message.data
      })
    },
    handleSubmit () {
      this.$refs["formList"].validate((valid) => {
        if (valid) {
          API.InspectionCheck(this.formList).then((res) => {
            if (res.message.success === true) {
              this.hide = true;
              this.$message({message: '点检成功',type: 'success',});
              API.InspectionList(this.$route.query.id, {
                type: 2,
                page: 1,
                count: 100,
              }).then((res) => {
                this.formList = res.message.data
                if (this.formList.cleanScore) {
                  this.formList.cleanScore2 = this.formList.cleanScore
                }
                if (this.formList.cleanScore === 0) {
                  this.formList.cleanScore = ""
                }
                if (this.formList.adjustScore === 0) {
                  this.formList.adjustScore = ""
                }
              })
            } else {
              this.$message({message: '点检失败',type: 'error',});
            }
          })
        }
      })
    },
     handleSwitch() {
     API.getPointListSwitch(this.$route.query.id).then(res => {
       if (res.message.success) {
         this.formList = res.message.data;
         this.formList.cleanScore2 = ''
         this.hide = false;
         this.$message({message: this.$t('switching_success'),type: 'success',});
         this.$router.push(`inspection?id=${res.message.data.id}`);
         API.InspectionList(res.message.data.id, {
           type: 2,
           page: 1,
           count: 100,
         }).then((res) => {
           this.formList = res.message.data
           if (this.formList.cleanScore) {
             this.formList.cleanScore2 = this.formList.cleanScore
           }
           if (this.formList.cleanScore === 0) {
             this.formList.cleanScore = ""
           }
           if (this.formList.adjustScore === 0) {
             this.formList.adjustScore = ""
           }
         })
       } else {
         this.$message({message: this.$t('noData'),type: 'error',});
         if (this.$route.query.type === 2) {
           this.$router.push(`PrintHome`);
         } else  {
           this.$router.push(`PrintHome`);
         }
       }
     })

    },
    limitInputNumber (
        value,
        max = null,
        min = null,
        length = null,
        isBlur = false,
        isMoney = false
    ) {
      if (!value && value !== 0) {
        return min > 0 ? min : null
      }
      //是否需要保留小数
      value = value.toString()
      if (!length) {
        if (max < 0 || min < 0) {
          value = value.replace(/[^\-\d]+/g, "")
        } else {
          value = value.replace(/[^0-9]/gi, "")
        }
      } else {
        // 保留数字和小数点
        if (max < 0 || min < 0) {
          value = value.replace(/[^\-\d^\\.]+/g, "")
        } else {
          value = value.replace(/[^\d^\\.]+/g, "")
        }
        if (value.indexOf(".") > 0) {
          value = value.slice(0, value.indexOf(".") + (length + 1))
        } else if (value.indexOf(".") === 0 && value.length === 1) {
          value = 0 + value
        } else if (
            value.indexOf(".") === value.length - 1 &&
            value.length != 0 &&
            isBlur
        ) {
          value = value.substring(0, value.length - 1)
        }
      }
      if (max) {
        max = Number(max)
        let tempValue = Number(value)
        if (tempValue > max) {
          value = max
        }
      }
      if (min) {
        min = Number(min)
        let tempValue = Number(value)
        if (tempValue < min) {
          value = min
        }
      }
      if (isMoney) {
        for (var i = 0; i < value.length; i++) {
          if (value[i] === ".") {
            break
          }
          if (value[i] == 0 && value[i + 1] !== "." && i == 0) {
            value = value.slice(i + 1, value.length - 1)
            i--
          }
        }
      }
      if (isBlur) {
        return Number(value)
      } else {
        return value
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.widthTable {
  width: 1550px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.title div:last-child{
  font-size: 16px;
  font-weight: 400;
}
.el-table .hidden-row {
  display: none;
}
.bofang {
  cursor: pointer;
}
.group {
.item_img {
  //height: 100px;
  overflow: hidden;
  //line-height: 100px;
  //margin-bottom: 10px;
  text-align: center;
}
}
.point {
  position: absolute;
  right: 0;
  top: -45px;
}
</style>
