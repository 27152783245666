<template>
<div class="page-container">
  <div>
    <el-input
        :placeholder="$t('enter_keywords')"
        @change="getList2"
        v-model="params.name"
        suffix-icon="el-icon-search">
<!--      <i slot="append" class="el-input__icon el-icon-search"></i>-->
    </el-input>
  </div>
  <div class="section">
    <div class="section-left">
      <div class="total">{{ $t('all_documents') }}</div>
      <div class="conter">
        <div class="title" v-for="(item,index) in data" :key="index" @click="goNext(item)">
          {{ item.title }}
        </div>
        <el-pagination
            class="pagination"
            v-show="totalCount > 0"
            :current-page="params.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="params.count"
            :total="totalCount"
            layout="total, sizes, prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>

    </div>
    <div class="section-right">
      <div class="status" :class="params.classid === line.id ? 'active':''" v-for="(line) in classList" :key="line.id"  @click="handleSelete(line.id)">{{ line.classification }}</div>
    </div>
  </div>
</div>
</template>

<script>
import API from '@/api'
export default {
name: "technicalSupport.vue",
  data() {
  return{
    data: [],
    params: {
      page: 1,
      count: 10,
      name: "",
      classid: ''
    },
    totalCount: 0,
    classList: [],
    TableParams: {
      name: '',
      state: 1,
      page: 1,
      count: 1000000,
    },
  }
  },
  created() {
    // setToken(this.$route.query.token);
    this.getList()
    this.getClassList()
  },
  methods: {
    getClassList() {
      API.getclasslist(this.TableParams).then((res) => {
        console.log(res)
        this.classList = res.message.data.list;
        this.classList.unshift({
          classid: '',
          classification: this.$t('all_categories'),
          id:''
        })
      });
    },
    getList2() {
      this.params.page = 1
      API.getDocumentList(this.params).then((res) => {
        console.log(res)
        this.data = res.message.data.list
        this.totalCount = res.message.data.totalcount
      })
    },
    // 获取页面数据
    getList() {
      API.getDocumentList(this.params).then((res) => {
        console.log(res)
        this.data = res.message.data.list
        this.totalCount = res.message.data.totalcount
      })
    },
    goNext(row) {
      console.log(row)
      if (row.type === 1) {
        this.$router.push({
          path: "technicalSupportDetail",
          query: { type: row.type, content: row.info, title: row.title },
        })
      } else if (row.type === 3) {
        this.$router.push({
          path: "technicalSupportDetail",
          query: { type: row.type, content: row.info, title: row.title,id:row.content },
        })
      } else{
        window.open(row.content)
        // openPDF(row.title, row.content)
      }
    },
    handleSelete(e) {
      console.log(e)
      this.params.classid = e
      this.getList2()
    },
    handleSizeChange(val) {
      this.params.count = val;
      this.params.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getList();
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
.section {
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 20px;
}
.section-left {
  width: 69%;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  height: 100%;
}
.section-right {
  width: 29%;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  height: 100%;
}
.total {
  font-size: 16px;
  color: rgba(0, 0, 0, .88);
  padding-bottom: 20px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, .08);
}
.conter {
  //padding-top: 20px;
}
.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 24px 0;
  cursor: pointer;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, .08);
}
.status {
  color: rgba(0, 0, 0, .7);
  padding: 11px 0;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  font-weight: 600;
}
.status:hover {
  color: rgba(0, 0, 0, .56);
}
.active {
  color: #4bbbfa;
}
.active:hover {
  color: #4bbbfa;
}
.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
