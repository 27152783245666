var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"control-box"},[_c('div',{staticClass:"cb-left"},[_c('el-input',{staticClass:"control-item",staticStyle:{"width":"200px","margin-right":"20px"},attrs:{"placeholder":"文档名称","clearable":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getTechnicalSpportList.apply(null, arguments)}},model:{value:(_vm.TableParams.name),callback:function ($$v) {_vm.$set(_vm.TableParams, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"TableParams.name"}}),_c('el-button',{staticClass:"control-item",attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.getTechnicalSpportList}},[_vm._v(" "+_vm._s(_vm.$t('search'))+" ")])],1)]),_c('div',[_c('div',{staticClass:"table-box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","fit":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"文档名称","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('type'),"align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.type === 1 ? '富文本' : row.type === 2 ? 'PDF' : '视频'))])]}}])}),_c('el-table-column',{attrs:{"label":"上架状态","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.shelves === '1' ? '已上架' : '未上架'))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('creation_time'),"align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.dCreateDate))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('caozuo'),"align":"left","width":"120","class-name":"small-padding fixed-width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.toCheckInfo(row)}}},[_vm._v(" "+_vm._s(_vm.$t('device_view'))+" ")])]}}])})],1)],1),_c('div',{staticClass:"pagination-box"},[_c('el-pagination',{attrs:{"page-sizes":[10, 20, 30, 40],"page-size":_vm.TableParams.count,"total":_vm.totalCount,"current-page":_vm.TableParams.page,"layout":"total, sizes, prev, pager, next, jumper"},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }