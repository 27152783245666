// menuTitles.js
const menuTitles = {
  'zh': {
    team: '团队管理',
    personnel_management: '人员管理',
    team_information: '团队信息',
    shift_management: '班组管理',
    meal_management: '用餐管理',
    external_maintenance: '外部维修工管理',
    equipment_maintenance: '设备运维',
    submission_record:'提报记录',
    big_screen_display_boss:'大屏展示老板',
    big_screen_display_workshop:'大屏展示车间',
    big_screen_display_machine:'大屏展示机台',
    big_screen_display: '大屏展示',
    submission_reason:'提报原因',
    device_list: '设备列表',
    template_list:'模板列表',
    technical_support: '技术支持',
    Data_Report: '数据报告',
    maintenance_record:'保养记录',
    inspection_record:'点检记录',
    replacement_record:'更换记录',
    repair_record:'维修记录',
    Plate_Quality: '制版质量',
    zhiliangReport: '质量报告',
    prepress_automation:'印前自动化',
    approval_record: '审批记录',
    Plate_maintenance:'版材维护',
  },
  'en': {
    team: 'Team Management',
    personnel_management: 'Personnel Management',
    team_information: 'Team Information',
    shift_management: 'Shift Management',
    meal_management: 'Meal Management',
    external_maintenance: 'External Maintenance Worker Management',
    equipment_maintenance: 'Equipment Operation & Maintenance',
    submission_record:'Submission Record',
    big_screen_display_boss:'Big Screen Display Boss',
    big_screen_display_workshop:'Big Screen Display Workshop',
    big_screen_display_machine:'Big Screen Display Machine',
    big_screen_display: 'Big Screen Display',
    submission_reason:'Reason',
    device_list: 'Device List',
    template_list:'Template List',
    technical_support: 'Technical Support',
    Data_Report: 'Data Report',
    maintenance_record:'Maintenance Record',
    inspection_record:'Inspection Record',
    replacement_record:'Replacement Record',
    repair_record:'Repair Record',
    Plate_Quality: 'Plate Quality',
    zhiliangReport: 'Quality Report',
    prepress_automation:'Prepress automation',
    approval_record: 'approval record',
    Plate_maintenance:'Plate Maintenance',
    // 其他英文标题...
  }
};

export default menuTitles;
