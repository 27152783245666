
<!--维修记录：维修记录-->
<template>
  <div class="app-container">
    <!--页头-->
    <div class="page-container">
      <div class="section">
        <div class="control-box">
          <div class="cb-left">
            <h1 style="font-size: 20px;">{{ $t('device_repair_record') }}</h1>
          </div>
        </div>
        <div class="table-box">
          <el-tabs v-model="activeName" type="card" @tab-click="getDeviceListById">
            <el-tab-pane :label="$t('all')" name="first">
              <div class="table-box">
                <el-table
                    :data="maintainanceData"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <el-table-column :label="$t('deviceName')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.device }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('type')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.repairType }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('repair_personnel')" align="center" width="150">
                    <template slot-scope="scope">
                      <span>{{scope.row.maintainPerson}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('device_state')" align="center" width="170">
                    <template slot-scope="scope">
                      <div style="color: #37C239" v-if="scope.row.type === '0'">{{ $t('not_started') }}</div>
                      <div style="color: #EA000E " v-if="scope.row.type === '1'">{{ $t('pending_start') }}</div>
                      <div style="color: #409eff " v-if="scope.row.type === '2'">{{ $t('in_progress') }}</div>
                      <div style="color: #F7B515 " v-if="scope.row.type === '3'">{{ $t('pending_confirmation') }}</div>
                      <div style="color:#666;" v-if="scope.row.type === '4'">{{ $t('finish') }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('device_repair_time')" align="center" width="200">
                    <template slot-scope="scope">
                      {{ scope.row.repairTime }}
                    </template>
                  </el-table-column>
                  <!--操作-->
                  <el-table-column :label="$t('caozuo')" header-align="center" align="center" width="240">
                    <template slot-scope="scope">
                      <el-button type="primary" size="mini" @click="checkReplacement(scope.row)">
                        {{ $t('device_view') }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="maintainanceForm.count"
                    :total="maintainanceCount"
                    :current-page="maintainanceForm.page + 1"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('incomplete')" name="second">
              <div class="table-box">
                <el-table
                    :data="maintainanceData"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <el-table-column :label="$t('deviceName')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.device }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('type')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.repairType }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('repair_personnel')" align="center" width="150">
                    <template slot-scope="scope">
                      <span>{{scope.row.maintainPerson}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('device_state')" align="center" width="150">
                    <template slot-scope="scope">
                      <div style="color: #37C239" v-if="scope.row.type === '0'">{{ $t('not_started') }}</div>
                      <div style="color: #EA000E " v-if="scope.row.type === '1'">{{ $t('pending_start') }}</div>
                      <div style="color: #409eff " v-if="scope.row.type === '2'">{{ $t('in_progress') }}</div>
                      <div style="color: #F7B515 " v-if="scope.row.type === '3'">{{ $t('pending_confirmation') }}</div>
                      <div style="color:#666;" v-if="scope.row.type === '4'">{{ $t('finish') }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('device_repair_time')" align="center" width="200">
                    <template slot-scope="scope">
                      {{ scope.row.repairTime }}
                    </template>
                  </el-table-column>
                  <!--操作-->
                  <el-table-column :label="$t('caozuo')" header-align="center" align="center" width="240">
                    <template slot-scope="scope">
                      <el-button type="primary" size="mini" @click="checkReplacement(scope.row)">
                        {{ $t('device_view') }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="maintainanceForm.count"
                    :total="maintainanceCount"
                    :current-page="maintainanceForm.page + 1"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('finish')" name="three">
              <div class="table-box">
                <el-table
                    :data="maintainanceData"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <el-table-column :label="$t('deviceName')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.device }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('type')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.repairType }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('repair_personnel')" align="center" width="150">
                    <template slot-scope="scope">
                      <span>{{scope.row.maintainPerson}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('device_state')" align="center" width="150">
                    <template slot-scope="scope">
                      <div style="color: #37C239" v-if="scope.row.type === '0'">{{ $t('not_started') }}</div>
                      <div style="color: #EA000E " v-if="scope.row.type === '1'">{{ $t('pending_start') }}</div>
                      <div style="color: #409eff " v-if="scope.row.type === '2'">{{ $t('in_progress') }}</div>
                      <div style="color: #F7B515 " v-if="scope.row.type === '3'">{{ $t('pending_confirmation') }}</div>
                      <div style="color:#666;" v-if="scope.row.type === '4'">{{ $t('finish') }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('device_repair_time')" align="center" width="200">
                    <template slot-scope="scope">
                      {{ scope.row.repairTime }}
                    </template>
                  </el-table-column>
                  <!--操作-->
                  <el-table-column :label="$t('caozuo')" header-align="center" align="center" width="240">
                    <template slot-scope="scope">
                      <el-button type="primary" size="mini" @click="checkReplacement(scope.row)">
                        {{ $t('device_view') }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="maintainanceForm.count"
                    :total="maintainanceCount"
                    :current-page="maintainanceForm.page + 1"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api';
import {base64toFile} from '@/utils/file';
import moment from "moment";

export default {
  name: "maintenanceRecords",
  data() {
    return {
      abnormal: [],// 异常提报
      option1: [],
      option2: [
        {text: this.$t('all_maintenance'), value: -1},
        {text: this.$t('day_maintainance'), value: 1},
        {text: this.$t('weeks_maintainance'), value: 2},
        {text: this.$t('half_months_maintainance'), value: 30},
        {text: this.$t('months_maintainance'), value: 3},
        {text: this.$t('quarter_maintainance'), value: 4},
        {text: this.$t('half_year_maintainance'), value: 5},
        {text: this.$t('year_maintainance'), value: 6},
      ],
      maintainanceForm: {
        count: 10,
        page: 0,
        state: 0,
        type: -1,
        date: null,
        printType: null,
        deviceId: ''
      },
      abnormalCount: 0,
      maintainanceData: [],// 定期更换
      loading: false,
      maintainanceCount: 0,
      totalCount: 0,
      faultList: [],// 异常提报
      faultForm: {
        page: 1,
        count: 10,
        type: 3
      },
      faultCount: 0,
      activeName: 'first',
      rules: {},
    };
  },
  computed: {},
  created() {
    this.activeName = this.$store.state.recordsForm.activeName
    this.maintainanceForm.state = this.$store.state.recordsForm.state
    this.maintainanceForm.page = this.$store.state.recordsForm.page
    this.maintainanceForm.count = this.$store.state.recordsForm.count
    this.getList()
  },
  mounted() {

  },
  methods: {
    handleSearch() {
      this.getList()
    },
    getList() {
      API.listmaintainRepair(this.maintainanceForm).then(res => {
        console.log(res)
        this.maintainanceData = res.message.data.list
        this.maintainanceCount = res.message.data.totalCount
      })
    },
    dateFmt(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm')
    },
    // 查看
    checkReplacement(row) {
      this.$store.commit('recordsFormSet', {page: this.maintainanceForm.page,count: this.maintainanceForm.count, activeName: this.activeName,state:this.maintainanceForm.state});
      this.$router.push({path: 'faultDetail', query: {deviceRepairId: row.id}});
    },
    back() {
      this.$router.go(-1);
    },
    getDeviceListById(tab) {
      this.maintainanceForm.count = 10
      this.maintainanceForm.page = 0
      if (tab.name === 'first') {
        this.maintainanceForm.state = 0
        this.getList()
      } else if(tab.name === 'second') {
        this.maintainanceForm.state = 1
        this.getList()
      }else {
        this.maintainanceForm.state = 2
        this.getList()
      }
      console.log(tab.name)
    },
    handleSizeChange(val) {
      this.maintainanceForm.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.maintainanceForm.page = val - 1;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.table-box {
  position: relative;
}

::v-deep .dialog-footer {
  text-align: right;
}

::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #F3A100;
}

.control-item {

}

.control-btn {
  text-align: right;
  position: absolute;
  right: 0px;
  top: -0px;
  z-index: 9999;
}

.control-box1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: absolute;
  right: 21px;
  top: 127px;
  z-index: 999;
}

.btn {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdfe6;
  width: 100%;
  border-radius: 4px;
  line-height: 1;
  height: 40px;
  padding: 12px 10px 0px 15px;
  color: #cccfd6;
  cursor: pointer;
}

.person {
  margin-bottom: 20px;
}

.history {
  text-align: right;
}

.record {
  margin-bottom: 0px;

  img {
    width: 15px;
    margin-right: 10px;
  }

  span {
    //display: inline-block;
    text-align: right;
    width: 100%;
    cursor: pointer;
  }
}

.pic-and-video-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .btn {
    width: 90px;
    height: 90px;
    margin-top: 10px;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border: 0;
    padding: 0;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      margin-top: 5px;
      font-size: 13px;
      color: grey;
    }

    .closeVideo-btn {
      margin-top: 8px;
    }
  }
}

.preview {
  width: 90px;
  height: 90px;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;

  img {
    position: absolute;
    width: 90px;
    height: 90px;
  }

  .van-btton {
    position: absolute;
    right: 0;
    font-size: 20px;
    //top: -15px;
    background: #fff;
    padding: 0;
    color: #f56c6c;
  }
}

.voice {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 22px;
    height: 22px;
  }
}

.video-label {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: lightgrey;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  font-size: 13px;
  color: grey;

  .close-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.item {
  padding: 8px 10px;

  img {
    width: 32px;
    margin-right: 10px;
  }
}

.item:hover {
  background-color: #FFFAED;
}

.list-item {
  display: flex;
  justify-content: space-between;
  color: #9F9F9F;
  font-size: 14px;

  .name {
    color: #3D3D3D;
    font-size: 16px;
    margin-bottom: 10px;
  }

  padding: 15px 0;
  border-bottom: 1px solid #F1F1F1;
}

.item-left {
  display: flex;

  img {
    width: 56px;
    margin-right: 15px;
  }
}

.control-search {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 20px;
  //项
  .control-item {
    display: inline-block;

    + .control-item {
      margin-left: 10px;
    }
  }

  //左栏
  .cb-left {
  }

  //右栏
  .cb-right {
  }

  //表单项
  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>

