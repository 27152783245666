<template>
  <!--  日清周清：保养&点检-->
  <div>
    <div class="section">
      <condition :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :conditionFrom="conditionFrom" @search="search"></condition>
    </div>

    <div class="content" v-if="showDisplay" style="padding: 0 0 0 20px">
      <div class="table-box" >
        <div style="margin-bottom: 20px;font-size: 16px;font-weight: bold">{{ $t('maintenance_inspection_achievement_rate') }}</div>
        <el-table
          :data="maintainData"
          border
          style="width: 100%;"
          height="300"
        >
          <el-table-column
            prop="name"
            :label="$t('device_Name')"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="maintainDay"
            :label="$t('baoyangday')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="checkDay"
            :label="$t('dianjianday')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="maintainWeek"
            :label="$t('baoyangweeks')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="checkWeek"
            :label="$t('dianjianweeks')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="maintainMonth"
            :label="$t('baoyangmonths')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="checkMonth"
            :label="$t('dianjianmonths')"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div class="table-box">
        <div style="margin-bottom: 20px;font-size: 16px;font-weight: bold">{{ $t('score_ranking') }}</div>
        <div class="export">
          <el-button type="primary" @click="handleExport">{{ $t('export') }}</el-button>
        </div>
        <el-tabs v-model="activeName" :tab-position="tabPosition" type="card" @tab-click="handleClick">
          <el-tab-pane :label="$t('ribao_day')" name="first">
            <el-table
              :data="scoreData[0].scoreList"
              border
              style="width: 100%;"
              height="300"
            >
              <el-table-column
                prop="cPhone"
                :label="$t('ranking')"
                align="center"
                type="index"
                width="100px"
              ></el-table-column>
              <el-table-column
                prop="name"
                :label="$t('device_Name')"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="person"
                :label="$t('baoyangren')"
                align="center"
              ></el-table-column><el-table-column
                prop="score"
                :label="$t('score')"
                align="center"
              ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('zhoubao_week')" name="second">
            <el-table
              :data="scoreData[1].scoreList"
              border
              style="width: 100%;"
              height="300"
            >
              <el-table-column
                prop="cPhone"
                :label="$t('ranking')"
                align="center"
                width="100px"
                type="index"
              ></el-table-column>
              <el-table-column
                prop="name"
                :label="$t('device_Name')"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="person"
                :label="$t('baoyangren')"
                align="center"
              ></el-table-column><el-table-column
              prop="score"
              :label="$t('score')"
              align="center"
            ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('banyuebao')" name="six">
            <el-table
                :data="scoreData[2].scoreList"
                border
                style="width: 100%;"
                height="300"
            >
              <el-table-column
                  prop="cPhone"
                  :label="$t('ranking')"
                  align="center"
                  type="index"
                  width="100px"
              ></el-table-column>
              <el-table-column
                  prop="name"
                  :label="$t('device_Name')"
                  align="center"
              ></el-table-column>
              <el-table-column
                  prop="person"
                  :label="$t('baoyangren')"
                  align="center"
              ></el-table-column><el-table-column
                prop="score"
                :label="$t('score')"
                align="center"
            ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('months')" name="third">
            <el-table
              :data="scoreData[3].scoreList"
              border
              style="width: 100%;"
              height="300"
            >
              <el-table-column
                prop="cPhone"
                :label="$t('ranking')"
                align="center"
                type="index"
                width="100px"
              ></el-table-column>
              <el-table-column
                prop="name"
                :label="$t('device_Name')"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="person"
                :label="$t('baoyangren')"
                align="center"
              ></el-table-column><el-table-column
              prop="score"
              :label="$t('score')"
              align="center"
            ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="半年保" name="fourth">
            <el-table
              :data="scoreData[4].scoreList"
              border
              style="width: 100%;"
              height="300"
            >
              <el-table-column
                prop="cPhone"
                :label="$t('ranking')"
                align="center"
                type="index"
                width="100px"
              ></el-table-column>
              <el-table-column
                prop="name"
                :label="$t('device_Name')"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="person"
                :label="$t('baoyangren')"
                align="center"
              ></el-table-column><el-table-column
              prop="score"
              :label="$t('score')"
              align="center"
            ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('nianbao')" name="five">
            <el-table
              :data="scoreData[5].scoreList"
              border
              style="width: 100%;"
              height="300"
            >
              <el-table-column
                prop="cPhone"
                :label="$t('ranking')"
                align="center"
                type="index"
                width="100px"
              ></el-table-column>
              <el-table-column
                prop="name"
                :label="$t('device_Name')"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="person"
                :label="$t('baoyangren')"
                align="center"
              ></el-table-column><el-table-column
              prop="score"
              :label="$t('score')"
              align="center"
            ></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!--分页-->
<!--    <div class="pagination-box">-->
<!--      <el-pagination-->
<!--        v-show="filter.total>0"-->
<!--        :current-page="filter.page"-->
<!--        :page-sizes="[10, 20, 30, 40]"-->
<!--        :page-size="filter.count"-->
<!--        :total="filter.total"-->
<!--        layout="total, sizes, prev, pager, next, jumper"-->
<!--        @size-change="handleSizeChange"-->
<!--        @current-change="handleCurrentChange"-->
<!--      />-->
<!--    </div>-->
  </div>
</template>

<script>
import API from '@/api';
import condition from './condition'
export default {
  name: "maintainPoint.vue",
  components: { condition },
  props: {
    enterpriseList: {
      type: Array,
      default: () => []
    },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showDisplay: false,
      filter: {
        startTime: undefined,
        endTime: undefined,
        teamId: ''
      },
      teamId: '',
      options: {
        status: [],
      },
      maintainData: [],
      scoreData: [],
      pickerOptions: {
        disabledDate: time => {
          if (this.filter.endTime) {
            return (
              time.getTime() > new Date(this.filter.endTime).getTime() || time.getTime() < new Date(this.filter.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.filter.startTime) {
            return (
              time.getTime() < new Date(this.filter.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.filter.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      activeName: 'first',
      tabPosition: 'top',
      historyQuery:{}
    };
  },
  mounted() {

  },
  created() {
  },
  methods: {
    startTimeChange(val){
      if(this.filter.endTime && new Date(val).getTime() > new Date(this.filter.endTime).getTime()){
        this.filter.startTime = null
      }else{
        this.filter.startTime = val
      }
    },
    endTimeChange(val){
      if(this.filter.startTime && new Date(val).getTime() < new Date(this.filter.startTime).getTime()){
        this.filter.endTime = null
      }else{
        this.filter.endTime = val
      }
    },
    handleExport() {
      console.log(this.activeName)
      let list = []
      let sting = ''
      if (this.activeName === 'first') {
        list = this.scoreData[0].scoreList
        sting = this.$t('ribao_day')
      } else if (this.activeName === 'second') {
        list = this.scoreData[1].scoreList
        sting = this.$t('zhoubao_week')
      }else if (this.activeName === 'six') {
        list = this.scoreData[2].scoreList
        sting = this.$t('banyuebao')
      } else if (this.activeName === 'third') {
        list = this.scoreData[3].scoreList
        sting = this.$t('months')
      }else if (this.activeName === 'fourth') {
        list = this.scoreData[4].scoreList
        sting = '半年保'
      }else if (this.activeName === 'five') {
        list = this.scoreData[5].scoreList
        sting = this.$t('nianbao')
      }
      require.ensure([], () => {
        const {export_json_to_excel} = require('@/views/ExcelLeadOut/Export2Excel'); // 注意这个Export2Excel路径
        const tHeader = [
          this.$t('ranking'),
          this.$t('device_Name'),
          this.$t('baoyangren'),
          this.$t('score'),
        ]; // 表格标题
        const filterVal = [
          'name',
          'person',
          'score',
        ]; // 标题属性key值
        const arr = list; // 把要导出的数据tableData存到list
        const data = this.formatJson(filterVal, arr);
        export_json_to_excel(tHeader, data, sting); // 最后一个是表名字
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => filterVal.map(j => v[j]))
          .map((v, i) => {
            v.unshift(i + 1); // 添加序号字段，从1开始
            return v;
          });
    },
    getDate() {
      API.getMaintain({...this.filter}).then(res => {
        this.maintainData = res.message.data
      })
      API.getmaintainRanking({...this.filter}).then(res => {
        this.scoreData = res.message.data
      })
    },
    // 查询
    search(condition) {
      if (!this.$store.state.condition.enterprise) {
        console.log(this.$store.state.condition)
        this.$store.commit('setFormDate',condition)
      }
      this.showDisplay = false
      this.filter = {
        ...condition.query,
        teamId:condition.enterprise,
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise
      }
      this.getDate()
      this.showDisplay = true
    },
    handleClick(tab, event) {
      console.log(tab);
    },
    // 每页显示个数改变时会触发
    // handleSizeChange(val) {
    //   this.filter.count = val;
    //   this.getList();
    // },
    // // 当前页数改变时会触发
    // handleCurrentChange(val) {
    //   this.filter.page = val;
    //   this.getList();
    // },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--card > .el-tabs__header{
  border-bottom: 0px solid #e4e7ed;
}
.content{
  height: calc(100vh - 290px);
  overflow: auto;
  .chart{
    width: 94%;
    height: 500px;
    .title{
      display: flex;
      justify-content: space-between;
    }

    .echatsImg{
      width: 100%;
      height: 300px;
    }
  }
}
.content ::v-deep .el-tabs__nav{
  height: 50px !important;
  display: inline-flex;
  align-items: center;
}
.content ::v-deep .el-tabs__item{
  //height: 11.11%;
  height: 50px !important;
  width: 100%;
  display: flex;
  align-items: center;
}
.content ::v-deep .el-tabs__item:last-child{
  border-right: 1px solid #e4e7ed;
}
//height: calc(100vh - 50px);
.content .section{
  height: 100%;
}
::v-deep .table-box table thead th .cell{
  color: #606266;
  white-space: normal;
}
.export {
  position: relative;
  left: -20px;
  width: 100%;
  top: -10px;
  text-align: right;
}
</style>
