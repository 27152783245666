
<!--用餐管理：用餐管理-->
<template>
  <div class="page-container">
    <div class="section">
      <div class="table-box">
        <div>
          <div class="control-box">
            <!--添加按钮-->
            <div class="cb-left">

            </div>
            <div class="cb-right">
              <el-button
                  class="control-item"
                  type="primary"
                  style="margin-left: 10px;"
                  icon="el-icon-edit"
                  @click="toAdd(1)"
              >
                {{ $t('add_increase') }}
              </el-button>
            </div>
          </div>
          <!--表格-->
          <div class="table-box">
            <el-table
                :data="abnormal"
                border
                highlight-current-row
                style="width: 100%"
            >
              <!--序号-->
              <el-table-column :label="$t('no')" type="index" align="center" width="80">
              </el-table-column>
              <el-table-column :label="$t('reason')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.reason }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('startTime')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.startTime }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('endTime')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.endTime }}
                </template>
              </el-table-column>

              <!--操作-->
              <el-table-column :label="$t('caozuo')" header-align="center" align="center" width="240">
                <template slot-scope="scope">
                  <el-button  type="primary" size="mini" @click="updateDevice(scope.row)">
                    {{ $t('device_edit') }}
                  </el-button>
                  <el-button
                      type="danger"
                      plain
                      size="mini"
                      icon="el-icon-delete"
                      @click="deleteDevice(scope.row,)"
                  >
                    {{ $t('delete') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--分页-->
          <div class="pagination-box">
            <el-pagination
                :page-sizes="[10, 20, 30, 40]"
                :page-size="form.count"
                :total="totalCount"
                :current-page="form.page"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
          </div>
        </div>
        <!-- 添加编辑 -->
        <el-dialog
            :visible.sync="daoruVal"
            :title="title"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
            :append-to-body="false"
            width="550px"
        >
          <div>
            <el-form :model="ruleForm" :rules="rules" label-position="left" ref="ruleForm1" label-width="90px"
                     class="demo-ruleForm">
              <el-form-item :label="$t('reason')" prop="reason">
                <el-select
                    v-model="ruleForm.reason"
                    clearable
                    :placeholder="$t('please_select')"
                    style="width: 200px;margin-right:10px"
                >
                  <el-option
                      v-for="(item,index) in reasonList"
                      :key="index"
                      :label="item.name"
                      :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('startTime')" prop="startTime">
                <el-time-picker
                    @change="handleStartTime"
                    :placeholder="$t('start_time')"
                    v-model="ruleForm.startTime"
                    :picker-options="{
                  start: '00:00',
                  end: '23:59',
                  format: 'HH:mm'
                }"
                    value-format="HH:mm">
                </el-time-picker>
              </el-form-item>
              <el-form-item :label="$t('endTime')" prop="endTime">
                <el-time-picker
                    @change="handleEndTime"
                    :placeholder="$t('endTime')"
                    v-model="ruleForm.endTime"
                    :picker-options="{
               start: '00:00',
                  end: '23:59',
                  format: 'HH:mm'
              }"
                    value-format="HH:mm">
                </el-time-picker>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="daoruVal = false">{{ $t('cancel') }}</el-button>
              <el-button v-if="title === $t('add')" v-loading="loading" type="primary" @click="handleAddConfirm">{{ $t('queding') }}</el-button>
              <el-button v-if="title === $t('device_edit')" v-loading="loading" type="primary" @click="handleAddConfirmEdit">{{ $t('queding') }}</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>

</template>
<script>
import API from '@/api';
export default {
  name:"mealTimes",
  props: {
    id: ''
    // role: {
    //   type: Array,
    //   default: () => []
    // },
  },
  data() {
    return {
      reasonList: [
        {
          name:this.$t('late_shift_meal')
        },
        {
          name:this.$t('lunch')
        },
        {
          name:this.$t('dinner')
        }
      ],
      abnormal: [],
      loading:false,
      title: this.$t('add'),
      daoruVal: false,
      form: {
        page: 1,
        count: 10,
        teamId: ''
      },
      totalCount: 0,
      ruleForm: {
        reason: '',
        startTime: '',
        endTime: '',
        id: ''
      },
      rules: {
        reason: [
          {
            required: true,
            message: this.$t('Please_enter_the_reason'),
            trigger: 'blur'
          },
          { max: 20, message: this.$t('length_within_characters'), trigger: 'blur' },
        ],
        startTime: [
          {
            required: true,
            message: this.$t('select_start_time'),
            trigger: 'blur'
          }
        ],
        endTime: [
          {
            required: true,
            message: this.$t('select_end_time'),
            trigger: 'blur'
          }
        ]
      },
    };
  },
  computed: {
  },
  created() {
    console.log(this.$route.query)
    this.getDevicePrintTypeList();
  },
  mounted() {

  },

  methods: {
    getSerch() {
      this.form.page = 1
      API.teamTimeList(this.form).then(res => {
        this.abnormal = res.message.data.list
        this.totalCount = res.message.data.totalCount
      })
    },
    getDevicePrintTypeList() {
      API.teamTimeList(this.form).then(res => {
        this.abnormal = res.message.data.list
        this.totalCount = res.message.data.totalCount
      })
    },
    handleStartTime() {

    },
    handleEndTime(value) {},
    back() {
      this.$router.go(-1);
    },
    // 弹窗确定
    handleAddConfirm() {
      console.log(this.ruleForm.startTime)
      this.$refs['ruleForm1'].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm,22)
          this.ruleForm.teamId = this.$route.query.id
          API.teamTimeAdd({id:this.ruleForm.id,teamId: this.ruleForm.teamId,reason: this.ruleForm.reason,startTime: this.ruleForm.startTime,endTime:this.ruleForm.endTime}).then(res => {
            this.getDevicePrintTypeList();
            this.$message({
              message: this.$t('add_successful'),
              type: 'success',
              duration: 2000,
            });
            this.daoruVal = false
          })
        }
      })
    },
    handleAddConfirmEdit() {
      this.$refs['ruleForm1'].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm,11)
          API.teamTimeAdd(this.ruleForm).then(res => {
            this.getDevicePrintTypeList();
            this.$message({
              message: this.$t('edit_successful'),
              type: 'success',
              duration: 2000,
            });
            this.daoruVal = false
          })
        }
      })
    },
    deleteDevice(val) {
      this.$confirm(this.$t('confirm_deletion_device'), this.$t('prompt'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('device_confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      }).then(() => {
        API.deleteTeamTime(val.id).then(res => {
          console.log(res)
          this.$message({
            message: this.$t('Deleted_successfully'),
            type: 'success',
            duration: 2000,
          });
          this.getDevicePrintTypeList();
        })
      }).catch(() => {
        console.log(988)
        this.$message({
          message: this.$t('deletion_cancelled'),
          type: 'info',
        });
      });
    },
    toAdd() {
      this.title = this.$t('add')
      this.daoruVal = true
      this.ruleForm.reason = ''
      this.ruleForm.startTime = ''
      this.ruleForm.endTime = ''
      this.ruleForm.id = ''
      this.$nextTick(() => {
        this.$refs.ruleForm1.clearValidate();
      });
    },
    updateDevice(device) {
      this.title = this.$t('device_edit')
      this.$nextTick(() => {
        this.$refs.ruleForm1.clearValidate();
      });
      this.ruleForm = JSON.parse(JSON.stringify(device))
      this.daoruVal = true
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.getDevicePrintTypeList();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getDevicePrintTypeList();
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .dialog-footer {
  text-align: right;
}
</style>
