<template>
  <div class="page-container">
    <div class="section">
      <el-form
        ref="formSupport"
        :model="formSupport"
        :rules="addRules"
        label-position="left"
        label-width="100px"
      >
        <el-form-item label="文档标题：" prop="title">
          <el-input
            v-model.trim="formSupport.title"
            placeholder="请输入"
            clearable
            show-word-limit
            maxlength="30"
            style="width: 510px"
          />
        </el-form-item>
        <el-form-item label="文档类型：" prop="type">
          <el-select v-model="formSupport.type" clearable :placeholder="$t('please_select')" style="width: 510px">
            <el-option
              v-for="item in searchType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('type') + '：'" prop="type">
          <el-select v-model="formSupport.type" clearable :placeholder="$t('please_select')" style="width: 510px">
            <el-option
              v-for="item in searchType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="formSupport.type === 3" label="上传视频：" prop="url">
          <el-upload
            ref="upload"
            drag
            action="#"
            accept=".mp4,.pdf"
            :limit="1"
            :auto-upload="false"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :http-request="uploadFile"
            :file-list="files"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              将视频文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div slot="tip" class="el-upload__tip">只能上传 .mp4 文件，且不超过512M</div>
          </el-upload>
          <el-tooltip class="item" effect="dark" :content="formSupport.urlName" placement="top">
            <div v-show="formSupport.urlName" type="text" class="fileName">
              {{ formSupport.urlName }}
            </div>
          </el-tooltip>
        </el-form-item>
        <el-form-item v-show="formSupport.type === 2" label="上传PDF：" prop="url">
          <el-upload
            ref="loadPDF"
            class="upload-demo"
            :action="loadPDF"
            multiple
            :auto-upload="true"
            :show-file-list="false"
            :file-list="fileList"
            :http-request="uploadFilePDF"
            :on-change="handleChangePDF"
          >
            <el-button :loading="loading" size="small" type="primary">选择文件</el-button>
          </el-upload>
          <el-tooltip class="item" effect="dark" :content="formSupport.urlName" placement="top">
            <div v-show="formSupport.urlName" type="text" class="fileName">
              {{ formSupport.urlName }}
            </div>
          </el-tooltip>
        </el-form-item>
        <el-form-item v-show="formSupport.type === 1" label="内容说明：" prop="info">
          <div id="content" class="text"></div>
          <div style="width:100%;text-align: right;">字数:{{ info }}/15000</div>
        </el-form-item>
      </el-form>
      <div class="btn-box">
        <el-button @click="handleCancle">{{ $t('cancel') }}</el-button>
        <el-button
          :loading="loading"
          type="primary"
          @click="SupportId ? handleSubmitEdit() : handleSubmitModify()"
        >
          {{ $t('device_save') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from 'wangeditor';
import axios from 'axios';
import { getToken } from '@/utils/auth';
import API from '@/api'
export default {
  data() {
    return {
      formSupport: {
        title: '',
        info: '',
        type: '',
        url: '',
        urlName: '',
        manual: {
          manualType: 1,
          manualIntro: '',
          manualName: '',
        },
      },
      searchType: [
        {
          value: 1,
          label: '富文本',
        },
        {
          value: 2,
          label: 'PDF',
        },
        {
          value: 3,
          label: '视频',
        },
      ],
      fileList: [],
      editor: '',
      addRules: {
        title: [{ required: true, trigger: 'blur', message: '请输入文档标题' }],
        type: [{ required: true, trigger: 'blur', message: '请选择文档类型' }],
        url: [{ required: true, trigger: 'blur', message: '请上传PDF' }],
        info: [{ required: true, trigger: 'blur', message: '请输入内容说明' }],
      },
      fileName: '',
      info: 0,
      pic: new FormData(),
      loading: false,
      SupportId: '', // 文档id
      files: [],
    };
  },
  computed: {
    loadPDF() {
      return `${process.env.VUE_APP_BASE_API}document/addImages`;
    },
  },
  created() {
    this.SupportId = this.$route.query.id;
    if (this.SupportId) {
      this.getSupportInfo();
    }
  },
  mounted() {
    this.createContent();
  },
  methods: {
    // 获取详情
    getSupportInfo() {
      API.SupportInfo({ id: this.SupportId }).then((res) => {
        if (res.message.data.type === 2) {
          this.formSupport = {
            title: res.message.data.title,
            type: res.message.data.type,
            url: res.message.data.content,
            urlName: res.message.data.urlName,
          };
        } else {
          this.formSupport = {
            title: res.message.data.title,
            info: res.message.data.info,
            type: res.message.data.type,
          };
          this.editor.txt.html(this.formSupport.info);
        }
      });
    },
    // 编辑点击保存
    handleSubmitEdit() {
      if (this.formSupport.type === 2) {
        this.formSupport.info = 1;
      } else {
        this.formSupport.url = 1;
        this.formSupport.info = this.editor.txt.html();
      }
      this.$refs.formSupport.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.formSupport.type === 2) {
            const para = {
              type: this.formSupport.type,
              title: this.formSupport.title,
              url: this.formSupport.url,
              urlName: this.formSupport.urlName,
            };
            API.EditSupport(para, this.SupportId)
              .then((res) => {
                this.$message({
                  message: this.$t('edit_successful'),
                  type: 'success',
                });
                this.$router.go(-1);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            if (this.info > 15000) {
              this.$message({
                message: '内容说明字符长度超过15000',
                type: 'warning',
              });
              this.loading = false;
              return;
            }
            const para = {
              type: this.formSupport.type,
              title: this.formSupport.title,
              info: this.editor.txt.html(),
            };
            API.EditSupport(para, this.SupportId)
              .then(() => {
                this.$message({
                  message: this.$t('edit_successful'),
                  type: 'success',
                });
                this.$router.go(-1);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    // 保存
    handleSubmitModify() {
      if (this.formSupport.type === 2) {
        this.formSupport.info = 1;
      } else {
        this.formSupport.url = 1;
        this.formSupport.info = this.editor.txt.html();
      }
      this.$refs.formSupport.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.formSupport.type === 2) {
            const para = {
              type: this.formSupport.type,
              title: this.formSupport.title,
              url: this.formSupport.url,
              urlName: this.formSupport.urlName,
            };
            API.addSupport(para)
              .then((res) => {
                this.$message({
                  message: this.$t('add_successful'),
                  type: 'success',
                });
                this.$router.go(-1);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            if (this.info > 15000) {
              this.$message({
                message: '内容说明字符长度超过15000',
                type: 'warning',
              });
              this.loading = false;
              return;
            }
            const para = {
              type: this.formSupport.type,
              title: this.formSupport.title,
              info: this.editor.txt.html(),
            };
            API.addSupport(para)
              .then(() => {
                this.$message({
                  message: this.$t('add_successful'),
                  type: 'success',
                });
                this.$router.go(-1);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    // 内容
    createContent() {
      this.editor = new Editor('#content');
      this.editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'justify', // 对齐方式
        'quote', // 引用
        'image', // 插入图片
        'undo', // 撤销
        'redo', // 重复
      ];
      this.editor.config.zIndex = 10;
      this.editor.config.uploadImgServer = `${process.env.VUE_APP_BASE_API}document/addImages`;
      this.editor.config.uploadFileName = 'pic';
      this.editor.config.uploadImgHeaders = {
        headers: { 'Content-Type': 'application/json' },
        token: getToken(),
      };
      this.editor.config.uploadImgHooks = {
        customInsert(insertImgFn, result) {
          let ImgUrl;
          // result 即服务端返回的接口
          if (result.code === '200') {
            ImgUrl = result.message.data;
          }
          insertImgFn(ImgUrl);
        },
      };
      const that = this;
      this.editor.config.onchange = function (newHtml) {
        that.info = newHtml.length - 7;
        if (that.info < 0) {
          that.info = 0;
        }
      };
      this.editor.config.onchangeTimeout = 500;
      this.editor.create();
    },
    uploadFilePDF(file) {
      this.pic = new FormData();
      this.pic.append('pic', file.file);
    },
    // 上传
    handleChangePDF(file) {
      const filelimit = file.name.split('.');
      const pdf = filelimit[filelimit.length - 1] === 'pdf';
      const PDF2 = filelimit[filelimit.length - 1] === 'PDF';
      if (!pdf && !PDF2) {
        this.$message.warning('只允许上传.pdf格式文件');
        this.fileList = [];
        return;
      }
      if (file.name.length > 30) {
        this.$message.warning('文件名过长,pdf文件名最多为30个字');
        return;
      }
      this.loading = true;
      this.formSupport.urlName = file.name;
      this.$refs.loadPDF.submit();
      axios
        .post(this.loadPDF, this.pic, {
          headers: {
            token: getToken(),
            'Content-Type': 'application/octet-stream',
          },
        })
        .then((res) => {
          if (res.data.code === '200') {
            this.formSupport.url = res.data.message.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancle() {
      this.$router.go(-1);
    },
    handleChange(file, fileList) {
      if (!file.name) {
        this.files = [];
        return this.$message.warning('上传文件有误');
      }
      const filename = file.name.split('.');
      const mp4 = filename[filename.length - 1] === 'mp4';
      const pdf = filename[filename.length - 1] === 'pdf';
      const type = this.formSupport.manual.manualType;
      if (type === 3 && !mp4) {
        this.files = [];
        return this.$message.warning('只允许上传.mp4格式文件');
      }
      if (type === 2 && !pdf) {
        this.files = [];
        return this.$message.warning('只允许上传.pdf格式文件');
      }
      const isGt10M = file.size / 1024 / 1024 > 1024;
      if (isGt10M) {
        return this.$message.warning('上传文件大小不能超过512M');
      }
      this.files = fileList;
    },
    handleExceed() {
      this.$message.warning('最多上传1个文件，请删除原文件再重新上传');
    },
    handleRemove() {
      this.files = [];
      this.$refs.upload.clearFiles();
    },
    uploadFile(file) {
      this.formData.append('file', file.file);
    },
  },
};
</script>

<style lang="scss">
.fileName {
  width: 500px;
  overflow: hidden;
  // display: inline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
