<template>
  <!--  日清周清：日清周清-->
  <div class="page-container">
    <div class="section">
      <el-tabs :tab-position="tabPosition" v-model="activeName" type="card" style="height: 100%;" @tab-click="handleClick">
        <el-tab-pane :label="$t('yinliang')" name="first">
            <printing-count :conditionFrom="query" :enterpriseList="enterpriseList" :selectedSheetType="1"></printing-count>
        </el-tab-pane>
        <el-tab-pane :label="$t('utilization')" name="second">
            <printing-count :conditionFrom="query" :enterpriseList="enterpriseList" :selectedSheetType="2"></printing-count>
        </el-tab-pane>
        <el-tab-pane :label="$t('Performance')" name="third">
            <printing-count :conditionFrom="query" :enterpriseList="enterpriseList" :selectedSheetType="3"></printing-count>
        </el-tab-pane>
        <el-tab-pane :label="$t('yichangdaiji')" name="forth">
          <abnormal-shutdown :conditionFrom="query" :enterpriseList="enterpriseList"></abnormal-shutdown>
        </el-tab-pane>
        <el-tab-pane :label="$t('printing_quality')" name="five">
          <userManagement :conditionFrom="query" :enterpriseList="enterpriseList"></userManagement>
        </el-tab-pane>
        <el-tab-pane :label="$t('Fountain_Solution')" name="six">
          <fountainSolution :conditionFrom="query" :enterpriseList="enterpriseList"></fountainSolution>
        </el-tab-pane>
        <el-tab-pane :label="$t('maintenance_inspection')" name="seven">
          <maintainPoint :conditionFrom="query" :enterpriseList="enterpriseList"></maintainPoint>
        </el-tab-pane>
        <el-tab-pane :label="$t('weixiu')" name="eight">
          <maintenance :conditionFrom="query" :enterpriseList="enterpriseList"></maintenance>
        </el-tab-pane>
        <el-tab-pane :label="$t('Plate_Quality')" name="nine">
          <makingQuality :conditionFrom="query" :enterpriseList="enterpriseList"></makingQuality>
        </el-tab-pane>
<!--        <el-tab-pane label="日活报表" name="ten">-->
<!--          <DailyLiveReport :conditionFrom="query" :enterpriseList="enterpriseList"></DailyLiveReport>-->
<!--        </el-tab-pane>-->
        <el-tab-pane :label="$t('printing_quantity_analysis')" name="eleven">
          <watermarkingCapacity  :conditionFrom="query" :enterpriseList="enterpriseList"  :teamGroup="teamGroup"></watermarkingCapacity>
        </el-tab-pane>
        <el-tab-pane :label="$t('replacement')" name="twelve">
          <replacement :conditionFrom="query" :enterpriseList="enterpriseList"></replacement>
        </el-tab-pane>
      </el-tabs>
      <el-divider class="splitLine" direction="vertical"></el-divider>
    </div>
  </div>
</template>

<script>
import printingCount from "@/views/HomePage/PrintPage/dayWeekReport/printingCount";
import abnormalShutdown from "@/views/HomePage/PrintPage/dayWeekReport/abnormalShutdown";
import fountainSolution from "@/views/HomePage/PrintPage/dayWeekReport/fountainSolution";
import makingQuality from "@/views/HomePage/PrintPage/dayWeekReport/plateMakingQuality";
import maintenance from "@/views/HomePage/PrintPage/dayWeekReport/maintenance";
import maintainPoint from "@/views/HomePage/PrintPage/dayWeekReport/maintainPoint";
import userManagement from "@/views/HomePage/PrintPage/dayWeekReport/userManagement";
import DailyLiveReport from '@/views/HomePage/PrintPage/dayWeekReport/DailyLiveReport'
import watermarkingCapacity from '@/views/HomePage/PrintPage/dayWeekReport/watermarkingCapacity'
import replacement from '@/views/HomePage/PrintPage/dayWeekReport/replacement'

// import { listByName } from '@/api/product'
import API from '@/api';
// import {getClassDate} from "@/api/CompanyPreserve"; // 企业名称下拉列表
export default {
  components: { watermarkingCapacity, makingQuality, maintenance, maintainPoint, printingCount, abnormalShutdown, fountainSolution, userManagement, DailyLiveReport, replacement },
  data() {
    return {
      tabPosition: 'left',
      activeName: 'first',
      enterpriseList: [],
      teamGroup: [], // 公司
      query: {},
    // tableImport: {
    //   count: 1000000,
    //     page: 1,
    //     teamId: '',
    //     name: '',
    //     icollection: 1,
    //     type: -1,
    // },
    //   tableNumber: [] // 数采设备
    };
  },
  created() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName
    } else {
      this.activeName = 'first'
    }
    if (this.$store.state.condition.enterprise) {
      this.query = JSON.parse(JSON.stringify(this.$store.state.condition))
    }
    // API.listByName({
    //   name: '',
    //   count: 1000,
    //   page: 0
    // })
    //   .then((res) => {
    //     this.enterpriseList = res.message.data.list
    //   })


    // API.getClassDate({name: '', page: 1, count: 1000000000, teamId: ''}).then((response) => {
    //   this.teamGroup = response.message.data.list;
    //   // this.totalCount = response.totalCount;
    // });
    // API.CompanyPreserveList(this.tableImport)
    //   .then((res) => {
    //     this.tableNumber = res.message.data.list;
    //     // this.tableImport.total = res.totalCount;
    //   })
    //   .catch((mgs) => {
    //     console.log(mgs);
    //   });
  },
  mounted() {

  },
  methods: {
    handleClick(tab, event) {
      this.query.tab = tab.index
      this.activeName = tab.name;
      if (this.activeName === 'forth' || this.activeName === 'eight' || this.activeName === 'six') {
        this.$router.replace({ path: 'dayWeekReport', query: { activeName: this.activeName } })
      } else {
        this.$router.replace({ path: 'dayWeekReport' })
      }
      // console.log(this.$store.state.condition)
      if (this.$store.state.condition.enterprise) {
        this.query = JSON.parse(JSON.stringify(this.$store.state.condition))
      }
    }
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--card > .el-tabs__header{
  border-bottom: 0px solid #e4e7ed;
}
.page-container{
  ::v-deep .el-tabs__nav{
      height: 450px;
  }
  ::v-deep .el-tabs__item{
      height: 9.1%;
      display: flex;
      align-items: center;
      justify-content: center;
    font-size: 14px;
    font-weight: normal;
  }
    height: calc(100vh - 50px);
    .section{
      height: 100%;
      .splitLine{
        position: absolute;
        height: 0;
        left: 123px;
        top: 20px;
      }
    }
}
h2 {
  margin: 18px 0;
}
</style>
