
<!--待办事项：待办事项-->
<template>
  <div class="app-container">
    <!--页头-->
    <div class="page-container">
      <div class="section">
        <div class="control-box">
          <div class="cb-left">
            <h1 style="font-size: 20px;">待办事项</h1>
          </div>
        </div>
        <div class="table-box">
          <el-tabs v-model="activeName" @tab-click="getDeviceListById">
            <el-tab-pane :label="$t('all')" name="first">
              <!--表格-->
              <div class="table-box">
                <el-table
                    :data="replacementData"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <!--序号-->
                  <el-table-column :label="$t('no')" type="index" align="center" width="80">
                  </el-table-column>
                  <el-table-column :label="$t('reason_optional')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.value }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('type')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.teamState === 1 ? $t('platform') : $t('enterprise') }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('is_it_enable')" align="center">
                    <template slot-scope="scope">
                      <el-switch
                          v-model="scope.row.state === 1 ? true : false"
                          @change="handleSwitch(scope.row)"
                      >
                      </el-switch>
                    </template>
                  </el-table-column>

                  <!--操作-->
                  <el-table-column :label="$t('caozuo')" header-align="center" align="center" width="240">
                    <template slot-scope="scope">
                      <el-button type="primary" v-if="scope.row.teamState !== 1" size="mini" @click="updateDevice(scope.row,2)">
                        {{ $t('device_edit') }}
                      </el-button>
                      <el-button
                          v-if="scope.row.teamState !== 1"
                          type="primary"
                          plain
                          size="mini"
                          icon="el-icon-delete"
                          @click="deleteDevice(scope.row, 2)"
                      >
                        {{ $t('delete') }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
<!--                <el-pagination-->
<!--                    :page-sizes="[10, 20, 30, 40]"-->
<!--                    :page-size="replacement.count"-->
<!--                    :total="replacementCount"-->
<!--                    :current-page="replacement.page"-->
<!--                    layout="total, sizes, prev, pager, next, jumper"-->
<!--                    @size-change="handleSizeChange2"-->
<!--                    @current-change="handleCurrentChange2"-->
<!--                />-->
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('replacement')" name="second">
              <div class="table-box">
                <el-table
                    :data="replacementData"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <el-table-column :label="$t('deviceName')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.name }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('replacement_item')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.project}}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('remark')" align="center">
                    <template slot-scope="scope">
                      <!--                          <el-switch-->
                      <!--                              v-model="scope.row.state === 1 ? true : false"-->
                      <!--                              @change="handleSwitch(scope.row)"-->
                      <!--                          >-->
                      <!--                          </el-switch>-->
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('submit_time')" align="center">
                    <template slot-scope="scope">
                      {{scope.row.date}}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('device_state')" align="center">
                    <template slot-scope="scope">
                      {{scope.row.result}}
                    </template>
                  </el-table-column>
                  <!--操作-->
                  <el-table-column :label="$t('caozuo')" header-align="center" align="center" width="240">
                    <template slot-scope="scope">
                      <el-button type="primary"  size="mini" @click="updateDevice(scope.row,2)">
                        {{ $t('device_view') }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="replacement.count"
                    :total="replacementCount"
                    :current-page="replacement.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('exception_submission')" name="three">
              <!--表格-->
              <div class="table-box">
                <el-table
                    :data="replacementData"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <el-table-column :label="$t('deviceName')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.name }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('yichangTime')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.actualTime}}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('submission_reason')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.reason}}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('reporter')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.person}}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('submit_time')" align="center">
                    <template slot-scope="scope">
                      {{scope.row.date}}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('device_state')" align="center">
                    <template slot-scope="scope">
                      <span :style="{color:scope.row.state === 0 ? '#EA000E' : scope.row.state === 1 ? '#F7B515' : scope.row.state === 2 ? '#37C239' :'#777474' }">{{scope.row.state === 0 ? '未提报' : scope.row.state === 1 ? $t('unreviewed') : scope.row.state === 2 ? $t('reviewed') :$t('overtime_cancellation')}}</span>
                    </template>
                  </el-table-column>
                  <!--操作-->
                  <el-table-column :label="$t('caozuo')" header-align="center" align="center" width="240">
                    <template slot-scope="scope">
                      <el-button type="primary"  size="mini" @click="updateDevice(scope.row,2)">
                        {{ $t('device_view') }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="replacement.count"
                    :total="replacementCount"
                    :current-page="replacement.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('fault_repair')" name="four">
              <div class="control-box">
                <!--添加按钮-->
                <div class="cb-left">
                  <el-input
                      v-model="form.name"
                      :placeholder="$t('search_reason')"
                      style="width: 200px; margin-left: 10px"
                      class="control-item"
                      clearable
                      @keyup.enter.native="getDevicePrintTypeList(1)"
                  />

                  <el-button
                      class="control-item"
                      type="primary"
                      icon="el-icon-search"
                      @click="getDevicePrintTypeList(1)"
                  >
                    {{ $t('search') }}
                  </el-button>
                </div>
                <div class="cb-right">
                  <el-button
                      class="control-item"
                      type="primary"
                      style="margin-left: 10px;"
                      icon="el-icon-edit"
                      @click="toAdd(1)"
                  >
                    {{ $t('add_increase') }}
                  </el-button>
                </div>
              </div>
              <!--表格-->
              <div class="table-box">
                <el-table
                    :data="abnormal"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <!--序号-->
                  <el-table-column :label="$t('no')" type="index" align="center" width="80">
                  </el-table-column>
                  <el-table-column :label="$t('reason_optional')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.value }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('type')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.teamState === 1 ? $t('platform') : $t('enterprise') }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('is_it_enable')" align="center">
                    <template slot-scope="scope">
                      <el-switch
                          v-model="scope.row.state === 1 ? true : false"
                          @change="handleSwitch(scope.row)"
                      >
                      </el-switch>
                    </template>
                  </el-table-column>

                  <!--操作-->
                  <el-table-column :label="$t('caozuo')" header-align="center" align="center" width="240">
                    <template slot-scope="scope">
                      <el-button type="primary" v-if="scope.row.teamState !== 1" size="mini" @click="updateDevice(scope.row,1)">
                        {{ $t('device_edit') }}
                      </el-button>
                      <el-button
                          v-if="scope.row.teamState !== 1"
                          type="primary"
                          plain
                          size="mini"
                          icon="el-icon-delete"
                          @click="deleteDevice(scope.row,1)"
                      >
                        {{ $t('delete') }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="form.count"
                    :total="totalCount"
                    :current-page="form.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- 添加编辑 -->
    <el-dialog
        :visible.sync="daoruVal"
        :title="title"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :append-to-body="false"
        width="550px"
    >
      <div>
        <el-form :model="ruleForm" :rules="rules" label-position="left" ref="ruleForm1" label-width="70px"
                 class="demo-ruleForm">
          <el-form-item :label="$t('reason')" prop="value">
            <el-input v-model="ruleForm.value" maxlength="20" :placeholder="$t('Please_enter_the_reason')" ></el-input>
          </el-form-item>
          <!--          <el-form-item label="状态" prop="state">-->
          <!--            <el-select-->
          <!--                v-model="ruleForm.state"-->
          <!--                clearable-->
          <!--                placeholder="请选择"-->
          <!--                style="width: 100%"-->
          <!--            >-->
          <!--              <el-option-->
          <!--                  v-for="item in state"-->
          <!--                  :key="item.type"-->
          <!--                  :label="item.label"-->
          <!--                  :value="item.type"-->
          <!--              ></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="daoruVal = false">{{ $t('cancel') }}</el-button>
          <el-button v-if="title === $t('add')" v-loading="loading" type="primary" @click="handleAddConfirm">{{ $t('queding') }}</el-button>
          <el-button v-if="title === $t('device_edit')" v-loading="loading" type="primary" @click="handleAddConfirmEdit">{{ $t('queding') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from '@/api';
import { manageOauth } from '@/utils/validate';

export default {
  data() {
    return {
      abnormal: [],
      replacementData: [],
      loading:false,
      title: this.$t('add'),
      type: 1,
      daoruVal: false,
      form: {
        page: 1,
        count: 10,
        name: '',
        type: 2,
      },
      replacement: {
        page: 1,
        count: 10,
        name: '',
        type: -1
      },
      state: [
        {
          label:this.$t('enable'),
          type: 1
        },
        {
          label:this.$t('not_enabled'),
          type: 0
        }
      ],
      totalCount: 0,
      replacementCount: 0,
      activeName: 'first',
      activeName2: 'first',
      ruleForm: {
        value: '',
        type: null,
        teamId: '',
        id: ''
      },
      rules: {
        value: [
          {
            required: true,
            message: this.$t('Please_enter_the_reason'),
            trigger: 'blur'
          },
          { max: 12, message: this.$t('length_within12_characters'), trigger: 'blur' },
        ],
        state: [
          {
            required: true,
            message: this.$t('select_status'),
            trigger: 'blur'
          }
        ]
      },
    };
  },
  computed: {
  },
  created() {
    this.getDevicePrintTypeList();
    this.getDevicePrintTypeList(2);
  },

  methods: {
    getDevicePrintTypeList() {
      API.getTaskList({...this.replacement}).then(res => {
        console.log(res)
          this.replacementData = res.message.data.list
          this.replacementCount = res.message.data.totalCount
      })
    },
    back() {
      this.$router.go(-1);
    },
    // 弹窗确定
    handleAddConfirm() {
      if (this.type === 1) {
        this.ruleForm.type = 13
      } else {
        this.ruleForm.type = 14
      }
      this.$refs['ruleForm1'].validate((valid) => {
        if (valid) {
          API.abnormalDataAdd({id: this.ruleForm.id,type:this.ruleForm.type,teamId:this.ruleForm.teamId,value:this.ruleForm.value}).then(res => {
            console.log(res.message.success)
            this.getDevicePrintTypeList(1);
            this.getDevicePrintTypeList(2);
            if (res.message.success) {
              this.$message({
                message: this.$t('add_successful'),
                type: 'success',
                duration: 2000,
              });
              this.daoruVal = false
            } else {
              this.$message({
                message: res.message.message,
                type: 'error',
                duration: 2000,
              });
            }

          })
        }
      })
    },
    handleAddConfirmEdit() {
      if (this.type === 1) {
        this.ruleForm.type = 13
      } else {
        this.ruleForm.type = 14
      }
      this.$refs['ruleForm1'].validate((valid) => {
        if (valid) {
          API.abnormalDataAdd(this.ruleForm).then(res => {
            console.log(res.message.success)
            this.getDevicePrintTypeList(1);
            this.getDevicePrintTypeList(2);
            if (res.message.success) {
              this.$message({
                message: this.$t('edit_successful'),
                type: 'success',
                duration: 2000,
              });
              this.daoruVal = false
            }

          })
        }
      })
    },
    handleSwitch(v) {
      if (v.state === 0) {
        v.state = 1
        API.abnormalDataState(v).then(res => {
          if (res.message.success) {
            this.$message({
              message: this.$t('success_enabled'),
              type: 'success',
              duration: 2000,
            });
          }
        })
      } else {
        v.state = 0
        API.abnormalDataState(v).then(res => {
          if (res.message.success) {
            this.$message({
              message: this.$t('successfully_disabled'),
              type: 'success',
              duration: 2000,
            });
          }
        })
      }
    },
    getDeviceListById(tab) {
      console.log(tab)
      if (tab.name === 'second') {
        this.replacement.page = 1
        this.replacement.type = 1
        this.replacement.count = 10
        this.getDevicePrintTypeList();
      } else if (tab.name === 'three'){
        this.replacement.page = 1
        this.replacement.type = 2
        this.replacement.count = 10
        this.getDevicePrintTypeList();
      }

      console.log(tab.name)
    },
    getDeviceListById2(tab) {
      if (tab.name === 'first') {
        this.replacement.page = 1
        this.replacement.type = 1
        this.replacement.count = 10
        this.getDevicePrintTypeList();
      }
      console.log(tab.name)
    },
    deleteDevice(val,type) {
      this.$confirm(this.$t('confirm_deletion_reason'), this.$t('prompt'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('device_confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      }).then(() => {
        API.abnormalDataDelete(val.id).then(res => {
          console.log(res)
          if (res.message.success) {
            this.$message({
              message: this.$t('Deleted_successfully'),
              type: 'success',
              duration: 2000,
            });
            this.getDevicePrintTypeList(1);
            this.getDevicePrintTypeList(2);
          }
        })
      });
    },
    toAdd(type) {
      this.ruleForm.value = ''
      this.ruleForm.id = ''
      this.ruleForm.type = ''
      this.title = this.$t('add')
      this.daoruVal = true
      this.$nextTick(() => {
        this.$refs.ruleForm1.clearValidate();
      });
      this.type = type
    },
    updateDevice(device,type) {
      this.title = this.$t('device_edit')
      this.type = type
      this.$nextTick(() => {
        this.$refs.ruleForm1.clearValidate();
      });
      this.ruleForm = JSON.parse(JSON.stringify(device))
      this.daoruVal = true
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.getDevicePrintTypeList(1);
    },
    handleCurrentChange(val) {
      this.form.page = val ;
      this.getDevicePrintTypeList(1);
    },
    handleSizeChange2(val) {
      this.replacement.count = val;
      this.getDevicePrintTypeList(2);
    },
    handleCurrentChange2(val) {
      this.replacement.page = val ;
      this.getDevicePrintTypeList(2);
    },
  },
};
</script>
<style scoped lang="scss">
.table-box {
  position: relative;
}
::v-deep .dialog-footer {
  text-align: right;
}
::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #F3A100;
}
.control-item {

}
.control-btn {
  text-align: right;
  position: absolute;
  right: 0px;
  top: -0px;
  z-index: 9999;
}
</style>

