<template>
    <div class="detailDialog">
      <el-dialog
        :visible.sync="dialogVisibleVal"
        width="50%"
        :close-on-click-modal="false"
        @close="handleDialogCancelClick"
        footer="null"
      >
        <el-tabs type="border-card" class="detailTab" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
                :key="item.name"
                v-for="(item, index) in editableTabs"
                :label="item.name"
                :name="item.name"
            >
            </el-tab-pane>
        </el-tabs>
        <div class="table-box">
            <el-table :data="tableData" border height="500">
                <el-table-column
                    prop="date"
                    :label="$t('date')"
                >
                </el-table-column>
                <el-table-column
                    v-if="sheetType===1"
                    prop="value"
                    label="印张数量"
                >
                </el-table-column>
                <el-table-column
                    v-if="sheetType===2"
                    prop="value"
                    :label="$t('utilization')"
                >
                </el-table-column>
                <el-table-column
                    v-if="sheetType===2"
                    prop="data"
                    label="有效工作时间"
                >
                </el-table-column>
                <el-table-column
                    v-if="sheetType===3"
                    prop="value"
                    :label="$t('Performance')"
                >
                </el-table-column>
                <el-table-column
                    v-if="sheetType===3"
                    prop="data"
                    label="有效时速"
                >
                </el-table-column>
            </el-table>
        </div>
      </el-dialog>
    </div>
  </template>

  <script>
//   import { getDeviceDataList } from "@/api/om/incident";
//   import HTransferTree from "@/harley-ui/transferTree";

  export default {
    props: {
      dialogVisible: Boolean,
      data: Object,
      device: Array,
      sheetType: Number
    },
    components: {
    },
    data() {
      return {
        editableTabs: [],
        tableData: [],
        activeName:""
      };
    },
    created: function() {},
    watch: {
      dialogVisible: function(val) {
        if(this.data.deviceList && this.data.deviceList.length){
          console.log("==================")
          console.log(this.data.deviceList)
          if(this.data.deviceList[0].name == "总计"){
            this.editableTabs = this.data.deviceList.slice(1)
          }else{
            this.editableTabs = this.data.deviceList
          }
        }
        this.activeName = this.editableTabs.length ? this.editableTabs[0].name : ""
        this.tableData = this.editableTabs.length ? this.editableTabs[0].valueList : ""

        // this.loadDeviceData();
        // this.loadTableData();
        // this.$emit("dialogVisible", val);
      }
    },
    computed: {
      dialogVisibleVal: {
        get() {
          return this.dialogVisible;
        },
        set() {}
      }
    },
    methods: {
      handleClick(e){
        this.activeName = e.name
        this.tableData = this.editableTabs[e.index].valueList
      },
      loadDeviceData() {
        getDeviceDataList({
          communityId: this.projectId,
          devicetypeId: this.deviceTypeIdDescription,
          deviceCategoryId: this.deviceCategoryId,
          incident: this.incident,
          // 1表示正常的设备
          deviceStatus: 1,
          // 1表示提交
          flag: 1,
          isPage: 0
        }).then(response => {
          this.data = response.list || [];
        });
      },
      loadTableData() {
        let len = this.tableData.length;
        for (let i = 0; i < len; i++) {
          this.value[i] = this.tableData[i].deviceSn;
        }
      },

      handleDialogOkClick: function() {
        let deviceList = [];
        for (let i = 0; i < this.value.length; i++) {
          let device = this.data.find(item => item.deviceSn === this.value[i]);
          deviceList.push(device);
        }
        this.$emit("deviceData", deviceList);
        this.handleDialogCancelClick();
      },
      handleDialogCancelClick: function() {
        this.value = [];
        this.data = [];
        this.$emit("close");
      }
    }
  };
  </script>
  <style lang="scss" scoped>

  .detailDialog {
    ::v-deep .el-tabs--border-card > .el-tabs__content{
        padding: 0;
    }
    ::v-deep .el-tabs--border-card{
        box-shadow: none;
    }
    ::v-deep .el-tabs__nav{
        height: 40px !important;
      line-height: 40px;
    }
     ::v-deep .el-tabs__item{
      display: inline-block !important;
    }
    ::v-deep .el-tabs__active-bar {
      height: 2px;
      background-color: #F3A100;
    }
    .detailTab{
        width: 90%;
        position: absolute;
        top: 10px;

    }
  }
  ::v-deep .table-box table thead th .cell{
    color: #606266;
  }
  </style>
