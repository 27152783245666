<!--设备运维：设备列表-：设备明细-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">{{ $t('device_details') }}</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
          ref="infoForm"
          :model="info"
          :rules="isUpdate ? {} : infoRules"
          :label-width="isChineseLocale ? '120px' : '160px'"
          label-position="left"
        >
          <el-form-item :label="$t('device_type')" prop="typeIds">
            <el-cascader
              ref="myCascader"
              v-model="info.typeIds"
              :placeholder="$t('please_select_device_type')"
              :disabled="true"
              :options="typeList"
              :props="{ expandTrigger: 'hover' }"
              style="width: 100%"
              size="medium"
              @change="handleChangeBrand"
            />
          </el-form-item>
          <el-form-item :label="$t('deviceName')" prop="name">
            <el-input
              v-model.trim="info.name"
              :disabled="isUpdate"
              maxlength="32"
              clearable
              :placeholder="$t('Please_fill_in_name')"
              size="medium"
            />
          </el-form-item>
          <el-form-item :label="$t('device_code')" prop="" style="max-width: 1000px">
            <el-input
                v-model="info.deviceCode"
                :disabled="isUpdate"
                clearable
                :placeholder="$t('enter_device_code')"
                size="medium"
                maxlength="20"
            />
          </el-form-item>
          <el-form-item :label="$t('machine_serial_number')" prop="">
            <el-input
              v-model.trim="info.code"
              :disabled="isUpdate"
              clearable
              :placeholder="$t('enter_machine_serial_number')"
              size="medium"
              maxlength="20"
            />
          </el-form-item>
          <el-form-item :label="$t('brand_model')" prop="brandIds">
            <el-cascader
              ref="myCascader1"
              v-model.trim="info.brandIds"
              :placeholder="$t('select_brand_model')"
              :disabled="true"
              :options="brandList"
              :props="{ expandTrigger: 'hover' }"
              style="width: 100%"
              size="medium"
              @change="handleChange"
            />
          </el-form-item>
<!--          <el-form-item v-if="infoTime" label="额定待机时长" prop="time">-->
<!--            <el-input-number :disabled="isUpdate" :min="5" :max="720" size="medium" v-model="info.time" :step="5"></el-input-number>-->
<!--            <span> 分钟</span>-->
<!--&lt;!&ndash;            <el-input&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="info.time"&ndash;&gt;-->
<!--&lt;!&ndash;                clearable&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="isUpdate"&ndash;&gt;-->
<!--&lt;!&ndash;                placeholder="请输入额定换型时间(单位：分钟)"&ndash;&gt;-->
<!--&lt;!&ndash;                size="medium"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--          </el-form-item>-->
          <!-- <el-form-item label="" v-if="brandbool" prop="brandStr">
            <el-input
              v-model.trim="info.brandStr"
              clearable
              :disabled="isUpdate"
              placeholder="请填写品牌型号"
              size="medium"
            />
          </el-form-item> -->
          <!-- <el-form-item label="出厂日期" prop="factoryDate">
          <el-date-picker
            v-model="info.factoryDate"
            type="date"
            :disabled="isUpdate"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            style="width: 100%"
            size="medium"
          />
        </el-form-item> -->

          <!--备注-->
          <el-form-item :label="$t('remark')" prop="remark">
            <el-input
              v-model.trim="info.remark"
              type="textarea"
              :disabled="isUpdate"
              clearable
              maxlength="100"
              :placeholder="$t('Please_fill_remarks')"
              :autosize="{ minRows: 4, maxRows: 4 }"
            />
          </el-form-item>
          <el-form-item :label="$t('class')" prop="groupIds" style="max-width: 1000px">
            <el-select
                v-model="info.groupIds"
                clearable
                multiple
                :disabled="isUpdate"
                :placeholder="$t('please_select_team')"
                style="width: 100%"
                @change="handleId"
            >
              <el-option
                  v-for="(item, index) in groupIds"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="设备属性" prop="iCollection">
            <el-select
              v-model="info.iCollection"
              clearable
              :disabled="true"
              placeholder="请选择设备属性"
              style="width: 100%"
              @change="handleId"
            >
              <el-option
                v-for="(item, index) in attributeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item> -->

          <!-- <el-form-item style="width: 730px" label="工作幅面">
          <el-col :span="5">
            <el-form-item>
              <el-input-number
                v-model="info.length"
                :min="0"
                :max="2000"
                :disabled="isUpdate"
                size="small"
              />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <span class="mr-10 ml-10 font-24">*</span>
          </el-col>
          <el-col :span="5">
            <el-form-item>
              <el-input-number
                v-model="info.width"
                :min="0"
                :max="2000"
                :disabled="isUpdate"
                size="small"
              />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <span class="mr-10 ml-10 font-24">-</span>
          </el-col>
          <el-col :span="5">
            <el-form-item>
              <el-input-number
                v-model="info.length2"
                :min="0"
                :max="2000"
                :disabled="isUpdate"
                size="small"
              />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <span class="mr-10 ml-10 font-24">*</span>
          </el-col>
          <el-col :span="5">
            <el-form-item>
              <el-input-number
                v-model="info.width2"
                :min="0"
                :max="2000"
                :disabled="isUpdate"
                size="small"
              />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <span class="note">（mm）</span>
          </el-col>
        </el-form-item> -->
          <!-- <el-form-item prop="ratedCapacity" label="运行速度">
            <el-input-number
              v-model="info.ratedCapacity"
              :min="0"
              :max="60000"
              :disabled="isUpdate"
              size="small"
            />
            <span class="note">（张/小时）</span>
          </el-form-item> -->
          <!-- <el-form-item prop="ratedWorkhours" label="每天额定工时">
            <el-input-number
              v-model="info.ratedWorkhours"
              :min="1"
              :max="1440"
              :disabled="isUpdate"
              size="small"
            />
            <span class="note">小时</span>
          </el-form-item> -->
          <!-- <el-form-item label="额定产能">
            <el-input-number
              v-model="info.ratedCapacity"
              :min="1"
              :max="1000000"
              :disabled="isUpdate"
              size="small"
            />
          </el-form-item> -->
          <!--<el-form-item label="保养人" prop="maintainPerson">
            <el-select
              v-model="info.maintainPerson"
              multiple
              :disabled="isUpdate"
             :placeholder="$t('select_maintenance_person')"
              style="width: 100%"
            >
              <el-option
                v-for="item in maintainPersons"
                :key="item.id"
                :label="item.cName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="点检人" prop="checkPerson">
            <el-select
              v-model="info.checkPerson"
              multiple
              :disabled="isUpdate"
              :placeholder="$t('select_inspection_person')"
              style="width: 100%"
            >
              <el-option
                v-for="item in checkPersons"
                :key="item.id"
                :label="item.cName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>-->
          <el-form-item :disabled="isUpdate" class="widthTable">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              :row-class-name="tableRowClassName"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                v-if="!isUpdate"
                :selectable="selectable"
                type="selection"
                model=""
                width="50"
                :disabled="true"
              />
              <el-table-column :label="$t('maintenance_strategy')" width="190">
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 1">
                    {{ $t('day_maintainance') }}
                  </span>
                  <span v-if="scope.row.type == 2">
                    {{ $t('weeks_maintainance') }}
                  </span>
                  <span v-if="scope.row.type == 30">
                    {{ $t('half_months_maintainance') }}
                  </span>
                  <span v-if="scope.row.type == 3">
                    {{ $t('months_maintainance') }}
                  </span>
                  <span v-if="scope.row.type == 4">
                    {{ $t('quarter_maintainance') }}
                  </span>
                  <span v-if="scope.row.type == 5">
                    {{ $t('semi_annual_maintenance') }}
                  </span>
                  <span v-if="scope.row.type == 6">
                    {{ $t('annual_maintenance') }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="name" :label="$t('time_range')" width="510">
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 1">
                    {{ $t('daily') }}
                  </span>
                  <div v-if="scope.row.type == 2">
                    <el-select v-model="scope.row.week" :disabled="true" :placeholder="$t('please_select')" >
                      <el-option
                        v-for="item in weeks"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                  <div v-if="scope.row.type == 30">
                    {{ $t('monthly') }}
                    <el-select
                        v-model="scope.row.day1"
                        :placeholder="$t('please_select')"
                        style="width: 100px"
                        :disabled="true"
                        @change="handleChangeValueMoth(scope.row.day1)"
                    >
                      <el-option
                          v-for="item in daysTime1"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select
                        v-model="scope.row.day2"
                        :placeholder="$t('please_select')"
                        style="width: 100px"
                        :disabled="true"
                        @change="handleChangeValueMoth2(scope.row.day2)"
                    >
                      <el-option
                          v-for="item in daysTime2"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 3">
                    {{ $t('monthly') }}
                    <el-select
                      v-model="scope.row.day1"
                      :placeholder="$t('please_select')"
                      style="width: 100px"
                      :disabled="true"
                      @change="handleChangeValue(scope.row.day1)"
                    >
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select
                      v-model="scope.row.day2"
                      :placeholder="$t('please_select')"
                      style="width: 100px"
                      :disabled="true"
                      @change="handleChangeValue2(scope.row.day2)"
                    >
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 4">
                    {{ $t('first_quarter_february') }}：
                    <el-select
                      v-model="scope.row.month1"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in month3"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{$t('Month') }}
                    <el-select
                      v-model="scope.row.day1"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select
                      v-model="scope.row.day2"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 4">
                    {{ $t('second_quarter') }}：
                    <el-select
                      v-model="scope.row.month2"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in month4"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{$t('Month') }}
                    <el-select
                      v-model="scope.row.day3"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select
                      v-model="scope.row.day4"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 4">
                    {{ $t('third_quarter') }}：
                    <el-select
                      v-model="scope.row.month3"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in month5"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{$t('Month') }}
                    <el-select
                      v-model="scope.row.day5"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select
                      v-model="scope.row.day6"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 4">
                    {{ $t('fourth_quarter') }}：
                    <el-select
                      v-model="scope.row.month4"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in month6"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{$t('Month') }}
                    <el-select
                      v-model="scope.row.day7"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select
                      v-model="scope.row.day8"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 5">
                    {{ $t('first_half') }}：
                    <el-select
                      v-model="scope.row.month1"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in month1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{$t('Month') }}
                    <el-select
                      v-model="scope.row.day1"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select
                      v-model="scope.row.day2"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 5">
                    {{ $t('second_half') }}：
                    <el-select
                      v-model="scope.row.month2"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in month2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{$t('Month') }}
                    <el-select
                      v-model="scope.row.day3"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select
                      v-model="scope.row.day4"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 6">
                    <el-select
                      v-model="scope.row.month"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in month"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{$t('Month') }}
                    <el-select
                      v-model="scope.row.day1"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select
                      v-model="scope.row.day2"
                      :placeholder="$t('please_select')"
                      style="width: 80px"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                width="175"
                prop="address"
                align="center"
                :label="$t('strategy')"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-select
                    v-if="scope.row.type == 1"
                    v-model="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                    :disabled="true"
                    @change="handleChangeButton1(scope.row)"
                  >
                    <el-option
                      v-for="item in dayTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select
                    v-if="scope.row.type == 2"
                    v-model="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                    :disabled="true"
                    @change="handleChangeButton2(scope.row)"
                  >
                    <el-option
                      v-for="item in weekTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 30"
                      v-model="scope.row.templateId"
                      style="width: 100%"
                      :placeholder="$t('please_select')"
                      value=""
                      :disabled="true"
                      @change="handleChangeButton7(scope.row)"
                  >
                    <el-option
                        v-for="item in halfMonthTemplateList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                    v-if="scope.row.type == 3"
                    v-model="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                    :disabled="true"
                    @change="handleChangeButton3(scope.row)"
                  >
                    <el-option
                      v-for="item in monthTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select
                    v-if="scope.row.type == 4"
                    v-model="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                    :disabled="true"
                    @change="handleChangeButton4(scope.row)"
                  >
                    <el-option
                      v-for="item in quarterTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select
                    v-if="scope.row.type == 5"
                    v-model="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                    :disabled="true"
                    @change="handleChangeButton5(scope.row)"
                  >
                    <el-option
                      v-for="item in halfyearTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select
                    v-if="scope.row.type == 6"
                    v-model="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                    :disabled="true"
                    @change="handleChangeButton6(scope.row)"
                  >
                    <el-option
                      v-for="item in yearTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                  width="175"
                  prop="address"
                  align="center"
                  :label="$t('baoyangren')"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-select
                      v-if="scope.row.type == 1"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                     :placeholder="$t('select_maintenance_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 2"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                     :placeholder="$t('select_maintenance_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 30"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                     :placeholder="$t('select_maintenance_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 3"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                     :placeholder="$t('select_maintenance_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 4"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                     :placeholder="$t('select_maintenance_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 5"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                     :placeholder="$t('select_maintenance_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 6"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                     :placeholder="$t('select_maintenance_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                  width="175"
                  prop="address"
                  align="center"
                  :label="$t('inspector')"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-select
                      v-if="scope.row.type == 1"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      :placeholder="$t('select_inspection_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 2"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      :placeholder="$t('select_inspection_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 30"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      :placeholder="$t('select_inspection_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 3"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      :placeholder="$t('select_inspection_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 4"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      :placeholder="$t('select_inspection_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 5"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      :placeholder="$t('select_inspection_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 6"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      :placeholder="$t('select_inspection_person')"
                      multiple
                      :disabled="isUpdate"
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                  width=""
                  prop="address"
                  align="center"
                  label=""
              >
                <template slot-scope="scope">
                  <el-checkbox :disabled="isUpdate" v-if="scope.row.type == 1" v-model="scope.row.isGroup">{{ $t('class') }}</el-checkbox>
                  <el-checkbox :disabled="isUpdate" v-if="scope.row.type == 2" v-model="scope.row.iBiweekly">{{ $t('bi_weekly') }}</el-checkbox>
<!--                  <el-checkbox v-if="scope.row.type == 3" :disabled="isUpdate" v-model="scope.row.iHalfMoth">半月</el-checkbox>-->
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item :label="$t('device_qr_code')">
            <div>
              <vue-qr
                v-show="false"
                ref="Qrcode"
                :text="qrCodeConfig.text"
                :download="downloadFilename"
                :margin="10"
                :size="qrImgSize"
                :dot-scale="qrCodeConfig.dotScale"
                :color-dark="qrCodeConfig.colorDark"
              />
              <canvas
                ref="qrcanvas"
                :width="qrSize"
                :height="qrSizeH"></canvas>
            </div>
          </el-form-item>

          <!--按钮组-->
          <el-form-item>
            <!-- <el-button
              :loading="saveLoading"
              size="small"
              type="primary"
              @click="showOtherConfigClick"
            >
              设备配置
            </el-button> -->
            <el-button v-if="managePermission" size="small" type="primary" @click="downloadImg">
              <i class="el-icon-download" />
              {{ $t('download_qr_code') }}
            </el-button>
            <el-button
              v-show="isUpdate"
              size="small"
              type="primary"
              icon="el-icon-edit"
              @click="updateDevice"
            >
              {{ $t('device_edit') }}
            </el-button>
            <el-button
              v-show="!isUpdate"
              :loading="saveLoading"
              size="small"
              type="primary"
              @click="saveDevice"
            >
              <!-- <svg-icon icon-class="icon_save" /> -->
              {{ $t('device_save') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-dialog
        :visible.sync="showOtherConfig"
        :title="$t('device_configuration')"
        :modal-append-to-body="false"
        :append-to-body="true"
        @close="closeCreateDialog"
      >
        <el-form
          ref="otherInfoForm"
          :rules="isUpdate ? {} : otherInfoRules"
          :model="dialogOtherConfigInfo"
          label-width="100px"
          class="mr-70"
        >
          <div v-show="isPrintConfig">
            <!--印刷单元-->
            <el-form-item label="印刷单元" prop="yinShuaDanYuan">
              <el-select
                v-model="dialogOtherConfigInfo.yinShuaDanYuan"
                placeholder="请选择印刷单元"
                style="width: 100%"
                size="medium"
                :disabled="isUpdate"
                clearable
              >
                <el-option
                  v-for="item in otherConfigValue10"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!--上光单元-->
            <el-form-item label="上光单元" prop="shangGuangDanyuan">
              <el-select
                v-model="dialogOtherConfigInfo.shangGuangDanyuan"
                placeholder="请选择上光单元"
                style="width: 100%"
                size="medium"
                :disabled="isUpdate"
                clearable
              >
                <el-option
                  v-for="item in otherConfigValue4"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!--干燥单元-->
            <el-form-item label="干燥单元" prop="ganZaoDanYuan">
              <el-select
                v-model="dialogOtherConfigInfo.ganZaoDanYuan"
                placeholder="请选择干燥单元"
                style="width: 100%"
                size="medium"
                :disabled="isUpdate"
                clearable
              >
                <el-option
                  v-for="item in otherConfigValue4"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!--加长收纸-->
            <el-form-item label="加长收纸" prop="jiaChangShouZhi">
              <el-select
                v-model="dialogOtherConfigInfo.jiaChangShouZhi"
                placeholder="请选择加长收纸"
                style="width: 100%"
                size="medium"
                :disabled="isUpdate"
                clearable
              >
                <el-option
                  v-for="item in otherConfigValue4"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!--翻转机构-->
            <el-form-item label="翻转机构" prop="fanZhuanJiGou">
              <el-select
                v-model="dialogOtherConfigInfo.fanZhuanJiGou"
                placeholder="请选择翻转机构"
                style="width: 100%"
                size="medium"
                :disabled="isUpdate"
                clearable
              >
                <el-option
                  v-for="item in otherConfigValue"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!--基座加高-->
            <el-form-item label="基座加高" prop="jiZuoJiaGao">
              <el-select
                v-model="dialogOtherConfigInfo.jiZuoJiaGao"
                placeholder="请选择基座加高"
                style="width: 100%"
                size="medium"
                :disabled="isUpdate"
                clearable
              >
                <el-option
                  v-for="item in otherConfigValue"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!--印刷方式-->
            <el-form-item label="印刷方式">
              <el-select
                v-model="dialogOtherConfigInfo.yinShuaFangShi"
                placeholder="请选择印刷方式"
                style="width: 100%"
                size="medium"
                :disabled="isUpdate"
                clearable
              >
                <el-option
                  v-for="item in yinShuaFangShi"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!--上光方式-->
            <el-form-item label="上光方式">
              <el-select
                v-model="dialogOtherConfigInfo.shangGuangFangShi"
                placeholder="请选择上光方式"
                style="width: 100%"
                size="medium"
                :disabled="isUpdate"
                clearable
              >
                <el-option
                  v-for="item in shangGuangFangShi"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!--干燥方式-->
            <el-form-item label="干燥方式">
              <el-select
                v-model="dialogOtherConfigInfo.ganZaoFangShi"
                placeholder="请选择干燥方式"
                style="width: 100%"
                size="medium"
                :disabled="isUpdate"
                clearable
              >
                <el-option
                  v-for="item in ganZaoFangShi"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="配置">
            <el-select
              v-model="dialogotherConfig"
              multiple
              placeholder="配置"
              :disabled="isUpdate"
              style="width: 100%"
            >
              <el-option
                v-for="item in configoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button :loading="createOtherConfigBtnLoading" type="primary" @click="saveOtherConfig">
            {{ $t('device_confirm') }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
  <!--表单-->

  <!--弹出框-->
</template>
<style>
.el-table .hidden-row {
  display: none;
}
</style>
<script>
import API from '@/api'
// {
//   getDeviceConfigList,
//   getDeviceBrandList,
//   createDevice,
//   getDeviceInfo,
//   getDeviceConfig,
//   getTemplateListByType,
//   getDeviceOrderList,
//   getTeamUserXiaLa
// }
// import { getTeamUserXiaLa } from '@/api/team'
// import { mapGetters } from 'vuex'
import { manageOauth } from '@/utils/validate'
import valid from '@/utils/validator'
import convert from '@/utils/convert'
import VueQr from 'vue-qr'
// import {getClassDate} from "../../../../../../adminplat/src/api/CompanyPreserve";

export default {
  components: { VueQr },
  data() {
    return {
      buttons: '',
      qrCodeConfig: {
        text: '',
        dotScale: 1,
        colorDark: '#000000'
      },
      qrSize: 140,
      qrSizeH: 160,
      qrImgSize: 120,
      orders: [],
      downloadFilename: '',
      label: '承印物厚度',
      printTypeStr: '',
      showOtherConfig: false,
      isPrintConfig: false,
      isUpdate: false,
      typeList: [],
      brandList: [],
      strategyList: [],
      saveLoading: false,
      createOtherConfigBtnLoading: false,
      configoptions: [],
      otherConfig: [],
      dialogotherConfig: [],
      maintainPersons: [],
      checkPersons: [],
      dayTemplateList: [],
      halfMonthTemplateList: [],
      weekTemplateList: [],
      monthTemplateList: [],
      quarterTemplateList: [],
      halfyearTemplateList: [],
      attributeList: [
        { value: 1, label: this.$t('data_acquisition_device') },
        { value: 0, label: this.$t('non_data_acquisition_device') }
      ],
      yearTemplateList: [],
      brandbool: false,
      info: {
        deviceCode: '',
        code: '',
        id: '',
        printType: '',
        typeIds: [],
        brandIds: [],
        groupIds: '',
        deviceType: '',
        brand: '',
        brandStr: '',
        series: '',
        model: '',
        infoName: '',
        // factoryDate: '',
        name: '',
        remark: '',
        address: '',
        otherConfig: '',
        printConfig: '',
        // ratedCapacity: 0,
        // ratedWorkhours: 1,
        length: 0,
        length2: 0,
        width: 0,
        width2: 0,
        // ratedCapacity: 1,
        thickness: 0,
        thickness2: 0,
        strategy: [],
        maintainPerson: '',
        checkPerson: '',
        time: ''
      },
      infoTime: '',
      infoRules: {
        name: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              valid.checkNull(rule, value, callback, this.$t('enter_device_name'), false)
              // valid.checkByRegex(rule, value, callback, /^.{4,32}$/, '设备名称为4-32个字符')
              valid.checkBybite(rule, value, callback,this.$t('device_name_characters'))
              callback()
            }
          }
        ],
        code: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              valid.checkNull(rule, value, callback, this.$t('please_enter_machine_serial_number'), false)
              valid.checkByRegex(rule, value, callback, /^.{1,20}$/, this.$t('not_exceed_characters'))
              callback()
            }
          }
        ],
        // ratedCapacity: [
        //   {
        //     required: true,
        //     message: '请填写运行速度',
        //     trigger: 'blur'
        //   }
        // ],
        typeIds: [
          {
            required: true,
            message: this.$t('please_select_device_type'),
            trigger: 'change'
          }
        ],
        brandIds: [
          {
            required: true,
            message: this.$t('select_brand_model'),
            trigger: 'change'
          }
        ],
        time: [
          { required: true, message: this.$t('enter_rated_standby_time_min'), trigger: 'blur' },
          { pattern: /[1-9]\d*/, max: 720, message: this.$t('positive_integer'), trigger: 'change' }

        ],
        brandStr: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var success = true
              if (!value) {
                callback()
              }
              if (this.brandbool) {
                if (value) {
                  if (value.length === 0 || value.length > 20) {
                    success = false
                    callback()
                  }
                }
              }
              if (success) {
                callback()
              } else {
                callback(new Error(this.$t('enter_brand_model_within_characters')))
              }
            }
          }
        ],
        // factoryDate: [
        //   {
        //     required: true,
        //     trigger: 'change',
        //     validator: (rule, value, callback) => {
        //       valid.checkSelect(rule, value, callback, '请选择出厂日期')
        //     }
        //   }
        // ],
        address: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                valid.checkNull(rule, value, callback, '请填写安装位置', false)
                valid.checkByRegex(rule, value, callback, /^.{1,20}$/, '安装位置不超过20个字')
                callback()
              }
            }
          }
        ],
        guGaoCode: [
          {
            required: false,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                valid.checkByRegex(rule, value, callback, /^.{0,30}$/, 'neptune序列号不超过30个字')
                callback()
              }
            }
          }
        ],
        // iCollection: [
        //   {
        //     required: true,
        //     message: '请选择设备属性',
        //     trigger: 'blur'
        //   }
        // ],
        remark: [
          {
            required: false,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                valid.checkComment(rule, value, callback, 100, this.$t('remarks_should_not_exceed_characters'))
                callback()
              }
            }
          }
        ],
        maintainPerson: [
          {
            required: true,
            message: this.$t('select_maintenance_person'),
            trigger: 'change'
          }
        ],
        checkPerson: [
          {
            required: true,
            message: this.$t('select_inspection_person'),
            trigger: 'change'
          }
        ]
        // ratedWorkhours: [
        //   {
        //     required: true,
        //     trigger: 'blur',
        //     validator: (rule, value, callback) => {
        //       var success = true
        //       if (value > 24 || value < 1) {
        //         success = false
        //       }
        //       if (success) {
        //         callback()
        //       } else {
        //         callback(new Error('请正确填写每日额定工时'))
        //       }
        //     }
        //   }
        // ]
      },
      otherInfoRules: {
        yinShuaDanYuan: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                valid.checkSelect(rule, value, callback, '请配置印刷单元')
                callback()
              }
            }
          }
        ],
        shangGuangDanyuan: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkSelect(rule, value, callback, '请配置上光单元')
              callback()
            }
          }
        ],
        ganZaoDanYuan: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                valid.checkSelect(rule, value, callback, '请配置干燥单元')
                callback()
              }
            }
          }
        ],
        jiaChangShouZhi: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                valid.checkSelect(rule, value, callback, '请配置加长收纸')
                callback()
              }
            }
          }
        ],
        fanZhuanJiGou: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                valid.checkSelect(rule, value, callback, '请配置反转机构')
                callback()
              }
            }
          }
        ],
        jiZuoJiaGao: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                valid.checkSelect(rule, value, callback, '请配置基座加高')
                callback()
              }
            }
          }
        ]
      },
      otherConfigValue: [
        { id: '是', name: '是' },
        { id: '否', name: '否' }
      ],
      otherConfigValue10: [
        { id: '1', name: '1' },
        { id: '2', name: '2' },
        { id: '3', name: '3' },
        { id: '4', name: '4' },
        { id: '5', name: '5' },
        { id: '6', name: '6' },
        { id: '7', name: '7' },
        { id: '8', name: '8' },
        { id: '9', name: '9' },
        { id: '10', name: '10' }
      ],
      otherConfigValue4: [
        { id: '0', name: '0' },
        { id: '1', name: '1' },
        { id: '2', name: '2' },
        { id: '3', name: '3' },
        { id: '4', name: '4' }
      ],
      yinShuaFangShi: [
        { id: '普通', name: '普通' },
        { id: 'UV', name: 'UV' },
        { id: '两用', name: '两用' }
      ],
      shangGuangFangShi: [
        { id: '水性', name: '水性' },
        { id: 'UV', name: 'UV' }
      ],
      ganZaoFangShi: [
        { id: '传统', name: '传统' },
        { id: 'IR', name: 'IR' },
        { id: 'UV', name: 'UV' }
      ],
      dialogOtherConfigInfo: {
        yinShuaDanYuan: '',
        shangGuangDanyuan: '',
        ganZaoDanYuan: '',
        jiaChangShouZhi: '',
        fanZhuanJiGou: '',
        jiZuoJiaGao: '',
        yinShuaFangShi: '',
        shangGuangFangShi: '',
        ganZaoFangShi: ''
      },
      otherConfigInfo: {
        yinShuaDanYuan: '',
        shangGuangDanyuan: '',
        ganZaoDanYuan: '',
        jiaChangShouZhi: '',
        fanZhuanJiGou: '',
        jiZuoJiaGao: '',
        yinShuaFangShi: '',
        shangGuangFangShi: '',
        ganZaoFangShi: ''
      },
      tableData: [
        {
          type: 1,
          templateId: '',
          maintainIds: [],
          checkIds: [],
          isGroup: false
        },
        {
          type: 2,
          week: 1,
          templateId: '',
          maintainIds: [],
          checkIds: [],
          iBiweekly: false
        },
        {
          type: 30,
          day1: 1,
          day2: 16,
          templateId: '',
          codeList: [],
          maintainIds: '',
          checkIds: [],
          iHalfMoth: false,
          disabled: false
        },
        {
          type: 3,
          day1: 1,
          day2: 2,
          templateId: '',
          maintainIds: [],
          checkIds: [],
          iHalfMoth: false,
          disabled: false
        },
        {
          type: 4,
          month1: 1,
          month2: 4,
          month3: 7,
          month4: 10,
          day1: 1,
          day2: 2,
          day3: 1,
          day4: 2,
          day5: 1,
          day6: 2,
          day7: 1,
          day8: 2,
          templateId: '',
          maintainIds: [],
          checkIds: []
        },
        {
          type: 5,
          month1: 1,
          month2: 7,
          day1: 1,
          day2: 2,
          day3: 1,
          day4: 2,
          templateId: '',
          maintainIds: [],
          checkIds: []
        },
        {
          type: 6,
          month: 1,
          day1: 1,
          day2: 2,
          templateId: '',
          maintainIds: [],
          checkIds: []
        }
      ],
      multipleSelection: [],
      weeks: [
        {
          value: 1,
          label: this.$t('monday')
        },
        {
          value: 2,
          label: this.$t('tuesday')
        },
        {
          value: 3,
          label: this.$t('wednesday')
        },
        {
          value: 4,
          label: this.$t('thursday')
        },
        {
          value: 5,
          label: this.$t('friday')
        },
        {
          value: 6,
          label: this.$t('saturday')
        },
        {
          value: 7,
          label:  this.$t('sunday')
        }
      ],
      templateList: [],
      days1: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        },
        {
          value: 13,
          label: 13
        },
        {
          value: 14,
          label: 14
        },
        {
          value: 15,
          label: 15
        },
        {
          value: 16,
          label: 16
        },
        {
          value: 17,
          label: 17
        },
        {
          value: 18,
          label: 18
        },
        {
          value: 19,
          label: 19
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 21,
          label: 21
        },
        {
          value: 22,
          label: 22
        },
        {
          value: 23,
          label: 23
        },
        {
          value: 24,
          label: 24
        },
        {
          value: 25,
          label: 25
        },
        {
          value: 26,
          label: 26
        },
        {
          value: 27,
          label: 27
        },
        {
          value: 28,
          label: 28
        }
      ],
      days2: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        },
        {
          value: 13,
          label: 13
        },
        {
          value: 14,
          label: 14
        },
        {
          value: 15,
          label: 15
        },
        {
          value: 16,
          label: 16
        },
        {
          value: 17,
          label: 17
        },
        {
          value: 18,
          label: 18
        },
        {
          value: 19,
          label: 19
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 21,
          label: 21
        },
        {
          value: 22,
          label: 22
        },
        {
          value: 23,
          label: 23
        },
        {
          value: 24,
          label: 24
        },
        {
          value: 25,
          label: 25
        },
        {
          value: 26,
          label: 26
        },
        {
          value: 27,
          label: 27
        },
        {
          value: 28,
          label: 28
        },
        {
          value: 29,
          label: 29
        },
        {
          value: 30,
          label: 30
        },
        {
          value: 31,
          label: 31
        }
      ],
      daysTime1:[
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        },
        {
          value: 13,
          label: 13
        },
        {
          value: 14,
          label: 14
        },
      ],
      daysTime2: [
        {
          value: 16,
          label: 16
        },
        {
          value: 17,
          label: 17
        },
        {
          value: 18,
          label: 18
        },
        {
          value: 19,
          label: 19
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 21,
          label: 21
        },
        {
          value: 22,
          label: 22
        },
        {
          value: 23,
          label: 23
        },
        {
          value: 24,
          label: 24
        },
        {
          value: 25,
          label: 25
        },
        {
          value: 26,
          label: 26
        },
        {
          value: 27,
          label: 27
        },
        {
          value: 28,
          label: 28
        },
        {
          value: 29,
          label: 29
        },
        {
          value: 30,
          label: 30
        },
        {
          value: 31,
          label: 31
        }
      ],
      month: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        }
      ],
      month1: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        }
      ],
      month2: [
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        }
      ],
      month3: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        }
      ],
      month4: [
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        }
      ],
      month5: [
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        }
      ],
      month6: [
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        }
      ],
      trategyInfo: [],
      form: {
        name: '',
        page: 1,
        count: 100000000,
        teamId: ''
      },
      groupIds: [] // 班组
    }
  },
  computed: {
    managePermission: {
      get: function() {
        return manageOauth('device_operate', this.buttons)
      }
    },
    isChineseLocale() {
      return localStorage.getItem('locale') === 'zh';
    }
  },
  created() {
    if (this.$route.query.isUpdate) {
      this.isUpdate = !this.isUpdate
    }
    if (this.$store.getters.userInfo.buttons) {
      this.buttons = this.$store.getters.userInfo.buttons
    } else {
      this.buttons = sessionStorage.getItem('buttons')
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('buttons', JSON.stringify(this.$store.getters.userInfo.buttons))
    })
    this.updateDevice()
    API.getDeviceConfigList().then(response => {
      this.typeList = convert.arr2cascader_deviceconfig(response.message.data)
    })
    // API.getTeamUserXiaLa({ type: 1 })
    //   .then(response => {
    //     this.maintainPersons = response.message.data
    //   })
    //   .catch(err => {
    //     this.$message({
    //       message: err,
    //       type: 'error'
    //     })
    //   })
    // console.log(this.maintainPersons,12111)
    // this.form.teamId = this.$route.query.teamId
    API.getClassDate(this.form).then((response) => {
      this.groupIds = response.message.data.list;
    });
    // API.getTeamUserXiaLa({ type: 2 })
    //   .then(response => {
    //     this.checkPersons = response.message.data
    //   })
    //   .catch(err => {
    //     this.$message({
    //       message: err,
    //       type: 'error'
    //     })
    //   })

    // API.getDeviceOrderList()
    //   .then(response => {
    //     console.log(response)
    //     this.orders = response.message.data.list
    //   })
    //   .catch(err => {
    //     this.$message({
    //       message: err,
    //       type: 'error'
    //     })
    //   })
    const request1 = API.getTeamUserXiaLa({ type: 1 });
    const request2 = API.getTeamUserXiaLa({ type: 2 });

// 使用 Promise.all 等待两个请求都完成
    Promise.all([request1, request2])
        .then(responses => {
          // 分别处理两个请求的返回数据
          this.maintainPersons = responses[0].message.data;
          this.checkPersons = responses[1].message.data;

          // 检查数据是否都获取到了
          if (this.maintainPersons && this.checkPersons) {
            var deviceId = this.$route.query.id
            if (deviceId !== '' && deviceId !== undefined) {
              API.getDeviceInfo({ deviceId: deviceId }).then(response => {
                this.printTypeStr = response.message.data.printTypeStr
                this.info = response.message.data
                // console.log(response.message.data)
                // console.log(this.info)
                if (response.message.data.groupIds) {
                  this.info.groupIds = response.message.data.groupIds.split(',')
                }
                this.qrCodeConfig.text = this.info.id
                // 生成二维码下面的文字
                this.genQRandName(this.info.name)
                if (this.info.printType === '630a511d-42a6-4ccc-a495-1afb3debe272') {
                  this.infoTime = '630a511d-42a6-4ccc-a495-1afb3debe272'
                  this.printingEquipment = this.info.printType
                }
                this.info.typeIds = []
                this.info.typeIds.push(this.info.printType)
                this.info.typeIds.push(this.info.deviceType)
                this.info.brandIds = []
                this.info.brandIds.push(this.info.brand)
                if (this.info.series) {
                  this.info.brandIds.push(this.info.series)
                  this.info.brandIds.push(this.info.model)
                } else {
                  this.brandbool = true
                }
                this.info.brandStr = this.info.brandStrInfo
                API.getDeviceBrandList({ printTypeId: this.info.deviceType })
                    .then(response => {
                      this.brandList = response.message.data
                    })
                    .catch(err => {
                      this.$message({
                        message: err,
                        type: 'error'
                      })
                    })
                API.getTemplateListByType({ deviceId: this.info.deviceType }).then(response => {
                  this.templateList = response.message.data
                  this.dayTemplateList = []
                  this.weekTemplateList = []
                  this.halfMonthTemplateList = []
                  this.monthTemplateList = []
                  this.quarterTemplateList = []
                  this.halfyearTemplateList = []
                  this.yearTemplateList = []
                  response.message.data.forEach(item => {
                    if (item.type === 1) {
                      this.dayTemplateList.push(item)
                    } else if (item.type === 2) {
                      this.weekTemplateList.push(item)
                    } else if (item.type === 3) {
                      this.monthTemplateList.push(item)
                    } else if (item.type === 4) {
                      this.quarterTemplateList.push(item)
                    } else if (item.type === 5) {
                      this.halfyearTemplateList.push(item)
                    } else if (item.type === 6) {
                      this.yearTemplateList.push(item)
                    } else if (item.type === 30) {
                      this.halfMonthTemplateList.push(item)
                    }
                  })
                })
                if (this.info.strategy) {
                  this.info.strategy.forEach(item => {
                    if (item.templateName) {
                      item.maintainIds = item.maintainIds.split(',')
                      item.checkIds = item.checkIds.split(',')
                      if ((item.type) === 1) {
                        if (item.isGroup === 1) {
                          item.isGroup = true
                        } else {
                          item.isGroup = false
                        }
                      }
                    }
                    // item.maintainIdsList = item.maintainIds.split(',')
                    // item.checkIdsList = item.checkIds.split(',')

                    // console.log(item.maintainIds.filter(id => this.maintainPersons.some(item => item.id === id)))
                    // console.log(item.checkIds.filter(id => this.checkPersons.some(item => item.id === id)))
                    if (item.type === 30) {
                      this.tableData[2] = item;
                      // this.tableData2[2] = item.codeList
                      // console.log(item.maintainIds.filter(id => this.maintainPersons.some(item => item.id === id)))
                      this.tableData[2].maintainIds = item.maintainIds.filter(id => this.maintainPersons.some(item => item.id === id))
                      this.tableData[2].checkIds = item.checkIds.filter(id => this.checkPersons.some(item => item.id === id))
                    } else if (item.type < 3) {
                      this.tableData[item.type - 1] = item;
                      console.log(this.maintainPersons)
                      console.log(item.maintainIds.filter(id => this.maintainPersons.some(item => item.id === id)))
                      console.log(item.maintainIds)
                      // this.tableData2[item.type - 1] = item.codeList
                      this.tableData[item.type - 1].maintainIds = item.maintainIds.filter(id => this.maintainPersons.some(item => item.id === id))
                      this.tableData[item.type - 1].checkIds = item.checkIds.filter(id => this.checkPersons.some(item => item.id === id))
                    } else {
                      this.tableData[item.type] = item;
                      // this.tableData2[item.type] = item.codeList
                      this.tableData[item.type].maintainIds = item.maintainIds.filter(id => this.maintainPersons.some(item => item.id === id))
                      this.tableData[item.type].checkIds = item.checkIds.filter(id => this.checkPersons.some(item => item.id === id))
                    }

                    // if (this.$route.query.state === 'check') {
                    //   this.tableData[2].disabled = true
                    // }
                    if (this.tableData[3].day1 >= 15 || this.$route.query.state === 'check') {
                      this.tableData[3].disabled = true
                    } else {
                      this.tableData[3].disabled = false
                    }

                    // console.log(this.tableData2[item.type - 1])
                    // console.log(this.tableData[item.type - 1])
                    if (item.type === 30) {
                      this.toggleSelection([this.tableData[2]]);
                    } else if (item.type < 3) {
                      this.toggleSelection([this.tableData[item.type - 1]]);
                    } else {
                      this.toggleSelection([this.tableData[item.type]]);
                    }
                    // this.tableData[item.type - 1] = item
                    // this.toggleSelection([this.tableData[item.type - 1]])
                  })
                }
                if (this.info.deviceType && this.info.deviceType.length > 0) {
                  API.getDeviceConfig({ id: this.info.deviceType }).then(async response => {
                    this.configoptions = await response.message.data
                  })
                }
                if (this.info.otherConfig && this.info.otherConfig !== '') {
                  this.otherConfig = this.info.otherConfig.split(',')
                }
                if (this.info.printConfig && this.info.printConfig !== '') {
                  this.otherConfigInfo = JSON.parse(this.info.printConfig)
                }
              })
            }
          } else {
            // 数据不完整的处理逻辑
          }
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    if (this.maintainPersons.length > 0 && this.checkPersons.length > 0){
      console.log(5898898)
    }

  },
  methods: {
    genQRandName(text) {
      const canvas = this.$refs.qrcanvas
      const that = this;
      let ctx = canvas.getContext("2d");
      let tw = ctx.measureText(text).width; //文字真实宽度
      let margin = 0
      if (tw > 140) {
        margin = 20
        this.qrSize = tw + margin + 12
        this.qrImgSize = this.qrSize
        this.qrSizeH = this.qrSize + 30
      } else {
        margin = 8
        this.qrSize = 160
        this.qrImgSize = 160
        this.qrSizeH = this.qrSize + 30
      }
      setTimeout(() => {
        ctx.fillStyle = "#aaa";
        ctx.fill();
        let img = new Image();
        img.src = that.$refs.Qrcode.$el.src
        img.onload = () => {
          ctx.drawImage(img, 0, margin / 2, this.qrImgSize, this.qrImgSize);
          ctx.font = "" + 12 + "px";
          let ftop = this.qrImgSize + margin / 2; //根据字体大小计算文字top
          let fleft = (this.qrSize - tw) / 2; //根据字体大小计算文字left
          let tp = 12 / 2; //字体边距为字体大小的一半可以自己设置
          ctx.fillStyle = "#fff";
          ctx.fillRect(
            0,
            ftop - tp / 2,
            this.qrSize,
            12 + tp
          );
          ctx.textBaseline = "top"; //设置绘制文本时的文本基线。
          ctx.fillStyle = "#000";
          ctx.fillText(text, fleft, ftop);
        }

      }, 500);
    },
    tableRowClassName(row) {
      if (this.info.strategy.indexOf(row.row) === -1 && this.isUpdate) {
        return 'hidden-row'
      }
      return ''
    },
    selectable() {
      return false
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs['multipleTable'].toggleRowSelection(row)
        })
      } else {
        this.$refs['multipleTable'].clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 日期选择变化
    handleChangeValue(val) {
      if (val>15 || this.tableData[3].day2 <= 15) {
        this.tableData[3].disabled = true
        this.tableData[3].iHalfMoth = false
      } else {
        this.tableData[3].disabled = false
      }
      // if (!val) {
      //   if (this.tableData[2].day2 < 15 || this.tableData[2].day1 >= 15) {
      //     console.log(11)
      //     this.tableData[2].disabled = true
      //     this.tableData[2].iHalfMoth = false
      //   } else {
      //     this.tableData[2].disabled = false
      //   }
      // }
    },
    handleChangeValue2(val) {
      if (val <= 15 || this.tableData[3].day1 > 15) {
        this.tableData[3].disabled = true
        this.tableData[3].iHalfMoth = false
      } else {
        this.tableData[3].disabled = false
      }
      if (!val) {
        if (this.tableData[3].day2 < 15) {
          this.tableData[3].disabled = true
          this.tableData[3].iHalfMoth = false
        } else {
          this.tableData[3].disabled = false
        }
      }
    },
    handleChangeValueMoth(val) {
      if (val>15 || this.tableData[2].day2 <= 15) {
        this.tableData[2].disabled = true
        this.tableData[2].iHalfMoth = false
      } else {
        this.tableData[2].disabled = false
      }
      if (!val) {
        if (this.tableData[2].day2 < 15 || this.tableData[2].day1 >= 15) {
          this.tableData[2].disabled = true
          this.tableData[2].iHalfMoth = false
        } else {
          this.tableData[2].disabled = false
        }
      }
    },
    handleChangeValueMoth2(val) {
      if (val <= 15 || this.tableData[2].day1 > 15) {
        this.tableData[2].disabled = true
        this.tableData[2].iHalfMoth = false
      } else {
        this.tableData[2].disabled = false
      }
      if (!val) {
        if (this.tableData[2].day2 < 15) {
          this.tableData[2].disabled = true
          this.tableData[2].iHalfMoth = false
        } else {
          this.tableData[2].disabled = false
        }
      }
    },
    handleId(val) {
      this.inline = val
    },
    downloadImg() {
      const iconUrl = this.$refs.qrcanvas.toDataURL('image/png')
      const a = document.createElement('a')
      const event = new MouseEvent('click')
      a.download = this.$t('qr_code')
      a.href = iconUrl
      a.dispatchEvent(event)
    },
    back() {
      this.$router.go(-1)
    },
    closeCreateDialog() {
      this.dialogOtherConfigInfo = {
        yinShuaDanYuan: '',
        shangGuangDanyuan: '',
        ganZaoDanYuan: '',
        jiaChangShouZhi: '',
        fanZhuanJiGou: '',
        jiZuoJiaGao: '',
        yinShuaFangShi: '',
        shangGuangFangShi: '',
        ganZaoFangShi: ''
      }
      this.dialogotherConfig = []
    },
    saveOtherConfig() {
      let flag = true
      if (this.printTypeStr === this.$t('yinshua_device')) {
        this.$refs['otherInfoForm'].validate(v => {
          if (!v) {
            flag = false
            return false
          }
        })
      }
      if (flag) {
        this.showOtherConfig = false
        this.otherConfigInfo = this.dialogOtherConfigInfo
        this.info.printConfig = JSON.stringify(this.otherConfigInfo)
        this.otherConfig = this.dialogotherConfig
      }
    },
    // showOtherConfigClick() {
    //   if (this.info.deviceType === '' || this.info.deviceType === undefined) {
    //     this.$message({
    //       message: '请先选择设备类型',
    //       type: 'error'
    //     })
    //   } else {
    //     if (this.printTypeStr === '印刷设备') {
    //       this.isPrintConfig = true
    //     } else {
    //       this.isPrintConfig = false
    //     }
    //     this.dialogOtherConfigInfo = this.otherConfigInfo
    //     this.dialogotherConfig = this.otherConfig
    //     this.showOtherConfig = true
    //   }
    // },
    handleChangeBrand() {
      this.brandbool = false
      var list = this.info.typeIds
      if (list[0] === '630a511d-42a6-4ccc-a495-1afb3debe272') {
        this.infoTime = list[0]
        this.printingEquipment = list[0]
        // this.dailyMaintenance = ''
        // this.weekMaintenance = '' // 周保养
        // this.monthlyMaintenance = '' // 月保养
        // this.quarterlyMaintenance = '' // 季度保养
        // this.halfYearMaintenance = '' // 半年度保养
        // this.yearMaintenance = ''
      }else {
        this.infoTime = ''
        // this.dailyMaintenance = ''
        // this.weekMaintenance = '' // 周保养
        // this.monthlyMaintenance = '' // 月保养
        // this.quarterlyMaintenance = '' // 季度保养
        // this.halfYearMaintenance = '' // 半年度保养
        // this.yearMaintenance = ''
      }
      this.info.brandIds = []
      this.info.brandStr = ''
      this.info.brand = ''
      this.info.series = ''
      this.info.model = ''
      API.getDeviceBrandList({ printTypeId: list[1] })
        .then(response => {
          this.brandList = response.message.data
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          })
        })
    },
    handleChange() {
      const labels = this.$refs['myCascader'].getCheckedNodes()[0].pathLabels
      if (this.$refs['myCascader1'].getCheckedNodes().length > 0) {
        const labels2 = this.$refs['myCascader1'].getCheckedNodes()[0].pathLabels
        var brand = labels2[0]
        this.info.series = this.info.brandIds[1]
        this.info.model = this.info.brandIds[2]
        // if (brand !== '其它') {
        //
        //   this.brandbool = false
        //   this.info.brandStr = ''
        // } else {
        //   this.info.infoName = labels.join('/')
        //   this.info.series = ''
        //   this.info.model = ''
        //   this.brandbool = true
        // }
        this.printTypeStr = labels[0]
        if (this.printTypeStr === this.$t('yinqian_device')) {
          this.label = '版材厚度'
        } else {
          this.label = '承印物厚度'
        }
        var list = this.info.typeIds
        this.info.printType = list[0]
        this.info.deviceType = list[1]
        this.info.brand = this.info.brandIds[0]
        // this.info.brand = list[2]
        // this.info.series = list[3]
        // this.info.model = list[4]
        API.getDeviceConfig({ id: this.info.deviceType }).then(response => {
          this.configoptions = response.message.data
        })
      }
    },
    saveDevice() {
      this.$refs['infoForm'].validate(v => {
        if (v) {
          if (valid.isNullOrEmpty(this.info.printType)) {
            return this.$message.error(this.$t('please_select_device_type'))
          }
          // if (this.printTypeStr === '印刷设备') {
          //   if (valid.isNullOrEmpty(this.info.printConfig)) {
          //     return this.$message.error('请配置设备信息')
          //   }
          // }
          this.info.otherConfig = this.otherConfig.join()
          this.saveLoading = true
          var day = ''
          var week = ''
          let halfmonth = ''
          var month = ''
          var quarter = ''
          var halfyaer = ''
          var year = ''
          var dayTemplateId = ''
          var weekTemplateId = ''
          var halfmonthTemplateId = ''
          var monthTemplateId = ''
          var quarterTemplateId = ''
          var halfyaerTemplateId = ''
          var yearTemplateId = ''
          this.trategyInfo = [].concat(JSON.parse(JSON.stringify(this.multipleSelection)))
          // eslint-disable-next-line no-redeclare
          for (var i = 0; i < this.trategyInfo.length; i++) {
            if (this.trategyInfo[i].type === 1) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('daily_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('daily_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('daily_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              if (this.trategyInfo[i].isGroup=== true) {
                this.trategyInfo[i].isGroup = 1
              } else {
                this.trategyInfo[i].isGroup = 0
              }
              // this.trategyInfo[i].codeList = []
              dayTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              day = JSON.stringify(this.trategyInfo[i])
            } else if (this.trategyInfo[i].type === 2) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('weekly_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('weekly_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('weekly_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              // this.trategyInfo[i].codeList = []
              weekTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              week = JSON.stringify(this.trategyInfo[i])
            } else if (this.trategyInfo[i].type === 30) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('semi_monthly_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('semi_monthly_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('semi_monthly_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              // this.trategyInfo[i].codeList = []
              if (this.trategyInfo[i].day1 > this.trategyInfo[i].day2) {
                this.saveLoading = false
                return this.$message.error(this.$t('Semi_monthly_maintenance_range_selection'))
              }
              halfmonthTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              halfmonth = JSON.stringify(this.trategyInfo[i])
            }else if (this.trategyInfo[i].type === 3) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('monthly_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('monthly_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              // this.trategyInfo[i].codeList = []
              if (this.trategyInfo[i].day1 > this.trategyInfo[i].day2) {
                this.saveLoading = false
                return this.$message.error(this.$t('monthly_maintenance_range_selection'))
              }
              monthTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              month = JSON.stringify(this.trategyInfo[i])
            } else if (this.trategyInfo[i].type === 4) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('quarterly_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('quarterly_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('quarterly_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              // this.trategyInfo[i].codeList = []
              if (this.trategyInfo[i].day1 > this.trategyInfo[i].day2) {
                this.saveLoading = false
                return this.$message.error(this.$t('quarterly_maintenance_first_quarter_time'))
              }
              if (this.trategyInfo[i].day3 > this.trategyInfo[i].day4) {
                this.saveLoading = false
                return this.$message.error(this.$t('quarterly_maintenance_second_quarter_time'))
              }
              if (this.trategyInfo[i].day5 > this.trategyInfo[i].day6) {
                this.saveLoading = false
                return this.$message.error(this.$t('quarterly_maintenance_third_quarter_time'))
              }
              if (this.trategyInfo[i].day7 > this.trategyInfo[i].day8) {
                this.saveLoading = false
                return this.$message.error(this.$t('quarterly_maintenance_fourth_quarter_time'))
              }
              quarterTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              quarter = JSON.stringify(this.trategyInfo[i])
            } else if (this.trategyInfo[i].type === 5) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('semi_annual_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('semi_annual_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('semi_annual_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              // this.trategyInfo[i].codeList = []
              if (this.trategyInfo[i].day1 > this.trategyInfo[i].day2) {
                this.saveLoading = false
                return this.$message.error(this.$t('semi_annual_maintenance_first_half_time'))
              }
              if (this.trategyInfo[i].day3 > this.trategyInfo[i].day4) {
                this.saveLoading = false
                return this.$message.error(this.$t('semi_annual_maintenance_second_half_time'))
              }
              halfyaerTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              halfyaer = JSON.stringify(this.trategyInfo[i])
            } else if (this.trategyInfo[i].type === 6) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('annual_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('annual_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error('请选择年度保养点检人');
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              // this.trategyInfo[i].codeList = []
              if (this.trategyInfo[i].day1 > this.trategyInfo[i].day2) {
                this.saveLoading = false
                return this.$message.error(this.$t('annual_maintenance_time_range'))
              }
              yearTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              year = JSON.stringify(this.trategyInfo[i])
            }
          }
          this.info.strategy = JSON.stringify({
            day: day,
            week: week,
            halfmonth: halfmonth,
            month: month,
            quarter: quarter,
            halfyaer: halfyaer,
            year: year,
            dayTemplateId: dayTemplateId,
            weekTemplateId: weekTemplateId,
            halfmonthTemplateId: halfmonthTemplateId,
            monthTemplateId: monthTemplateId,
            quarterTemplateId: quarterTemplateId,
            halfyaerTemplateId: halfyaerTemplateId,
            yearTemplateId: yearTemplateId
          })
          const labels2 = this.$refs['myCascader1'].getCheckedNodes()[0].pathLabels
          const labels = this.$refs['myCascader'].getCheckedNodes()[0].pathLabels
          var brand = labels2[0]
          if (brand !== this.$t('other')) {
            this.info.infoName = labels.join('/') + '/' + labels2.join('/')
          } else {
            this.info.infoName = labels.join('/') + '/' + this.info.brandStr
            this.brandbool = true
          }
          this.info.groupIds = this.info.groupIds.join(',');
          API.createDevice1(this.info)
            .then(response => {
              this.saveLoading = false
              this.typeList = response.message.data
              this.$message({
                message: response.message.message,
                showClose: true,
                type: response.message.success ? 'success' : 'error',
              })
              this.updateDevice()
              this.$router.go(-1)
            })
            .catch(() => {
              this.saveLoading = false
            })
        } else {
          return false
        }
      })
    },
    updateDevice() {
      this.isUpdate = !this.isUpdate
      this.handleChangeValue()
      this.handleChangeValue2()
      this.handleChangeValueMoth()
      this.handleChangeValueMoth2()
      console.log(this.tableData[2].disabled)
    },
    // 策略变化
    handleChangeButton1(row) {
      row.codeList = []
    },
    handleChangeButton2(row) {
      row.codeList = []
    },
    handleChangeButton3(row) {
      row.codeList = []
    },
    handleChangeButton7(row) {
      row.codeList = []
    },
    handleChangeButton4(row) {
      row.codeList = []
    },
    handleChangeButton5(row) {
      row.codeList = []
    },
    handleChangeButton6(row) {
      row.codeList = []
    }
  }
}
</script>
<style>
.widthTable {
  width: 1550px;
}
</style>
