<template>
  <div>
    <el-menu
      v-if="power === '1'"
      ref="menu"
      :default-active="activeSideMenu"
      :default-openeds="activeSideMenuArr"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      :background-color="sidebarStyle.menuBg"
      :text-color="sidebarStyle.menuText"
      router
      :active-text-color="sidebarStyle.menuActiveText"
      @select="handleSelect"
    >
      <!-- width:22px;height:22px; || this.userInfo.roleId === '0ea0531a-94c9-4b57-90cb-7315b8c2b961'-->
      <el-menu-item :index="$store.getters.userInfo.roleId === '5a729bb3-f6c7-499b-b3c2-0193a99addda' ? '/PrintSteward/firstHome' : $store.getters.userInfo.roleId === '3644fc5d-ef69-4a30-8dad-6407b3120eed' ? '/PrintSteward/firstHome': $store.getters.userInfo.roleId === '0ea0531a-94c9-4b57-90cb-7315b8c2b961' ? '/PrintSteward/firstHome' : '/PrintSteward/PrintHome'">
        <svg-icon icon-class="home" />
        <!-- <img src="~@/assets/img/Slice 16.png" alt="" /> -->
        <span slot="title">{{ $t('主页') }}</span>
      </el-menu-item>
<!--      <el-menu-item index="/PrintSteward/class">-->
<!--        <svg-icon icon-class="home" />-->
<!--        &lt;!&ndash; <img src="~@/assets/img/Slice 16.png" alt="" /> &ndash;&gt;-->
<!--        <span slot="title">班组管理</span>-->
<!--      </el-menu-item>-->
<!--      <el-menu-item index="/PrintSteward/maintenance">-->
<!--        <svg-icon icon-class="home" />-->
<!--        &lt;!&ndash; <img src="~@/assets/img/Slice 16.png" alt="" /> &ndash;&gt;-->
<!--        <span slot="title">外部维修工管理</span>-->
<!--      </el-menu-item>-->
      <template v-if="Menu.length !== 0">
        <el-submenu class="submenu-box" v-for="item in Menu" :key="item.menuId" :index="item.path" v-if="item.meta.teamType ? item.meta.teamType.indexOf(item.meta.teamType1) !== -1 : 3">
          <template slot="title" >
            <!-- <img :src="require(`../assets/img/${item.meta.icon}.png`)" alt="" /> -->
            <svg-icon  :icon-class="item.meta.icon" />
            <span slot="title">{{ item.meta.title }}</span>
          </template>
          <template v-if="item.children.length > 0">
            <div v-for="items in item.children" :key="items.menuId">
              <el-menu-item-group v-if="!items.hidden">
                <el-menu-item :index="items.path">● {{ items.meta.title }}</el-menu-item>
              </el-menu-item-group>
            </div>
          </template>
        </el-submenu>
      </template>

      <!-- </div> -->
      <div class="icon-box">
        <svg-icon v-show="!isCollapse" icon-class="fewer" style="font-size:16px;" @click="showList"/>
        <svg-icon v-show="isCollapse" icon-class="expansion" style="font-size:16px;" @click="showList"/>
        <!-- <img v-show="!isCollapse" src="~@/assets/img/Slice 9.png" alt="" @click="showList" />
        <img v-show="isCollapse" src="~@/assets/img/Slice 15.png" alt="" @click="showList" /> -->
      </div>
    </el-menu>
  </div>
</template>

<script>
import API from '@/api';
import cookies from '@/utils/cookies';
import sidebarStyle from '@/components/sidebarStyle.scss';
import { menuRouter } from '@/router';
// import { store } from '@/store/index.js'
// import router from '@/router/index.js'
export default {
  name: 'sidebar-left',
  data() {
    return {
      isCollapse: false,
      activeRouter: '',
      testArray: [],
      Menu: [],
      teamType: undefined,
      roleType: '',
    };
  },
  computed: {
    role() {
      return this.$store.getters.userInfo.roleType;
    },
    activeSideMenu: {
      set() {
        this.handleSelect();
      },
      get() {
        return this.$store.getters.menuActivety;
      },
    },
    activeSideMenuArr: {
      set() {
        this.handleSelect();
      },
      get() {
        return this.$store.getters.MenuArr;
      },
    },
    power() {
      const a = cookies.get('userInfo');
      return a;
    },
    sidebarStyle() {
      return sidebarStyle;
    },
  },
  created() {
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('key', this.activeSideMenu);
      sessionStorage.setItem('keyPath', this.activeSideMenuArr);
    });
    console.log(this.$store.getters.userInfo.roleId)
    this.getInfo();
    // this.getSelfMenu();
    // console.log(this.$store.getters.userInfo.roleType)
  },
  mounted() {
    this.openMenu();
  },
  methods: {
    getInfo() {
      API.information({ token: this.token })
        .then((res) => {
          // this.roleType = res.message.data.roleType;
          // this.Menu = this.filterAsyncRoutes(menuRouter, [this.roleType.toString()])
          this.Menu = this.filterAsyncRoutes(menuRouter, res.message.data.roleId.split(','))
          if (res.message.data.teamType) {
            const yinYaYunItem = this.Menu.find(item => item.name === "yinYaYun");
            if (yinYaYunItem) {
              yinYaYunItem.children[0].meta.teamType1 = res.message.data.teamType;
              yinYaYunItem.meta.teamType1 = res.message.data.teamType;
            }
            // this.Menu[this.Menu.length - 2].children[0].meta.teamType1 = res.message.data.teamType
            // this.Menu[this.Menu.length - 2].meta.teamType1 = res.message.data.teamType
          }
          if (this.userInfo.userlanguage === 'en_US') {
            this.$i18n.locale = 'en'
            localStorage.setItem('locale', 'en')
          } else {
            this.$i18n.locale = 'zh'
            localStorage.setItem('locale', 'zh')
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
    },
    filterAsyncRoutes(routes, roles) {
      const res = []
      routes.forEach(route => {
        const tmp = { ...route }
        if (this.hasPermission(roles, tmp)) {
          if (tmp.children) {
            tmp.children = this.filterAsyncRoutes(tmp.children, roles)
          }
          res.push(tmp)
        }
      })
      return res
    },
    hasPermission(roles, route) {
      if (route.meta && route.meta.roles) {
        return roles.some(role => route.meta.roles.includes(role))
      } else {
        return true
      }
    },
    openMenu() {
      if (!this.$store.getters.menuActivety) {
        this.$store.dispatch('reSetMenu', sessionStorage.getItem('key'));
        this.$store.dispatch('reSetMenuArr', sessionStorage.getItem('keyPath'));
      }
    },
    handleSelect(key, indexPath) {
      console.log(key,indexPath)
      this.$store.dispatch('reSetMenu', key);
      this.$store.dispatch('reSetMenuArr', indexPath);
    },
    getSelfMenu() {
      // // console.log(this.roleType)
      // // console.log(this.$store.getters.userInfo.roleType)
      // if (this.roleType === 1) {
      //   this.Menu = menuRouter
      // }
      // return new Promise((resolve, reject) => {
      //   // var menus = ''
      //   const router = [];
      //
      //   API.getSelfMenu()
      //     .then((response) => {
      //       // var menus = JSON.stringify(response.message.data)
      //       const list1 = response.message.data.filter((one) => one.parentId === '0');
      //       for (let i = 0; i < list1.length; i += 1) {
      //         const list = response.message.data.filter((one) => one.parentId === list1[i].MenuId);
      //         if (list.length === 0) {
      //           // router.push({
      //           //   path: '/' + list1[i].path,
      //           //   component: 'Layout',
      //           //   menuId: list1[i].MenuId,
      //           //   children: [
      //           //     {
      //           //       path: 'index',
      //           //       name: list1[i].path,
      //           //       component: list1[i].url,
      //           //       meta: { title: list1[i].cName, icon: list1[i].icon }
      //           //     }
      //           //   ]
      //           // })
      //         } else {
      //           const item = {
      //             path: `/${list1[i].path}`,
      //             component: 'Layout',
      //             name: list1[i].path,
      //             menuId: list1[i].MenuId,
      //             meta: { title: list1[i].cName, icon: list1[i].icon },
      //             children: [],
      //           };
      //           for (let x = 0; x < list.length; x += 1) {
      //             item.children.push({
      //               path: list[x].path,
      //               hidden: list[x].visible,
      //               component: list[x].url,
      //               menuId: list[x].MenuId,
      //               name: list[x].path,
      //               meta: { title: list[x].cName, icon: list[x].icon },
      //             });
      //           }
      //
      //           router.push(item);
      //         }
      //       }
      //       this.Menu = router;
      //       console.log(this.Menu)
      //       // console.log(menus)
      //       resolve(response);
      //     })
      //     .catch((error) => {
      //       reject(error);
      //     });
      // });
    },
    showList() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  /*min-height: calc(100vh - 62px);*/
  height: calc(100vh - 62px);
  overflow-y: auto
}
.el-menu--collapse {
  min-height: calc(100vh - 62px);
}
.el-submenu.is-active .el-submenu__title {
  border-left: 4px solid transparent;
  color: #F3A100!important;
  background: #655d5b !important;
  opacity: 1;
}
.el-submenu__title {
  border-left: 4px solid transparent!important;
}
</style>

<style lang="scss" scoped>
.icon-box {
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  left: 23px;
  img {
    width: 20px;
    height: 20px;
  }
}
.el-menu-item {
  border-left: 4px solid transparent;
  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}

.el-menu-item.is-active {
  width: 201px;
  border-left: 4px solid #f7b515;
  // color: #F3A100!important;
  background: rgba(255, 255, 255, 0.12) !important;
}
@media screen and (max-width:800px) {
.el-menu-vertical-demo {
  display: none;
}
}

.submenu-box {
  //height: 100px;
  //overflow-y: auto;
}
</style>
