const en = {
  login_select_avatar: 'Select Avatar',
  login_login: 'Login',
  login_regist: 'Registration',
  login_company_regist: 'Company Regist',
  login_personal_regist: 'Personal Regist',
  login_forget_password: 'Forget Password？',
  login_company_name: 'Company Name',
  login_phone: 'Phone',
  login_username: 'UserName',
  login_nick_name: 'Nick Name',
  login_password: 'Password',
  login_email: 'Email',
  login_verification_code: 'Verification Code',
  login_now: 'Login Now',
  login_password_again: 'Input password again',
  login_submit: 'Submit',
  login_back: 'Back to login',
  login_invalid_phone: 'Please enter a valid phone number',
  login_no_name: 'Please enter name',
  login_invalid_password: 'Please check the password, 6 - 16 digits should be used',
  login_invalid_email: 'Please enter a valid email',
  login_no_verification_code: 'Please enter the verification code',
  login_passwords_differ: 'Passwords Differ',
  login_get_code: 'Send Code',
  login_get_code_later: 's try again',
  user_info_avatar: 'Avatar',
  user_info_edit_avatar: 'Edit Avatar',
  user_info_phone: 'Phone',
  user_info_email: 'Email',
  user_info_nickname: 'Nick Name',
  user_info_gender: 'Gender',
  user_info_secret: 'Secret',
  user_info_man: 'Man',
  user_info_woman: 'Woman',
  user_info_submit: 'Submit',
  user_info_check_phone: 'Verify original phone number',
  user_info_modify_phone: 'Modify Phone',
  user_info_modify_password: 'Modify Password',
  user_info_code: 'Code',
  user_info_confirm: 'Confirm',
  user_info_old_password: 'Old',
  user_info_new_password: 'New',
  user_info_password_again: 'Again',
  team_user_add_user: 'Add team user',
  team_user_manage: 'User Manage',
  team_user_no: 'No.',
  team_user_nickname: 'Nick Name',
  team_user_gender: 'Gender',
  team_user_role: 'Role',
  team_user_phone: 'Phone',
  team_user_email: 'Email',
  team_user_department: 'Department',
  team_user_regist_time: 'Regist Time',
  team_user_operation: 'Operation',
  team_user_edit: 'Edit',
  team_user_delete: 'Delete',
  team_user_invite: 'Invite User',
  team_user_confirm: 'Confirm',
  team_user_invite_prompt: 'Please enter the phone of inviting user',
  team_user_role_select: 'Please select role',
  team_user_delete_user_prompt: 'Delete confirm, continue?',
  team_user_prompt: 'Prompt:',
  team_user_cancel: 'Cancel',
  team_info: 'Team Info',
  role_add: 'Add Role',
  role_no: 'No.',
  role_name: 'Role Name',
  role_manage: 'Role Manage',
  create_time: 'Create Time',
  role_operation: 'Operation',
  role_modify_rolename: 'Modify Name',
  role_view_auth: 'View Auth',
  role_delete_role: 'Delete Role',
  role_enter_rolename: 'Please enter role name',
  confirm: 'Confirm',
  cancel_cancel: 'Cancel',
  role_modify_role: 'Modification Role',
  role_back: 'Back',
  role_menu: 'Menu',
  role_auth: 'Auth',
  role_name_need: 'Role name needed',
  // team_name: 'Name',
  team_address: 'Address',
  team_province: 'Province',
  team_city: 'City',
  team_area: 'Area',
  team_address_detail: 'Detail Address',
  team_email: 'Email',
  team_profile: 'Profile',
  team_submit: 'Submit',
  team_name_invalid: 'Team name invalid',
  menu_user_info: 'User Information',
  ali_pay: 'Alipay',
  pay_success: 'Pay Success',
  device_check_device_name: 'Device Name',
  device_print_type_pre_press: 'Pre-Press',
  device_print_type_print: 'Printing Device',
  device_print_type_post_press: 'Post-Press',
  device_check_recent_task_list: 'Recent Task',
  device_check_overdue_task_list: 'Overdue Task',
  device_operation: 'Operation',
  device_print_add_maintain: 'Add Maintain',
  device_print_view_check_list: 'View Check List',
  device_maintain_template: 'Template',
  device_select: 'Select',
  device_maintain_name: 'Maintain',
  device_maintain_remark: 'remark',
  please_device_maintain_remark: 'Please fill in the Maintenance Remark',
  device_check_remark: 'remark',
  please_device_check_remark: 'Please fill in the Check Remark',
  device_maintainer: 'Maintainer',
  device_select_time: 'Time',
  device_start_time: 'Start',
  device_end_time: 'End',
  device_maintain_add: 'Maintain Add',
  device_save: 'Save',
  device_no: 'No.',
  device_part: 'Part',
  device_element: 'Element',
  device_work: 'Work',
  device_tool: 'Tool',
  device_standard: 'Standard',
  device_edit: 'Edit',
  device_delete: 'Delete',
  device_add: 'Add',
  device_back: 'Back',
  device_remark: 'Remark',
  device_check_finish: 'Finish Check',
  device_status: 'Abnormal?',
  device_finish: 'Finished?',
  device_maintain_score: 'Score',
  device_confirm: 'Confirm',
  device_maintain_release: 'Release',
  device_maintain_abnormal_confirm: 'Abnormal Confirm',
  device_fault_type: 'Fault Type',
  device_repair_desc: 'Repair DESC',
  device_enter: 'Enter...',
  device_repair_pic: 'Picture',
  device_state: 'State',
  device_view: 'View',
  device_maintain_to_maintain: 'To Maintain',
  device_maintain_to_check: 'To Check',
  device_maintain_checked: 'Checked',
  device_add_name: 'Name',
  device_add_enter_name: 'Enter Name...',
  serial_number: 'Code',
  device_add_enter_code: 'Enter Code...',
  device_repair_no: 'Repair No.',
  device_enter_type: 'Select Type',
  device_production_date: 'PD',
  device_select_date: 'Select Date',
  device_config: 'Config',
  device_select_config: 'Select Config',
  device_install_location: 'Location',
  device_enter_location: 'Enter Location...',
  device_enter_remark: 'Enter Remark...',
  device_modify: 'Modify',
  device_print_type: 'Print Type',
  device_brand: 'Brand',
  device_series: 'Series',
  device_model: 'Model',
  device_delete_prompt: 'Deleting Device, Continue?',
  device_prompt: 'Prompt',
  device_cancel: 'Cancel',
  device_commit: 'Commit',
  device_prompt_finish_all_maintain: 'Please complete all maintain before commit!',
  device_halfyear: 'Half Year',
  device_quarter: 'Quarter',
  device_month: 'Month',
  device_week: 'Week',
  device_day: 'Day',
  device_calendar_mode: 'Calendar',
  device_list_mode: 'List',
  device_prompt_delete_maintain: 'Deleting maintain bill, Continue?',
  device_create_bill: 'Create Bill',
  device_maintain_time: 'Repair Time',
  device_maintain_person: 'Maintainer',
  device_repair_person: 'Repairman',
  device_contact: 'Contact',
  device_prompt_select: 'Please select device!',
  device_prompt_repair_type: 'Please enter repair type!',
  device_prompt_repairman: 'Please select repairman!',
  device_prompt_repair_desc: 'Please enter repair desc!',
  device_prompt_confirm_delete: 'Delete?',
  device_prompt_upload_number: '3 pictures limit',
  device_repair_info: 'Repair Info',
  // no: 'Nothing',
  device_repair_record: 'Repair Record',
  device_repair_process: 'Repair Process',
  device_repair_time: 'Maintain Time',
  finish: 'Finish',
  unfinish: 'Unfinish',
  device_prompt_modify_commit: 'Modification will be commited, Continue?',
  device_template_item: 'Template Item',
  device_template_name: 'Template Name',
  not_null: 'can not be null!',
  device_prompt_item_add: 'Please add template!',
  template: 'Template',
  template_type: 'Template Type',
  copy: 'Copy',
  template_system: 'System Template',
  template_personal: 'Personal Template',
  prompt_select_avatar: 'Please Select a avatar!',
  prompt_pic_upload: 'Please select picture!',
  save_success: 'Save Success',
  abnormal: 'Abnormal',
  confirm_abnormal: 'Confirm Abnormal',
  release_abnormal: 'Release Abnormal',
  prompt_one_star: 'Score should be more than one star!',
  prompt_release_abnormal: 'Release Abnormal, Continue?',
  prompt_handel_abnormal: 'Please handel abnormal',
  prompt_repair_type_error: 'Maintenan type no more than 20 words',
  prompt_repair_desc_error: 'Maintenan description no more than 100 words',
  no_more_than_500: 'Not more than 500 words',
  check_team_name: 'Company name invaild, 2 to 50 words allowed',
  check_team_detail_address: 'Address can not more than 50 words',
  check_email: 'Email invaild',
  check_team_profile: 'Profile can not more than 500 words',
  login_passwords_nomodify: 'The original password is the same as the new password, no need to modify',
  prompt_maintain_type_error: 'Maintain type not more than 20 words',
  prompt_select_time: 'Please select time',
  prompt_select_type: 'Please select type',
  check_template_name: 'Template name invaild, 2 to 50 words allowed',
  check_device_part: 'Part value invaild, 2 to 50 words allowed',
  check_device_element: 'Element value invaild, 2 to 50 words allowed',
  check_device_work: 'Work value invaild, 2 to 50 words allowed',
  check_device_sop: 'SOP value invaild, 2 to 200 words allowed',
  check_device_tool: 'Tool can not more than 50 words',
  check_device_standard: 'Standard value invaild, 2 to 200 words allowed',
  prompt_delete_detail: 'Delete detail, continue?',
  check_select_maintainer: 'Please select maintainer',
  check_maintian_name: 'Maintain name value invaild, 2 to 50 words allowed',
  check_select_start_time: 'Please select start time',
  check_select_end_time: 'Please select end time',
  check_start_more_than_end: 'End time should greater than start time',
  check_add_miantian_detail: 'Please add maintain detail',
  device_check: 'Device Check',
  device_list: 'Device List',
  device_repair: 'Device Repair',
  add_device: 'Add Device',
  device_detail: 'Device Detail',
  device_fault_record: 'Fault Record',
  personal_settings: 'Personal settings',
  home: 'Home',
  '主页': 'Home',
  mryinliangfenxi: 'Daily Analysis',
  zongyinzhang: 'Total Sheets',
  timejiadonglv: 'Time Utilization Rate',
  Rate: 'Rate',
  utilization: 'Time Rate',
  utilization_utili: 'Time Utilization Rate',
  xingNengs: 'Performance',
  Performance: 'Performance Rate',
  xingnengjiadonglv: 'Performance Utilization Rate',
  shuimopinghengReport: 'Ink-Water Balance Report',
  cepingdevice: 'Number of Evaluated Devices',
  evaluated: 'Devices',
  runbanjiankong: 'Ink Fountain Solution Monitoring',
  Monitoring: 'Fountain solution Monitoring',
  ysgjshujuReport: 'Data Report',
  yinshua: 'Printing',
  zhiliangReport: 'Quality Report',
  ctp:'CTP',
  zhiban: 'Plate Making',
  weibaopingbi: 'Maintenance Evaluation',
  weixiuReport: 'Repair Report',
  banbengongneng: 'Unique Features of Standard, Professional, and Enhanced Professional Versions',
  shengji: 'Please Upgrade First!',
  queding: 'Confirm',
  zongyinzhangtongji: 'Total Sheet Statistics',
  day7: 'Last 7 Days',
  day15: 'Last 15 Days',
  day30: 'Last 30 Days',
  months2: 'Last 2 Months',
  noData: 'No Data',
  zhang: 'Sheets',
  year: 'Year',
  alldevice: 'All Devices',
  timejiadonglvReport: 'Time Utilization Rate Daily Report',
  pingjuntime: 'Today\'s Average Time Utilization Rate',
  timeguanxi: 'Today\'s Average Time Utilization Rate represents the relationship between the machine\'s working time and the rated operational time since startup',
  timeTongji: 'Time Utilization Rate Statistics',
  kaijiTime: 'Operating Duration',
  min: 'Mins',
  youxiaoTime: 'Effective Time',
  deviceName: 'Device Name',
  Please_fill_in_name: 'Please Fill In Device Name',
  device_Name: 'Device Name',
  yichangreasontongji: 'Abnormal Standby Reason Statistics',
  yinliangTime: 'Trial Printing Volume and Time',
  cishu: 'Times',
  timeHour: 'Duration',
  yinliang: 'Print Volume',
  caozuo: 'Operation',
  checkDetail: 'View Details',
  xuanzeTime: 'Please select year and month',
  allClass: 'All Shift Teams',
  device: 'Equipment',
  orderIndex: 'Order Number',
  edingTime: 'Rated Standby',
  yichangTime: 'Duration',
  yichangReason: 'Reason',
  shiyinzhanbi: 'Test Print Color Calibration Proportion',
  deviceTimeTongji: 'Time Utilization Rate Statistics',
  xingnengjiadonglvReport: 'Performance Utilization Rate Daily Report',
  pingjunjiadonglv: 'Daily Average Performance Utilization Rate',
  timeBizhiguanxi: 'The daily average performance utilization rate represents the ratio of printed quantity and running duration to stable capacity and rated working hours',
  deviceTongji: 'Equipment Statistics',
  classtongji: 'Shift Statistics',
  orderJiadonglv: 'Click on the corresponding print order of the equipment to view the performance utilization rate of that order',
  report: 'Report',
  edingchanneng: 'Rated Capacity',
  shijichanneng: 'Actual Capacity',
  xuanzheDate: 'Please select a date',
  devicexingnengTongji: 'Performance Utilization Rate Statistics',
  index: 'Serial Number',
  no:'No.',
  startTime: 'Start Time',
  endTime: 'End Time',
  zongyinzhangshu: 'Total Prints (Sheets)',
  youxiaoyinzhang: 'Valid Prints (Sheets)',
  wuxiaoyinzhang: 'Invalid Prints (Sheets)',
  guobanyinzhang: 'Over-Version Prints (Sheets)',
  shuimopinghengaStateReport: 'Ink-Water Balance Report',
  cepingdeviceshu: 'Evaluation Equipment',
  you: 'Excellent',
  liang: 'Good',
  zhong: 'Fair',
  cha: 'Poor',
  cepingTime: 'Time',
  mostRecentceping: 'Latest Result',
  shuizhijiance: 'Water Quality Monitoring',
  daybaojing: 'Daily Alarm',
  monthsBaojing: 'Monthly Alarm',
  chaobiaoTime: 'Issue Handling Time Statistics',
  chaobiaoTimetongji: 'Last 7 Times All Equipment Fountain Solution Excess Standard Issue Handling Time Statistics',
  chaobiaoreason: 'Cause Statistics',
  runbanDevice: 'Last 7 Days All Equipment Daily Fountain Solution Excess Standard Causes Statistics',
  PHvalue: 'PH Value',
  Water: 'Temperature',
  diandaolv: 'Conductivity',
  di: 'No.',
  top : 'Top',
  ci: 'Times',
  // '第--次',
  total: 'Total',
  dayReport: 'Day',
  weekReport: 'Week',
  monthsReport: 'Month',
  xuanze: 'Please Select',
  xuanzemonths: 'Select One or More Months',
  dataDay: 'Data Date',
  company: 'Ying Gong She (Qingdao) Digital Technology Co., Ltd.',
  yinshuanumber: 'Printing Quantity',
  unitzhang: 'Unit: Sheets',
  Capacity: 'Capacity',
  Utilization_Rate: 'Utilization Rate',
  Capacity_Utilization_Rate: 'Capacity Utilization Rate',
  numberliyonglv: 'Actual Printing Quantity / Capacity Utilization Rate',
  xiaolvguanli: 'Efficiency Management',
  Standard_Print_Volume: 'Standard Print Volume',
  sheets: '(sheets)',
  biaozhunyinliang: 'Standard Print Volume (sheets)',
  Actual_Print_Volume: 'Actual Print Volume',
  shijiyinliang: 'Actual Print Volume (sheets)',
  shujumiaoshu: 'Data Description',
  unitTime: 'Unit: Prints/Hour',
  Actual_Operating_Speed: 'Actual Operating Speed',
  sudujiadonglv: 'Actual Operating Speed / Performance Efficiency',
  edingyunxingsudu: 'Rated Operating Speed',
  unitmin: 'Unit: Minutes/Day',
  Unit_Hours: 'Unit: Hours',
  unithour: 'Unit: Hours/Day',
  Effective_Printing: 'Effective Printing',
  Duration: 'Duration',
  hourjiadonglv: 'Effective Printing Duration / Time Efficiency',
  Effective_Printing_Duration: 'Effective Printing Duration',
  minutes_hour: 'minutes/hour',
  youxiaohour: 'Effective Printing Duration (minutes/hour)',
  yichangdaiji: 'Abnormal Standby',
  unitminday: 'Unit: Minutes/Day',
  Unit_Minutes: 'Unit: Minutes',
  Unit_Times: 'Unit: Times',
  unitci: 'Unit: Times/Day',
  daijihour_daijici: 'Standby Duration / Standby Frequency',
  daijihour: 'Standby Duration',
  daijici: 'Standby Frequency',
  Submitted_Standby_Reason: 'Submitted Standby Reason',
  Unsubmitted_Standby_Reason: 'Unsubmitted Standby Reason',
  daijiReasonTijiao: 'Submitted Standby Reason / Unsubmitted Standby Reason',
  daijiReasonShenhe: 'Reviewed Standby Reason / Unreviewed Standby Reason',
  reasonTijiao: 'Reason Submitted',
  reasonUnTijiao: 'Reason Unsubmitted',
  reasonShenhe: 'Reason Reviewed',
  reasonUnShenhe: 'Reason Unreviewed',
  daijizhuangkuang: 'Standby Status',
  daijiReasonzhuangkuang: 'Standby Reason Submission Status',
  daijiReasonshenhe: 'Standby Reason Review Status',
  yichangReasintongji: 'Main Reasons for Abnormal Standby',
  unci: 'Not Filled ',
  hour: 'and Duration',
  cihour: 'Filled - Times/Duration',
  ciliao: 'Materials Include',
  shujumiaoshutext: 'Printing Plates, Paper, Ink, Rubber Rollers, Blanket, Fountain Solution, Powder, Varnish',
  yinshuaDevice: 'Printing Equipment Maintenance',
  daybaoyang: 'Daily Maintenance',
  yinshuajiState: 'Printer Status',
  dianjianday: 'Daily Inspection',
  baoyangday: 'Daily Maintenance',
  daybaoyangRate: 'Completion Rate',
  deviceweibao: 'Pre-press Equipment Maintenance',
  pqesReport: 'PQES Report Upload',
  secai: 'Color Management',
  class: 'Work Team',
  uploadci: 'Expected Upload Times',
  uploadcipoint: 'Actual Upload Times / Daily Average Score',
  shijiuploadci: 'Actual Upload Times',
  averageScore: 'Average Score',
  weeks4: '4 Weeks',
  weeks4s: '4 Weeks',
  average: 'Average',
  zongyinshuanumber: 'Total Printing Quantity of the Factory',
  unitweeks: 'Unit: Sheets/Week',
  time: 'Time',
  heji: 'Total',
  weeksshijiyinliang: 'Weekly Actual Output',
  weekSaverageliyonglv: 'Weekly Average Capacity Utilization Rate',
  jitainumber: 'Single Machine (4 Weeks) Printing Quantity',
  xuanzeDevice: 'Please Select Equipment',
  weeksyinliang: 'Weekly Printing Quantity',
  zongxingnengjiadonglv: 'Total Performance Utilization Rate of the Factory',
  Weekly_Average: 'Weekly Average',
  weeksAveragesudu: 'Weekly Average Operating Speed',
  weeksAveragejiadonglv: 'Weekly Average Performance Utilization Rate',
  jidaiweeks4jiadonglv: 'Single Machine (4 Weeks Summary) Average Performance Utilization Rate',
  edingsudu: 'Rated Speed',
  weeks4sudu: '4 Weeks Average Operating Speed',
  Weeks_Average: '4 Weeks Average',
  weeksxingnengjiadonglv: '4 Weeks Average Performance Utilization Rate',
  jitaiweeksjiadonglv: 'Single Machine (4 Weeks) Average Performance Utilization Rate',
  Operating: 'Operating',
  Speed: 'Speed',
  yunxingsudu: 'Operating Speed',
  yinchangjiadonglv: 'Total Time Utilization Rate of the Factory',
  unithourweeks: 'Unit: Hours/Week',
  Weekly_Effective_Printing_Duration: 'Weekly Effective Printing Duration',
  weeksyouxiaoyinshua: 'Weekly Effective Printing Duration (Hours)',
  weeksAverageTime: 'Weekly Average Time Utilization Rate',
  jitaiAverageTimes: 'Single Machine (4-week Summary) Average Time Utilization Rate',
  Effective_Printing_Duration_week: 'Effective Printing Duration',
  week_Effective_Printing: '4-week Effective Printing',
  weeks4yinshua: '4-week Effective Printing Duration (Hours)',
  week_4:'4-week',
  weeksshijianjiadonglv: '4-week Time Utilization Rate',
  week_Time_Utilization_Rate: 'Week Rate',
  jitaiAverageTime: 'Single Machine (4-week) Average Time Utilization Rate',
  yichangdaijidata: 'Abnormal Standby (Data)',
  daijihuizong: 'Print Shop Standby Situation Summary',
  daijici_Submitted_Unsubmitted: 'Standby Frequency / Submitted Standby Reasons / Unsubmitted Standby Reasons',
  daijihourReason: 'Standby Duration / Submitted Standby Reasons / Unsubmitted Standby Reasons',
  yichangdaijizhuangkuang: 'Abnormal Standby Situation',
  jitaidaijidata: 'Single Machine (4-week Summary) Abnormal Standby Data',
  jitaiweeksdata: 'Single Machine (4-week) Weekly Standby Data',
  reason: 'Reason',
  daijiReason: 'Abnormal Standby (Reasons)',
  daijiReasonhuizong: 'Standby Reasons Summary',
  daijicishuReason: 'Main Reasons for Standby Occurrences in 4 Weeks',
  zongcishu: 'Total Occurrences',
  weitianxie: 'Unfilled',
  duo: '',
  // '第--多',
  Proportion1: 'Proportion of 1-3 Occurrences',
  Proportion2: 'Proportion of 4-6 Occurrences',
  percentage: 'Top 6 Percentage',
  weekshourReason: 'Main Reasons for Standby Duration in 4 Weeks',
  zonghour: 'Total Duration',
  yinshuajidaijiReason: 'Single Printing Machine Standby Reasons',
  daijiTimecishu: 'Occurrences',
  reasoncishu: 'These 6 Reasons Account for --% of Total Occurrences',
  daijiTimeReason: 'Duration',
  reasonTime: 'These 6 Reasons Account for --% of Total Time',
  //6 reasons account for 1% of the total
  baoyangweeks: 'Maintenance Weekly Maintenance',
  dianjianweeks: 'Inspection Weekly Maintenance',
  zhoubaowanchenglv: 'Maintenance Completion Rate',
  yinggaiupload: 'Expected Upload Times',
  shijishangchuan: 'Actual Upload Times',
  months4: '4 Months',
  Minutes: 'Minutes',
  hour_h: 'Hour',
  day_day: 'Day',
  week_one: 'Week',
  Month: 'Month',
  ribao_day: 'Day',
  zhoubao_week: 'week',
  Printing_Plates: 'Printing Plates',
  Paper: 'Paper',
  Ink: 'Ink',
  Rubber_Rollers: 'Rubber Rollers',
  Blanket: 'Blanket',
  Fountain_Solution: 'Fountain Solution',
  Powder: 'Powder',
  Varnish: 'Varnish',
  unitMonths: 'Unit: Sheets/Month',
  monthsshijiyinliang: 'Monthly Actual Print Volume',
  monthsAverageliyonglv: 'Monthly Average Capacity Utilization Rate',
  jitaiyinshunumber: 'Single Machine (4 Months) Print Volume',
  monthsyinliang: 'Monthly Print Volume',
  monthsAveragesudu: 'Monthly Average Running Speed',
  monthsAveragejiadonglv: 'Monthly Average Performance Utilization Rate',
  months4Averagejiadonglv: 'Single Machine (4-Month Summary) Average Performance Utilization Rate',
  Month_Average: '4-Month Average',
  Running_Speed: 'Running Speed',
  months4Averagesudu: '4-Month Average Running Speed',
  months4Averagexnjdl: '4-Month Average Performance Utilization Rate',
  jtmonths4pjxnjdl: 'Single Machine (4 Months) Average Performance Utilization Rate',
  Monthly_Effective_Printing: 'Monthly Effective Printing',
  monthsyouxiaohour: 'Monthly Effective Printing Duration (Hours)',
  Monthly_Effective_Printing_Duration: 'Monthly Effective Printing Duration',
  Monthly_Average: 'Monthly Average',
  monthsAveragetime: 'Monthly Average Time Utilization Rate',
  jitaimonths4Averagetime: 'Single Machine (4-Month Summary) Average Time Utilization Rate',
  unitmonths: 'Unit: Hours/Month',
  Month_Effective_Printing: '4-Month Effective Printing',
  months4yxysHour: '4-Month Effective Printing Duration (Hours)',
  Month_Effective_Printing_Duration: '4-Month Effective Printing Duration',
  months4AverageTime: '4-Month Average Time Utilization Rate',
  jitaimonths4AverageTime: 'Single Machine (4 Months) Average Time Utilization Rate',
  jitaimonths4daijidata: 'Single Machine (4-Month Summary) Abnormal Standby Data',
  cishuci: 'Number of Occurrences (Times)',
  hours: 'Hours',
  hourTime: 'Duration (Hours)',
  Week_quipment_startup_time: 'Week Equipment Startup Time (Hours)',
  Month_equipment_startup_time:'Month Equipment Startup Time (Hours)',
  jitaimonths4data: 'Single Machine (4 Months) Monthly Standby Data',
  months4cishuReason: 'Main Reasons for Standby Occurrences in 4 Months',
  months4timeReason: 'Main Reasons for Standby Duration in 4 Months',
  banyuebao: 'Semi-monthly Maintenance',
  baoyangbanyuebao: 'Semi-monthly Maintenance',
  dianjianbanyuebao: 'Semi-monthly Inspection',
  Completion_Rate: 'Completion Rate',
  banyuebaoyangwanchenglv: 'Semi-monthly maintenance Completion Rate',
  months: 'Monthly Maintenance',
  months_nomon: 'Month',
  baoyangmonths: 'Monthly Maintenance',
  dianjianmonths: 'Monthly Inspection',
  monthsbaoyanglv: 'Monthly Maintenance completion rate',
  months4sjyxsd: 'Actual Operating Speed in 4 Months',
  Averagejiadonglv: 'Average Performance Activation Rate',
  djReasonytjsc: 'Idle Reason Submitted Duration',
  djReasonwytjsc: 'Idle Reason Not Submitted Duration',
  ycdjcs: 'Number of Abnormal Idle Instances',
  djReasontjcs: 'Number of Idle Reason Submissions',
  djReasonwtjcs: 'Number of Idle Reason Non-Submissions',
  xuanzefourday: 'Must Select Four Dates',
  submitcishu: 'Submitted Count',
  submitHour: 'Submitted Duration',
  submitted: 'Submitted',
  notSubmit: 'Not Submitted',
  reviewed: 'Reviewed',
  unreviewed: 'Not Reviewed',
  score: 'Score',
  reportDay: 'Data Report (Daily Report)',
  Data_Report: 'Data Report',
  reportWeeks: 'Data Report (Weekly Report)',
  reportmonths: 'Data Report (Monthly Report)',
  yszljc: 'Print Quality Monitoring',
  yszlreport: 'Print Quality Report',
  zhiliangshuju: 'Quality Data',
  zongtizhiliangqushi: 'Overall Quality Trend',
  months_total: 'Trend Statistics of Print Quality Data Uploaded by Equipment ',
  weeks_total: 'Trend Statistics of Print Quality Data Uploaded by Equipment ',
  score_contrast: 'Evaluation Score Comparison',
  months_overview: 'Comparative Analysis of Print Quality Overview This Month vs. Last Month',
  weeks_overview: 'Comparative Analysis of Print Quality Overview This Week vs. Last Week',
  upload_number: 'Number of Uploads',
  upload_number_no: '',
  huanbi: 'MoM',
  score_duibi: 'Score Ratio Comparison',
  months_zhanbi: 'Comparison of Proportions of Various Print Quality Score Segments This Month vs. Last Month',
  weeks_zhanbi: 'Comparison of Proportions of Various Print Quality Score Segments This Week vs. Last Week',
  all_yinshuaji: 'All Printers',
  max_score: 'Highest Score',
  min_score: 'Lowest Score',
  average_score: 'Average Score',
  quality_report: 'Plate Quality Report',
  submit_time: 'Time',
  bancai: 'Material',
  all: 'All',
  ribao: 'Daily',
  zhoubao: '',
  nianbao: 'Annual',
  ranking: 'Ranking',
  Ranked: 'Ranked by ',
  inspection_score: ' Daily Maintenance Inspection Score',
  day_ranking: 'Yesterday\'s',
  weeks_ranking: 'Last Week\'s',
  months_ranking: 'Last Month\'s',
  day_ranking30: '30',
  weeks_ranking4: '4',
  months_ranking6: '6',
  no_ranking_information: 'No Ranking Information Available',
  maintenance_quality_score: 'Maintenance Quality Score Statistics',
  device30_score: 'Daily Maintenance Inspection Score Statistics for Each Device\'s Last Thirty Instances',
  device_weeks_score: 'Weekly Maintenance Inspection Score Statistics for Each Device\'s Last Four Weeks',
  device_months_score: 'Monthly Maintenance Inspection Score Statistics for Each Device\'s Last Six Months',
  dianjian_score_ranking: 'Ranked by Last Semiannual Maintenance Inspection Score',
  half_year_ranking: 'Semiannual Maintenance Ranking',
  year_ranking: 'Annual Maintenance Ranking',
  last_year_ranking: 'Ranked by Last Year\'s Annual Maintenance Inspection Score',
  baoyangren: 'Person',
  point: 'Points',
  maintenance_report: 'Maintenance Report',
  maintenance_total: 'Total Repairs',
  in_maintenance: 'Internal Maintenance',
  out_maintenance: 'External Maintenance',
  maintenance_cost: 'Repair Costs',
  maintenance_problem: 'Repair Issue Summary',
  months_maintenance: 'This Month’s Repair Records',
  maintenance_person: 'Person',
  in: 'Internal',
  out: 'External',
  work_hours: 'Work Hours (Hours)',
  cost: 'Costs',
  maintenance_program: 'Repair Plan',
  finish_time: 'Completion Time',
  course: 'Course',
  my: 'User',
  please_input: 'Please Input',
  charge_person: 'Person',
  serve_normal: 'Normal',
  water_yichang: 'Water Tank Abnormal',
  maintainance: 'Maintenance',
  quality_abnormal: 'Quality Abnormality',
  yinqian_device: 'Pre-Press',
  yinshua_device: 'Printing',
  yinhou_device: 'Post-Press',
  device_info: 'Equipment Information',
  jishenbianhao: 'Serial Number',
  brand_model: 'Brand and Model',
  device_type: 'Device Type',
  Please_select_the_device_type_first: 'Please Select The Device Type First',
  class_info: 'Team Information',
  inspector: 'Inspector',
  color_quality_record: 'Most Recent Color Quality Record',
  fraction: 'Score',
  reporter: 'Submitter',
  maintenance_toDo: 'Maintenance To-Do',
  check_all: 'View All',
  maintainance_start_time: 'tart Time',
  maintainance_end_time: 'End Time',
  day_maintainance: 'Day Maintenance',
  weeks_maintainance: 'Week Maintenance',
  half_months_maintainance: 'Biweek Maintenance',
  months_maintainance: 'Month Maintenance',
  quarter_maintainance: 'Quarter Maintenance',
  half_year_maintainance: 'Semiannual Maintenance',
  year_maintainance: 'Annual Maintenance',
  go_maintenance: 'Go to Maintenance',
  abnormal_daiban: 'Abnormal Idle To-Do',
  go_report: 'Go to Submit',
  water_tank_daiban: 'Water Tank Alert To-Do',
  alarm_type: 'Alert Type',
  alarm_time: 'Alert Time',
  go_inspection: 'Inspection',
  range_value: 'Range Value',
  alarm_value: 'Alert Value',
  qutianbao: 'Go to Fill',
  To_process: 'To Process',
  inspection_daiban: 'Inspection To-Do',
  maintenance_time: 'Time',
  device_data: 'Equipment Data',
  wd: 'Temperature',
  wandibgxishu: 'Stability Coefficient',
  selete_time: 'Select Time',
  last_months: 'Last Month',
  last_week: 'Last Week',
  day: 'Today',
  week: 'This Week',
  month: 'This Month',
  The_week_before_last: 'The Week Before Last',
  Three_weeks_ago: 'Three Weeks Ago',
  month_before_last: 'Month Before Last',
  three_months_ago: 'Three Months Ago',
  technical_support: 'Technical Support',
  my_share: 'My Shares',
  set: 'Settings',
  selete_your_role: 'Please Select Your Role',
  selete_role: 'Please Choose a Role',
  cancel: 'Cancel',
  info_center: 'Message Center',
  unread: 'Unread',
  no_more: 'No More',
  gaojing: 'Alert',
  weixiu: 'Repair',
  Plate_Quality: 'Plate Quality',
  keywords: 'Please Enter Keyword',
  category_name: 'Please Choose a Category Name',
  updated: 'Recently Updated',
  personal_info: 'Personal Information',
  change_password: 'Change Password',
  guanyi_my: 'About Us',
  feedback: 'Feedback',
  deregister_account: 'Close Account',
  log_out: 'Logout',
  account_log_out: 'Account Will Be Permanently Deleted',
  account_log_out_text: 'After confirming your account deletion request, your current account will be logged out. Your account will be deleted after 14 days. If you wish to cancel the deletion during this period, you can log in again to cancel the deletion request.',
  cancel_log_out: 'Cancel Deletion',
  my_company: 'Company',
  Nickname: 'Nickname',
  content: 'Content',
  revise_name:'Modify Nickname',
  enter_name:'Please enter nickname',
  gender:'Gender',
  selete_gender:'Select gender',
  // cell_phone:'手机',
  email:'Email',
  revise_email:'Modify Email',
  enter_email:'Please enter email',
  title:'Title',
  baomi:'Private',
  men:'Male',
  women:'Female',
  revise_success:'Modified successfully',
  revise_error:'Modification failed',
  name_empty:'Name cannot be empty!',
  email_empty:'Email cannot be empty!',
  original_password:'Original password',
  enter_password:'Please enter original password',
  new_password:'New password',
  enter_new_password:'Please enter new password',
  confirm_password:'Confirm password',
  again_enter_password:'Please re-enter password',
  password_yanzheng:'Password should be 6-16 characters, numbers or symbols',
  tianxiewanzheng:'Please fill in completely',
  password_length:'Password length should be 6-16',
  twice_password:'Passwords entered twice do not match!',
  password_error:'Original password is incorrect',
  Please_enter_your_feedback: 'Please enter your feedback',
  feedback_text:'Please enter your feedback (5-500 characters)',
  question_img:'Please provide screenshots or photos of the issue (optional)',
  add_img:'Add photo',
  Add_Video: 'Add Video',
  feedback_hint:'We will address your feedback as soon as possible, but cannot guarantee timely response to every submission. For urgent inquiries, please call customer service directly',
  submit:'Submit',
  most_uploaded_img:'Upload up to 3 photos',
  most_uploaded_img9: 'Upload up to 9 photos',
  uploaded_beyond:'Uploaded image is larger than 20M, please select again',
  submit_success:'Submitted successfully',
  printing_cybertech:'Printing Manager',
  printing_uer_protocol:'Yingongshe User Agreement',
  privacy_policy_statement:'Privacy Policy Statement',
  check_renew:'Check for updates',
  printing_copyright:'Copyright Yingongshe',
  version:'Current version is the latest',
  privacy_policy:'Privacy Policy',
  ToDo_list:'To-Do List',
  inspection:'Inspection',
  regular_replacement:'Regular Replace',
  replacement: 'Replacement',
  assigned:'Pending Assignment',
  planned_date:'Planned',
  completion_date:'Actual',
  maintenance_type:'Maintenance Type',
  fault_issue:'Fault Issue',
  replacement_item:'Item',
  duration:'Duration',
  maintenance_record:'Maintenance Record',
  inspection_record:'Inspection Record',
  replacement_record:'Replacement Record',
  repair_record:'Repair Record',
  alarm_record:'Alarm Record',
  inspection_sheet:'Inspection Sheet',
  sheet_number:'Sheet Number',
  maintenance_details:'Detail',
  site_details:'Site Details',
  lubrication:'Lubrication',
  inspection_cleaning:'Inspection, Cleaning',
  adjustment:'Adjustment',
  switch:'Switch',
  number:'Number',
  completion_status:'Completion Status',
  inspection_success:'Inspection Success',
  inspection_failure:'Inspection Failure',
  switching_success:'Switching Success',
  maintenance_item_detail:'Maintenance Item Detail',
  remark:'Remark',
  photo_upload:'Photo Upload',
  regular_replacement_approval:'Regular Replacement Approval',
  submitter:'Submitter',
  rejected:'Rejected',
  approved:'Approved',
  equipment_repair_assignment:'Equipment Repair Assignment',
  basic_data:'Basic info',
  not_started:'Not Started',
  pending_start:'Pending Start',
  pending_confirmation:'Pending Confirmation',
  completed:'Completed',
  in_progress:'In Progress',
  repair_request_number:'Order Number',
  equipment_model:'Equipment Model',
  repair_request_time:'Request Time',
  repair_request_personnel:'Request Person',
  repair_personnel:'Person',
  detailed_information:'Detail',
  fault_type:'Type',
  fault_description:'Description',
  assign:'Assign',
  reassign:'Reassign',
  not_maintained:'Not Maintained',
  maintenance_completed:'Completed',
  inspection_completed:'Inspection Completed',
  cancelling:'Cancelling',
  cancelled:'Cancelled',
  overtime_cancellation:'Overtime Cancellation',
  inspection_overtime_cancellation:'Overtime',
  incomplete:'Incomplete',
  maintenance_information:'Info',
  maintenance_sheet_number:'Order Number',
  estimated_time:'Estimated Time',
  Submitter_per: 'Submitter',
  Optional: 'Optional',
  // submitter:'Submitter',
  submission_reason:'Reason',
  start:'Start',
  cancel_maintenance: 'Cancel',
  maintenance_items:'Item',
  upload_img:'Up to 9 Images Can Be Uploaded',
  enter_cancel_reason:'Please Enter Cancellation Reason',
  device_maintenance_mismatch:'QR Code Device and Maintenance Device Mismatch!',
  operation_successful:'Operation Successful',
  maintenance_items_submitting:'Please Complete All Maintenance Items Before Submitting',
  position:'Position',
  component:'Component',
  work:'Work',
  maintenance_standard:'Standard',
  inspection_time:'Time',
  all_maintenance:'All Maintenance',
  basic_information:'Basic Info',
  submission_sheet_number:'Order Number',
  approval_information:'Approval Info',
  approval_result:'Result',
  approver:'Approver',
  approval_time:'Time',
  fault_image:'Image',
  no_image:'No Image',
  fault_video:'Video',
  click_play:'Click to Play',
  repair_information:'Repair Info',
  work_report:'End of work, fill out the report',
  confirm_completion:'Confirm completion',
  start_work:'Start work',
  video_available:'No video available',
  acceptance_results:'Acceptance results',
  please_enter_acceptance_results:'Please enter acceptance results',
  Partially_Repaired: 'Partially Repaired',
  Not_Repaired: 'Not Repaired',
  Repaired: 'Repaired',
  Enter_Remarks: 'Please Enter Remarks',
  order_completed:'Order completed',
  personnel_selection:'Person selection',
  contact_information_available:'No contact information available',
  alarm_type2: 'Alert Type',
  alarm_time2: 'Alert Time',
  alarm_details:'Alarm details',
  alarm_information:'Alarm info',
  alarm_reason:'Alarm reason',
  // range_value:'范围值',
  alarm_value2:'Alert Value',
  solution:'Solution',
  maintenance_date:'Date',
  Plate_making_submission:'Plate Submit',
  plate_making_quality_monitoring:'Plate Quality',
  check_cleaning:'Check cleaning',
  add:'Add',
  add_report:'Add report',
  CTP_machine:'CTP machine',
  please_select_CTP_machine:'Please select CTP machine',
  Add_Plate_Material: 'Add Plate Material',
  Modify_Plate_Material: 'Modify Plate Material',
  plate_material:'Please select plate material',
  CTP_machine_name:'CTP machine name',
  plate_material_name:'Name',
  Please_fill_in: 'Please fill in ',
  please_fill_25:'Please fill in 25%/30%',
  enter_plate_material_name:'Please enter the plate material name',
  Add_Successful:'Add Successful',
  confirm_delete_current_plate_material:'Confirm to delete current plate material?',
  delete_plate_material:'Delete plate material',
  maintenance_approval:'Maintenance form approval',
  cancel_maintenance_reason:'Cancel maintenance reason',
  exception_submission:'Exception',
  exception:'Exception',
  ink_precision:'Ink precision',
  ink_status:'Ink status',
  approval_record: 'approval record',
  quality_monitoring:'Quality monitoring',
  exception_submission_approval_record:'Exception submission approval record',
  exception_submission_approval: 'Exception submission approval',
  working_hours:'Working hours',
  approval_opinion:'Opinion',
  enter_approval_submitting:'Please enter approval opinion before submitting',
  latest_score:'Latest score',
  none:'None',
  report_details:'Report details',
  historical_processing_records:'Historical processing records',
  ink_precision_report:'Ink precision report',
  ink_precision_printing_machine_week:'View the CMYK four-color ink precision error trend data for each printing machine in the past week',
  ink_precision_printing_machine_month:'View the CMYK four-color ink precision error trend data for each printing machine in the past month',
  last_month:'Last month',
  last_week2:'Last Week',
  average_deviation_trend:'Average deviation trend',
  Average_deviation:'Average deviation',
  over_standard_ink:'Reason',
  details:'Details',
  over_sstandard_status:'Status',
  over_standard_order:'Order',
  processing_time:'Processing time',
  processing_completed:'Processing completed',
  maintenance_personnel:'Person',
  create_submission:'Submit',
  work_plan:'Work Plan',
  Submission_Record: 'Submission Record',
  equipment_repair:'Equipment Repair',
  repair:'Repairment',
  water_tank_alarm:'Alarm',
  material_reason: 'Material Reason',
  please_select_material_reason:'Please select material reason',
  Please_fill_remarks:'Please fill in the remarks',
  select_start_time:'Please select start time',
  select_end_time:'Please select end time',
  enter_working_hours:'Please enter working hours',
  select_equipment_name:'Please select equipment name',
  enter_rated_standby_time:'Please enter rated standby time',
  enter_abnormal_standby_time:'Please enter abnormal standby time',
  start_time_greater_end_time:'The start time cannot be greater than or equal to the end time',
  please_select_submission_reason:'Please select submission reason',
  submission_successful:'Submission successful',
  fault_repair:'Fault repair',
  repair_type: 'Type',
  Mandatory: 'Mandatory',
  historical_repair_records:'Historical repair records',
  please_enter_fault_issue:'Please enter fault issue (up to 150 characters)',
  uploaded_image_large50M_please_reselect:'Uploaded image larger than 50M, please reselect',
  uploaded_video_large50M_please_reselect:'Uploaded video larger than 50M, please reselect',
  please_select_fault_type:'Please select fault type',
  please_fill_fault_issue:'Please fill in fault issue',
  submit_exception:'Submit exception',
  Optional_tian: 'Optional',
  please_select_replacement_project:'Please select replacement project',
  work_start_time:'Work start time',
  please_select_work_start_time:'Please select work start time',
  please_select_work_plan:'Please select work plan',
  production:'Production',
  shutdown_maintenance:'Shutdown maintenance',
  new_product:'Proofing (new product)',
  return_order:'Proofing (return order)',
  sample_chasing:'Proofing (sample chasing)',
  no_task:'Shutdown (no task)',
  please_complete_information:'Please complete all information',
  update_time:'Update time',
  creator:'Creator',
  length_characters:'Length between 2 to 20 characters',
  Edit_Successful: 'Edit Successful',
  order_number:'Order number',
  precision_erro:'Precision error',
  total_score:'Total score',
  paper:'Paper',
  device_warning_submission:'Device warning submission',
  submit_report:'Submit report',
  please_select_alarm_reason:'Please select alarm reason',
  please_select_solution:'Please select solution',
  my_courses:'My courses',
  featured_courses:'Featured courses',
  free:'Free',
  service_agreement_privacy_policy:'Service agreement and privacy policy',
  service_agreement_privacy_policy_text:'Please carefully read and fully understand each clause of the "Service Agreement" and "Privacy Policy", including but not limited to: To provide you with better service, we need to collect your operation logs and other information for analysis and application performance optimization',
  You_can_read: 'You can read',
  and: 'and',
  greement:' for more details. If you agree, please click the button below to start our service',
  service_greement:'You can read the "Service Agreement" and "Privacy Policy" for more details. If you agree, please click the button below to start our service',
  disagree:'Disagree',
  agree:'Agree',
  introduction:'Introduction',
  contents:'Content',
  course_introduction:'Course introduction',
  enroll_now:'Enroll now',
  purchase_now:'Purchase',
  start_learning:'Start learning',
  confirm_enrollment_now:'Confirm enrollment now?',
  enrollment_successful:'Enrollment successful',
  course_price:'Course price',
  purchase_course:'Purchase Course',
  money: '￥',
  actual_payment_amount:'Actual payment amount',
  proceed_payment:'Pay',
  alipay:'Alipay',
  weChat:'WeChat',
  weChat_pay:'WeChat Pay',
  course_payment:'Course payment',
  assignments:'Assignment',
  reviewedwork:'Reviewed',
  // reviewed:'已点评',
  pending_completion:'Pending completion',
  assignment_submission:'Assignment submission',
  file_upload:'File upload',
  login:'Login',
  register:'Register',
  phone:'Phone',
  password:'Password',
  please_enter_verification_code:'Please enter verification code',
  read_agree_registration_agreement_privacy_policy:'Read and agree to the "Registration Agreement" and "Privacy Policy"',
  forgot_password:'Forgot password',
  welcome_printer_cybertech:'Welcome to Printer Cybertech',
  please_enter_phone_number:'Please enter phone number',
  please_enter_valid_phone_number:'Please enter valid phone number',
  please_enter_password:'Please enter password',
  please_enter_account_nickname:'Please enter account nickname',
  get_verification_code:'Get verification code',
  please_enter_nickname:'Please enter nickname',
  please_enter_confirmation_password:'Please enter confirmation password',
  read_agree_registration_agreement:'Read and agree to the "Registration Agreement"',
  please_check_registration_agreement:'Please check the registration agreement',
  password_confirmation_password_match:'Password and confirmation password do not match',
  retrieve_again:'Retrieve again',

  verification_code_login:'Verification Code Login',
  phone_number:'Phone Number',
  account_password_login:'Account and Password Login',
  account_registration:'Account Registration',
  Logging_indicates: 'Logging in indicates you agree to our platform\'s',
  Service_Agreement: 'Service Agreement',
  Privacy_Policy: 'Privacy Policy',
  // and: 'and',
  login_test:'Logging in indicates you agree to our platform\'s Service Agreement and Privacy Policy',
  correct_verification_code_format:'Please enter the correct verification code format',
  verification_code:'Please enter the verification code',
  mobile_number_cannot_empty:'Mobile number cannot be empty',
  already_have_account:'Already have an account',
  Login:'Login',
  repeat_password:'Repeat Password',
  numbers_letters_symbols:'6-16 characters, including numbers, letters, and symbols',
  agree_service_agreement_privacy_policy:'Registration indicates you agree to our platform\'s Service Agreement and Privacy Policy',
  please_check_agree_agreement:'Please check and agree to the agreement',
  retrieve_password:'Retrieve Password',
  return_login:'Return to Login',
  password_reset_successful:'Password Reset Successful',
  big_screen_display: 'Big Screen Display',
  big_screen_display_boss:'Big Screen Display Boss',
  big_screen_display_workshop:'Big Screen Display Workshop',
  total_employees:'Employees',
  total_number_data_acquisition_devices:'Data Collection Devices',
  ming:'',
  tower:'',
  total_number_devices:'Devices',
  yesterday_quantity:'Yesterday Print Quantity',
  yesterday_time_utilization_Rate:'Yesterday Time Utilization Rate',
  yesterday_performance_utilization_rate:'Yesterday Performance Utilization Rate',
  big_screen_display_machine:'Big Screen Display Machine',
    printing_manager_data_operation_big_screen:'Printing Manager Data Analyse',
  printing_machine_time_utilization_rate:'Printing Machine Time Utilization Rate',
  trend_chart:'7-Day Trend Chart',
  compared_last_week:'WoW',
  monthly_time_utilization_rate:'Month Rate',
  compared_last_month:'MoM',
  printing_machine_performance_utilization_rate:'Printing Machine Performance Utilization Rate',
  weekly_performance_utilization_rate:'Week Rate',
  monthly_performance_utilization_rate:'Month Rate',
  color_quality:'Color Quality',
  score_month:'Score This Month',
  increase_from:'Increase from',
  increase_of: 'an increase of',
  equals_printing:'equals printin',
  more_sheets_per_day:' more sheets per day',
  decrease_from:'Decrease from',
  decrease_of:'a decrease of',
  fewer_sheets_per_day:'fewer sheets per day',
  printing_sheets_increase:'Increase from 0% to 2.45%, an increase of 2.45% equals printing 12,250 more sheets per day',
  printing_sheets_decrease:'Decrease from 0% to 2.45%, a decrease of 2.45% equals printing 12,250 fewer sheets per day',
  last_month_score:'Last Month\'s Score',
  this_week_score:'This Week\'s Score',
  last_week_score:'Last Week\'s Score',
  project_entry:'Project Entry',
  color_quality_trend:'Color Quality Trend',
  Equipment_Status: 'Equipment Status',
Ink_Water_Balance: 'Ink-Water Balance',
  equipment_status:'Equipment Status (Ink-Water Balance)',
  system_messages:'System Messages',
  view_user_profile:'View User Profile',
  edit_user_profile:'Edit User Profile',
  please_enter_old_password:'Please enter the old password',
  characters:'Please enter 6-16 non-space characters',
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  weeks:'Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday',
  log_out_user:'Are you sure you want to log out of this user?',
  view_profile:'View Profile',
  edit_profile:'Edit Profile',
  edit_mobile_number:'Edit Mobile Number',
  edit_password:'Edit Password',
  space_usage:'Space Usage',
  account_nickname:'Account Nickname',
  contact_phone:'Contact Phone',
  contact_email:'Contact Email',
  company_name:'Company Name',
  upload_avatar:'Upload Avatar',
  enter_account_nickname:'Enter Account Nickname',
  original_phone_number:'Original Phone Number',
  resend_in:'Resend in',
  seconds: 'Seconds',
  resend_seconds:'Resend in 5 Seconds',
  new_phone:'New Phone Number',
  uploaded_image:'Upload Failed! The uploaded image must be in JPG or JPEG format!',
  uploaded_file_size_exceed:'Upload Failed! The uploaded file size exceeds 5MB!',
  verification_code_cannot:'Verification Code Cannot be Empty',
  verification_code_format:'Incorrect Verification Code Format',
  phone_verification_code:'Phone or Verification Code Cannot be Empty',
  incorrect_phone_number_format:'Incorrect Phone Number Format',
  phone_again:'Phone Number has been Changed, Please Log in Again!',
  prompt:'Prompt',
  invalid_email:'Invalid Email',
  password_edited_success:'Password Edited Successfully',
  password_changed_again:'Password has been Changed, Please Log in Again!',
  printing_quality:'Printing Quality',
  balance_report:'Ink-Water Balance Test Report',
  evaluation_time:'Evaluation Time',
  printing_maintenance_completion_status:'Printing Maintenance Completion Status',
  maintenance_completion_rate:'Maintenance',
  inspection_completion_rate:'Inspection',
  weekly_maintenance:'Bi-weekly Maintenance',
  quarterly_maintenance:'Quarterly Maintenance',
  semi_annual:'Semi-annual',
  annual:'Annual',
  abnormal_standby_reporting:'Abnormal Standby Reporting',
  trigger_count:'Trigger',
  report_submission_count:'Submit',
  omission_count:'Omit',
  water_alarm:'Water Tank Alarm',
  water_tank_data:'Water Tank',
  abnormal_trigger:'Trigger',
  report_completion_rate:'Report',
  water_tank_monitoring:'Water Tank Monitoring',
  member:'Member',
  today:'Today',
  maintenance_work_status:'Maintenance Work Status',
  uncompleted_maintenance_orders:'Uncompleted Maintenance Orders',
  ongoing_maintenance_order_status:'Ongoing Maintenance Order Status',
  uncompleted_inspection_points:'Uncompleted Inspection Points',
  inspection_points:'Total Inspection Points',
  uncompleted_maintenance:'Uncompleted Maintenance List',
  latest_evaluation:'Latest Evaluation',
  repair_work_status:'Repair Work Status',
  no_data_unbound_devices:'No Data due to Unbound Devices!',
  damping_solution:'Damping Solution',
  maintenance_inspection:'Maintenance & Inspection',
  printing_quantity_analysis:'Printing Quantity Analysis',
  view_details:'View Details',
  select_start_date:'Select Start Date',
  Please_select_start_date:'Please Select Start Date',
  select_date:'Select Date',
  select_end_date:'Select End Date',
  machine:'Machine',
  shift:'Shift',
  acquisition_device:'Please Select Data Acquisition Device',
  please_select_team:'Please Select Team',
  please_select_device_type:'Please Select Device Type',
  export:'Export',
  query:'Search',
  enterprise_leader:'Enterprise Leader',
  production_manager:'Production Manager',
  equipment_supervisor:'Equipment Supervisor',
  prepress_supervisor:'Prepress Supervisor',
  printing_supervisor:'Printing Supervisor',
  printing_staff:'Printing Staff',
  manager:'HR Manager',
  total_statistics:'Total Statistics',
  view_large_image:'View Large Image',
  rated_changeover_time:'Rated Changeover Time',
  excess_changeover_time:'Excess Changeover Time',
  changeover_exception_cause:'Changeover Exception Cause',
  date:'Date',
  actual_changeover_hours:'Actual Changeover Hours',
  effective_work_duration:'Effective Work Duration',
  please_select_start_end_time:'Please Select Start or End Time!',
  exception_standby_details:'Exception Standby Details',
  return:'Return',
  quality_score:'Quality Score',
  upload_quantity:'Upload Quantity',
  select_companies:'Can Select Up to 5 Companies',
  alert_start_time:'Alert Start Time',
  alert_end_time:'Alert End Time',
  out_range_value:'Out of Range Value',
  handler:'Handler',
  // reason:'Reason',
  damping_solution_details:'Damping Solution Details',
  maintenance_inspection_achievement_rate:'Maintenance & Inspection Achievement Rate',
  score_ranking:'Score Ranking',
  working_hours_min:'Working Hours (Minutes)',
  maintenance_cost_yuan:'Maintenance Cost (￥)',
  trend_plate_quality:'Trend of 50% Plate Quality',
  effective_duration_minutes:'Effective Duration (Minutes)',
  overrun_duration_minutes:'Overrun Duration (Minutes)',
  ineffective_duration_minutes:'Ineffective Duration (Minutes)',
  periodic_replacement:'Periodic Replacement List',
  all_documents:'All Documents',
  all_categories:'All Categories',
  enter_keywords:'Enter Keywords',
  team_management:'Team Management',
  personnel_management:'Personnel Management',
  team_information:'Team Information',
  shift_management:'Shift Management',
  meal_management:'Meal Management',
  external_maintenance:'External Maintenance Worker Management',
  equipment_maintenance:'Equipment Operation & Maintenance',
  submission_record:'Submission Record',
  nickname_phone:'Nickname/Phone Number',
  account_role:'Account Role',
  respective_shift:'Respective Shift',
  search:'Search',
  registration_time:'Registration Time',
  edit_account:'Edit Account',
  day_shift:'Day Shift',
  night_shift:'Night Shift',
  invalid_phone:'Invalid Phone Number',
  confirm_delete_current_account:'Confirm Delete Current Account?',
  delete:'Delete',
  enterprise_address:'Enterprise Address',
  province:'Province',
  city:'City',
  district:'District',
  work_cycle:'Work Cycle',
  yesterday:'Yesterday',
  detailed_address:'Detailed Address',
  enterprise_email:'Enterprise Email',
  enterprise_introduction:'Enterprise Introduction',
  upload_logo:'Upload Logo',
  click_upload:'Drag and drop the Logo here, or ',
  Click_to_Upload: 'Click to Upload',
  allowed_should:'Only jpg/png image files are allowed, and should not exceed 500kb',
  confirm_upload:'Confirm Upload',
  company_name_characters:'Please enter a company name with 2-50 characters',
  detailed_address_characters:'Detailed address should not exceed 50 characters',
  company_introduction_should:'Company introduction should not exceed 500 characters',
  file_name_incorrect:'File name is incorrect',
  file_cannot_exceed:'File cannot exceed 500kb',
  upload_file_original_file:'You can only upload 1 file, please delete the original file and re-upload',
  upload_company:'Please upload the company\'s Logo',
  upload_success:'Upload successful',
  working_time:'Working Time (Hours)',
  Working_Time: 'Working Time',
  working_time_min:'Non-Working Time (Minutes)',
  creation_time:'Creation Time',
  start_time:'Start Time',
  please_enter_working_time:'Please enter working time',
  non_working_time:'Non-working time',
  rest_time:'Rest Time',
  add_team:'Add Team',
  length30_characters:'Length must be between 2 and 30 characters',
  length6_characters: 'Length must be between 6 and 30 characters',
  team_name: 'Team Name',
  enter_team_name:'Please enter team name',
  enter_rest_time:'Please enter rest time',
  positive_integer:'Must be a positive integer',
  delete_current_team:'Are you sure you want to delete the current team?',
  delete_team:'Delete Team',
  edit_team:'Edit Team',
  non_working_working_time:'Non-working time cannot be greater than working time',
  late_shift_meal: 'Meal downtime (late shift meal)',
  lunch: 'Meal time (lunch)',
  dinner: 'Meal time (dinner)',
  Please_enter_the_reason: 'Please Enter The Reason',
  Deleted_successfully:'Delete Success',
  add_increase:'Add',
  length_within_characters:'Length within 20 characters',
  confirm_deletion_device:'Confirm deletion of device, continue?',
  deletion_cancelled:'Deletion cancelled',
  name_phone:'Name/Phone Number',
  account_status:'Account Status',
  adding_time:'Adding Time',
  last_login_time:'Last Login Time',
  please_enter_name:'Please enter a name',
  name:'Name',
  name_name:'Name',
  enter_password_again:'Please enter the password again',
  enable:'Enable',
  disable:'Disable',
  add_repairman:'Add Repairman',
  edit_repairman:'Edit Repairman',
  edit_successful:'Edit Successful',
  add_successful:'Add Successful',
  enable_current_repairman_account:'Are you sure you want to enable the current repairman account?',
  enable_account:'Enable Account',
  disable_current_repairman_account:'Are you sure you want to disable the current repairman account?',
  disable_account:'Disable Account',
  search_reason:'Search Reason',
  reason_optional:'Reason is optional',
  type:'Type',
  platform:'Platform',
  enterprise:'Enterprise',
  is_it_enable:'Enable',
  not_enabled:'Disable',
  length_within12_characters:'Length within 12 characters',
  select_status:'Please select status',
  success_enabled:'Successfully Enabled',
  Deactivate_success: 'Deactivate Success',
  successfully_disabled:'Successfully disabled',
  confirm_deletion_reason:'Confirm deletion reason, continue?',
  // device_list:'设备列表',
  device_details:'Device details',
  device_code:'Device code',
  enter_device_code:'Please enter device code',
  enter_machine_serial_number:'Please enter machine serial number',
  machine_serial_number:'Machine Serial Number',
  select_brand_model:'Please select brand and model',
  maintenance_strategy:'Maintenance strategy',
  semi_annual_maintenance:'Semi-annual maintenance',
  annual_maintenance:'Annual maintenance',
  time_range:'Time range',
  daily:'Day',
  monthly:'Monthly',
  to:'to',
  // '1日到9日',
  first_quarter_february:'First quarter February',
  // '第一季度2月12日到15日',
  second_quarter:'Second quarter',
  third_quarter:'Third quarter',
  fourth_quarter:'Fourth quarter',
  first_half:'First half',
  second_half:'Second half',
  strategy:'Strategy',
  select_maintenance_person:'Please select maintenance person',
  select_inspection_person:'Please select inspection person',
  bi_weekly:'Bi-weekly',
  device_qr_code:'Device QR code',
  download_qr_code:'Download QR code',
  device_configuration:'Device configuration',
  non_data_acquisition_device:'Non-data acquisition device',
  data_acquisition_device:'Data acquisition device',
  device_name_characters:'Device name must be 4-32 characters, one Chinese character equals two characters!',
  enter_device_name:'Please enter device name',
  please_enter_machine_serial_number:'Please enter machine serial number',
  not_exceed_characters:'Serial number should not exceed 20 characters',
  enter_rated_standby_time_min:'Please enter rated standby time (in minutes)',
  enter_brand_model_within_characters:'Please enter brand and model within 20 characters',
  remarks_should_not_exceed_characters:'Remarks should not exceed 100 characters',
  download: 'Download',
  monday:'Monday',
  tuesday:'Tuesday',
  wednesday:'Wednesday',
  thursday:'Thursday',
  friday:'Friday',
  saturday:'Saturday',
  sunday:'Sunday',
  qr_code:'QR code',
  daily_maintenance_strategy_template:'Please select daily maintenance strategy template',
  daily_maintenance_person:'Please select daily maintenance person',
  daily_inspection_person:'Please select daily inspection person',
  printmaking_machine_name:'Printmaking machine name',
  weekly_maintenance_strategy_template:'Please select weekly maintenance strategy template',
  weekly_maintenance_person:'Please select weekly maintenance person',
  weekly_inspection_person:'Please select weekly inspection person',
  semi_monthly_maintenance_strategy_template:'Please select semi-monthly maintenance strategy template',
  semi_monthly_maintenance_person:'Please select semi-monthly maintenance person',
  semi_monthly_inspection_person:'Please select semi-monthly inspection person',
  Semi_monthly_maintenance_range_selection:'Semi-monthly maintenance time range selection error, please confirm',
  maintenance_strategy_template:'Please select monthly maintenance strategy template',
  monthly_maintenance_person:'Please select monthly maintenance person',
  monthly_inspection_person:'Please select monthly inspection person',
  monthly_maintenance_range_selection:'Monthly maintenance time range selection error, please confirm',
  quarterly_maintenance_strategy_template:'Please select quarterly maintenance strategy template',
  quarterly_maintenance_person:'Please select quarterly maintenance person',
  quarterly_inspection_person:'Please select quarterly inspection person',
  quarterly_maintenance_first_quarter_time:'Quarterly maintenance first quarter time range selection error, please confirm',
  quarterly_maintenance_second_quarter_time:'Quarterly maintenance second quarter time range selection error, please confirm',
  quarterly_maintenance_third_quarter_time:'Quarterly maintenance third quarter time range selection error, please confirm',
  quarterly_maintenance_fourth_quarter_time:'Quarterly maintenance fourth quarter time range selection error, please confirm',
  semi_annual_maintenance_strategy_template:'Please select semi-annual maintenance strategy template',
  semi_annual_maintenance_person:'Please select semi-annual maintenance person',
  semi_annual_inspection_person:'Please select semi-annual inspection person',
  semi_annual_maintenance_first_half_time:'Semi-annual maintenance first half time range selection error, please confirm',
  semi_annual_maintenance_second_half_time:'Semi-annual maintenance second half time range selection error, please confirm',
  annual_maintenance_strategy_template:'Please select annual maintenance strategy template',
  annual_maintenance_person:'Please select annual maintenance person',
  annual_inspection_person:'Please select annual inspection person',
  annual_maintenance_time_range:'Annual maintenance time range selection error, please confirm',
  Electrical: 'Electrical',
  Mechanical: 'Mechanical',
  Debugging: 'Debugging',
  other:'Other',
  Week_Average_Time: '4-Week Average Time Utilization Rate',
  Week_Actual_Running_Speed: '4-Week Actual Running Speed',
  // template:'模板',
  template_list:'Template List',
  // template_type:'模板类型',
  printing_type:'Printing type',
  personal_template:'Personal template',
  Revise: 'Revise',
  // copy:'复制',
  select_file_upload:'Please select a file to upload',
  download_import_template:'Download import template',
  import: 'Import Excel',
  system_template:'System template',
  enter_template_name_characters:'Please enter a template name of 1-30 characters',
  enter_template_name:'Please enter template name',
  confirm_deletion:'Confirm deletion?',
  prepress_automation:'Prepress automation',
  prepress_project:'project',
  enter_project_search:'Please enter project name',
  project_information:'Project information',
  delete_project:'Delete project',
  original_file:'Original file',
  enter_file_name_search:'Please enter file name',
  view_original:'View original',
  download_original:'Download original',
  delete_original:'Delete original',
  process_file:'Process file',
  view_file:'View file',
  download_file:'Download file',
  delete_file:'Delete file',
  selected_file:'Selected file',
  file_processing:'File processing',
  history:'History',
  original_file2:'Original file',
  preview:'Preview',
  generate_file:'Generate file',
  parameter:'Param',
  layout_parameter:'Layout parameter',
  modify_parameter:'Modify parameter',
  create_project:'Create project',
  enter_project_name:'Please enter project name',
  project_name:'Project name',
  project_type:'Project type',
  select_project_type:'Please select project type',
  project_client:'Project client',
  enter_project_client_name:'Please enter project client name',
  Modification_time: 'Modification Time',
  // project_information:'项目信息',
  project_number:'Project number',
  tag:'Tag',
  card_box:'Card box',
  soft_pack:'Soft pack',
  enter_select:'Please enter or select',
  created_success:'Project created successfully',
  modified_succes:'Project modified successfully',
  project_deletion:'Confirm project deletion, continue?',
  upload_failedpdf_cf2:'Upload failed! Upload file can only be in pdf and cf2 formats!',
  Upload_failed_file_size_exceeds:'Upload failed! Upload file size exceeds 1GB!',
  file_name_cannot_exceed_characters:'File name too long, cannot exceed 50 characters',
  select_project_first_before_uploading_file:'Please select project first before uploading file',
  original_deletion:'Confirm original deletion, continue?',
  feature_available:'This feature is not available yet',
  please_select:'Please select',
  files: 'File',
// '请选择2个文件',
  imposition:'Imposition',
  wait: 'Wait',
  success: 'Success',
  fail: 'Fail',
  select_one_pdf_file_one_cf2:'Please select one pdf file and one cf2 file',
  only_pdf_files_selected:'Only pdf files can be selected',
  please_select_file:'Please select file',
  check_file_selected:'Check if file is selected',
  view_parameter:'View parameter',
  printer_cybertech:'Printer Cybertech',
  Next_step: 'Next Step',
  Maintenance_Summary: 'Summary',
  Acceptance_Info: 'Acceptance Info',
  Acceptance_Person: 'Acceptance Person',
  Enter_Acceptance_Result: 'Please Enter Acceptance Result',
  Acceptance_Remarks: 'Acceptance Remarks',
  Acceptance_Date: 'Acceptance Date',
  Select_Acceptance_Result: 'Please Select Acceptance Result',
  Select_Maintenance_Person: 'Please Select Maintenance Person',
  pre_press:'Pre-Press ',
  printing: 'printing ',
  Post_Press: 'Post-Press ',
  week_Print_Volume: 'Taking two machines as an example,The weekly average capacity utilization rate  is calculated as (the weekly average capacity utilization rate of Machine A + the weekly average capacity utilization rate of Machine B) / 2',
  week_Rated_Speed: 'Taking two machines as an example, the weekly average capacity utilization rate is calculated as (the weekly average capacity utilization rate of Machine A + the weekly average capacity utilization rate of Machine B) / 2',
  week_device_time: 'Taking two machines as an example, the weekly average effective time utilization rate is calculated as (the weekly average effective time utilization rate of Machine A + the weekly average effective time utilization rate of Machine B) / 2',
  Monthly_Print_Volume: 'Taking two machines as an example,The Monthly average capacity utilization rate  is calculated as (the Monthly average capacity utilization rate of Machine A + the Monthly average capacity utilization rate of Machine B) / 2',
  Monthly_Rated_Speed: 'Taking two machines as an example, the Monthly average capacity utilization rate is calculated as (the Monthly average capacity utilization rate of Machine A + the Monthly average capacity utilization rate of Machine B) / 2',
  Monthly_device_time: 'Taking two machines as an example, the Monthly average effective time utilization rate is calculated as (the Monthly average effective time utilization rate of Machine A + the Monthly average effective time utilization rate of Machine B) / 2',
  Remove_single_standby: 'Remove The No-single Standby',
  Please_enter_the_duration: 'Please Enter The Duration',
  reason1: 'The reporting reason duration cannot be longer or shorter than the abnormal standby duration.',
  reason2:'The sum of working time and abnormal standby time cannot be greater than the difference between the start time and the end time.',
  reason3:'The reporting reason duration cannot be empty or equal to 0',
  reason4:'The standby time without an order exceeds the total time of the day. Please fill in the standby time without an order again.',
  reason5:'The reporting reason duration cannot be longer or shorter than the abnormal standby duration.',
  reason6: 'Except for the no-order standby, the reporting reason duration cannot be empty or equal to 0',

  fill_device_name: 'Please fill in the device name',

  select_maintenance_type:'Please select the maintenance type',

  Maintenance_template:'Maintenance template',

  Template_Information: 'Template Information',

  enter_part_content:'Please enter the part content',

  content_characters:'Please enter the content of the part with 2-50 characters',

  component_content:'Please enter component content',

  component_content_words:'Please enter the component content of 2-50 words',

  job_content:'Please enter the job content',

  words_work_content:'Please enter 2-50 words of work content',

  SOP_content:'Please enter the SOP content',

  content_of_words:'Please enter the SOP content of 2-200 words',

  maintenance_standard_content:'Please enter the maintenance standard content',

  standard_content_words:'Please enter the maintenance standard content of 2-200 words',
  Please_add_more_details: 'Please Add More Details',
  template_details_continue:'The template details are about to be deleted. Do you want to continue?',
  Confirm_Close: 'Confirm Close?',
  Maintenance_order_details:'Maintenance order details',

  fill_submitter:'Please fill in the submitter',

  completion_time:'Please enter the completion time',

  enter_submitter:'Please enter the submitter',

  reason_submission:'Please fill in the reason for submission',
  Photo_upload:'Photo upload',
  change_details:'Regularly change details',
  Repair_order_details:'Repair order details',
  Not_fixed:'Not fixed',
  Add_regular_replacement:'Add regular replacement',
  exception_reporting:'Add exception reporting',
  date_and_time: 'Select date and time',
  fault_report:'Add fault report',
  correct_working_hours:'Please enter the correct working hours',
  Cloud_disk_space_used: 'Cloud Disk Space Ts Used',
  common: 'Common',
  used:'Used',
};

export default en;
