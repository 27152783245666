<template>
  <!--  日清周清：日活报表-->
<div>
  <div class="section">
    <condition :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :roleType="true" :conditionFrom="conditionFrom" @search="search"></condition>
  </div>
  <div>
    <BarChart
      style="padding: 0 15px"
      v-if="chartData.source.length > 0"
      :id="'chartRole'"
      :data="chartData"
      :title="'日活趋势'"
      :legend-show="false"
    />
    <div v-else style="text-align: center">{{ $t('noData') }}</div>
<!--  :bar-color="barColor"  -->
  </div>
</div>
</template>

<script>
import BarChart from '@/components/ChartsTime/BarChart'; // 柱状图组件
import condition from './condition'
import API from '@/api';
export default {
name: "DailyLiveReport.vue",
  props: {
    enterpriseList: {
      type: Array,
      default: () => []
    },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  components: { BarChart, condition },
  data() {
    return {
      historyQuery:{},
      filter: {
        startTime: undefined,
        endTime: undefined,
        orderId: '',
        teamId: ''
      },
      pickerOptions: {
        disabledDate: time => {
          if (this.filter.endTime) {
            return (
              time.getTime() > new Date(this.filter.endTime).getTime() || time.getTime() < new Date(this.filter.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.filter.startTime) {
            return (
              time.getTime() < new Date(this.filter.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.filter.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      chartData: {
        // 数据集
        source: [],
        // 系列
        series: [{
          type: 'line',
          barWidth: 30,
        }],
      },
      // 柱形颜色
      // barColor: [
      //   // eslint-disable-next-line new-cap,no-new-require
      //   new require('echarts').graphic.LinearGradient(0, 0, 0, 1, [
      //     { offset: 0, color: '#FA460D' },
      //     { offset: 1, color: '#F7B515' },
      //   ]),
      // ],
    }
  },
  mounted() {

  },
  created() {
  },
  methods: {
    startTimeChange(val){
      if(this.filter.endTime && new Date(val).getTime() > new Date(this.filter.endTime).getTime()){
        this.filter.startTime = null
      }else{
        this.filter.startTime = val
      }
    },
    endTimeChange(val){
      if(this.filter.startTime && new Date(val).getTime() < new Date(this.filter.startTime).getTime()){
        this.filter.endTime = null
      }else{
        this.filter.endTime = val
      }
    },
    getChart() {
      API.getRoleLog({...this.filter}).then(res => {
        const list = []
        console.log(res)
        if (res.message.data.length > 0) {
          for (const item of res.message.data){
            list.push([
              item.date,
              item.count
            ])
          }
          this.chartData.source = [
            ['product', '日活跃度'],
            ...list
            // ['2023-05-16', 0],
            // ['2023-05-17', 1],
            // ['2023-05-18', 5],
            // ['2023-05-19', 3],
            // ['2023-05-20', 12],
          ];
        } else {
          this.chartData.source = [];
        }

        console.log(this.chartData.source)
      })
    },
    // 查询
    search(condition) {
      if (!this.$store.state.condition.enterprise) {
        // console.log(this.$store.state.condition)
        this.$store.commit('setFormDate',condition)
      }
      this.echartList = []
      this.showDisplay = false
      this.filter = {
        ...condition.query,
        teamId:condition.enterprise,
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise
      }
      this.getChart()
      this.showDisplay = true
    },
  }
}
</script>

<style scoped lang="scss">
//::v-deep .el-tabs--card > .el-tabs__header{
//  border-bottom: 0px solid #e4e7ed;
//}
//.content{
//  height: calc(100vh - 290px);
//  overflow: auto;
//  .chart{
//    width: 94%;
//    height: 400px;
//    .title{
//      display: flex;
//      justify-content: space-between;
//    }
//
//    .echatsImg{
//      width: 90%;
//      height: 300px;
//    }
//  }
//}
</style>
