<template>
  <!--线型图-->
  <div>
    <div :id="id"  class="chart" style="width: 105px;height: 185px" />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: 'chart',
    },
    // 图表数据
    data: {
      type: Object,
      default: () => {},
    },
    formDate:{
      type: String,
      default: '',
    },
    // 图表高度
    height: {
      type: Number,
      default: 220,
    },
    // 图表区域左侧边距
    gridLeft: {
      type: Number,
      default: 0,
    },
    // 图表区域右侧边距
    gridRight: {
      type: Number,
      default: 0,
    },
    gridTop: {
      type: Number,
      default: 0,
    },
    // true：曲线，false：折线
    smooth: {
      type: Boolean,
      default: true,
    },
    // 图表类型
    type: {
      type: String,
      default: 'line',
    },
    legend:{
      type: String,
      default: '',
    },
    color:{
      type: String,
      default: '',
    },
    // 是否显示图例
    legendShow: {
      type: Boolean,
      default: true,
    },
    // 线条颜色
    linecolor: {
      type: Array,
      default: () => ['#006df0', '#0acf97', '#fc7f03', '#d149fe', '#fe6d65', '#fcd02d', '#9ad864'],
    },
    // 平均线
    markLine: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labelSetting: {
        show: true,
        position: 'top',
        offset: [0, 65],
        formatter: function (param) {
          return ((param.value / 100) * 100).toFixed(0);
        },
        fontSize: 28,
        color: '#fff',
        fontFamily: 'Arial',
      },
      // 图表配置
      optionEcharts: {
        // 标题
        // title: {
        //   subtext: 'Example in MetricsGraphics.js',
        // },
        // 提示框
        tooltip: {},
        // 图例
        legend: {
          show: false,
          data: ['typeA'],
          selectedMode: 'single'
        },
        // 数据集
        dataset: {
          // source: this.data.source,
        },
        // 图表区域大小
        grid: {
          width:115,
          top: '30',
          left: '-3',
          // height: 115,
        },
        // X轴
        xAxis: {
          data: [this.formDate],
          color: '#333333',
          axisTick: { show: false },
          axisLine: { show: false },
          // axisLabel: { show: false }
          axisLabel: {
            margin: 30 // 标签文字距离x轴刻度线的距离
          }
        },
        // y轴
        yAxis: {
          show: false,
          max: 100,
          // offset: 20,
          splitLine: { show: false }
        },
        // 系列
        series: [],
      },
    };
  },
  watch: {
    // 监听数据集
    data: {
      deep: true,
      handler(val) {
        console.log(val)
        // this.optionEcharts.dataset.source = val.source;
        this.initChart();
      },
    },
  },
  mounted() {
    this.charData(); // 处理数据
    this.initChart(); // 初始化echarts实例
  },
  methods: {
    charData() {
      // console.log(this.data.series)
      // console.log(this.optionEcharts.series)
      // 提示框
      // this.optionEcharts.tooltip = this.data.tooltip || this.optionEcharts.tooltip;
      // // y轴数据
      // this.optionEcharts.yAxis = this.data.yAxis || this.optionEcharts.yAxis;
      // 系列
      this.optionEcharts.series = [
        {
          name: this.$t('score'),
          type: 'pictorialBar',
          symbolClip: true,
          symbolBoundingData: 100,
          label: this.labelSetting,
          itemStyle: {
            color: this.color
          },
          data: [
            {
              value: this.gridLeft,
            },
          ],
          // markLine: markLineSetting,
          z: 10
        },
        {
          name: this.$t('score'),
          type: 'pictorialBar',
          // animationDuration: 1,
          itemStyle: {
            color: '#ccc'
          },
          data: [
            {
              value: 100,
              // symbol: symbols[0],
            },
          ]
        }
      ]
      // this.data.series.forEach((item, index) => {
      //   this.optionEcharts.series.push({
      //     name:item.name,
      //     type: item.type, // 图表类型
      //     symbolClip: true,
      //     label: this.labelSetting,
      //     symbolBoundingData: 100,
      //     markLine: item.markLine, // 平均线
      //     yAxisIndex: item.yAxisIndex, // 有多条y轴时，设置y轴索引
      //     color: item.color || this.linecolor[index], // 线颜色
      //     smooth: this.smooth, // 曲线、折线
      //     symbolSize: '5', // 折点大小
      //   });
      // });
    },
    initChart() {
      // const char = this.$echarts.init(document.getElementById(this.id))
      const echarts = require('echarts');
      const chartList = document.getElementById(this.id);
      // 初始化echarts实例
      const chart = echarts.init(chartList);
      this.$nextTick(() => {
        // 图表配置
        chart.setOption(this.optionEcharts);
        // 图表的自适应
        window.addEventListener('resize', () => {
          chart.resize();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .chart{
  //min-width: 150px;
  //width: 150px;
  height: 195px;
  //overflow-y: hidden;
  //text-overflow: ellipsis; white-space: nowrap;
  //text-align: right;
}
</style>
