import Vue from 'vue'
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Player from '@/views/Player';
import {getMenuTitle} from '@/utils/router2.js'
const locale = localStorage.getItem('locale') || 'zh'; // 默认中文
Vue.use(VueRouter)
// import Layout from '@/layout'

export const menuRouter = [
  {
    path: '/team',
    name: 'team',
    component: 'Layout',
    // redirect: '/PrintSteward/PrintHome',
    meta: {
      icon:'icon_team',
      title: getMenuTitle('/team', locale),
      // roles: ['7'],
      // 人事主管、财务主管的角色id
      roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
    },
    children:[
      {
        path: 'teamUser',
        name: 'teamUser',
        meta: {
          icon:'icon_person',
          title: getMenuTitle('/personnel_management', locale),
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/TeamManagement/PersonManagement.vue'),
        hidden: false
      },
      {
        path: 'teamInfo',
        name: 'teamInfo',
        meta: {
          icon:'device_repository',
          title: getMenuTitle('/team_information', locale),
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/TeamManagement/TeamInfo'),
        hidden: false
      },
      // {
      //   path: 'role',
      //   name: 'role',
      //   meta: {
      //     icon:'icon_role',
      //     title: '角色管理',
      //     // roles: ['7'],
      //     roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
      //   },
      //   component: () => import('@/views/HomePage/PrintPage/TeamManagement/RolesManage'),
      //   hidden: false
      // },
      {
        path: 'class',
        name: 'TeamClass',
        meta: {
          icon:'device_repository',
          title: getMenuTitle('/shift_management', locale),
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/TeamManagement/TeamClass'),
      },
      {
        path: 'mealTimes',
        name: 'mealTimes',
        meta: {
          icon:'device_repository',
          title: getMenuTitle('/meal_management', locale),
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/TeamManagement/mealTimes'),
      },
      {
        path: 'maintenance',
        name: 'maintenance',
        meta: {
          icon:'icon_role',
          title: getMenuTitle('/external_maintenance', locale),
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/TeamManagement/maintenance'),
      },
    ]
  },
  {
    path: '/device',
    name: 'device',
    component: 'Layout',
    // redirect: '/PrintSteward/PrintHome',
    meta: {
      icon:'device',
      title: getMenuTitle('/equipment_maintenance', locale),
      // roles: ['7'],
      roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','3411fd45-4143-4b18-ba7c-ca6fe596563a','afaaeaa3-5e93-44ad-b204-2121f400d436','e2c38b03-989c-454b-aa65-7035c41ae1fb','2b771be5-b636-41db-ada4-7164e7b638fc','a26bdd9d-0c59-4e27-a938-2e845d8192aa','5c8427b0-26f9-4f42-9b86-cd70a1d7139a','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
    },
    children:[
      {
        path: 'reportList',
        name: 'reportList',
        meta: {
          icon:'form',
          title: getMenuTitle('/submission_record', locale),
          // roles: ['7'],
          roles: ['a26bdd9d-0c59-4e27-a938-2e845d8192aa','5c8427b0-26f9-4f42-9b86-cd70a1d7139a'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/reportList.vue'),
        hidden: false
      },
      {
        path: 'maintainance',
        name: 'maintainance',
        meta: {
          icon:'form',
          title: getMenuTitle('/maintenance_record', locale),
          // roles: ['7'],
          roles: ['3411fd45-4143-4b18-ba7c-ca6fe596563a','afaaeaa3-5e93-44ad-b204-2121f400d436','e2c38b03-989c-454b-aa65-7035c41ae1fb', 'a26bdd9d-0c59-4e27-a938-2e845d8192aa','5c8427b0-26f9-4f42-9b86-cd70a1d7139a'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/maintainance.vue'),
        hidden: false
      },
      {
        path: 'inspectionRecords',
        name: 'inspectionRecords',
        meta: {
          icon:'form',
          title: getMenuTitle('/inspection_record', locale),
          // roles: ['7'],
          roles: ['3411fd45-4143-4b18-ba7c-ca6fe596563a','afaaeaa3-5e93-44ad-b204-2121f400d436','e2c38b03-989c-454b-aa65-7035c41ae1fb'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/inspectionRecords.vue'),
        hidden: false
      },
      {
        path: 'replacementRecord',
        name: 'replacementRecord',
        meta: {
          icon:'form',
          title: getMenuTitle('/replacement_record', locale),
          // roles: ['7'],
          roles: ['3411fd45-4143-4b18-ba7c-ca6fe596563a'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/replacementRecord.vue'),
        hidden: false
      },
      {
        path: 'maintenanceRecords',
        name: 'maintenanceRecords',
        meta: {
          icon:'form',
          title: getMenuTitle('/repair_record', locale),
          // roles: ['7'],
          roles: ['3411fd45-4143-4b18-ba7c-ca6fe596563a','2b771be5-b636-41db-ada4-7164e7b638fc'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/maintenanceRecords.vue'),
        hidden: false
      },
      {
        path: 'abnormalApproval',
        name: 'abnormalApproval',
        meta: {
          icon:'form',
          title: getMenuTitle('/approval_record', locale),
          // roles: ['7'],
          roles: ['e2c38b03-989c-454b-aa65-7035c41ae1fb'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/abnormalApproval.vue'),
        hidden: false
      },
      // {
      //   path: 'replacementDetail',
      //   name: 'replacementDetail',
      //   meta: {
      //     icon:'form',
      //     title: '定期更换明细',
      //     // roles: ['7'],
      //     roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
      //   },
      //   component: () => import('@/views/HomePage/PrintPage/DeviceOperation/replacementDetail.vue'),
      //   hidden: true
      // },
      // {
      //   path: 'abnormalDetail',
      //   name: 'abnormalDetail',
      //   meta: {
      //     icon:'form',
      //     title: '异常提报明细',
      //     // roles: ['7'],
      //     roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
      //   },
      //   component: () => import('@/views/HomePage/PrintPage/DeviceOperation/abnormalDetail.vue'),
      //   hidden: true
      // },
      // {
      //   path: 'faultDetail',
      //   name: 'faultDetail',
      //   meta: {
      //     icon:'form',
      //     title: '维修单明细',
      //     // roles: ['7'],
      //     roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
      //   },
      //   component: () => import('@/views/HomePage/PrintPage/DeviceOperation/faultDetail.vue'),
      //   hidden: true
      // },

      // {
      //   path: 'abnormalPeport',
      //   name: 'abnormalPeport',
      //   meta: {
      //     icon:'form',
      //     title: '异常提报',
      //     // roles: ['7'],
      //     roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
      //   },
      //   component: () => import('@/views/HomePage/PrintPage/DeviceOperation/abnormalPeport.vue'),
      //   hidden: false
      // },
      // {
      //   path: 'replacement',
      //   name: 'replacement',
      //   meta: {
      //     icon:'form',
      //     title: '定期更换',
      //     // roles: ['7'],
      //     roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
      //   },
      //   component: () => import('@/views/HomePage/PrintPage/DeviceOperation/replacement.vue'),
      //   hidden: false
      // },
      // {
      //   path: 'faultRepair',
      //   name: 'faultRepair',
      //   meta: {
      //     icon:'form',
      //     title: '故障报修',
      //     // roles: ['7'],
      //     roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
      //   },
      //   component: () => import('@/views/HomePage/PrintPage/DeviceOperation/faultRepair.vue'),
      //   hidden: false
      // },
      {
        path: 'reasonReporting',
        name: 'reasonReporting',
        meta: {
          icon:'form',
          title: getMenuTitle('/submission_reason', locale),
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/reasonReporting.vue'),
        hidden: false
      },

      {
        path: 'deviceadd',
        name: 'deviceadd',
        meta: {
          icon:'form',
          title: '新增设备',
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/DeviceAdd.vue'),
        hidden: true
      },
      {
        path: 'templateDetail',
        name: 'templateDetail',
        meta: {
          icon:'form',
          title: '模板明细',
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/templateDetail.vue'),
        hidden: true
      },
      {
        path: 'deviceDetail',
        name: 'deviceDetail',
        meta: {
          icon:'form',
          title: '模板明细',
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/DeviceDetail.vue'),
        hidden: true
      },
      {
        path: 'deviceList',
        name: 'deviceList',
        meta: {
          icon:'form',
          title: getMenuTitle('/device_list', locale),
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/DeviceList.vue'),
        hidden: false
      },
      {
        path: 'templateList',
        name: 'templateList',
        meta: {
          icon:'form',
          title: getMenuTitle('/template_list', locale),
          // roles: ['7'],
          roles: ['0ea0531a-94c9-4b57-90cb-7315b8c2b961','5bca7eca-b09c-4982-8ad7-5e707fa91ea1'],
        },
        component: () => import('@/views/HomePage/PrintPage/DeviceOperation/TemplateList.vue'),
        hidden: false
      },
    ]
  },
  {
    path: '/plate',
    name: 'plate',
    component: 'Layout',
    // redirect: '/PrintSteward/PrintHome',
    meta: {
      icon:'device',
      title: getMenuTitle('/Plate_Quality', locale),
      // roles: ['4'],
      // 印前主管的角色id
      roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
    },
    children:[
      {
        path: 'report',
        name: 'report',
        meta: {
          icon:'form',
          title: getMenuTitle('/zhiliangReport', locale),
          // roles: ['4'],
          roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
        },
        component: () => import('@/views/HomePage/PrintPage/Plate/report.vue')
      },
      {
        path: 'panel',
        name: 'panel',
        meta: {
          icon:'form',
          title: getMenuTitle('/Plate_maintenance', locale),
          // roles: ['4'],
          roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
        },
        component: () => import('@/views/HomePage/PrintPage/Plate/panel.vue')
      },
    ]
  },
  {
    path: '/largeModule',
    name: 'largeModule',
    component: 'Layout',
    // redirect: '/PrintSteward/PrintHome',
    meta: {
      icon:'device',
      title: getMenuTitle('/big_screen_display', locale),
      // roles: ['4'],
      // 印前主管的角色id
      // roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
    },
    children:[
      {
        path: 'boss',
        name: 'boss',
        meta: {
          icon:'form',
          title: getMenuTitle('/big_screen_display_boss', locale),
          // roles: ['4'],
          // roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
        },
        component: () => import('@/views/HomePage/PrintPage/largeModule/boss.vue')
      },
      {
        path: 'workshop',
        name: 'workshop',
        meta: {
          icon:'form',
          title: getMenuTitle('/big_screen_display_workshop', locale),
          // roles: ['4'],
          // roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
        },
        component: () => import('@/views/HomePage/PrintPage/largeModule/workshop.vue')
      },
      {
        path: 'equipment',
        name: 'equipment',
        meta: {
          icon:'form',
          title: getMenuTitle('/big_screen_display_machine', locale),
          // roles: ['4'],
          // roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
        },
        component: () => import('@/views/HomePage/PrintPage/largeModule/equipment.vue')
      },
      // {
      //   path: 'dayWeekReport',
      //   name: 'dayWeekReport',
      //   meta: {
      //     icon:'form',
      //     title: '日清周清',
      //     // roles: ['4'],
      //     // roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
      //   },
      //   component: () => import('@/views/HomePage/PrintPage/dayWeekReport/dayWeekReport.vue')
      // }
    ]
  },
  {
    path: '/dayWeekReport',
    name: 'dayWeekReport',
    component: 'Layout',
    // redirect: '/PrintSteward/PrintHome',
    meta: {
      icon:'device',
      title: '日清周清',
    },
    children:[
      {
        path: 'dayWeekReport',
        name: 'dayWeekReport',
        meta: {
          icon:'form',
          title: '日清周清',
          teamType:undefined,
        },
        component: () => import('@/views/HomePage/PrintPage/dayWeekReport/dayWeekReport.vue')
      },
    ]
  },
  {
    path: '/dataReportPage',
    name: 'dataReportPage',
    component: 'Layout',
    // redirect: '/PrintSteward/PrintHome',
    meta: {
      icon:'device',
      title: getMenuTitle('/Data_Report', locale),
      roles: ['3644fc5d-ef69-4a30-8dad-6407b3120eed','5a729bb3-f6c7-499b-b3c2-0193a99addda'],
    },
    children:[
      {
        path: 'dataReportPage',
        name: 'dataReportPage',
        meta: {
          icon:'form',
          title: getMenuTitle('/Data_Report', locale),
          teamType:undefined,
        },
        component: () => import('@/views/HomePage/PrintPage/dataReportPage/dataReport.vue')
      },
    ]
  },
  {
    path: '/yinYaYun',
    name: 'yinYaYun',
    component: 'Layout',
    // redirect: '/PrintSteward/PrintHome',
    meta: {
      icon:'device',
      title: getMenuTitle('/prepress_automation', locale),
      teamType:[3,4],
      // roles: ['4'],
      // 印前主管的角色id
      roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
    },
    children:[
      {
        path: 'project',
        name: 'project',
        meta: {
          icon:'form',
          title: getMenuTitle('/prepress_automation', locale),
          teamType:[3,4],
          // roles: ['4'],
          roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
        },
        component: () => import('@/views/HomePage/PrintPage/yinYaYun/project.vue')
      },
    ]
  },
  {
    path: '/TechnicalSupport',
    name: 'TechnicalSupport',
    component: 'Layout',
    // redirect: '/PrintSteward/PrintHome',
    meta: {
      icon:'device',
      title: getMenuTitle('/technical_support', locale),
      // teamType:[3,4],
      // roles: ['4'],
      // 印前主管的角色id
      // roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
    },
    children:[
      {
        path: 'technicalSupport',
        name: 'technicalSupport',
        meta: {
          icon:'form',
          title: getMenuTitle('/technical_support', locale),
          teamType:undefined,
          // roles: ['4'],
        },
        component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/technicalSupport.vue')
      },
      {
        path: 'technicalSupportDetail',
        name: 'technicalSupportDetail',
        meta: {
          icon:'form',
          title: '技术支持明细',
          teamType:undefined,
          // roles: ['4'],
        },
        hidden: true,
        component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/technicalSupportDetail.vue')
      },
      {
        path: 'TechnicalSupportList',
        name: 'TechnicalSupportList',
        meta: {
          icon:'form',
          title: '文档维护',
          teamType:undefined,
          // roles: ['4'],
        },
        hidden: true,
        component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/TechnicalSupportList.vue')
      },
      // {
      //   path: 'TechnicalSupportClass',
      //   name: 'TechnicalSupportClass',
      //   meta: {
      //     icon:'form',
      //     title: '分类维护',
      //     teamType:undefined,
      //     // roles: ['4'],
      //   },
      //   component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/TechnicalSupportClass.vue')
      // },
      // {
      //   path: 'TechnicalSupportInfo',
      //   name: 'TechnicalSupportInfo',
      //   meta: {
      //     icon:'form',
      //     title: '添加文档',
      //     teamType:undefined,
      //     // roles: ['4'],
      //   },
      //   hidden: true,
      //   component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/TechnicalSupportInfo.vue')
      // },
      {
        path: 'CheckInfo',
        name: 'CheckInfo',
        meta: {
          icon:'form',
          title: '查看文档',
          teamType:undefined,
          // roles: ['4'],
        },
        hidden: true,
        component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/CheckInfo.vue')
      },
    ]
  },
  // {
  //   path: '/matterData',
  //   name: 'matterData',
  //   component: 'Layout',
  //   // redirect: '/PrintSteward/PrintHome',
  //   meta: {
  //     icon:'device',
  //     title: '待办事项',
  //     // teamType:[3,4],
  //     // roles: ['4'],
  //     // 印前主管的角色id
  //     // roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
  //   },
  //   children:[
  //     {
  //       path: 'matterList',
  //       name: 'matterList',
  //       meta: {
  //         icon:'form',
  //         title: '待办事项',
  //         teamType:undefined,
  //         // roles: ['4'],
  //       },
  //       component: () => import('@/views/HomePage/PrintPage/matterData/matterList.vue')
  //     },
  //     {
  //       path: 'technicalSupportDetail',
  //       name: 'technicalSupportDetail',
  //       meta: {
  //         icon:'form',
  //         title: '技术支持明细',
  //         teamType:undefined,
  //         // roles: ['4'],
  //       },
  //       hidden: true,
  //       component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/technicalSupportDetail.vue')
  //     },
  //     {
  //       path: 'TechnicalSupportList',
  //       name: 'TechnicalSupportList',
  //       meta: {
  //         icon:'form',
  //         title: '文档维护',
  //         teamType:undefined,
  //         // roles: ['4'],
  //       },
  //       hidden: true,
  //       component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/TechnicalSupportList.vue')
  //     },
  //     // {
  //     //   path: 'TechnicalSupportClass',
  //     //   name: 'TechnicalSupportClass',
  //     //   meta: {
  //     //     icon:'form',
  //     //     title: '分类维护',
  //     //     teamType:undefined,
  //     //     // roles: ['4'],
  //     //   },
  //     //   component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/TechnicalSupportClass.vue')
  //     // },
  //     // {
  //     //   path: 'TechnicalSupportInfo',
  //     //   name: 'TechnicalSupportInfo',
  //     //   meta: {
  //     //     icon:'form',
  //     //     title: '添加文档',
  //     //     teamType:undefined,
  //     //     // roles: ['4'],
  //     //   },
  //     //   hidden: true,
  //     //   component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/TechnicalSupportInfo.vue')
  //     // },
  //     {
  //       path: 'CheckInfo',
  //       name: 'CheckInfo',
  //       meta: {
  //         icon:'form',
  //         title: '查看文档',
  //         teamType:undefined,
  //         // roles: ['4'],
  //       },
  //       hidden: true,
  //       component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/CheckInfo.vue')
  //     },
  //   ]
  // },

  // {
  //   path: '/dayWeekReport',
  //   name: 'dayWeekReport',
  //   component: 'Layout',
  //   // redirect: '/PrintSteward/PrintHome',
  //   meta: {
  //     icon:'device',
  //     title: '日清周清',
  //     // teamType:[3,4],
  //     // roles: ['4'],
  //     // 印前主管的角色id
  //     // roles: ['afaaeaa3-5e93-44ad-b204-2121f400d436'],
  //   },
  //   children:[
  //     {
  //       path: 'dayWeekReport',
  //       name: 'dayWeekReport',
  //       meta: {
  //         icon:'form',
  //         title: '日清周清',
  //         teamType:undefined,
  //         // roles: ['4'],
  //       },
  //       component: () => import('@/views/HomePage/PrintPage/dayWeekReport/dayWeekReport.vue')
  //     },
  //   ]
  // },
]
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/PrintSteward/PrintHome',
    children: [
      {
        path: '/HomePage',
        name: 'HomePage',
        component: () => import('@/views/HomePage/HomePage.vue')
      },
      {
        path: 'PrintSteward',
        name: 'PrintSteward',
        component: () => import('@/views/HomePage/PrintSteward.vue'),
        children: [
          {
            path: 'NoOpen',
            name: 'NoOpen',
            component: () => import('@/views/HomePage/PrintPage/NoOpen.vue')
          },
          {
            path: 'PrintHome',
            name: 'PrintHome',
            component: () => import('@/views/HomePage/PrintPage/PrintHome.vue')
          },
          {
            path: 'firstHome',
            name: 'firstHome',
            component: () => import('@/views/HomePage/PrintPage/firstHome.vue')
          },
          {
            path: 'teamUser',
            name: 'teamUser',
            component: () => import('@/views/HomePage/PrintPage/TeamManagement/PersonManagement')
          },
          {
            path: 'teamInfo',
            name: 'teamInfo',
            component: () => import('@/views/HomePage/PrintPage/TeamManagement/TeamInfo.vue')
          },
          {
            path: 'role',
            name: 'role',
            component: () => import('@/views/HomePage/PrintPage/TeamManagement/RolesManage')
          },
          {
            path: 'class',
            name: 'TeamClass',
            component: () => import('@/views/HomePage/PrintPage/TeamManagement/TeamClass'),
          },
          {
            path: 'mealTimes',
            name: 'mealTimes',
            component: () => import('@/views/HomePage/PrintPage/TeamManagement/mealTimes'),
          },
            {
                path: 'maintenance',
                name: 'maintenance',
                component: () => import('@/views/HomePage/PrintPage/TeamManagement/maintenance'),
            },
          {
            path: 'deviceList',
            name: 'deviceList',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/DeviceList.vue')
          },
          {
            path: 'reportList',
            name: 'reportList',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/reportList.vue')
          },
          {
            path: 'abnormalPeport',
            name: 'abnormalPeport',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/abnormalPeport.vue')
          },
          {
            path: 'replacementDetail',
            name: 'replacementDetail',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/replacementDetail.vue')
          },
          {
            path: 'abnormalDetail',
            name: 'abnormalDetail',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/abnormalDetail.vue')
          },
          {
            path: 'replacement',
            name: 'replacement',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/replacement.vue')
          },
          {
            path: 'faultRepair',
            name: 'faultRepair',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/faultRepair.vue')
          },
          {
            path: 'faultDetail',
            name: 'faultDetail',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/faultDetail.vue')
          },
          {
            path: 'maintainance',
            name: 'maintainance',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/maintainance.vue')
          },
          {
            path: 'inspectionRecords',
            name: 'inspectionRecords',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/inspectionRecords.vue')
          },
          {
            path: 'replacementRecord',
            name: 'replacementRecord',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/replacementRecord.vue')
          },
          {
            path: 'maintenanceRecords',
            name: 'maintenanceRecords',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/maintenanceRecords.vue')
          },
          {
            path: 'abnormalApproval',
            name: 'abnormalApproval',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/abnormalApproval.vue')
          },
          {
            path: 'maintenDetail',
            name: 'maintenDetail',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/maintenanceDetail.vue')
          },
          {
            path: 'maintenanceReport',
            name: 'maintenanceReport',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/maintenanceReport.vue')
          },
          {
            path: 'reasonReporting',
            name: 'reasonReporting',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/reasonReporting.vue')
          },
          {
            path: 'deviceDetail',
            name: 'deviceDetail',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/DeviceDetail.vue')
          },
          {
            path: 'inspectionDetail',
            name: 'inspectionDetail',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/inspectionDetail.vue')
          },
          {
            path: 'inspection',
            name: 'inspection',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/inspection.vue')
          },
          {
            path: 'maintenanceApproval',
            name: 'maintenanceApproval',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/maintenanceApproval.vue')
          },
          {
            path: 'Approval',
            name: 'Approval',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/Approval.vue')
          },
          {
            path: 'abnormalReportDetail',
            name: 'abnormalReportDetail',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/abnormalReportDetail.vue')
          },
          {
            path: 'deviceAdd',
            name: 'deviceAdd',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/DeviceAdd.vue')
          },
          {
            path: 'templateList',
            name: 'templateList',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/TemplateList.vue')
          },
          {
            path: 'templateDetail',
            name: 'templateDetail',
            component: () => import('@/views/HomePage/PrintPage/DeviceOperation/templateDetail.vue')
          },
          {
            path: 'report',
            name: 'report',
            component: () => import('@/views/HomePage/PrintPage/Plate/report.vue')
          },
          {
            path: 'panel',
            name: 'panel',
            component: () => import('@/views/HomePage/PrintPage/Plate/panel.vue')
          },
          {
            path: 'boss',
            name: 'boss',
            component: () => import('@/views/HomePage/PrintPage/largeModule/boss.vue')
          },
          {
            path: 'workshop',
            name: 'workshop',
            component: () => import('@/views/HomePage/PrintPage/largeModule/workshop.vue')
          },
          {
            path: 'equipment',
            name: 'equipment',
            component: () => import('@/views/HomePage/PrintPage/largeModule/equipment.vue')
          },
          {
            path: 'project',
            name: 'project',
            component: () => import('@/views/HomePage/PrintPage/yinYaYun/project.vue')
          },
          {
            path: 'technicalSupport',
            name: 'technicalSupport',
            component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/technicalSupport.vue')
          },
          {
            path: 'technicalSupportDetail',
            name: 'technicalSupportDetail',
            component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/technicalSupportDetail.vue')
          },
          {
            path: 'TechnicalSupportList',
            name: 'TechnicalSupportList',
            component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/TechnicalSupportList.vue')
          },
          {
            path: 'TechnicalSupportClass',
            name: 'TechnicalSupportClass',
            component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/TechnicalSupportClass.vue')
          },
          {
            path: 'TechnicalSupportInfo',
            name: 'TechnicalSupportInfo',
            component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/TechnicalSupportInfo.vue')
          },
          {
            path: 'CheckInfo',
            name: 'CheckInfo',
            component: () => import('@/views/HomePage/PrintPage/TechnicalSupport/CheckInfo.vue')
          },
          {
            path: 'dayWeekReport',
            name: 'dayWeekReport',
            component: () => import('@/views/HomePage/PrintPage/dayWeekReport/dayWeekReport.vue')
          },
          {
            path: 'dataReportPage',
            name: 'dataReportPage',
            component: () => import('@/views/HomePage/PrintPage/dataReportPage/dataReport.vue')
          },
          {
            path: 'abnormalDetails',
            name: 'abnormalDetails',
            component: () => import('@/views/HomePage/PrintPage/dayWeekReport/abnormalDetails.vue')
          },
          {
            path: 'fountainSolutionDetails',
            name: 'fountainSolutionDetails',
            component: () => import('@/views/HomePage/PrintPage/dayWeekReport/fountainSolutionDetails.vue')
          },
          {
            path: 'maintenanceDetail',
            name: 'maintenanceDetail',
            component: () => import('@/views/HomePage/PrintPage/dayWeekReport/maintenanceDetail.vue')
          },
          {
            path: 'matterList',
            name: 'matterList',
            component: () => import('@/views/HomePage/PrintPage/matterData/matterList.vue')
          },
          // {
          //   path: 'TeamManagement',
          //   name: 'TeamManagement',
          //   redirect: '/PrintSteward/TeamManagement/PersonManagement',
          //   component: () => import('@/views/HomePage/PrintPage/TeamManagement.vue'),
          //   children: [
          //     {
          //       path: 'teamUser',
          //       name: 'teamUser',
          //       component: () =>
          //         import('@/views/HomePage/PrintPage/TeamManagement/PersonManagement')
          //     },
          //     {
          //       path: 'TeamInfo',
          //       name: 'TeamInfo',
          //       component: () => import('@/views/HomePage/PrintPage/TeamManagement/TeamInfo.vue')
          //     },
          //     {
          //       path: 'RolesManage',
          //       name: 'RolesManage',
          //       component: () => import('@/views/HomePage/PrintPage/TeamManagement/RolesManage.vue')
          //     }
          //   ]
          // },
          // {
          //   path: 'DeviceOperation',
          //   name: 'DeviceOperation',
          //   redirect: '/PrintSteward/DeviceOperation/DeviceList',
          //   component: () => import('@/views/HomePage/PrintPage/DeviceOperation.vue'),
          //   children: [
          //     {
          //       path: 'DeviceList',
          //       name: 'DeviceList',
          //       component: () => import('@/views/HomePage/PrintPage/DeviceOperation/DeviceList.vue')
          //     },
          //     {
          //       path: 'TemplateList',
          //       name: 'TemplateList',
          //       component: () =>
          //         import('@/views/HomePage/PrintPage/DeviceOperation/TemplateList.vue')
          //     }
          //   ]
          // }
        ]
      },
      {
        path: 'ResearchInstitute',
        name: 'ResearchInstitute',
        component: () => import('@/views/HomePage/ResearchInstitute.vue')
      },
      {
        path: 'PersonalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/UserName/PersonalCenter/PersonalCenter.vue'),
        children: [
          {
            path: 'PersonalDatum',
            name: 'PersonalDatum',
            component: () => import('@/views/UserName/PersonalCenter/Sidebar/PersonalDatum')
          },
          {
            path: 'SetPassword',
            name: 'SetPassword',
            component: () => import('@/views/UserName/PersonalCenter/Sidebar/SetPassword')
          },
          {
            path: 'Information',
            name: 'Information',
            component: () => import('@/views/UserName/PersonalCenter/Sidebar/Information')
          }
          // {
          //   path: 'Indent',
          //   name: 'Indent',
          //   component: () => import('@/views/UserName/Indent/Indent.vue'),
          //   children: [
          //     // {
          //     //   path: '',
          //     //   name: 'Indent',
          //     //   component: () => import('@/views/UserName/Indent/IndentBox/Indent.vue')
          //     // },
          //     {
          //       path: 'Invoice',
          //       name: 'Invoice',
          //       component: () => import('@/views/UserName/Indent/Invoice.vue')
          //     },
          //     {
          //       path: 'IndentInformation',
          //       name: 'IndentInformation',
          //       component: () => import('@/views/UserName/Indent/IndentInformation.vue')
          //     }
          //   ]
          // }
        ]
      },
      {
        path: 'BeeEnjoy',
        name: 'BeeEnjoy',
        component: () => import('@/views/UserName/BeeEnjoy/BeeEnjoy.vue'),
      },
      {
        path: 'DigitalFactory',
        name: 'DigitalFactory',
        component: () => import('@/views/UserName/DigitalFactory/DigitalFactory.vue'),
      },
      {
        path: 'Course',
        name: 'Course',
        redirect: '/Course/Selection',
        component: () => import('@/views/UserName/Course/Course.vue'),
        children: [
          {
            path: 'MyCourse',
            name: 'MyCourse',
            redirect: '/Course/Selection',
            component: () => import('@/views/UserName/Course/Sidebar/MyCourse'),
            children: [
              {
                path: 'My-Course',
                name: 'My-Course',
                component: () => import('@/views/UserName/Course/Sidebar/MyCourse/My-Course')
              },
              {
                path: 'Task',
                name: 'Task',
                component: () => import('@/views/UserName/Course/Sidebar/Task.vue')
              },
              {
                path: 'Test',
                name: 'Test',
                component: () => import('@/views/UserName/Course/Sidebar/Test.vue')
              },
              {
                path: 'grade',
                name: 'grade',
                component: () => import('@/views/UserName/Course/Sidebar/Grade.vue')
              },
              {
                path: 'TaskParticular',
                name: 'TaskParticular',
                component: () => import('@/views/UserName/Course/Sidebar/TaskParticular.vue')
              }
            ]
          },
          {
            path: 'Selection',
            name: 'Selection',
            component: () => import('@/views/UserName/Course/Sidebar/Selection')
          },
          {
            path: 'Indent',
            name: 'Indent',
            component: () => import('@/views/UserName/Indent/Indent.vue')
          },
          {
            path: 'Distribute',
            name: 'Distribute',
            component: () => import('@/views/UserName/Course/Sidebar/Distribute')
          }
        ]
      },

    ]
  },
  {
    path: '/projectImg',
    name: 'projectImg',
    component: () => import('@/views/HomePage/PrintPage/yinYaYun/projectImg.vue')
  },
  {
    path: '/equipmentBig',
    name: 'equipmentBig',
    component: () => import('@/views/equipment.vue')
  },
  {
    path: '/Player',
    name: 'Player',
    component: Player
  },
  {
    path: '/waterTankMonitoring',
    name: 'waterTankMonitoring',
    component: () => import('@/views/waterTankMonitoring.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/components/404')
  }
];

export const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

// export default router
