
<!--点检单明细-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">{{ $t('inspection_sheet') }}</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
        </div>
      </div>
      <div class="center-top-border">
        <div class="center-top">
          <span>{{ formList.totalScore }}</span>
          <span>{{ $t('point') }}</span>
        </div>
        <hr v-if="formList.type === 1" />
        <div v-if="formList.type === 1" class="center-top">
          <span class="font-size20">{{ formList.lubricationScore }}</span>
          <span>{{ $t('lubrication') }}</span>
        </div>
        <hr />
        <div class="center-top">
          <span class="font-size20">{{ formList.cleanScore }}</span>
          <span>{{ $t('check_cleaning') }}</span>
        </div>
        <hr />
        <div class="center-top">
          <span class="font-size20">{{ formList.adjustScore }}</span>
          <span>{{ $t('adjustment') }}</span>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
            ref="infoForm"
            :model="formList"
            :label-width="isChineseLocale ? '120px' : '140px'"
            label-position="left"
        >
          <div class="title">

          </div>
          <el-form-item :label="$t('deviceName')" prop="name">
            <div>{{formList.deviceName}}</div>
          </el-form-item>
          <el-form-item :label="$t('sheet_number')" prop="" style="max-width: 1000px">
            <div>{{formList.code}}</div>
          </el-form-item>
          <el-form-item :label="$t('maintenance_type')" prop="code">
            <div>
              {{ formList.type === 3 ? $t('zhiban') : formList.type === 1 ? $t('printing') : $t('Post_Press')}}
              {{ formList.maintainType === '1' ? $t('day_maintainance') : formList.maintainType === '2' ? $t('weeks_maintainance') : formList.maintainType === '3' ? $t('months_maintainance') :formList.maintainType === '30' ? $t('half_months_maintainance') :formList.maintainType === '4' ? $t('quarter_maintainance') :formList.maintainType === '5' ? $t('half_year_maintainance') : $t('year_maintainance') }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('maintenance_time')" prop="name">
            <div>{{formList.maintainDate}}</div>
          </el-form-item>
          <el-form-item :label="$t('baoyangren')" prop="name">
            <div>{{formList.maintain}}</div>
          </el-form-item>
          <el-form-item :label="$t('inspector')" prop="name">
            <div>{{formList.check}}</div>
          </el-form-item>
          <el-form-item :label="$t('inspection_time')" prop="name">
            <div>{{formList.checkDate}}</div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api'

export default {
  name: "inspectionDetail",
  data() {
    return {
      isUpdate: true,
      formList: {
        totalScore: "",
        lubricationScore: "",
        cleanScore: "",
        adjustScore: "",
        deviceName: "",
        code: "",
        maintainType: "",
        maintainDate: "",
        maintain: "",
        check: "",
        checkDate: "",
      },
    }
  },
  created() {
    this.updateDevice()
  },
  computed: {
    isChineseLocale() {
      return localStorage.getItem('locale') === 'zh';
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    updateDevice() {
      API.checkDetailList(this.$route.query.id).then(res => {
        this.formList = res.message.data
      })
    },
  }
}
</script>
<style>
.widthTable {
  width: 1550px;
}
.page-header {
  border-bottom: 1px solid #E8EAEF;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
}
.el-table .hidden-row {
  display: none;
}
.center-top-border {
  /*border-bottom: 1px solid #d7d7d7;*/
  /*width: 500px;*/
  /*display: flex;*/
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 5px;
}
.center-top {
  display: inline-block;
  /*width: 170px;*/
  font-weight: bold;
  color: #281315;
  text-align: center;
  margin-right: 35px;
  margin-left: 35px;
}
.center-top:first-child {
  margin-left: 0px;
}
.center-top:last-child {
  border: 0;
}
.center-top:first-child span:first-child {
  font-size: 44px;
}
.font-size20 {
  font-size: 44px;
}
.center-top span:last-child {
  margin-left: 5px;
  font-size: 18px;
  font-weight: normal;
}
hr {
  border-right: 1px solid #f3f3f5;
  height: 50px;
  display: inline-block;
}
</style>
