
<!--保养单明细-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">{{ $t('Maintenance_order_details') }}</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('maintenance_information')" name="first">
          <div class="form-box" style="max-width: 1000px">
            <el-form
                ref="infoForm"
                :model="data"
                :label-width="isChineseLocale ? '120px' : '170px'"
                label-position="left"
            >
              <el-form-item :label="$t('maintenance_sheet_number')" prop="name">
                <div>{{data.name}}</div>
              </el-form-item>
              <el-form-item :label="$t('device_state')" prop="" style="max-width: 1000px">
                <span style="color:#EA000E;" v-if="data.orderState === 0 && data.state === 0">{{ $t('not_maintained') }}</span>
                <span style="color:#37C239;" v-if="data.state === 1">{{ $t('maintenance_completed') }}</span>
                <span style="color:#37C239;" v-if="data.state === 2">{{ $t('maintenance_completed') }}</span>
                <span style="color:#37C239;" v-if="data.state === 7">{{ $t('maintenance_completed') }}</span>
                <span style="color:#F7B515;" v-if="data.state === 3">{{ $t('cancelling') }}</span>
                <span style="color:#999999;" v-if="data.state === 4">{{ $t('cancelled') }}</span>
                <span style="color:#409EFF;" v-if="data.state === 5">{{ $t('overtime_cancellation') }}</span>
                <span :style="{ color: 'blue' }" v-if="data.orderState === 1 && data.state === 0">{{ $t('in_progress') }}</span>
              </el-form-item>
              <el-form-item :label="$t('maintenance_type')" prop="code">
                <div>{{ data.type === '1' ? $t('day_maintainance') : data.type === '2' ? $t('weeks_maintainance') : data.type === '3' ? $t('months_maintainance') :data.type === '30' ? $t('half_months_maintainance') :data.type === '4' ? $t('quarter_maintainance') :data.type === '5' ? $t('half_year_maintainance') : $t('year_maintainance') || "" }}</div>
              </el-form-item>
              <el-form-item :label="$t('deviceName')" prop="name">
                <div>{{data.deviceName}}</div>
              </el-form-item>
              <el-form-item :label="$t('device_type')" prop="" style="max-width: 1000px">
                <div>{{data.deviceInfoName}}</div>
              </el-form-item>
                <el-form-item :label="$t('machine_serial_number')" prop="code">
                  <div>{{data.code}}</div>
                </el-form-item>
                <el-form-item :label="$t('estimated_time')" prop="" style="max-width: 1000px">
                 <span class="content">
                <span>{{ data.startTime || "" }}</span>
                <span style="margin: 0 5px;">-</span>
                <span>{{ data.endTime || "" }}</span>
              </span>
                </el-form-item>
                <el-form-item :label="$t('baoyangren')" prop="code">
                  <div>{{data.cName}}</div>
                </el-form-item>
              <el-form-item :label="$t('Submitter_per')" prop="code" v-if="data.maintainUser">
                <el-input
                    v-model.trim="data.maintainUser"
                    :disabled="isUpdate"
                    clearable
                    placeholder="请填写提交人"
                    size="medium"
                    maxlength="20"
                />
              </el-form-item>
              <el-form-item :label="$t('finish_time')" prop="code" v-if="data.maintainTime">
                <el-input
                    v-model.trim="data.maintainTime"
                    :disabled="isUpdate"
                    clearable
                    placeholder="请输入完成时间"
                    size="medium"
                    maxlength="20"
                />
              </el-form-item>
              <el-form-item :label="$t('reporter')" prop="code" v-if="data.cancelPerson">
                <el-input
                    v-model.trim="data.cancelPerson"
                    :disabled="isUpdate"
                    clearable
                    placeholder="请输入提报人"
                    size="medium"
                    maxlength="20"
                />
              </el-form-item>
              <el-form-item :label="$t('submission_reason')" prop="code" v-if="data.reason">
                <el-input
                    v-model.trim="data.reason"
                    :disabled="isUpdate"
                    clearable
                    placeholder="请填写提报原因"
                    size="medium"
                    maxlength="20"
                />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="start1" v-if="data.orderState === 0 && data.state === 0">{{ $t('start') }}</el-button>
                <el-button type="primary" plain @click="handleVisible" v-if="data.orderState === 0 && data.state === 0">
                  {{ $t('cancel_maintenance') }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('maintenance_items')" name="second">
          <div class="form-box" style="max-width: 1000px">
            <div class="main">
              <div style="text-align: right;">
                <el-button type="primary"  size="small" v-if="data.deviceType === $t('yinshua_device')" @click="handlePoint">
                  {{ $t('site_details') }}</el-button>
              </div>

              <section class="">
                <div v-for="(item,index) in detailList" :key="index" class="item2">
                  <div>
<!--                    <el-switch-->
<!--                        v-model="item.iComplete"-->
<!--                        active-color="#13ce66"-->
<!--                        inactive-color="#ff4949">-->
<!--                    </el-switch>-->
                    <el-checkbox v-model="item.iComplete" :disabled="disabled"></el-checkbox>
                    <span class="iComplete" style="flex: 1; height: 100%;" @click="goDetail(item)">{{
                        item.sop
                      }}</span>
                  </div>

                  <div class="item2-right">

                    <el-button size="small" @click="goDetail(item)" plain type="primary">{{ $t('maintenance_information') }}</el-button>

                  </div>
                </div>
              </section>
              <el-form
                  ref="infoForm"
                  :model="data"
                  label-width="100px"
                  label-position="left"
              >
                <el-form-item :label="$t('remark')" prop="name">
                  <el-input
                      v-model.trim="data.remark"
                      show-word-limit
                      clearable
                      type="textarea"
                      :rows="4"
                      :disabled="disabled"
                      :placeholder="$t('Optional')"
                  />
                </el-form-item>
                <el-form-item :label="$t('Photo_upload')" prop="name">
                  <div class="m2" v-if="!data.pictureUrl">
                    <div class="pic-and-video-wrapper">
                      <div v-for="(img, index) in pics" :key="index" class="preview">
                        <!--                <img :src="img"/>-->
                        <el-image
                            style="width: 100%"
                            :src="img"
                            :preview-src-list="pics">
                        </el-image>
                        <el-button class="van-btton" icon="el-icon-delete-solid" type="text" @click="removeImage(index)"/>
                      </div>
                      <div class="btn" @click="selectImage" v-show="pics.length < 9">
                                            <img src="@/assets/img/add-pic2.png" alt="add pic"/>
                        <input v-show="false" :disabled="disabled" ref="imgInput" type="file" accept="image/*" multiple
                               onclick="event.cancelBubble = true" @input="pickImage"/>
                      </div>
                    </div>
                  </div>
                  <div class="m2" v-if="data.pictureUrl">
                    <div class="group">
<!--                      <el-row :gutter="24">-->
                        <div class="url" :span="3" v-for="(item,index) in pictureUrl" :key="index">
                          <div class="item_img">
                            <el-image
                                style="width: 100%"
                                :src="item"
                                :preview-src-list="pictureUrl">
                            </el-image>
                          </div>
                        </div>
<!--                      </el-row>-->
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="" prop="name">
                  <el-button v-loading="loading" type="primary" @click="submit" v-if="data.orderState === 1 && data.state === 0">
                    {{ $t('submit') }}</el-button>
                  <el-button v-loading="loading" type="primary" @click="submitSwitch" v-if="data.orderState === 1 && data.state === 1 && data.nextState === 1">
                    {{ $t('switch') }}</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

    </div>
    <el-dialog :title="$t('cancel_maintenance')" :visible.sync="dialogTableVisible" :close-on-click-modal="false"
               width="20%">
      <div>
        <el-form :model="form">
          <el-form-item>
            <el-input
                type="textarea"
                :rows="4"
                :placeholder="$t('enter_cancel_reason')"
                maxlength="150"
                v-model="form.reason">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogTableVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="handleCancel">{{ $t('queding') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('site_details')" :visible.sync="dialogTableVisiblePoint" :close-on-click-modal="false"
               width="20%" top="30vh">
      <el-table class="table" :data="tableData" size="mini" border>
        <el-table-column type="index" :label="$t('no')" align="center">
        </el-table-column>
        <el-table-column prop="key" :label="$t('number')" align="center">
        </el-table-column>
        <el-table-column prop="state" :label="$t('completion_status')" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.state === 1"><img src="@/assets/img/right.png" alt=""/></span>
            <span v-if="row.state === 0"><img src="@/assets/img/error.png" alt=""/></span>
          </template>
        </el-table-column>
      </el-table>
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button type="primary" @click="dialogTableVisiblePoint = false">确 定</el-button>-->
<!--      </span>-->
    </el-dialog>
    <el-dialog :title="$t('maintenance_information')" :visible.sync="maintenanceInfo" :close-on-click-modal="false"
               width="20%" top="30vh">
      <div>
        <section class="card">
          <div class="item">
            <span class="title">{{ $t('position') }}</span>
            <span class="content">{{formInfo.part || ''}}</span>
          </div>
          <div class="item">
            <span class="title">{{ $t('component') }}</span>
            <span class="content">{{formInfo.element || ''}}</span>
          </div>
          <div class="item">
            <span class="title">{{ $t('work') }}</span>
            <span class="content">{{formInfo.cWork || ''}}</span>
          </div>
          <div class="item">
            <span class="title">SOP</span>
            <span class="content">{{formInfo.sop || ''}}</span>
          </div>
          <div class="item">
            <span class="title">{{ $t('maintenance_standard') }}</span>
            <span class="content">{{formInfo.standard || ''}}</span>
          </div>
        </section>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from '@/api'
import {base64toFile} from '@/utils/file';
export default {
  name: "maintenanceDetail",
  data() {
    return {
      form: {
        reason: ''
      },
      maintenanceInfo: false,
      isUpdate: true,
      dialogTableVisiblePoint:false,
      dialogTableVisible: false,
      activeName: 'first',
      remark: "",
      pics: [],
      disabled: true,
      loading: false,
      tableData: [],
      formList: {
        remark: '',
        pictureUrl: ''
      },
      formInfo: {

      },
      pictureUrl: [],
      number: 0,
      data: {
        remark: ''
      },
      detailList: [],
    }
  },
  created() {
    this.getList()
    this.updateDevice()
  },
  computed: {
    isChineseLocale() {
      return localStorage.getItem('locale') === 'zh';
    }
  },
  methods: {
    back() {
      if (this.$route.query.type === 'list') {
        this.$router.push({ path: 'maintainance'});
      } else {
        this.$router.push({ path: 'PrintHome'});
      }
    },
    stateText() {
      if (this.data.orderState === 0) {
        return this.$t('not_maintained')
      }
      if (this.data.state === 0) {
        return this.$t('in_progress')
      }
      return this.$t('maintenance_completed')
    },
    getList() {
      API.getMaintainDetail(this.$route.query.id).then(res => {
        this.data = res.message.data
        this.pictureUrl = this.data.pictureUrl.split(',')
        if (this.data.orderState === 1 && this.data.state === 0) {
          this.disabled = false
        }
      })
    },
    updateDevice() {
      setTimeout(() => {
        API.getMaintainDetaiList(this.$route.query.id).then(res => {
          this.detailList = res.message.data
          if ((this.$route.query.id) && (this.data.orderState === 1 && this.data.state === 0)) {
            this.detailList = this.detailList.map((x) => ({
              ...x,
              iComplete: 0,
            }))
            console.log(this.detailList)
            // this.pics = this.$store.state.pics2
          } else {
            console.log(this.data.state)
            if (this.data.state === 1 || this.data.state === 2) {
              this.detailList = this.detailList.map((x) => ({
                ...x,
                iComplete: true,
              }))
              console.log(this.detailList)
            } else {
              this.detailList = this.detailList.map((x) => ({
                ...x,
                iComplete: 0,
              }))
            }
            console.log(this.detailList)
            this.pics = []
          }
        })
      },1000)
    },
    handleChange(item, index) {
      // console.log(item)
      this.$nextTick(() => {
        // 执行一些操作，比如重新计算布局或者更新样式
        this.detailList[index].iComplete = item.iComplete
        this.$forceUpdate();
      })
    },
    startMaintain() {
      API.startMaintain(this.$route.query.id).then(res => {
        if (res.message.success) {
          this.data.orderState = 1
          this.disabled = false
        } else {
          this.$message({
            message: res.message.message,
            // showClose: true,
            type: 'error',
          });
          // message('error', r.message)
        }
      })

    },
    submit(){
      console.log(this.detailList)
      if (this.detailList.some((i) => !i.iComplete)) {
        this.$message({message: this.$t('maintenance_items_submitting'), type: 'warning'});
        return
      }
      let that = this;
      let formdata = new FormData();
      that.pics.forEach((pic) => {
        formdata.append(`files`, base64toFile(pic))
      });
      formdata.append('deviceMaintainId', this.$route.query.id)
      formdata.append('state', 1)
      formdata.append('remark', this.data.remark)
      let datas = formdata;
      that.form_data = datas;
      API.submitMaintain(datas).then(res => {
        if (res.message.success) {
          this.data.state = 1
          this.$message({message: this.$t('submit_success'), type: 'success'});
          this.loading = false
          this.getList()
          this.pictureUrl = this.data.pictureUrl.split(',')
          API.getMaintainDetaiList(this.$route.query.id).then(res => {
            this.detailList = res.message.data
            if ((this.$route.query.id) && (this.data.orderState === 1 && this.data.state === 0)) {
              this.detailList = this.detailList.map((x) => ({
                ...x,
                iComplete: Boolean(1),
              }))
              // this.pics = this.$store.state.pics2
            } else {
              this.detailList = this.detailList.map((x) => ({
                ...x,
                iComplete: Boolean(1),
              }))
              this.pics = []
            }
          })
          this.updateDevice()
          // this.$router.back();
        } else {
          this.$message({message: res.message.message, type: 'error'});
        }
      })

    },
    submitSwitch() {
      API.getPointListMaintain(this.$route.query.id).then(res => {
        if (res.message.success) {
          this.activeName = 'first',
          this.$message({message: this.$t('switching_success'),type: 'success',});
          if (this.$route.query.type === 'list') {
            this.$router.push({path: 'maintenDetail', query: {id: res.message.data.id,type: 'list'}});
          } else {
            this.$router.push({path: 'maintenDetail', query: {id: res.message.data.id}});
          }

          this.getList()
          this.updateDevice()
        }
      })
    },
    start1() {
      this.startMaintain()
    },
    // 点位详情
    handlePoint() {
      this.dialogTableVisiblePoint = true
      API.pointList({id: this.$route.query.id}).then((res) => {
        this.tableData = res.message.data
      })
      // Dialog({ message: '点位详情' });
    },
    handleVisible() {
      this.form.reason = ''
      this.$forceUpdate()
      this.dialogTableVisible = true
    },
    // 取消保养确定
    handleCancel() {
      if (this.form.reason === '') {
        this.$message({
          showClose: true,
          message: this.$t('enter_cancel_reason'),
          type: 'warning'
        });
        return
      }
      API.checkCancel({id: this.$route.query.id, reason: this.form.reason}).then(res => {
        if (res.message.success === true) {
          this.$message({
            showClose: true,
            message: this.$t('operation_successful'),
            type: 'success'
          });
          this.$router.push({path: 'maintainance'})
        }
      })
    },
    goDetail(item) {
      this.formInfo = item
      this.maintenanceInfo = true
    },
    //选择图片
    selectImage() {
      this.$refs.imgInput.click()
    },
    //移除图片
    removeImage(index) {
      // console.log("index:" + index);
      this.pics.splice(index, 1);
      //this.picsBlob.splice(index, 1);
    },
    //点击选择图片
    pickImage() {
      let input = this.$refs.imgInput
      if (input.files.length + this.pics.length > 9) {
        this.$message({
          message: this.$t('most_uploaded_img9'),
          // showClose: true,
          type: 'warning',
        });
        return;
      }
      // console.log(input.files)
      for (let i = 0; i < input.files.length; i++) {
        if(input.files[i]) {
          //在此限制图片的大小
          let imgSize = input.files[i].size;
          //35160  计算机存储数据最为常用的单位是字节(B)
          //在此处我们限制图片大小为2M
          if(imgSize>50*1024*1024){
            this.$message({
              message: this.$t('uploaded_image_large50M_please_reselect'),
              // showClose: true,
              type: 'warning',
            });
            return;
          }
          let reader = new FileReader
          reader.onload = e => {
            this.pics.push(e.target.result)
          }
          reader.readAsDataURL(input.files[i])
        }
      }
      this.$refs.imgInput.value = null;
    },
    handleClick(tab) {
      console.log(tab)
    }
  }
}
</script>
<style scoped lang="scss">
.widthTable {
  width: 1550px;
}
.page-header {
  border-bottom: 1px solid #E8EAEF;
  padding: 0px 0 20px;
  margin-bottom: 20px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
}
.el-table .hidden-row {
  display: none;
}
/*::v-deep .el-tabs__item.is-active {*/
/*  border-bottom: 2px solid #F3A100;*/
/*  padding: 0;*/
/*}*/
::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #F3A100;
}
.item2 {
  display: flex;
  /*flex-direction: column;*/
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  color: #222222;
  font-size: 14px;
}
.item2 div:first-child {
  width: 730px;
  display: flex;
  align-items: center;
}
.iComplete {
  display: inline-block;
  margin-left: 20px;
  word-wrap: break-word; /* 允许在边界内折行长单词 */
  overflow-wrap: break-word;
  width: 750px;
}
.pic-and-video-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

.btn {
  width: 90px;
  height: 90px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 0;
  padding: 0;
img {
  width: 20px;
  height: 20px;
}

span {
  margin-top: 5px;
  font-size: 13px;
  color: grey;
}
.closeVideo-btn {
  margin-top: 8px;
}
}
}

.preview {
  width: 90px;
  height: 90px;
  margin-right: 10px;
  position: relative;

img {
  position: absolute;
  width: 90px;
  height: 90px;
}

.van-btton {
  position: absolute;
  right: 0;
  font-size: 20px;
  background: #fff;
  padding: 0;
  color: #f56c6c;
}
}

.voice {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

img {
  width: 22px;
  height: 22px;
}
}

.video-label {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: lightgrey;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  font-size: 13px;
  color: grey;

.close-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

img {
  width: 20px;
  height: 20px;
}
}
}
.group {
  .url {
    display: inline-block;
    margin-left: 10px;
  }
  .item_img {
    width: 100px;
    overflow: hidden;
    //line-height: 100px;
    margin-bottom: 10px;
    text-align: center;

  }
}
::v-deep .el-dialog {
  border-radius: 4px;
}
.table {
  ::v-deep .van-dialog__header {
    font-weight: bold;
    color: #281315;
  }

  ::v-deep .el-table thead {
    color: #000;
    background-color: #000;
  }

  ::v-deep .el-table tr {
    color: #000;
  }

  ::v-deep .el-table__header-wrapper,
  .el-table__footer-wrapper {
    //border-bottom: 1px solid #f7b515;
  }

  ::v-deep .el-dialog__header {
    background: #fff;
  }

  ::v-deep .el-dialog__title {
    font-weight: bold;
  }


}
::v-deep .el-table th > .cell {
  color: #000;
}
.card {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #222222;
  .item {
    min-height: 30px;
    display: flex;
    flex-direction: row;
    margin: 5px 0;
    .title {
      width: 100px;
      font-size: 14px;
      margin-bottom: 0;
    }
    .content {
      flex: 1;
    }
  }
  .item2 {
    min-height: 30px;
    display: flex;
    flex-direction: row;
    margin: 5px 0;
    align-items: center;
    .item2-right {
      width: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  hr {
    border: 0;
    border-top: 1px solid #ccc;
  }
}
</style>
