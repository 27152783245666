<template>
  <div class="">
    <div class="page-container">
      <div class="">
        <el-row style="display: flex;justify-content: space-between;">
          <el-col :sm="24" class="col-right">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span><img src="@/assets/img/prepress-icon1.png" alt="">{{ $t('prepress_project') }}</span>
                <span style="float: right; padding: 3px 10px">
                  <el-input
                      class="input-search"
                      size="mini"
                      :placeholder="$t('enter_project_search')"
                      prefix-icon="el-icon-search"
                      clearable
                      @change="handleSearch"
                      v-model="projectQuery.name">
                  </el-input>
                  <i class="el-icon-plus" @click="handleAdd"></i>
                </span>
              </div>
              <div class="text item">
                <el-tree v-if="projectList.length > 0" :data="projectList" :props="defaultProps"
                         node-key="id"
                         check-strictly
                         ref="tree"
                         :default-checked-keys="[1,0]"
                         :show-checkbox="true"
                         @check="handleListItem">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <el-tooltip effect="dark" placement="top-start" :content="data.name" >
                      <span class="text">
                        <i class="el-icon-folder-opened" style="color: #F3A100"></i>
                        <span>{{ data.name }}</span>
                        <!--                      <span v-else><el-input v-model="input" size="mini" placeholder="请输入内容"></el-input></span>-->
                      </span>
                    </el-tooltip>
                    <span>
                      <span class="omit">
                        <i class="el-icon-more"></i>
                        <ul class="operate-ul">
                           <li class="li-text li1" @click.stop="handleProjectInfo(data)">
                             <div style="display: flex;">
                               {{ $t('project_information') }}
                             </div>
                           </li>
                           <li class="li-text li1" @click.stop="handleProjectDelete(data)">
                             <div style="display: flex;">
                               {{ $t('delete_project') }}
                             </div>
                           </li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </el-tree>
                <div class="no-data" v-else>
                  <img src="@/assets/img/no-date.png" alt="">
                  <div>{{ $t('noData') }}</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="24" class="col-right">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span><img src="@/assets/img/prepress-icon2.png" alt="">{{ $t('original_file') }}</span>
                <span style="float: right; padding: 3px 10px">
                  <el-input
                      class="input-search"
                      size="mini"
                      :placeholder="$t('enter_file_name_search')"
                      prefix-icon="el-icon-search"
                      clearable
                      @change="handleFile"
                      v-model="FileName">
                  </el-input>
                  <el-upload
                      ref="LoadFilePerson"
                      class="upload-demo"
                      :action="uploadingAPI"
                      :auto-upload="false"
                      :http-request="handleRequestPerson"
                      :on-change="handleLoadingPerson"
                      :show-file-list="false"
                      :before-upload="handleBeforeUpload"
                      :file-list="uploadFileList">
                    <img  class="uploading" src="@/assets/img/uploading.png" alt="">
                  </el-upload>

                </span>
              </div>
              <div class="text item">
                <el-tree v-if="fileData.length > 0" :data="fileData" :props="defaultProps" default-expand-all
                         @node-click="handleNodeClick">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <el-tooltip effect="dark" placement="top-start" :content="data.name" >
                      <span class="text"><i class="el-icon-folder-opened" style="color: #F3A100"></i>{{ data.name }}</span>
                    </el-tooltip>

                    <span>
                      <span class="omit">
                        <i class="el-icon-more"></i>
                        <ul class="operate-ul">
                           <li class="li-text li1" @click.stop="handleCheck(data)">
                             <div style="display: flex;">
                               {{ $t('view_original') }}
                             </div>
                           </li>
                           <li class="li-text li1" @click.stop="handleDownload(data)">
                             <div style="display: flex;">
                               {{ $t('download_original') }}
                             </div>
                           </li>
                          <li class="li-text li1" @click.stop="handleFileDelete(data)">
                             <div style="display: flex;">
                               {{ $t('delete_original') }}
                             </div>
                           </li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </el-tree>
                <div class="no-data" v-else>
                  <img src="@/assets/img/no-date.png" alt="">
                  <div>{{ $t('noData') }}</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="24" class="col-right">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span><img src="@/assets/img/prepress-icon3.png" alt="">{{ $t('process_file') }}</span>
                <span style="float: right; padding: 3px 0 3px 10px">
                  <el-input
                      size="mini"
                      class="input-search2"
                      :placeholder="$t('enter_file_name_search')"
                      clearable
                      prefix-icon="el-icon-search"
                      @change="handleResult"
                      v-model="ResultName">
                  </el-input>
                </span>
              </div>
              <div class="text item">
                <el-tree v-if="disposeData.length > 0" :data="disposeData" :props="defaultProps" default-expand-all
                         @node-click="handleNodeClick">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <el-tooltip effect="dark" placement="top-start" :content="!data.children ? node.label : false" v-if="!data.children">
                      <span class="text">
                          <i class="el-icon-folder-opened" style="color: #F3A100"></i>{{ node.label }}
                      </span>
                    </el-tooltip>
                    <span class="text" v-else>
                      <i class="el-icon-folder-opened" style="color: #F3A100"></i>{{ node.label }}
                    </span>
                    <!--                    <span>{{node}} </span>-->
                    <span v-if="!data.children">
                      <span class="omit" >
                        <i class="el-icon-more"></i>
                        <ul class="operate-ul">
                            <li class="li-text li1" @click.stop="handleCheckResult(data)">
                             <div style="display: flex;">
                               {{ $t('view_file') }}
                             </div>
                           </li>
                           <li v-if="data.downloadUrl" class="li-text li1" @click.stop="handleDownloadResult(data)">
                             <div style="display: flex;">
                               {{ $t('download_file') }}
                             </div>
                           </li>
                          <li class="li-text li1" @click.stop="handleDeleteResult(data)">
                             <div style="display: flex;">
                               {{ $t('delete_file') }}
                             </div>
                           </li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </el-tree>
                <div class="no-data" v-else>
                  <img src="@/assets/img/no-date.png" alt="">
                  <div>{{ $t('noData') }}</div>
                </div>
                <!--                <el-checkbox>-->
                <!--                  <div class="file-list">-->
                <!--                    <div class="file-text">印工社“蜂享优育“数字化网培平台介绍-1224_1704331018175_预检,pdf</div>-->
                <!--                  </div>-->
                <!--                </el-checkbox>-->
                <!--                <el-checkbox label="复选框"></el-checkbox>-->
                <!--                <el-checkbox label="复选框 C"></el-checkbox>-->
              </div>
            </el-card>
          </el-col>
          <el-col :sm="24">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span><img src="@/assets/img/prepress-icon4.png" alt="">{{ $t('selected_file') }}</span>
              </div>
              <div class="text item">
                <el-tree v-if="selectedFile.length > 0" :data="selectedFile" draggable :props="defaultProps" default-expand-all
                >
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                       <el-tooltip effect="dark" placement="top-start" :content="data.name" >
                        <span @click.stop="handleClick(data)" class="file-text"><img src="@/assets/img/file-icon2.png" alt="">{{ data.name }}</span>
                    </el-tooltip>
                    <span>
                      <span class="omit" style="display: inline-block" @click.stop="handleDelete(node,data)">
                        <i class="el-icon-close"></i>
                      </span>
                    </span>

                  </span>
                </el-tree>
                <div class="no-data" v-else>
                  <img src="@/assets/img/no-date.png" alt="">
                  <div>{{ $t('noData') }}</div>
                </div>
                <!--                <div class="file-list" v-for="(item, index) in selectedFile" :key="index">-->
                <!--                  <div class="file-text"><img src="@/assets/img/file-icon.png" alt="">{{ item.label }}</div>-->
                <!--                  <div @click.stop="handleDelete(index)"><i class="el-icon-close"></i></div>-->
                <!--                </div>-->
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;display: flex;justify-content: space-between;">
          <el-col :sm="24" style="width: 35%" class="col-right">
            <el-card class="box-card table-a file">
              <div slot="header" class="clearfix">
                <span><img src="@/assets/img/prepress-icon5.png" alt="">{{ $t('file_processing') }}</span>
              </div>
              <div class="text item">
                <ul class="file-ul">
                  <li @click.stop="handleLi(item)" v-for="(item, index) in fileList" :key="index">
                    <div class="item-li">
                      <div>
                        <!--:src="item.name === '预检' ? require('@/assets/img/conduct-img.png') : item.name === '陷印' ? require('@/assets/img/conduct-img2.png') : item.name === '对比' ? require('@/assets/img/conduct-img3.png') : item.name === 'Len To TIFF' ? require('@/assets/img/conduct-img4.png') : item.name === '审批' ? require('@/assets/img/conduct-img5.png') :item.name === '色彩转换' ? require('@/assets/img/conduct-img6.png') : item.name === 'RIP' ? require('@/assets/img/conduct-img7.png') :item.name === '智能柔印' ? require('@/assets/img/conduct-img8.png') : item.name === '混拼' ? require('@/assets/img/conduct-img9.png') : item.name === '3D光线追踪' ? require('@/assets/img/conduct-img10.png') : item.name === 'VDP' ? require('@/assets/img/conduct-img11.png') :item.name === '软包订单拼版' ? require('@/assets/img/conduct-img12.png') : item.name === '画质增强' ? require('@/assets/img/conduct-img13.png') :require('@/assets/img/conduct-img14.png')" -->
                        <img
                            :src="item.url"
                            alt="">
                      </div>
                      <div>
                        <div class="item-top">
                          <div>{{ item.name }}</div>
                          <div @click.stop="handleParameter(item)"><img src="@/assets/img/conduct-icon.png" alt=""></div>
                        </div>
                        <el-tooltip class="item" effect="dark" :content="item.info" placement="top-start">
                          <div class="item-text">
                            <div class="">{{ item.info }}</div>
                          </div>
                        </el-tooltip>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="24" style="width: 65%;">
            <el-card class="box-card table-a">
              <div slot="header" class="clearfix">
                <span><img src="@/assets/img/prepress-icon6.png" alt="">{{ $t('history') }}</span>
              </div>
              <div class="text item">
                <ul v-if="historyData.length > 0" v-infinite-scroll="load">
                  <li class="text-li" v-for="(item, index) in historyData" :key="index">
                    <el-row style="display: flex;align-items: center">
                      <el-col :sm="24" :md="2">{{ item.typeName }}</el-col>
                      <el-col :sm="24" :md="3" :style="{color: item.state === 0 ? '#F3A100' : item.state === 1 ? '#00BB00' : '#FF0000'}">{{ item.state === 0 ? $t('wait') : item.state === 1 ? $t('success') : $t('fail') }}</el-col>
                      <el-col :sm="24" :md="5">
                        <el-tooltip effect="dark" :content="item.fileName" placement="top-start">
                          <div class="text-li-text">{{ item.fileName }}</div>
                        </el-tooltip>
                      </el-col>
                      <el-col :sm="24" :md="5">
                        <div class="text-li-text2" v-for="(line,key) in item.resultName" :key="key">
                          <el-tooltip effect="dark" :content="line.label" placement="top-start">
                            <div class="text-li-text2">{{ line.label }}</div>
                          </el-tooltip>
                        </div>
                      </el-col>
                      <el-col :sm="24" :md="3">{{ item.time }}</el-col>
                      <el-col :sm="24" :md="4">{{ item.endTime }}</el-col>
                      <el-col :sm="24" :md="2" style="display: flex;justify-content: space-between">
                        <el-popover
                            placement="top-start"
                            width="600"
                            trigger="click">
                          <p>{{ $t('original_file2') }}</p>
                          <ul>
                            <li class="file-li" v-for="(itemInfo,info) in item.fileInfo" :key="info">
                              <div>{{ itemInfo.fileName }}</div>
                              <div>
                                <el-button @click.stop="handlePreview1(itemInfo)" type="text">{{ $t('preview') }}</el-button>
                                <el-button @click.stop="handleDownloadRaw(itemInfo)" type="text">{{ $t('download') }}</el-button>
                              </div>
                            </li>
                          </ul>
                          <p>{{ $t('generate_file') }}</p>
                          <ul>
                            <li class="file-li" v-for="(line,key) in item.resultName" :key="key">
                              <div>{{ line.label }}</div>
                              <div>
                                <el-button v-if="line.url" @click.stop="handlePreview2(line)" type="text">
                                  {{ $t('preview') }}</el-button>
                                <el-button v-if="line.downloadUrl" @click.stop="handleDownloadCreate(line)" type="text">
                                  {{ $t('download') }}</el-button>
                              </div>
                            </li>
                          </ul>

                          <el-button slot="reference" type="text">{{ $t('files') }}</el-button>
                        </el-popover>
                        <el-button type="text" @click.stop="handleCheckParameter(item)">{{ $t('parameter') }}</el-button>
                      </el-col>
                    </el-row>
                  </li>
                </ul>
                <div class="no-data" v-else>
                  <img src="@/assets/img/no-date.png" alt="">
                  <div>{{ $t('noData') }}</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <el-drawer
          :title="title"
          :visible.sync="drawer"
          :direction="direction"
          :before-close="handleClose">
        <!--        :wrapperClosable='false'-->
        <span>
           <el-form ref="parameter" label-width="130px" label-position="left" :model="parameter" :rules="rules">
              <div class="title">{{ $t('layout_parameter') }}</div>
              <el-form-item :label="item.label" :prop="'parameterList.' + index + '.value'" v-for="(item, index) in parameter.parameterList" :key="index" :rules="item.required === 1 ? rules.value: []">
                  <el-input :disabled="disabled" v-if="item.type === 1" v-model="item.value" clearable></el-input>
                  <el-input-number :disabled="disabled" v-if="item.type === 2" v-model="item.value" controls-position="right" clearable @change="handleChange" :min="1" :max="10000000"></el-input-number>
                  <el-input-number :disabled="disabled" v-if="item.type === 3" v-model="item.value" :precision="2" :step="0.01" clearable controls-position="right" @change="handleChange" :min="0.01" :max="10000000"></el-input-number>
                <el-select v-if="item.type === 4" v-model="item.value" :placeholder="$t('please_select')" clearable>
                  <el-option
                      :disabled="disabled"
                      v-for="line in item.info"
                      :key="line"
                      :label="line"
                      :value="line">
                  </el-option>
                </el-select>
                <el-radio :disabled="disabled" v-if="item.type === 5" v-for="(line, index1) in item.info" :key="index1" v-model="item.value" :label="line">{{ line }}</el-radio>
              </el-form-item>
             <div v-if="title !== $t('modify_parameter')" style="margin-bottom: 15px">
               <div class="title">{{ $t('selected_file') }}</div>
                <div v-if="drawerData.length > 0">
                  <div v-for="(drawer,drawerIndex) in drawerData" :key="drawerIndex">{{drawer.name}}</div>
                </div>
               <div v-else-if="selectedFile.length">
                 <div v-for="(selecte,selecteIndex) in selectedFile" :key="selecteIndex">{{selecte.name}}</div>
               </div>
             </div>
             <el-form-item v-if="!disabled">
              <el-button v-if="title=== $t('modify_parameter')" size="small" type="primary" @click.stop="handleSubmit()">{{ $t('submit') }}</el-button>
               <el-button v-else size="small" type="primary" @click.stop="handleSubmitFile()">{{ $t('submit') }}</el-button>
              <el-button size="small" @click="drawer = false">{{ $t('cancel') }}</el-button>
            </el-form-item>
        </el-form>
        </span>
      </el-drawer>
      <el-dialog :title="$t('create_project')" :visible.sync="dialogFormVisible" :close-on-click-modal="false" class="dialog-box"
                 width="25%" top="13%">
        <el-form :model="form" ref="form" :rules="rules">
          <el-form-item :label="$t('project_name')" prop="name">
            <el-input v-model="form.name" autocomplete="off" :placeholder="$t('enter_project_name')" show-word-limit clearable maxlength="30"></el-input>
          </el-form-item>
          <el-form-item :label="$t('project_type')" prop="type">
            <el-select v-model="form.type" :placeholder="$t('select_project_type')" clearable>
              <el-option v-for="(item, index) in type" :key="index" :label="item.name" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('project_client')" prop="customer">
            <el-input clearable maxlength="30" v-model="form.customer" show-word-limit autocomplete="off" :placeholder="$t('enter_project_client_name')"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="handleProjectAdd(1)">{{ $t('queding') }}</el-button>
          <el-button size="small" @click="dialogFormVisible = false">{{ $t('cancel') }}</el-button>
        </div>
      </el-dialog>
      <el-dialog :title="$t('project_information')" :visible.sync="dialogProjectInfo" :close-on-click-modal="false" class="dialog-info"
                 width="50%" top="10%">
        <el-form :model="form" ref="form" :rules="rules">
          <div>
            <el-form-item :label="$t('project_number')" class="form-item">
              <span>{{ form.code }}</span>
            </el-form-item>
          </div>
          <el-form-item :label="$t('project_name')" prop="name" class="form-item">
            <el-input v-model="form.name" autocomplete="off" :placeholder="$t('enter_project_name')" show-word-limit clearable maxlength="30"></el-input>
          </el-form-item>
          <el-form-item :label="$t('project_type')" prop="type" class="form-itemType">
            <el-select v-model="form.type" :placeholder="$t('select_project_type')" clearable>
              <el-option v-for="(item, index) in type" :key="index" :label="item.name" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('project_client')" prop="customer">
            <el-input v-model="form.customer" autocomplete="off" show-word-limit :placeholder="$t('enter_project_client_name')" clearable maxlength="30"></el-input>
          </el-form-item>
          <div>
            <el-form-item :label="$t('creation_time')" class="form-item">
              <span>{{ form.createDtate }}</span>
            </el-form-item>
            <el-form-item :label="$t('Modification_time')">
              <span>{{ form.updateDtate ? form.updateDtate : '--' }}</span>
            </el-form-item>
          </div>
          <el-form-item :label="$t('remark')" class="textarea" >
            <el-input v-model="form.remark" :rows="10" autocomplete="off" type="textarea"
                      clearable maxlength="200" show-word-limit
                      :placeholder="$t('Enter_Remarks')"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="handleProjectAdd(2)">{{ $t('queding') }}</el-button>
          <el-button size="small" @click="dialogProjectInfo = false">{{ $t('cancel') }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import API from '@/api';
import {getToken} from "../../../../../../userConsole/src/utils/auth";
import axios from 'axios';

// const pdfjsLib = require('pdfjs-dist')
// pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.13.216/build/pdf.worker.js'
// import pdfjsLib from 'pdfjs-dist/build/pdf'
export default {
  name: "project",
  data() {
    return {
      disabled: false,
      defaultExpandedKeys: [0,0],
      title: '', //文件处理抽屉题目
      dialogFormVisible: false,// 添加项目弹窗
      dialogProjectInfo: false, //项目信息弹窗
      projectQuery: {
        count: 100000000,
        page: 1,
        name: ''
      },
      projectList: [], // 项目列表
      projectId: '', // 项目id
      FileName: '',
      ResultName: '',
      type: [
        {
          label: 1,
          name: this.$t('tag')
        }, {
          label: 2,
          name: this.$t('card_box')
        }, {
          label: 3,
          name: this.$t('soft_pack')
        }, {
          label: 4,
          name: this.$t('other')
        }
      ],
      fileData: [], // 原稿文件
      disposeData: [], // 处理文件
      historyData:[], // 历史数据
      uploadFileList: [], // 上传文件
      ExslFilePerson: new FormData(),
      parameter: {
        parameterList: [], // 参数列表
      },
      input2: '',
      selectedFile: [],
      drawerData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      fileIds: [],
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          time: '2024-01-04 09:16:14'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄',
          time: '2024-01-04 09:16:14'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄',
          time: '2024-01-04 09:16:14'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄',
          time: '2024-01-04 09:16:14'
        }
      ],
      drawer: false,
      direction: 'rtl',
      options: [],
      fileListForm: {},
      fileList: [],
      form: {
        name: '',
        type: '',
        customer: '',
        remark: ''
      },
      recordListForm: {
        count: 10,
        page: 1,
      },
      labelName: '',
      labelNameArr: [],
      rules: {
        value: [{required: true, message: this.$t('enter_select'), trigger: 'change'}],
        name: [{ required: true, trigger: 'change', message: this.$t('enter_project_name') }],
        type: [{required: true, message: this.$t('select_project_type'), trigger: 'change'}],
        customer: [{ required: true, trigger: 'change', message: this.$t('enter_project_client_name') }]
      }
    };
  },
  created() {
    this.getList()
    this.getTypeList()
    this.getInterval()
    // this.getInterval2()
  },
  computed: {
    uploadingAPI() {
      return `${process.env.VUE_APP_API}prepressProject/addFile`;
    }
  },
  mounted() {
  },
  methods: {
    getList() {
      // 项目列表
      API.ProjectList(this.projectQuery).then(res => {
        this.projectList = res.message.data.list
        if (this.projectList.length > 0) {
          this.projectId = ''

          // this.projectList[0].
          this.getFile()
          this.getResult()
          this.getRecord()
          // this.$refs.tree.setCheckedKeys([this.projectId])
        }
      })

    },
    getInterval() {
      setInterval(() => {
        this.getRecord();
      }, 5000);
    },
    getInterval2() {
      setInterval(() => {
        this.getResult()
      }, 15000);
    },
    getTypeList() {
      // 文件处理列表
      API.ProjectTypeList().then(res => {
        this.fileList = res.message.data
      })
    },
    // 搜索
    handleSearch() {
      this.getList()
    },
    // 添加项目按钮
    handleAdd() {
      this.form = {
        name: '',
        type: '',
        customer: '',
        // remark: ''
      }
      this.dialogFormVisible = true
      this.$nextTick(() => {
        console.log(111)
        // 清除验证
        this.$refs.form.clearValidate();
      });
    },
// 添加项目
    handleProjectAdd(number) {
      this.$refs.form.validate((v) => {
        if (v) {
          API.ProjectAdd(this.form).then(res => {
            if (number === 1) {
              if (res.message.success) {
                this.$message.success(this.$t('created_success'));
                this.dialogFormVisible = false
                this.getList()
              }
            } else {
              if (res.message.success) {
                this.$message.success(this.$t('modified_succes'));
                this.dialogProjectInfo = false
                this.getList()
              }
            }
          })
        }
      })
    },
    // 原稿文件
    getFile() {
      API.fileList({id:this.projectId,name:this.FileName}).then(res => {
        this.fileData = res.message.data
      })
    },
    // 处理文件
    getResult() {
      // console.log(this.projectId)
      API.resultList({id:this.projectId,name:this.ResultName}).then(res => {
        this.disposeData = res.message.data
      })
    },
    // 历史记录文件
    getRecord() {
      API.recordList({projectId:this.projectId,page:this.recordListForm.page,count:this.recordListForm.count}).then(res => {
        this.historyData = res.message.data.list
      })
    },
    load() {
      this.recordListForm.count += 10
      this.getRecord()
    },
    // 搜索原稿文件
    handleFile() {
      this.getFile()
    },
    // 搜索处理文件
    handleResult() {
      this.getResult()
    },
    // 点击项目
    handleListItem(data, checked) {
      if (checked.checkedKeys.length > 0) {
        this.$refs.tree.setCheckedKeys([data.id])
        this.projectId = data.id
        this.selectedFile = []
        // this.projectName = data.name
        this.getFile()
        this.getResult()
        this.getRecord()
        return
      }
      if (checked.checkedKeys.length < 1){
        this.$refs.tree.setCheckedKeys([]);
        this.projectId = ''
        this.fileData = []
        this.disposeData = []
        this.historyData = []
      }
    },
    // 点击项目信息
    handleProjectInfo(data) {
      this.form = JSON.parse(JSON.stringify(data))
      this.dialogProjectInfo = true
      this.$nextTick(() => {
        // 清除验证
        this.$refs.form.clearValidate();
      });
    },
    // 项目删除
    handleProjectDelete(data) {
      this.$confirm(this.$t('project_deletion'), this.$t('prompt'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('device_confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      }).then(() => {
        API.deleteProject(data.id).then((response) => {
          if (response.message.success) {
            this.projectId = ''
            this.fileData = []
            this.disposeData = []
            this.historyData = []
            this.getList();
            this.$message({
              message: this.$t('Deleted_successfully'),
              showClose: true,
              type: 'success',
            });
          }
        });
      });
    },
    handleRequestPerson(file) {
      this.ExslFilePerson = new FormData();
      this.ExslFilePerson.append('file', file.file);
      this.ExslFilePerson.append('id', this.projectId);
    },
    handleBeforeUpload(file){
      console.log(file)
      // return new Promise((resolve, reject) => {
      //   const fileReader = new FileReader();
      //   fileReader.onload = () => {
      //     const typedarray = new Uint8Array(fileReader.result);
      //     pdfjsLib.getDocument(typedarray).promise.then(pdf => {
      //       const numPages = pdf.numPages;
      //       if (numPages > 1) {
      //         reject(new Error('PDF文件页数超过1页，不允许上传！'));
      //       } else {
      //         resolve();
      //       }
      //     }).catch(err => {
      //       reject(err);
      //     });
      //   };
      //   fileReader.readAsArrayBuffer(file.raw);
      // });
    },
    // 上传
    handleLoadingPerson(file) {
      // this.readPdf(file).then(pdf => {
      //   const pageCount = pdf;
      //   console.log('PDF页数:', pageCount);
      //   // 根据pageCount做后续处理
      // }).catch(error => {
      //   console.error('读取PDF出错:', error);
      // });
      const isType = file.name.split('.');
      const pdf = isType[isType.length - 1] === 'pdf';
      const PDF2 = isType[isType.length - 1] === 'PDF';
      const cf = isType[isType.length - 1] === 'cf2';
      const cf2 = isType[isType.length - 1] === 'CF2';
      if (!pdf && !PDF2 && !cf && !cf2) {
        this.$message.error(this.$t('upload_failedpdf_cf2'));
        this.uploadFileList = [];
        return;
      }
      const isLt100M = file.size / 1024 / 1024 < 1024;
      if (!isLt100M) {
        this.$message.error(this.$t('Upload_failed_file_size_exceeds'));
        this.uploadFileList = [];
        return;
      }
      if (file.name.length > 50) {
        this.$message.warning(this.$t('file_name_cannot_exceed_characters'));
        this.uploadFileList = [];
        return;
      }
      if (!this.projectId) {
        this.$message.warning(this.$t('select_project_first_before_uploading_file'));
        return;
      }
      // this.$refs.LoadFile.submit()
      // this.loading = true;
      // this.ExslFile = new FormData()
      this.$refs.LoadFilePerson.submit();
      axios
          .post(this.uploadingAPI, this.ExslFilePerson, {
            headers: {token: getToken(), 'Content-Type': 'application/octet-stream'},
          })
          .then((res) => {
            if (res.data.code === '200') {
              if (res.data.message.success) {
                // 原稿文件
                this.getFile()
                this.$message({
                  message: this.$t('upload_success'),
                  type: 'success',
                  duration: 2000,
                });
              }
            }
          });
      // this.sendProofing.proof = file.name
    },
    readPdf(pdfFile) {
      // 使用Promise封装PDF.js asyn API
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const pdf = pdfjsLib.getDocument(e.target.result).promise;
          pdf.then(doc => {
            doc.getMetadata().then(data => {
              console.log('PDF信息:', data);
              resolve(doc);
            }).catch(err => {
              reject(err);
            });
          }).catch(err => {
            reject(err);
          });
        };
        reader.readAsArrayBuffer(pdfFile);
      });
    },
    // 删除原稿文件
    handleFileDelete(data) {
      this.$confirm(this.$t('original_deletion'), this.$t('prompt'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('device_confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      }).then(() => {
        API.deleteFile(data.id).then((response) => {
          if (response.message.success) {
            if (this.selectedFile.length > 0) {
              if (this.selectedFile[0].id === data.id ) {
                this.selectedFile = []
              }
            }
            this.getFile();
            this.$message({
              message: this.$t('Deleted_successfully'),
              showClose: true,
              type: 'success',
            });
          }
        });
      });
    },
    // 查看处理文件
    handleCheckResult(data) {
      // if (data.label.split('.').pop() === 'png' || data.label.split('.').pop() === 'jpg' || data.label.split('.').pop() === 'jpeg'  || data.label.split('.').pop() === 'raw'  || data.label.split('.').pop() === 'gif'  || data.label.split('.').pop() === 'svg'){
      //   const encodedUrl = encodeURIComponent(data.url)
      //   console.log(encodedUrl)
      //   const routeData = this.$router.resolve({
      //     path:data.url,
      //     query: {
      //       url:encodedUrl , //括号内是要传递给新窗口的参数
      //       name: data.label
      //     },
      //     name: 'projectImg' // 目标路由的名称
      //   });
      //
      //
      //   // 在新标签页中打开URL
      //   window.open(`${decodeURIComponent(routeData.href)}`, '_blank');
      // } else {
      window.open(data.url);
      // }
    },
    // 下载处理文件
    handleDownloadResult(data) {
      axios({
        url: data.downloadUrl,
        method: 'GET',
        responseType: 'blob', // 响应类型为二进制流
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.label);
        document.body.appendChild(link);
        link.click();
      });
    },
    // 删除处理文件
    handleDeleteResult(data){
      this.$confirm(this.$t('original_deletion'), this.$t('prompt'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('device_confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      }).then(() => {
        API.deleteResult(data.id).then((response) => {
          if (response.message.success) {
            if (this.selectedFile.length > 0) {
              if (this.selectedFile[0].id === data.id ) {
                this.selectedFile = []
              }
            }
            this.getResult();
            this.$message({
              message: this.$t('Deleted_successfully'),
              showClose: true,
              type: 'success',
            });
          }
        });
      });
    },
    handleLi(value) {
      this.disabled = false
      this.fileListForm = JSON.parse(JSON.stringify(value))
      if (value.count === null) {
        this.$message({
          message: this.$t('feature_available'),
          showClose: true,
          type: 'warning',
        });
        return;
      }
      // 文件处理
      if (this.selectedFile.length > 0) {
        console.log(this.selectedFile)
        console.log(value.count)
        if (this.selectedFile.length !== value.count) {
          this.$message({
            message: this.$t('please_select')`${value.count}` + this.$t('files'),
            showClose: true,
            type: 'warning',
          });
        } else {
          this.labelName = ''
          this.labelNameArr = []
          this.selectedFile.forEach(item => {
            console.log(item.label)
            console.log(item.label.split('.'))
            console.log(this.labelName + item.label.split('.')[1])
            this.labelNameArr.push(item.label.split('.')[1])
            this.labelName = this.labelName + item.label.split('.')[1]
          })
          if (value.name === this.$t('imposition')) {
            console.log(this.selectedFile)
            console.log(this.labelName)
            if (this.labelName === 'pdfcf2' || this.labelName === 'cf2pdf') {
              this.title = value.name
              API.typeDetail(value.id).then(res => {
                this.parameter.parameterList = res.message.data
                if (this.parameter.parameterList.length > 0) {
                  this.parameter.parameterList.forEach(item => {
                    item.typeId = value.id
                    if (item.info) {
                      item.info = item.info.split(',')
                    }
                  })
                  this.$nextTick(() => {
                    // 清除验证
                    this.$refs.parameter.clearValidate();
                  });
                }
              })
              this.drawer = true
            } else {
              this.$message({
                message: this.$t('select_one_pdf_file_one_cf2'),
                showClose: true,
                type: 'warning',
              });
            }
          } else {
            console.log(this.labelNameArr)
            if (this.labelNameArr.includes('cf2')){
              console.log(9999)
              this.$message({
                message: this.$t('only_pdf_files_selected'),
                showClose: true,
                type: 'warning',
              });
              return
            }
            this.title = value.name
            API.typeDetail(value.id).then(res => {
              this.parameter.parameterList = res.message.data
              if (this.parameter.parameterList.length > 0) {
                this.parameter.parameterList.forEach(item => {
                  item.typeId = value.id
                  if (item.info) {
                    item.info = item.info.split(',')
                  }
                })
                this.$nextTick(() => {
                  // 清除验证
                  this.$refs.parameter.clearValidate();
                });
              }
            })
            this.drawer = true
          }

        }
      } else {
        this.$message({
          message: this.$t('please_select_file'),
          showClose: true,
          type: 'warning',
        });
      }

    },
    // 图标参数
    handleParameter(value) {
      this.disabled = false
      this.title = this.$t('modify_parameter')
      API.typeDetail(value.id).then(res => {
        this.parameter.parameterList = res.message.data
        if (this.parameter.parameterList.length > 0) {
          this.parameter.parameterList.forEach(item => {
            item.typeId = value.id
            if (item.info) {
              item.info = item.info.split(',')
            }
          })
          this.$nextTick(() => {
            // 清除验证
            this.$refs.parameter.clearValidate();
          });
        }
      })
      this.drawer = true
    },
    // 提交
    handleSubmit() {
      this.disabled = false
      const list  = []
      this.parameter.parameterList.forEach(item => {
        list.push({
          typeDetailId: item.id,
          typeId: item.typeId,
          value: item.value
        })
      })
      this.$refs.parameter.validate((v) => {
        if (v) {
          API.saveUserTypeDetail(list).then(res => {
            if (res.message.success) {
              this.$message.success(this.$t('submit_success'));
              this.drawer = false
            }
          })
        }
      })
    },
    // 处理文件提交
    handleSubmitFile() {
      console.log(111)
      console.log(this.selectedFile[0].id)
      this.disabled = false
      const result = this.parameter.parameterList.reduce((acc, curr, index) => {
        acc[curr.name] = curr.value;
        return acc;
      },{});
      this.fileIds = []
      for (let i = 0; i < this.selectedFile.length; i++) {
        this.fileIds.push({
          fileId:this.selectedFile[i].id,
          type: this.selectedFile[i].type
        })
      }
      console.log(this.fileIds)
      console.log(JSON.stringify(this.fileIds))
      //[{"fileId":"2132","type":1},{"fileId":"2132","type":1}]
      const form = {
        fileId: JSON.stringify(this.fileIds),
        info: JSON.stringify(this.parameter.parameterList),
        infoJson: JSON.stringify(result),
        projectId: this.projectId,
        typeId: this.fileListForm.id
      }
      console.log(form)
      if(form.fileId) {
        // console.log(form)
        this.$refs.parameter.validate((v) => {
          if (v) {
            API.projectSubmit(form).then(res => {
              if (res.message.success) {
                this.$message.success(this.$t('submit_success'));
                this.getRecord()
                this.drawer = false
              }
            })
          }
        })
      } else {
        this.$message.warning(this.$t('check_file_selected'));
      }
    },
    // 查看原稿
    handleCheck(data) {
      if (data.name.split('.').pop() === 'png' || data.name.split('.').pop() === 'jpg' || data.name.split('.').pop() === 'jpeg'  || data.name.split('.').pop() === 'raw'  || data.name.split('.').pop() === 'gif'  || data.name.split('.').pop() === 'svg'){
        const encodedUrl = encodeURIComponent(data.url)
        const routeData = this.$router.resolve({
          path:data.url,
          query: {
            url:encodedUrl , //括号内是要传递给新窗口的参数
            name: data.name
          },
          name: 'projectImg' // 目标路由的名称
        });


        // 在新标签页中打开URL
        window.open(`${decodeURIComponent(routeData.href)}`, '_blank');
      } else {
        window.open(data.url);
      }
    },
    // 下载原稿
    handleDownload(data) {
      axios({
        url: data.url,
        method: 'GET',
        responseType: 'blob', // 响应类型为二进制流
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.name);
        document.body.appendChild(link);
        link.click();
      });
    },
    handleClose(done) {
      this.drawerData = []
      done();
    },
    handleNodeClick(data) {
      if (data.name ) {
        if (this.selectedFile.length > 0) {
          // this.selectedFile = []
          const index = this.selectedFile.findIndex(item =>
              item.id === data.id // 假设每个对象都有一个唯一的'id'属性
          );
          if(index === -1) {
            data.label = data.name
            this.selectedFile.push(data)
          }
        } else {
          data.label = data.name
          this.selectedFile.push(data)
        }
      } else {
        if (!data.children) {
          // this.selectedFile = []
          const index = this.selectedFile.findIndex(item =>
              item.id === data.id
          );
          if(index === -1) {
            data.name = data.label
            this.selectedFile.push(data)
          }
        }
      }
      console.log(this.selectedFile)
    },
    // 已选文件查看
    handleClick(data) {
      console.log(data)
      // if (data.type === 1) {
      //   if (data.name.split('.').pop() === 'png' || data.name.split('.').pop() === 'jpg' || data.name.split('.').pop() === 'jpeg'  || data.name.split('.').pop() === 'raw'  || data.name.split('.').pop() === 'gif'  || data.name.split('.').pop() === 'svg'){
      //     const encodedUrl = encodeURIComponent(data.url)
      //     const routeData = this.$router.resolve({
      //       path:data.url,
      //       query: {
      //         url:encodedUrl , //括号内是要传递给新窗口的参数
      //         name: data.name
      //       },
      //       name: 'projectImg' // 目标路由的名称
      //     });
      //
      //
      //     // 在新标签页中打开URL
      //     window.open(`${decodeURIComponent(routeData.href)}`, '_blank');
      //   }
      // } else {
      window.open(data.url);
      // }
    },
    // 删除已选文件
    handleDelete(node,data) {
      // console.log(node,data)
      // for (const i in fileList) {
      //   if (fileList[i].uid === file.uid) {
      //     fileList.splice(i, 1)
      //   }
      // }
      let index = this.selectedFile.findIndex(item => item.id === data.id)
      if (index !== -1) {
        this.selectedFile.splice(index, 1)
      }

    },
    // 原文件预览
    handlePreview1(data) {
      if (data.fileName.split('.').pop() === 'png' || data.fileName.split('.').pop() === 'jpg' || data.fileName.split('.').pop() === 'jpeg'  || data.fileName.split('.').pop() === 'raw'  || data.fileName.split('.').pop() === 'gif'  || data.fileName.split('.').pop() === 'svg'){
        const encodedUrl = encodeURIComponent(data.url)
        const routeData = this.$router.resolve({
          path:data.url,
          query: {
            url:encodedUrl , //括号内是要传递给新窗口的参数
            name: data.fileName
          },
          name: 'projectImg' // 目标路由的名称
        });

        // 在新标签页中打开URL
        window.open(`${decodeURIComponent(routeData.href)}`, '_blank');
      } else {
        window.open(data.url);
      }
    },
    // 原文件下载
    handleDownloadRaw(item) {
      // window.open(item.downloadUrl);
      axios({
        url: item.downloadUrl,
        method: 'GET',
        responseType: 'blob', // 响应类型为二进制流
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', item.fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
    // 生成文件预览
    handlePreview2(data) {
      // if (data.label.split('.').pop() === 'png' || data.label.split('.').pop() === 'jpg' || data.label.split('.').pop() === 'jpeg'  || data.label.split('.').pop() === 'raw'  || data.label.split('.').pop() === 'gif'  || data.label.split('.').pop() === 'svg'){
      //   const encodedUrl = encodeURIComponent(data.url)
      //   const routeData = this.$router.resolve({
      //     path:data.url,
      //     query: {
      //       url:encodedUrl , //括号内是要传递给新窗口的参数
      //       name: data.label
      //     },
      //     name: 'projectImg' // 目标路由的名称
      //   });
      //
      //   // 在新标签页中打开URL
      //   window.open(`${decodeURIComponent(routeData.href)}`, '_blank');
      // } else {
      window.open(data.url);
      // }
    },
    // 生成文件下载
    handleDownloadCreate(item) {
      axios({
        url: item.downloadUrl,
        method: 'GET',
        responseType: 'blob', // 响应类型为二进制流
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', item.label);
        document.body.appendChild(link);
        link.click();
      });
    },
    // 历史记录参数
    handleCheckParameter(item) {
      console.log(item)
      this.drawerData = []
      this.title = this.$t('view_parameter')
      this.disabled = true
      this.parameter.parameterList = JSON.parse(item.info)
      this.drawer = true
      item.fileInfo.forEach(line => {
        this.drawerData.push({
          name:line.fileName
        })
      })

    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },

    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
    },
    // 数字输入
    handleChange() {

    }
  }
}
</script>

<style scoped lang="scss">
.section {
  padding: 10px;
}

::v-deep .el-card.is-always-shadow {
  border-radius: 10px 10px;
  font-size: 14px;

}

::v-deep .el-card__header {
  padding: 10px;
  background-color: #f0f0f0;
  border: 0;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
}

::v-deep .el-card__body {
  padding: 10px 15px;
  height: 35vh;
  overflow: auto;
  position: relative;
}

//.text {
//  padding-left: 10px;
//}
::v-deep .el-tree-node__content:first-child {
  //padding-left: 10px !important;
}

::v-deep .file .el-card__body {
  padding: 15px;
}

::v-deep .table-a .el-card__body {
  height: 40vh;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .text{
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

//::v-deep .el-tree-node__expand-icon.expanded {
//  display: none;
//}
//::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
//  display: none;
//}
.omit {
  display: none;
  position: relative;
}

::v-deep .el-tree-node__content:hover .omit {
  display: inline;
}

.omit .el-icon-more {
  padding: 10px 0;
}

.operate-ul {
  display: none;
  position: absolute;
  top: 20px;
  right: -10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: -2px 0 12px 2px rgba(147, 147, 147, 0.25);
}

.operate-ul li {
  padding: 10px;
  margin: 3px;
  font-size: 16px;
}

.operate-ul li:hover {
  background: #F6F6F6;
  color: #F3A100;
  border-radius: 8px;
}

::v-deep .el-tree-node__content:hover .omit:hover .operate-ul {
  display: inline;
}

.file-ul li {
  display: inline-block;
  border: 1px solid #D0D0D0;
  padding: 15px 15px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  width: 48.1%;
}

.file-ul li:hover {
  border: 1px solid #F3A100;
}

.file-ul li:nth-child(odd) {
  margin-right: 20px;
}

.file-ul li .item-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-ul li img {
  width: 57px;
  //margin-right: 10px;
}

.file-ul li .item-li .item-top {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  color: #3D3D3D;
  align-items: center;
}

.file-ul li .item-li .item-top img {
  width: 15px;
}

.file-ul li .item-li .item-text {
  width: 162px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  height: 40px;
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 12px;
  margin-top: 8px;
  color: #7C7C7C;
}

.col-right {
  margin-right: 20px;
}

::v-deep .el-drawer__body {
  padding: 0 15px;
}

.title {
  margin-bottom: 15px;
  font-size: 16px;
}

::v-deep .el-tooltip__popper.is-light {
  border: 0 !important;
  box-shadow: -2px 0 12px 2px rgba(147, 147, 147, 0.25) !important;
}

::v-deep .is-light {
  border: 0 !important;
  box-shadow: -2px 0 12px 2px rgba(147, 147, 147, 0.25) !important;
}

.file-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  div:first-child {
    width: 470px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

::v-deep .el-checkbox__label {
  width: 93%;
}

::v-deep .el-checkbox {
  display: block;
  margin-right: 0;
}

.file-list {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  align-items: center;

  img {
    width: 11px;
    margin-right: 12.5px;
  }
}

.file-list:hover {
  background-color: #F5F7FA;
}

.file-text {
  width: 310px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  img {
    width: 11px;
    margin-right: 12.5px;
  }
}

::v-deep .el-tree-node__expand-icon.expanded {
  display: none;
}

::v-deep .el-tree-node__expand-icon.is-leaf {
  display: none;
}

::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
  display: none;
}

::v-deep .el-card__header {
  padding: 0px 0 10px;
  background-color: #fff;
  border-bottom: 1px solid #E5E5E5;
  margin: 15px 15px 0;
}

::v-deep .el-card__header .clearfix {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .el-card__header .clearfix:first-child span {
  display: flex;
  align-items: center;
  font-size: 18px;

  i {
    font-size: 14px;
  }
}

::v-deep .el-card__header .clearfix span:nth-child(2) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

::v-deep .el-card__header .clearfix img {
  width: 15px;
  height: 17px;
  margin-right: 5px;
}

::v-deep .el-input--mini .el-input__inner {
  border: 0;
  background: #F6F6F6;
}

.input-search {
  width: 200px;
  margin-right: 15px;
}

.input-search2 {
  width: 230px;
  padding-right: 0;
}

.text-li {
  border-bottom: 1px solid #F1F1F1;
  padding: 0px 10px;
}

.text-li-text {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-li-text2 {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 0;
}
.no-data {
  margin-top: auto;
  text-align: center;
  font-size: 14px;
  color: #BFBFBF;
  position: absolute; /* 相对定位 */
  top: 50%; /* 将元素向上移动自身高度的一半 */
  left: 50%; /* 将元素向左移动自身宽度的一半 */
  transform: translate(-50%, -50%); /* 根据自身大小调整位置 */
  img {
    width: 68px;
  }
}

::v-deep .dialog-box .el-form-item {
  display: flex;

  .el-form-item__content {
    width: 75%;
  }

  .el-select {
    width: 100%;
  }
}

::v-deep .el-dialog {
  border-radius: 8px;

  .el-dialog__header {
    padding: 25px 25px 20px;
    font-size: 18px;
    border-bottom: 1px solid #F0F0F0;
    //font-weight: 500;
  }

  .el-dialog__body {
    padding: 40px 30px 30px;
  }

  .el-dialog__footer {
    text-align: center;
    padding-bottom: 50px;
  }

  .el-button {
    width: 80px;
  }
}

::v-deep .dialog-info {
  .el-dialog__body {
    padding: 20px 30px 30px;
  }

  .textarea {
    width: 100%;

    .el-form-item__content {
      width: 95%;
    }
  }

  .el-textarea {
    width: 100%;
  }

  .form-item {
    width: 300px;
  }

  .form-itemType {
    width: 320px;
  }
}

::v-deep .dialog-info .el-form-item {
  display: inline-block;

  .el-form-item__content {
    display: inline-block;
  }

  //.el-select {
  //  width: 100%;
  //}
}

.el-icon-folder-opened {
  margin-right: 10px;
}
.uploading {
  width: 15px;
  margin: 0!important;
  height: 13px!important;
}
::v-deep .el-drawer__header {
  font-size: 18px;
  color: #3D3D3D;
}
::v-deep .el-tree-node.is-expanded > .el-tree-node__children {
  display: inline;
}
</style>
